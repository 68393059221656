import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type {
	ClientEventValidator,
	ClientValidator,
} from '@atlassian/jira-issue-field-validation-handler/src/controllers/client-validation-handler/types.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import messages from './messages.tsx';

export const useAssigneeValidator = (
	fieldName: string,
	isRequired: boolean,
): ClientEventValidator<AggUser | null> => {
	const { formatMessage } = useIntl();

	const submit: ClientValidator<AggUser | null> = useCallback(
		(value: AggUser | null) => {
			if (isRequired && value === null) {
				return {
					type: 'error',
					message: formatMessage(messages.requiredErrorMessage, { fieldName }),
				};
			}

			return null;
		},
		[fieldName, formatMessage, isRequired],
	);

	return { submit };
};
