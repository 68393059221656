/**
 * @generated SignedSource<<969838a19b3fc3bf2f85584e265260e4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_issueViewCommonViews_ProgressSummary$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly statusField: {
        readonly status: {
          readonly statusCategory: {
            readonly statusCategoryId: string;
          };
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "src_issueViewCommonViews_ProgressSummary";
};
export type src_issueViewCommonViews_ProgressSummary$key = {
  readonly " $data"?: src_issueViewCommonViews_ProgressSummary$data;
  readonly " $fragmentSpreads": FragmentRefs<"src_issueViewCommonViews_ProgressSummary">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "src_issueViewCommonViews_ProgressSummary",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraStatusField",
              "kind": "LinkedField",
              "name": "statusField",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraStatus",
                  "kind": "LinkedField",
                  "name": "status",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "concreteType": "JiraStatusCategory",
                        "kind": "LinkedField",
                        "name": "statusCategory",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "statusCategoryId"
                          }
                        ]
                      },
                      "action": "LOG",
                      "path": "edges.node.statusField.status.statusCategory"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "cde4e56634b80995596d93d0f192d865";

export default node;
