/** @jsx jsx */
import type { PropsWithChildren, FC } from 'react';
import { jsx, cssMap } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIssueLayoutActivitySidePanel } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';

const ReplyCommentWrapper: FC<
	PropsWithChildren<{
		isFirst?: boolean;
		isLast?: boolean;
		hasReplyEditor?: boolean;
		addMargin?: boolean;
		showNextButton?: boolean;
	}>
> = ({
	children,
	isFirst = false,
	isLast = false,
	hasReplyEditor = false,
	addMargin = false,
	showNextButton = false,
}) => {
	const { isActivityInVerticalSidePanel } = useIssueLayoutActivitySidePanel();
	return (
		<section
			data-testid="issue-view-activity-comment.comment-reply-wrapper.reply-container"
			css={[
				sectionStylesOverride.common,
				!expVal('issue_create_from_comment_experiment', 'hasFloatingMenu', false) &&
					sectionStylesOverride.commonWithMaxWidth,
				// apply style to the first section
				isFirst && !isLast && sectionStylesOverride.firstSection,
				// apply style to sections b/w first and last section
				!isFirst && !isLast && sectionStylesOverride.InBetweenSections,
				// when last button + editor is opened [edge case] focussed comment case
				showNextButton && hasReplyEditor && sectionStylesOverride.InBetweenSections,
				// add margin to replies
				addMargin && sectionStylesOverride.lastSectionNotShowMore,
				isActivityInVerticalSidePanel && sectionStylesOverride.smallAvatarContainer,
			]}
		>
			{children}
		</section>
	);
};

const sectionStylesOverride = cssMap({
	common: {
		position: 'relative',
		'--commentLineWidth': '1px',
		'--commentSpacing': token('space.150'),
		'--commentLineColor': token('color.background.accent.gray.subtler'),
		'--commentInlineStart': 'calc(-26px - var(--commentLineWidth)/2)',
	},
	commonWithMaxWidth: {
		// 32 px for the padding given to the parent container (24+8)
		maxWidth: 'calc(100% - 32px)',
	},
	lastSectionNotShowMore: {
		marginBottom: token('space.250'),
	},
	smallAvatarContainer: {
		// 8px padding + 0.5px (to center align line of 1px) + 12px center align avatar (24px size) = 20.5px
		'--commentInlineStart': 'calc(-22px - var(--commentLineWidth)/2)',
		position: 'relative',
		'--commentLineWidth': '1px',
		'--commentSpacing': token('space.100'),
		'--commentLineColor': token('color.background.accent.gray.subtler'),
		'&::before': {
			height: '100%',
		},
	},
	// first of type and not last of type
	firstSection: {
		position: 'relative',
		'&::before': {
			content: '',
			position: 'absolute',
			height: 'calc(100% - var(--commentSpacing))',
			top: 'var(--commentSpacing)',
			width: 'var(--commentLineWidth)',
			insetInlineStart: 'var(--commentInlineStart)',
			backgroundColor: 'var(--commentLineColor)',
		},
	},
	InBetweenSections: {
		position: 'relative',
		'&::before': {
			content: '',
			position: 'absolute',
			bottom: token('space.0'),
			width: 'var(--commentLineWidth)',
			insetInlineStart: 'var(--commentInlineStart)',
			backgroundColor: 'var(--commentLineColor)',
			height: 'calc(100% - var(--commentSpacing))',
			top: 'var(--commentSpacing)',
		},
	},
});

export default ReplyCommentWrapper;
