import React, { useContext } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { fetchSortedCommentsRequest } from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIsModal, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useProjectKey,
	useProjectType,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FlagFieldPopupContentEntryPointContext } from '@atlassian/jira-issue-view-foundation/src/status/flagged-field-popup/flag-field-popup-content/context.tsx';
import { useFlaggedFieldPopupStore } from '../../../status/flagged-field-popup/state.tsx';
import type { ConnectedComponentProps, Props } from './types.tsx';
import useToggleFlagWithComment from './toggle-flag-with-comment.tsx';
import useToggleFlag from './hook.tsx';

export const FlagIssueActionView = ({ onClick, itemKey, label }: Props) => {
	const toggleFlag = useToggleFlag(itemKey, onClick);

	return (
		<DropdownItem
			key="flagIssue"
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.foundation.issue-actions.add-to-issue-dropdown-group.flag.flag-issue-action-view"
			onClick={toggleFlag}
		>
			{label}
		</DropdownItem>
	);
};

export const FlagIssueWithComment = ({
	onClick,
	itemKey,
	label,
	onAddComment,
}: ConnectedComponentProps) => {
	const toggleFlag = useToggleFlagWithComment(itemKey, onAddComment);

	const onAddFlag = () => {
		onClick?.(itemKey);
		toggleFlag();
	};

	return (
		<DropdownItem
			key="flagIssue"
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.foundation.issue-actions.add-to-issue-dropdown-group.flag.flag-issue-action-view"
			onClick={onAddFlag}
		>
			{label}
		</DropdownItem>
	);
};

const ConnectFlagIssueWithComment = connect(null, (dispatch) => ({
	onAddComment: () => {
		dispatch(fetchSortedCommentsRequest());
	},
}))(FlagIssueWithComment);

const FlagIssueWrapper = (props: Props) => {
	const isModalView = useIsModal();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const edition = useEdition(projectKey, true);
	if (
		edition !== PREMIUM_EDITION &&
		projectType !== SERVICE_DESK_PROJECT &&
		!isModalView &&
		expVal('jira_task_off_track_experiment', 'isEnabled', false)
	) {
		return <ConnectFlagIssueWithComment {...props} />;
	}
	return <FlagIssueActionView {...props} />;
};

const FlagIssue = componentWithFG(
	'task_off_track_experiment_kill_switch',
	FlagIssueWrapper,
	FlagIssueActionView,
);

export const FlagIssueWithCommentGA = ({ onClick, itemKey, label }: Props) => {
	const [, { openFlaggedFieldPopup }] = useFlaggedFieldPopupStore();

	const { entryPointActions } = useContext(FlagFieldPopupContentEntryPointContext);

	const onAddFlag = () => {
		onClick?.(itemKey);
		openFlaggedFieldPopup();
		entryPointActions.load();
	};

	return (
		<DropdownItem
			key="flagIssue"
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.foundation.issue-actions.add-to-issue-dropdown-group.flag.flag-issue-action-view"
			onClick={onAddFlag}
		>
			{label}
		</DropdownItem>
	);
};

const FlagIssueWrapperGA = (props: Props) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const edition = useEdition(projectKey, true);
	if (
		edition !== PREMIUM_EDITION &&
		projectType === SOFTWARE_PROJECT &&
		expVal('jira_task_off_track_experiment_new_design', 'isTaskOffTrackGA', false)
	) {
		return <FlagIssueWithCommentGA {...props} />;
	}
	return <FlagIssue {...props} />;
};

export const FlagIssueGA = componentWithFG(
	'task_off_track_experiment_kill_switch_new_design',
	FlagIssueWrapperGA,
	FlagIssue,
);

export default FlagIssueGA;
