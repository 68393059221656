import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { userPreferenceAPI } from '@atlassian/jira-user-preferences-services/src/utils/index.tsx';
import {
	CUSTOM_NAV_BAR_COLORS_FLAG_DISMISSED_KEY,
	CUSTOM_NAV_BAR_COLORS_SECTION_MESSAGE_DISMISSED_KEY,
} from '../common/constants.tsx';

export const setCustomNavBarFlagDismissed = async (value: boolean): Promise<void> => {
	try {
		await userPreferenceAPI.set(CUSTOM_NAV_BAR_COLORS_FLAG_DISMISSED_KEY, value);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		fireErrorAnalytics({
			error: err,
			meta: {
				id: 'setCustomNavBarFlagDismissed',
				packageName: 'jiraCustomNavBarColorsFlag',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
};

export const getCustomNavBarFlagDismissed = async (): Promise<boolean | null> => {
	try {
		return await userPreferenceAPI.get(CUSTOM_NAV_BAR_COLORS_FLAG_DISMISSED_KEY);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		// 404 is acceptable as it means user hasn't dismissed it yet
		if (err.statusCode !== 404) {
			fireErrorAnalytics({
				error: err,
				meta: {
					id: 'getCustomNavBarFlagDismissed',
					packageName: 'jiraCustomNavBarColorsFlag',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
		return null;
	}
};

export const getCustomNavBarMessageDismissed = async (): Promise<boolean | null> => {
	try {
		return await userPreferenceAPI.get(CUSTOM_NAV_BAR_COLORS_SECTION_MESSAGE_DISMISSED_KEY);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		// 404 is acceptable as it means user hasn't dismissed it yet
		if (err.statusCode !== 404) {
			fireErrorAnalytics({
				error: err,
				meta: {
					id: 'getCustomNavBarMessageDismissed',
					packageName: 'jiraCustomNavBarColorsFlag',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
		return null;
	}
};

export const setCustomNavBarMessageDismissed = async (value: boolean): Promise<void> => {
	try {
		await userPreferenceAPI.set(CUSTOM_NAV_BAR_COLORS_SECTION_MESSAGE_DISMISSED_KEY, value);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		fireErrorAnalytics({
			error: err,
			meta: {
				id: 'setCustomTopNavDarkModeDismissed',
				packageName: 'jiraCustomNavBarColorsFlag',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
};
