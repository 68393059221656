import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	productRequirementsTemplateName: {
		id: 'confluence-placeholder-template.product-requirements-template-name',
		defaultMessage: 'Product requirements',
		description: 'Name of the Product Requirements template',
	},
	projectPlanTemplateName: {
		id: 'confluence-placeholder-template.project-plan-template-name',
		defaultMessage: 'Project plan',
		description: 'Name of the Project Plan template',
	},
	knownErrorsTemplateName: {
		id: 'confluence-placeholder-template.known-errors-template-name',
		defaultMessage: 'Known errors',
		description: 'Name of the Known Errors template',
	},
	blankTemplateName: {
		id: 'confluence-placeholder-template.blank-template-name',
		defaultMessage: 'Blank',
		description: 'Name of the Blank template',
	},
	tryTemplateLozenge: {
		id: 'confluence-placeholder-template.try-template-lozenge',
		defaultMessage: 'Try template',
		description: 'A call to action to try a template',
	},

	requestToTryLozenge: {
		id: 'confluence-placeholder-template.request-to-try-lozenge',
		defaultMessage: 'Request to try',
		description: 'A call to action to request to try a template',
	},

	requestSentLozenge: {
		id: 'confluence-placeholder-template.request-sent-lozenge',
		defaultMessage: 'Request sent',
		description: 'The lozenge text after a request has been sent',
	},

	updatedJustNow: {
		id: 'confluence-placeholder-template.updated-just-now',
		defaultMessage: 'Updated just now',
		description: 'An updated timestamp for the dummy link',
	},

	headingTitle: {
		id: 'confluence-placeholder-template.new-heading',
		defaultMessage: 'Confluence content',
		description: 'New heading for Confluence dummy link',
	},

	pageLoadFailFlagTitle: {
		id: 'confluence-placeholder-template.dismiss-flag-title',
		defaultMessage: 'Template failed to load',
		description: 'Title of the flag that shows up when the embedded confluence page fails to load',
	},

	pageLoadFailFlagDescription: {
		id: 'confluence-placeholder-template.dismiss-flag-description',
		defaultMessage: 'Refresh the page and try again',
		description:
			'Description of the flag that shows up when the embedded confluence page fails to load',
	},

	pagePublishedFlagTitle: {
		id: 'confluence-placeholder-template.publish-flag-title',
		defaultMessage: 'Published!',
		description: 'Title of the flag that shows up when the embedded confluence page is published',
	},

	pagePublishedFlagDescription: {
		id: 'confluence-placeholder-template.publish-flag-description',
		defaultMessage: 'Your page has been published and linked to your Jira issue.',
		description:
			'Description of the flag that shows up when the embedded confluence page is published',
	},

	loadingTemplate: {
		id: 'confluence-placeholder-template.loading-template',
		defaultMessage: 'Loading template',
		description: 'Text shown to users when loading the embedded confluence page',
	},

	loadingTemplateLong: {
		id: 'confluence-placeholder-template.loading-template-long',
		defaultMessage: 'Almost there! Your page will be ready soon.',
		description: 'Text shown to users when loading the embedded confluence page',
	},

	requestSentFlagTitle: {
		id: 'confluence-placeholder-template.request-sent-flag-title',
		defaultMessage: 'Confluence template failed to load',
		description: 'Title of the flag that shows up when the user requests to try the template',
	},

	placeholderNudgeTitle: {
		id: 'confluence-placeholder-template.placeholder-nudge-title',
		defaultMessage: 'See how Confluence works with Jira',
		description: 'Title of the flag that shows up when the user is nudged to go to Confluence',
	},

	placeholderNudgeDescription: {
		id: 'confluence-placeholder-template.placeholder-nudge-description',
		defaultMessage:
			'76% of Jira customers said they shipped projects faster after adding Confluence.',
		description: 'Text of the flag that shows up when the user is nudged to go to Confluence',
	},

	placeholderNudgeCta: {
		id: 'confluence-placeholder-template.placeholder-nudge-cta',
		defaultMessage: 'Go to Confluence',
		description: 'Button text that takes users to Confluence',
	},
});
