import React, { type ChangeEvent, useCallback, useState, useMemo } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import SingleLineTextEditViewEntryPoint from '@atlassian/jira-issue-field-single-line-text-editview-full/src/entrypoint.tsx';
import { SingleLineTextReadView } from '@atlassian/jira-issue-field-single-line-text-readview-full/src/ui/single-line-text/index.tsx';
import type { singleLineText_issueFieldSingleLineText_SingleLineTextField_Mutation as SingleLineTextMutation } from '@atlassian/jira-relay/src/__generated__/singleLineText_issueFieldSingleLineText_SingleLineTextField_Mutation.graphql';
import type { singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditView_fragmentRef$key as SingleLineTextFragment } from '@atlassian/jira-relay/src/__generated__/singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditView_fragmentRef.graphql';
import type { singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef$key as SingleLineTextWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef.graphql';
import type {
	SingleLineTextInlineEditViewProps,
	SingleLineTextInlineEditViewWithIsEditableProps,
} from './types.tsx';
import { useSingleLineTextValidator } from './utils.tsx';

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `SingleLineTextInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [SingleLineTextInlineEditViewProps](./types.tsx)
 */
export const SingleLineTextInlineEditViewWithIsEditable = ({
	attributes,
	spacing = 'compact',
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditable,
	isEditing: startWithEditViewOpen,
	isRequired = false,
	hideEditButton,
	maxLength,
	onCancel,
	onEdit,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	readViewFitContainerHeight,
	renderCustomReadView,
	hasReadViewTruncateDisabled = false,
}: SingleLineTextInlineEditViewWithIsEditableProps) => {
	// #region Relay
	const data = useFragment<SingleLineTextWithIsEditableFragment>(
		graphql`
			fragment singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef on JiraSingleLineTextField {
				...singleLineText_issueFieldSingleLineTextReadviewFull_SingleLineTextReadView
				text
				id
				fieldId
				type
				name
			}
		`,
		fragmentRef,
	);

	const { id: uniqueFieldId, fieldId, type, text, name } = data;

	const [commit] = useMutation<SingleLineTextMutation>(graphql`
		mutation singleLineText_issueFieldSingleLineText_SingleLineTextField_Mutation(
			$input: JiraUpdateSingleLineTextFieldInput!
		) @raw_response_type {
			jira {
				updateSingleLineTextField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
					success
					errors {
						message
					}
					field {
						text
						id
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const initialValue = text ?? '';
	const [updatedValue, setUpdatedValue] = useState<string>(initialValue);
	// #endregion

	const { overriding } = fg('relay-migration-issue-fields-single-line-text-fg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useInlineEditFieldInjections()
		: { overriding: undefined };
	const isFieldEditable = useMemo(
		() =>
			overriding && fg('relay-migration-issue-fields-single-line-text-fg')
				? overriding.overrideIsEditable(isEditable)
				: isEditable,
		[isEditable, overriding],
	);
	const fieldName = useMemo(
		() =>
			overriding && fg('relay-migration-issue-fields-single-line-text-fg')
				? overriding.overrideLabel(name)
				: name,
		[name, overriding],
	);

	const validator = useSingleLineTextValidator(fieldName, isRequired, maxLength);

	/**
	 * This will execute the GraphQL mutation.
	 */

	const handleSubmit = useCallback(
		(value: string, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(value);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							text: value,
							operation: 'SET',
						},
					},
				},
				onCompleted(mutationData) {
					if (mutationData.jira?.updateSingleLineTextField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error: Error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateSingleLineTextField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								text: value,
							},
						},
					},
				},
			});
		},
		[commit, onSubmit, uniqueFieldId],
	);

	const {
		invalidMessage,
		isEditing,
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
		handleCopy,
		handleCut,
		handlePaste,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType: type,
		onCancel,
		onEdit,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		initialValue,
		updatedValue,
		validator,
		clipboardComponentType: 'text',
	});

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			handleChange(event.target.value);
		},
		[handleChange],
	);

	// #region Read view

	const renderReadView = () => (
		<SingleLineTextReadView fragmentRef={data} renderCustomReadView={renderCustomReadView} />
	);
	// #endregion

	// #region Edit view
	const getEditViewProps = (fieldProps: ValidationFieldProps) => ({
		...fieldProps,
		spacing,
		value: updatedValue,
		onChange,
		isInvalid: !!invalidMessage,
		autoFocus: true,
		ariaLabel: fieldName,
	});
	// #endregion

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupMinWidth="large"
			editViewEntryPoint={SingleLineTextEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			hideEditButton={hideEditButton}
			invalidMessage={invalidMessage ?? undefined}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			onCopy={handleCopy}
			onCut={handleCut}
			onPaste={handlePaste}
			hasReadViewTruncateDisabled={hasReadViewTruncateDisabled}
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [SingleLineTextInlineEditViewProps](./types.tsx)
 */
export const SingleLineTextInlineEditView = ({
	fragmentRef,
	...props
}: SingleLineTextInlineEditViewProps) => {
	const data = useFragment<SingleLineTextFragment>(
		graphql`
			fragment singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditView_fragmentRef on JiraSingleLineTextField {
				...singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<SingleLineTextInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
