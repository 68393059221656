import React, { useCallback, useState } from 'react';
import Button from '@atlaskit/button/new';
import { useFlagsService } from '@atlassian/jira-flags';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { usePriorityField } from '@atlassian/jira-issue-field-priority-chip/src/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { PrioritySuggestedAction } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/service/types.tsx';
import messages from './messages.tsx';

export const updatePriorityTestId = 'servicedesk-ai-context-common.ui.actions.update-priority';

export const UpdatePriority = ({ onSuccess, onFailure, context, onClick }: ActionProps) => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const [isProcessing, setIsProcessing] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag } = useFlagsService();
	const [, { saveById }] = usePriorityField({ issueKey });

	const handleSuccess = useCallback(() => {
		showFlag({
			type: 'success',
			title: formatMessage(messages.successTitle),
			description: formatMessage(messages.successDescription, {
				priority: context?.suggestion?.name,
			}),
			messageId: 'servicedesk-ai-context-common.ui.actions.update-priority.show-flag.success',
			messageType: 'transactional',
		});

		onSuccess && onSuccess();
	}, [showFlag, onSuccess, context, formatMessage]);

	const handleFailure = useCallback(
		(e: unknown) => {
			showFlag({
				type: 'error',
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.errorDescription),
				messageId: 'servicedesk-ai-context-common.ui.actions.update-priority.show-flag.error',
				messageType: 'transactional',
			});

			onFailure && onFailure(e);
		},
		[showFlag, onFailure, formatMessage],
	);

	const updatePriority = useCallback(async () => {
		setIsProcessing(true);
		try {
			await saveById({ id: context.suggestion.id }, null, createAnalyticsEvent({}));
			handleSuccess();
		} catch (e) {
			handleFailure(e);
		} finally {
			setIsProcessing(false);
			onClick?.();
		}
	}, [
		saveById,
		context.suggestion.id,
		createAnalyticsEvent,
		handleSuccess,
		handleFailure,
		onClick,
	]);

	return (
		<>
			{fg('context_panel_ui_consistency') ? (
				<Button
					spacing="compact"
					onClick={updatePriority}
					isLoading={isProcessing}
					testId={updatePriorityTestId}
				>
					{formatMessage(messages.acceptPriority)}
				</Button>
			) : (
				<Button onClick={updatePriority} isLoading={isProcessing} testId={updatePriorityTestId}>
					{formatMessage(messages.acceptPriority)}
				</Button>
			)}
		</>
	);
};

export type ActionProps = {
	onSuccess?: () => void;
	onFailure?: (error?: unknown) => void;
	context: PrioritySuggestedAction['context'];
	onClick?: () => void;
};
