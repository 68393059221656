import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useIsEmbedMode } from '@atlassian/jira-issue-view-embed-mode/src/index.tsx';
import isMobileUserAgent from '@atlassian/jira-common-is-mobile-user-agent/src/index.tsx';

export const useIsTargetedForCreateIssueFromCommentExp = () => {
	const { data } = useProjectContext();
	const isSoftwareProject = data?.projectType === SOFTWARE_PROJECT;
	const isBusinessProject = data?.projectType === CORE_PROJECT;
	const isEmbedMode = useIsEmbedMode();
	const isMobileUser = isMobileUserAgent();

	return (isSoftwareProject || isBusinessProject) && !isEmbedMode && !isMobileUser;
};
