/**
 * @generated SignedSource<<03f85f90e74f8660d7184570d2e5039d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_storageLimitsBanner_StorageLimitsBannerComponentWithQuery$data = {
  readonly tenantContexts: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly entitlement: {
        readonly experienceCapabilities: {
          readonly changeOffering: {
            readonly experienceUrl: string | null | undefined;
            readonly isAvailableToUser: boolean | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly subscription: {
          readonly accountDetails: {
            readonly invoiceGroup: {
              readonly experienceCapabilities: {
                readonly configurePayment: {
                  readonly isAvailableToUser: boolean | null | undefined;
                } | null | undefined;
              } | null | undefined;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly transactionAccount: {
          readonly experienceCapabilities: {
            readonly addPaymentMethod: {
              readonly isAvailableToUser: boolean | null | undefined;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "ui_storageLimitsBanner_StorageLimitsBannerComponentWithQuery";
};
export type ui_storageLimitsBanner_StorageLimitsBannerComponentWithQuery$key = {
  readonly " $data"?: ui_storageLimitsBanner_StorageLimitsBannerComponentWithQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_storageLimitsBanner_StorageLimitsBannerComponentWithQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "isAvailableToUser"
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "offeringKey"
    },
    {
      "kind": "RootArgument",
      "name": "productKey"
    }
  ],
  "kind": "Fragment",
  "name": "ui_storageLimitsBanner_StorageLimitsBannerComponentWithQuery",
  "selections": [
    {
      "args": [
        {
          "items": [
            {
              "kind": "Variable",
              "name": "cloudIds.0",
              "variableName": "cloudId"
            }
          ],
          "kind": "ListValue",
          "name": "cloudIds"
        }
      ],
      "concreteType": "TenantContext",
      "kind": "LinkedField",
      "name": "tenantContexts",
      "plural": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "hamsProductKey",
              "variableName": "productKey"
            }
          ],
          "kind": "LinkedField",
          "name": "entitlementInfo",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "entitlement",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "name": "transactionAccount",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "experienceCapabilities",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "addPaymentMethod",
                          "plural": false,
                          "selections": (v1/*: any*/)
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "name": "subscription",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "accountDetails",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "invoiceGroup",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "experienceCapabilities",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "name": "configurePayment",
                                  "plural": false,
                                  "selections": (v1/*: any*/)
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "name": "experienceCapabilities",
                  "plural": false,
                  "selections": [
                    {
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "offeringKey",
                          "variableName": "offeringKey"
                        }
                      ],
                      "kind": "LinkedField",
                      "name": "changeOffering",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "experienceUrl"
                        },
                        (v0/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "4bb6099913d0c82b477cf16017091a0f";

export default node;
