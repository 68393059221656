import React, { useCallback } from 'react';
import { createSelector } from 'reselect';
import { fg } from '@atlassian/jira-feature-gating';
import type { ChildIssuesLimitStatus } from '@atlassian/jira-issue-view-common-constants/src/child-issues.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { toIssueId, type IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useChildIssuesSortBy, useSortableIssueIds } from '../../../hooks/main.tsx';
import { getAllIssues } from '../../../state/entities/selectors.tsx';
import type { State } from '../../../state/types.tsx';
import { ActionsSortByMenu } from './main.tsx';

type ReduxStateProps = {
	allIssueIds: IssueId[];
};

type OwnProps = {
	childIssuesLimitStatus: ChildIssuesLimitStatus;
	isRankingEnabled?: boolean;
};

export type Props = OwnProps & ReduxStateProps;

export const ConnectedComponent = ({
	allIssueIds,
	childIssuesLimitStatus,
	isRankingEnabled,
}: Props) => {
	const [sortByValue, { setSortBy }] = useChildIssuesSortBy();
	const [, { setSortableIssueIds }] = useSortableIssueIds();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onSelect = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
		(value) => {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'dropdown',
				}),
				'childIssuesSortBy',
				{
					selection: value,
				},
			);

			setSortableIssueIds(allIssueIds);
			setSortBy(value);
		},
		[allIssueIds, createAnalyticsEvent, setSortBy, setSortableIssueIds],
	);

	return (
		<ActionsSortByMenu
			sortedBy={sortByValue}
			// @ts-expect-error - TS2322 - Type '(value: any) => void' is not assignable to type '(selection: SortingOption, event: UIAnalyticsEventInterface) => Promise<undefined> | undefined'.
			onSelect={onSelect}
			childIssuesLimitStatus={childIssuesLimitStatus}
			isRankingEnabled={fg('hide-rank-option-when-unavailable') ? isRankingEnabled : true}
		/>
	);
};

const toIssueIds = createSelector(getAllIssues, (issues) =>
	issues.map((issue) => toIssueId(issue.id)),
);

const ReduxConnectedComponent = connect(
	(state: State): ReduxStateProps => ({
		allIssueIds: toIssueIds(state),
	}),
	{},
)(ConnectedComponent);

export default ReduxConnectedComponent;
