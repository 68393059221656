import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { ASYNC_ERROR_BOUNDARY_ID, PACKAGE_NAME } from './constants.tsx';
import type { StorageLimitsBannerProps } from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyStorageLimitBanner = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-storage-limits-banner" */ './index.tsx'),
	{
		ssr: false,
	},
);

export const StorageLimitsBanner = (props: StorageLimitsBannerProps) => (
	<ErrorBoundary
		id={ASYNC_ERROR_BOUNDARY_ID}
		packageName={PACKAGE_NAME}
		teamName="growth-tako"
		render={() => <></>}
	>
		<Placeholder name="storage-limits-banner" fallback={<></>}>
			<LazyStorageLimitBanner {...props} />
		</Placeholder>
	</ErrorBoundary>
);
