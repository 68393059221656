import { useState, useRef, useEffect, useCallback, type RefObject } from 'react';

type Props = {
	hoverRef?: RefObject<HTMLElement>;
};

const getClosestTextElement = (el: Element | null): Element | undefined | null => {
	if (!el || !el.children || el.children.length === 0) return el;
	const elements = [...el.children];
	for (const e of elements) {
		if (e.clientWidth !== e.scrollWidth) {
			return e;
		}
		for (const child of e.children) {
			elements.push(child);
		}
	}
	return el;
};

export const useShowHoverPopover = (props?: Props) => {
	const { hoverRef } = props || {};
	const [isHoverPopoverVisible, setIsHoverPopoverVisible] = useState(false);
	const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

	useEffect(() => {
		return () => {
			timeoutRef.current && clearTimeout(timeoutRef.current);
		};
	}, []);

	const setShowHoverPopover = () => {
		timeoutRef.current = setTimeout(() => setIsHoverPopoverVisible(true), 200);
	};

	const handleShowHoverPopover = useCallback(() => {
		if (!hoverRef) {
			setShowHoverPopover();
			return;
		}

		const target = getClosestTextElement(hoverRef.current);

		const clientWidth = target?.clientWidth || 0;
		const scrollWidth = target?.scrollWidth || 0;

		if (scrollWidth > clientWidth) {
			setShowHoverPopover();
		}
	}, [hoverRef]);

	const handleRemoveHoverPopover = useCallback(() => {
		timeoutRef.current && clearTimeout(timeoutRef.current);
		setIsHoverPopoverVisible(false);
	}, []);

	return {
		isHoverPopoverVisible,
		hoverAttributes: {
			onMouseEnter: handleShowHoverPopover,
			onMouseLeave: handleRemoveHoverPopover,
			onFocus: handleShowHoverPopover,
			onBlur: handleRemoveHoverPopover,
		},
	};
};
