import { useCallback, useMemo, useState } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	ClientValidationState,
	Actions,
	ClientEventValidator,
	ValidationStatus,
	ClientValidationEvent,
} from './types.tsx';

/** Return an `ValidationStatus` for a given validation message. */
const getValidationStatus = (message: ClientValidationState) =>
	message ? message.type : 'success';

// Priority order for ValidationStatus. Higher value = higher priority.
const invalidStatusPriority: Record<ValidationStatus, number> = {
	success: 0,
	warning: 1,
	error: 2,
};

/**
 * Return the higher priority message of `messageA` and `messageB`. If the priority is equal then `messageA` will be
 * returned.
 */
const getHigherPriorityMessage = (
	messageA: ClientValidationState,
	messageB: ClientValidationState,
): ClientValidationState => {
	const statusA = getValidationStatus(messageA);
	const statusB = getValidationStatus(messageB);
	return invalidStatusPriority[statusA] >= invalidStatusPriority[statusB] ? messageA : messageB;
};

/**
 * Return a `[ClientValidationState, Actions]` tuple where `ClientValidationState` represents whether the user input is
 * valid/invalid, while `Actions` is a collection of callbacks to validate the input.
 *
 * @param validator Validator functions to run when specific form events occur, i.e. input `change` and form `submit`.
 */
export const useClientValidationHandler = <FieldValue,>(
	validator?: ClientEventValidator<FieldValue>,
): [ClientValidationState, Actions<FieldValue>] => {
	const [invalidMessage, setInvalidMessage] = useState<ClientValidationState>(null);
	const [invalidEvent, setInvalidEvent] = useState<ClientValidationEvent>(null);
	const { change, submit } = validator ?? {};

	const validateChange = useCallback(
		(value: FieldValue) => {
			// If no change validator is defined then the value is considered valid
			const message = change ? change(value) : null;
			if (fg('jsc_inline_editing_field_refactor')) {
				setInvalidEvent(
					message
						? {
								action: 'change',
								state: message,
							}
						: null,
				);
			} else {
				setInvalidMessage(message);
			}
			return getValidationStatus(message);
		},
		[change],
	);

	const validateSubmit = useCallback(
		(value: FieldValue) => {
			// If no submit validator is defined then the value is considered valid
			const message = submit ? submit(value) : null;
			let higherPriorityMessage;
			if (fg('jsc_inline_editing_field_refactor')) {
				const changeInvalidMessage = invalidEvent?.action === 'change' ? invalidEvent.state : null;
				// Only update the change event invalid state if a higher priority message is returned
				higherPriorityMessage = getHigherPriorityMessage(message, changeInvalidMessage);
				setInvalidEvent(
					higherPriorityMessage
						? {
								action: 'submit',
								state: higherPriorityMessage,
							}
						: null,
				);
			} else {
				// Only update the existing invalid state if a higher priority message is returned
				higherPriorityMessage = getHigherPriorityMessage(message, invalidMessage);
				setInvalidMessage(higherPriorityMessage);
			}

			return getValidationStatus(higherPriorityMessage);
		},
		[invalidEvent, invalidMessage, submit],
	);

	const clearValidation = useCallback(() => {
		if (fg('jsc_inline_editing_field_refactor')) {
			setInvalidEvent(null);
		} else {
			setInvalidMessage(null);
		}
	}, []);

	const actions = useMemo(
		() => ({
			validateChange,
			validateSubmit,
			clearValidation,
		}),
		[clearValidation, validateChange, validateSubmit],
	);
	if (fg('jsc_inline_editing_field_refactor')) {
		return [invalidEvent?.state ?? null, actions];
	}
	return [invalidMessage, actions];
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ClientEventValidator, ClientValidator, ClientValidationState } from './types';
