import {
	CHILDREN_ISSUES,
	EPIC_ISSUES,
	PORTFOLIO_CHILD_ISSUES,
	SUBTASKS,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import {
	CLASSIC_PORTFOLIO_CHILDREN,
	CLASSIC_PROJECT_EPIC_CHILDREN,
	CLASSIC_SUBTASKS,
	CHILDREN_ISSUES_PANEL,
} from '../model/types.tsx';

/**
 * Maps child issues panel types to its content item configuration.
 */
export const CHILD_ISSUES_KEY_MAP = {
	[CHILDREN_ISSUES_PANEL]: CHILDREN_ISSUES,
	[CLASSIC_PROJECT_EPIC_CHILDREN]: EPIC_ISSUES,
	[CLASSIC_SUBTASKS]: SUBTASKS,
	[CLASSIC_PORTFOLIO_CHILDREN]: PORTFOLIO_CHILD_ISSUES,
} as const;
