import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
// Helper functions to get the checkbox value in sprint edit component from local storage
const SHARED_RESTRICT_SPRINTS_CACHE_KEY = 'edit_sprint_cache_value';

// TODO Add an app prefix to separate apps
const storageProvider = createLocalStorageProvider('');

const getRestrictSprintsValueFromLocalStorage = (key: string): boolean => {
	let value;
	try {
		if (fg('jfp-vulcan-browser-storage-migration')) {
			value = storageProvider.get(key);
		} else {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			value = window.localStorage?.getItem(key);
		}
		return value ? JSON.parse(value) : true;
	} catch {
		return true;
	}
};

const setRestrictSprintsValueInLocalStorage = (key: string, value: boolean) => {
	try {
		const valueToStore = String(value);
		if (fg('jfp-vulcan-browser-storage-migration')) {
			storageProvider.set(key, valueToStore);
		} else {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.localStorage.setItem(key, valueToStore);
		}
	} catch {
		// do nothing
	}
};

/**
 * Retrieve the default restricts sprints checkbox state for the sprint edit component. This value uses a common local
 * storage key that is shared among multiple consumers of the sprint edit component throughout Jira.
 */
export const getSharedRestrictSprintsValueFromLocalStorage = (): boolean =>
	getRestrictSprintsValueFromLocalStorage(SHARED_RESTRICT_SPRINTS_CACHE_KEY);

/**
 * Set the default restricts sprints checkbox state for the sprint edit component into local storage. This value uses a
 * common local storage key that is shared among multiple consumers of the sprint edit component throughout Jira.
 */
export const setSharedRestrictSprintsValueInLocalStorage = (value: boolean) =>
	setRestrictSprintsValueInLocalStorage(SHARED_RESTRICT_SPRINTS_CACHE_KEY, value);
