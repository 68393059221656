// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import {
	getCustomNavBarFlagDismissed,
	getCustomNavBarMessageDismissed,
	setCustomNavBarFlagDismissed,
	setCustomNavBarMessageDismissed,
} from '../services/index.tsx';
import type { State } from './types.tsx';

export const actions = {
	initialFetch:
		(): Action<State> =>
		async ({ setState, getState }) => {
			const { isLoading } = getState();
			if (!isLoading) {
				return;
			}
			setState({
				isLoading: false,
			});
			const [flagDismissedRes, sectionMessageDismissedRes] = await Promise.allSettled([
				getCustomNavBarFlagDismissed(),
				getCustomNavBarMessageDismissed(),
			]);
			setState({
				flagIsDismissed:
					flagDismissedRes.status === 'fulfilled' ? flagDismissedRes.value || false : false,
				sectionMessageIsDismissed:
					sectionMessageDismissedRes.status === 'fulfilled'
						? sectionMessageDismissedRes.value || false
						: false,
			});
		},
	setIsLoadingWithRelay:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isLoadingWithRelay: true,
				isLoading: false,
			});
		},
	setFlagIsDismissed:
		(data = true, setRemote = true): Action<State> =>
		({ setState }) => {
			setState({
				flagIsDismissed: data,
			});
			if (setRemote) {
				setCustomNavBarFlagDismissed(data);
			}
		},
	setMessageIsDismissed:
		(data = true, setRemote = true): Action<State> =>
		({ setState }) => {
			setState({
				sectionMessageIsDismissed: data,
			});
			if (setRemote) {
				setCustomNavBarMessageDismissed(data);
			}
		},
} as const;

export type Actions = typeof actions;
