import {
	BAD_REQUEST,
	UNAUTHORIZED,
	NOT_FOUND,
	TOO_MANY_REQUESTS,
} from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import {
	TASK_SUCCESS,
	TASK_ABORT,
	TASK_FAIL,
} from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	sendExperienceAnalytics as sendLegacyExperienceAnalytics,
	type ExperienceDescription,
} from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';

type ErrorType = {
	statusCode?: number;
} | null;
type GetExperienceDescription = (arg1: {
	wasSuccessful: boolean;
	statusCode?: number;
}) => ExperienceDescription;
type Options = {
	getExperienceDescription: GetExperienceDescription;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error?: any;
};

const successfulErrorStatusCodes = [BAD_REQUEST, UNAUTHORIZED, NOT_FOUND];
const abortableStatusCodes = [TOO_MANY_REQUESTS];

const classificationPayload = {
	ANALYTICS_SUCCESS: {
		wasSuccessful: true,
		action: TASK_SUCCESS,
	},
	ANALYTICS_ABORT: {
		wasSuccessful: true,
		action: TASK_ABORT,
	},
	ANALYTICS_FAIL: {
		wasSuccessful: false,
		action: TASK_FAIL,
	},
} as const;

const isErrorOmitted = (error: ErrorType) => error === null;

const isStatusCodeConsideredSuccessful = (error: ErrorType) =>
	// @ts-expect-error - TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
	successfulErrorStatusCodes.includes(error?.statusCode);

const isStatusCodeAbortable = (error: ErrorType) =>
	// @ts-expect-error - TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
	abortableStatusCodes.includes(error?.statusCode);

const reduceClassificationOld = (error: ErrorType) => {
	if (isErrorOmitted(error) || isStatusCodeConsideredSuccessful(error)) {
		return 'ANALYTICS_SUCCESS';
	}

	if (isStatusCodeAbortable(error)) {
		return 'ANALYTICS_ABORT';
	}

	return 'ANALYTICS_FAIL';
};

const reduceClassificationNew = (error: ErrorType) => {
	if (isErrorOmitted(error)) {
		return 'ANALYTICS_SUCCESS';
	}

	return 'ANALYTICS_FAIL';
};

const getReduceClassification = () =>
	fg('bento_send_4xx_to_task_fail_gate_1') ? reduceClassificationNew : reduceClassificationOld;

/**
 * Augments calls to sendExperienceAnalytics within the @atlassian/jira-issue-view-analytics
 * package.
 *
 * If provided with a client fetch error (@atlassian/jira-fetch), sendExperienceAnalytics will not
 * be called.
 *
 * If provided with an abortable error (TOO_MANY_REQUESTS) sendExperienceAnalytics will be called
 * with TASK_ABORT. If provided with a "successful" error (BAD_REQUEST, UNAUTHORIZED, NOT_FOUND),
 * sendExperienceAnalytics will be called with TASK_SUCCESS
 *
 * @param {GetExperienceDescription} options.getExperienceDescription A callback returning the
 *      experience description. `getExperienceDescription` is provided the wasSuccessful to
 *      optionally log and/or alter the experience description
 * @param {any} options.error
 */
export const sendExperienceAnalytics = ({
	getExperienceDescription,
	error = null,
}: Options): void => {
	// We intentionally do not capture analytics during a client a fetch error. However, we do
	// return the successful state so external flows can record logs
	if (
		!isErrorOmitted(error) &&
		isClientFetchError(error) &&
		!fg('bento_send_4xx_to_task_fail_gate_1')
	) {
		getExperienceDescription({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			wasSuccessful: isErrorOmitted(error as ErrorType),
			statusCode: error?.statusCode,
		});
		return;
	}

	const reduceClassification = getReduceClassification();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const classification = reduceClassification(error as ErrorType);
	const { action, wasSuccessful } = classificationPayload[classification];

	sendLegacyExperienceAnalytics({
		...getExperienceDescription({
			wasSuccessful,
			statusCode: error?.statusCode,
		}),
		action,
	});
};
