import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import type { RecordSourceSelectorProxy } from 'relay-runtime';
import { useIntl } from '@atlassian/jira-intl';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import type { updateHideDoneStateMutation } from '@atlassian/jira-relay/src/__generated__/updateHideDoneStateMutation.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import type { updateHideDoneState$key } from '@atlassian/jira-relay/src/__generated__/updateHideDoneState.graphql.ts';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import { createJiraIssueSearchViewAri } from '../../../../../configurable-child-issues-panel/src/utils/index.tsx';
import messages from './messages.tsx';

export const useUpdateHideDoneStateMutation = () => {
	const cloudId = useCloudId();
	const activationId = useActivationId();
	const issueSearchViewResultAri = createJiraIssueSearchViewAri({ cloudId, activationId });

	const [commitMutation] = useMutation<updateHideDoneStateMutation>(graphql`
		mutation updateHideDoneStateMutation($viewId: ID!, $isHideDoneEnabled: Boolean!)
		@raw_response_type {
			jira {
				updateIssueSearchHideDonePreference(viewId: $viewId, isHideDoneEnabled: $isHideDoneEnabled)
					@optIn(to: "JiraUpdateIssueSearchHideDonePreference") {
					success
					isHideDoneEnabled
				}
			}
		}
	`);

	const { showFlag } = useFlagsService();
	const { formatMessage } = useIntl();

	const hideDonePreferenceUpdater = useCallback(
		(
			store: RecordSourceSelectorProxy,
			hasHideDonePreference: boolean,
			rootRelayFragment: MainIssueAggQueryRelayFragment | null,
		) => {
			// eslint-disable-next-line @atlassian/relay/must-use-inline
			const UpdateHideDoneStateFragment = () => graphql`
				fragment updateHideDoneState on JiraUserPreferences @updatable {
					isIssueViewHideDoneChildIssuesFilterEnabled
				}
			`;

			const { updatableData } = store.readUpdatableFragment<updateHideDoneState$key>(
				UpdateHideDoneStateFragment(),
				// TODO: Resolve type error in the following line where Property '$updatableFragmentSpreads' is missing but required in type 'view_hideDoneChildIssuesUserPreference_updatable$key'
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				rootRelayFragment?.jira?.userPreferences as unknown as updateHideDoneState$key,
			);
			if (updatableData) {
				updatableData.isIssueViewHideDoneChildIssuesFilterEnabled = hasHideDonePreference;
			}
		},
		[],
	);

	const updateHideDone = useCallback(
		({
			isHideDoneEnabled,
			rootRelayFragment,
		}: {
			isHideDoneEnabled: boolean;
			rootRelayFragment: MainIssueAggQueryRelayFragment | null;
		}) => {
			commitMutation({
				variables: {
					viewId: issueSearchViewResultAri,
					isHideDoneEnabled,
				},
				updater(store) {
					hideDonePreferenceUpdater(store, isHideDoneEnabled, rootRelayFragment);
				},
				optimisticUpdater(store) {
					hideDonePreferenceUpdater(store, isHideDoneEnabled, rootRelayFragment);
				},
				onError: (error) => {
					fireErrorAnalytics({
						meta: {
							id: 'jiv-child-issue-table-hide-done-toggle',
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						error,
					});

					showFlag({
						messageId:
							'issue-view-common-views.child-issues-panel.heading.meatballs.services.update-hide-done-state.show-flag.error.update-hide-done-toggle.show-flag.error',
						messageType: 'transactional',
						id: 'hideDoneToggleError',
						title: formatMessage(messages.hideDoneErrorFlagTitle),
						description: formatMessage(messages.hideDoneErrorFlagDescription),
						isAutoDismiss: true,
						type: 'error',
					});
				},
			});
		},
		[commitMutation, formatMessage, hideDonePreferenceUpdater, issueSearchViewResultAri, showFlag],
	);

	return updateHideDone;
};
