import React from 'react';
import { styled } from '@compiled/react';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	FireUiAnalytics,
	ContextualAnalyticsData,
	useAnalyticsEvents,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useIsProductUsingCcp } from '@atlassian/jira-billing/src/controllers/billing-system/use-is-product-using-ccp.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import messages from './messages.tsx';

const STANDARD_PLAN_STORAGE_LIMIT = 250;

type BannerContentProps = {
	isFullWidth: boolean;
	upgradeLinkWithOrigin: string | undefined;
	storageLimit: number;
	usedStorageRatio: number;
};

export function BannerContent({
	isFullWidth,
	upgradeLinkWithOrigin,
	storageLimit,
	usedStorageRatio,
}: BannerContentProps) {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isUsingCcp = useIsProductUsingCcp(SOFTWARE_PROJECT);

	const { formatMessage } = useIntl();

	const bannerText =
		usedStorageRatio < 1
			? formatMessage(messages.storageLimits90PercentBannerText, {
					freePlanStorageLimit: storageLimit,
				})
			: formatMessage(messages.storageLimitsBannerText, {
					freePlanStorageLimit: storageLimit,
				});

	const handleOpenBillingPage = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'link',
			}),
			'storageLimitsBannerLink',
		);
	};

	return (
		<ContextualAnalyticsData
			attributes={{
				billingSourceSystem: isUsingCcp ? 'CCP' : 'HAMS',
				usedStorage: storageLimit * usedStorageRatio,
			}}
		>
			<Box xcss={isFullWidth ? fullWidthBannerWrapperStyles : partialWidthBannerWrapperStyles}>
				<FireUiAnalytics
					action="viewed"
					actionSubject="banner"
					actionSubjectId="storageLimitsBanner"
				/>
				<BannerContainer data-testid="storage-limits-banner.ui.storage-limits-banner">
					<WarningIcon
						spacing="spacious"
						label=""
						color={token('color.icon.warning')}
						LEGACY_secondaryColor={token('color.background.warning')}
					/>
					<BannerText
						data-testid="storage-limits-banner.ui.storage-limits-banner-text"
						title={bannerText}
					>
						{bannerText}
					</BannerText>

					<BannerLink
						onClick={handleOpenBillingPage}
						href={upgradeLinkWithOrigin}
						target="_blank"
						data-testid="storage-limits-banner.ui.storage-limits-banner-link"
					>
						{formatMessage(messages.hamsLinkText, {
							standardPlanStorageLimit: STANDARD_PLAN_STORAGE_LIMIT,
						})}
					</BannerLink>
				</BannerContainer>
			</Box>
		</ContextualAnalyticsData>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerContainer = styled.div({
	backgroundColor: token('color.background.warning'),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontWeight: token('font.weight.medium'),
	color: token('color.text.subtle'),
	minHeight: token('space.300'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	maxHeight: `${token('space.300')} !important`,
	gap: token('space.050'),
	whiteSpace: 'nowrap',
	paddingTop: token('space.050'),
	paddingRight: token('space.300'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.300'),
});

const fullWidthBannerWrapperStyles = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
	paddingRight: 'space.0',
	paddingLeft: 'space.0',
});

const partialWidthBannerWrapperStyles = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
	paddingRight: 'space.500',
	paddingLeft: 'space.500',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerLink = styled.a({
	color: token('color.text.subtle'),
	textDecoration: 'underline',
	cursor: 'pointer',
	'&:hover': {
		color: token('color.text.subtle'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerText = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
