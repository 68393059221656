import {
	setUserProperties,
	getUserProperty,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { localStorageManager } from './local-storage-manager.tsx';

export const getPlaceholderTemplateUserProperty = async (
	accountId: AccountId,
	propertyKey: string,
) => {
	const { getLocalStorage, setLocalStorage } = localStorageManager(
		propertyKey,
		'placeholderTemplateStorage',
		accountId,
	);

	const hasDismissed = getLocalStorage();
	if (hasDismissed !== undefined) {
		return hasDismissed;
	}

	try {
		const property = await getUserProperty(accountId, propertyKey);
		setLocalStorage(!!property);
		return !!property;
	} catch (error) {
		if (error instanceof FetchError) {
			if (error.statusCode === 401 || error.statusCode === 403) {
				return true;
			}

			// 404: API deliberately returns 404 if user property is not found, which implies the property has not been set
			if (error.statusCode === 404) {
				setLocalStorage(false);
				return false;
			}
		}

		let adControlsError: Error;

		if (error instanceof FetchError) {
			adControlsError = new Error(
				`Fetch Error, failed to get user property: ${propertyKey}, status code was: ${error.statusCode}`,
			);
		} else {
			adControlsError = new Error(`Failed to get user property: ${propertyKey}`, {
				cause: error,
			});
		}

		fireErrorAnalytics({
			meta: {
				id: 'userPropertiesFetchError',
				packageName: 'jiraIssueViewBase',
				teamName: 'starburst',
			},
			error: adControlsError,
		});

		return true;
	}
};

export const setPlaceholderTemplateUserProperty = async (
	accountId: AccountId,
	propertyKey: string,
) => {
	const { setLocalStorage } = localStorageManager(
		propertyKey,
		'placeholderTemplateStorage',
		accountId,
	);
	setLocalStorage(true);
	await setUserProperties(accountId, propertyKey, JSON.stringify(true));
};
