import { useState, useEffect, useCallback } from 'react';
import { isWithinInterval, subDays, startOfDay, endOfDay, parseISO } from 'date-fns';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	type TapTrait,
	fetchPersonalizationSiteTraits,
} from '../services/fetch-personalization-site-traits.tsx';
import { localStorageManager } from '../services/local-storage-manager.tsx';

// TODO : Add a prefix to identify each app and also make sure you update the same in test
const CONFLUENCE_ACTIVATION_DATE_TAP_TRAIT = 'confluence.ondemand.activationDate' as const;

// This hook checks if confluence has been activated on site with cloudId within the last 14 days
// If the value has already been passed to the hook then the check is skipped
// Don't send hasActivatedConfluenceWithinFourteenDays if you want to compute that value
export const useHasConfluenceActivatedWithinFourteenDays = (
	hasActivatedConfluenceWithinFourteenDays?: boolean,
) => {
	const cloudId = useCloudId();

	const { getLocalStorage, setLocalStorage } = localStorageManager(
		'hasActivatedConfluenceWithinFourteenDays',
		'confluenceContentPlaceholderTemplate',
		cloudId,
	);
	const cachedHasActivatedConfluenceWithinFourteenDays = getLocalStorage();

	const [isActivatedWithinFourteenDays, setIsActivatedWithinFourteenDays] = useState(false);

	const checkIfConfluenceActivatedWithinFourteenDays = useCallback(async () => {
		const traits = await fetchPersonalizationSiteTraits(cloudId);
		if (traits) {
			const confluenceActivationDateTrait = traits.find(
				(tapTrait: TapTrait) => tapTrait.name === CONFLUENCE_ACTIVATION_DATE_TAP_TRAIT,
			);
			if (
				confluenceActivationDateTrait &&
				typeof confluenceActivationDateTrait.value === 'string'
			) {
				const today = endOfDay(new Date());
				const fourteenDaysAgo = startOfDay(subDays(today, 14));
				const dateToCheck = parseISO(confluenceActivationDateTrait.value);

				const isDateWithinInterval = isWithinInterval(dateToCheck, {
					start: fourteenDaysAgo,
					end: today,
				});
				setIsActivatedWithinFourteenDays(isDateWithinInterval);
				setLocalStorage(isDateWithinInterval);
			}
		}
	}, [cloudId, setLocalStorage]);

	useEffect(() => {
		// Don't need to check if we already know the site has activated confluence within the past 14 days
		if (
			hasActivatedConfluenceWithinFourteenDays === undefined &&
			cachedHasActivatedConfluenceWithinFourteenDays === undefined
		) {
			checkIfConfluenceActivatedWithinFourteenDays();
		} else if (hasActivatedConfluenceWithinFourteenDays) {
			setIsActivatedWithinFourteenDays(hasActivatedConfluenceWithinFourteenDays);
			setLocalStorage(hasActivatedConfluenceWithinFourteenDays);
		} else {
			setIsActivatedWithinFourteenDays(cachedHasActivatedConfluenceWithinFourteenDays);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		cloudId,
		cachedHasActivatedConfluenceWithinFourteenDays,
		hasActivatedConfluenceWithinFourteenDays,
	]);

	return isActivatedWithinFourteenDays;
};
