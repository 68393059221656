/**
 * @generated SignedSource<<90d50e48dabdaf1146baccbccd70ea5f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraStatusCategoryColor = "BLUE_GRAY" | "BROWN" | "GREEN" | "MEDIUM_GRAY" | "WARM_RED" | "YELLOW" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type view_issueViewFoundation_StatusView$data = {
  readonly resolutionField?: {
    readonly fieldConfig: {
      readonly isEditable: boolean | null | undefined;
    } | null | undefined;
    readonly resolution: {
      readonly __typename: "JiraResolution";
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutResolutionField_IssueViewResolutionField">;
  } | null | undefined;
  readonly statusField?: {
    readonly status: {
      readonly description: string | null | undefined;
      readonly id: string;
      readonly name: string | null | undefined;
      readonly statusCategory: {
        readonly colorName: JiraStatusCategoryColor | null | undefined;
        readonly id: string;
        readonly key: string | null | undefined;
        readonly name: string | null | undefined;
        readonly statusCategoryId: string;
      } | null | undefined;
      readonly statusId: string;
    };
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutStatusField_IssueViewStatusField" | "view_issueViewFoundation_ResolutionField">;
  readonly " $fragmentType": "view_issueViewFoundation_StatusView";
};
export type view_issueViewFoundation_StatusView$key = {
  readonly " $data"?: view_issueViewFoundation_StatusView$data;
  readonly " $fragmentSpreads": FragmentRefs<"view_issueViewFoundation_StatusView">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatuspanelrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatusrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "view_issueViewFoundation_StatusView",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "view_issueViewFoundation_ResolutionField"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraResolutionField",
          "kind": "LinkedField",
          "name": "resolutionField",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "ui_issueViewLayoutResolutionField_IssueViewResolutionField"
            },
            {
              "concreteType": "JiraFieldConfig",
              "kind": "LinkedField",
              "name": "fieldConfig",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "isEditable"
                }
              ]
            },
            {
              "concreteType": "JiraResolution",
              "kind": "LinkedField",
              "name": "resolution",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatuspanelrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraStatusField",
          "kind": "LinkedField",
          "name": "statusField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraStatus",
              "kind": "LinkedField",
              "name": "status",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "statusId"
                },
                {
                  "kind": "ScalarField",
                  "name": "description"
                },
                {
                  "concreteType": "JiraStatusCategory",
                  "kind": "LinkedField",
                  "name": "statusCategory",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "name": "statusCategoryId"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "key"
                    },
                    (v1/*: any*/),
                    {
                      "kind": "ScalarField",
                      "name": "colorName"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatusrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutStatusField_IssueViewStatusField"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "aa8bcb5425fa79c71ef00fbe6b346b2b";

export default node;
