/**
 * @generated SignedSource<<2a94c74a6d7078e338335f0a6a512fa2>>
 * @relayHash cdbe35b58a0a02e50d421b72ec07d6b2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7db0bb556b9ca0f00a34b9dff54017f437baf19b34ec430fc6a7caee557723c2

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  namespace?: string | null | undefined;
  parentIssueKey: string;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchFieldSetsInput = {
  fieldSetIds?: ReadonlyArray<string> | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchViewInput = {
  context?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  filterId?: string | null | undefined;
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsContext = {
  issueContext?: JiraIssueSearchViewFieldSetsIssueContext | null | undefined;
  projectContext?: JiraIssueSearchViewFieldSetsProjectContext | null | undefined;
};
export type JiraIssueSearchViewFieldSetsIssueContext = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsProjectContext = {
  issueType?: string | null | undefined;
  project?: string | null | undefined;
};
export type ui_main_ConfigurableChildIssuesPanelWithEntryPointQuery$variables = {
  amountOfColumns: number;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  fieldSetsContext?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  fieldSetsInput?: JiraIssueSearchFieldSetsInput | null | undefined;
  filterId?: string | null | undefined;
  issueKey: string;
  issueSearchInput: JiraIssueSearchInput;
  namespace?: string | null | undefined;
  shouldQueryFieldSetsById: boolean;
  viewId?: string | null | undefined;
};
export type ui_main_ConfigurableChildIssuesPanelWithEntryPointQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewCommonViews_ConfigurableChildIssuesPanelWithFragment_data">;
};
export type ui_main_ConfigurableChildIssuesPanelWithEntryPointQuery = {
  response: ui_main_ConfigurableChildIssuesPanelWithEntryPointQuery$data;
  variables: ui_main_ConfigurableChildIssuesPanelWithEntryPointQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewchildissuespanelissuesearchrelayprovider": isIssueSearchAPIEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewchildissuespanelrealtimeupdaterelayprovider": isRealtimeUpdateEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider": isReparentingEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewchildissuespanelissuesearchrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewchildissuespanelrealtimeupdaterelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider: {
    readonly get: () => boolean;
  };
});

import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import isIssueSearchAPIEnabled_provider from '@atlassian/jira-relay-provider/src/jira-issue-view-child-issues-panel-issuesearch.relayprovider';
import isRealtimeUpdateEnabled_provider from '@atlassian/jira-relay-provider/src/jira-issue-view-child-issues-panel-realtime-update.relayprovider';
import isReparentingEnabled_provider from '@atlassian/jira-relay-provider/src/jira-list-reparenting.relayprovider';
import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import isJscM2Enabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsContext"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsInput"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKey"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v11 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v12 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v13 = {
  "kind": "ScalarField",
  "name": "id"
},
v14 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v15 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v16 = {
  "kind": "ScalarField",
  "name": "type"
},
v17 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v18 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v19 = {
  "kind": "ScalarField",
  "name": "__id"
},
v20 = {
  "kind": "ClientExtension",
  "selections": [
    (v19/*: any*/)
  ]
},
v21 = {
  "kind": "ScalarField",
  "name": "key"
},
v22 = [
  (v11/*: any*/),
  {
    "kind": "Variable",
    "name": "fieldSetsInput",
    "variableName": "fieldSetsInput"
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  }
],
v23 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v24 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v25 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v26 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v25/*: any*/),
    (v13/*: any*/)
  ]
},
v27 = {
  "kind": "InlineFragment",
  "selections": [
    (v24/*: any*/),
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        (v13/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v28 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "level"
    }
  ]
},
v29 = {
  "kind": "ScalarField",
  "name": "name"
},
v30 = {
  "kind": "ScalarField",
  "name": "value"
},
v31 = [
  (v30/*: any*/),
  (v13/*: any*/),
  {
    "kind": "ScalarField",
    "name": "isDisabled"
  }
],
v32 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "lazyIsEditableInIssueView"
    }
  ]
},
v33 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v34 = {
  "alias": "value",
  "kind": "ScalarField",
  "name": "id"
},
v35 = {
  "alias": "label",
  "kind": "ScalarField",
  "name": "name"
},
v36 = {
  "kind": "ScalarField",
  "name": "dateTime"
},
v37 = {
  "kind": "ScalarField",
  "name": "text"
},
v38 = {
  "kind": "InlineFragment",
  "selections": [
    (v37/*: any*/),
    (v29/*: any*/),
    (v32/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v39 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "colorKey"
    },
    (v13/*: any*/)
  ]
},
v40 = [
  (v39/*: any*/)
],
v41 = {
  "kind": "ScalarField",
  "name": "optionId"
},
v42 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v43 = {
  "kind": "ScalarField",
  "name": "picture"
},
v44 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v45 = [
  (v29/*: any*/),
  (v13/*: any*/)
],
v46 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v47 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    (v37/*: any*/),
    (v13/*: any*/)
  ]
},
v48 = {
  "concreteType": "JiraColorField",
  "kind": "LinkedField",
  "name": "issueColorField",
  "plural": false,
  "selections": [
    (v39/*: any*/),
    (v13/*: any*/)
  ]
},
v49 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v50 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v49/*: any*/),
        (v29/*: any*/),
        (v13/*: any*/)
      ]
    },
    (v13/*: any*/)
  ]
},
v51 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v52 = {
  "kind": "ScalarField",
  "name": "number"
},
v53 = [
  {
    "concreteType": "JiraIssueFieldSetEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssueFieldSet",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          (v15/*: any*/),
          {
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "fields",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v24/*: any*/),
                      (v16/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isJiraIssueField"
                      },
                      (v13/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraAffectedServiceConnection",
                            "kind": "LinkedField",
                            "name": "selectedAffectedServicesConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraAffectedServiceEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraAffectedService",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "serviceId"
                                      },
                                      (v29/*: any*/),
                                      (v13/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraAffectedServicesField"
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v29/*: any*/),
                              {
                                "concreteType": "JiraCascadingOption",
                                "kind": "LinkedField",
                                "name": "cascadingOption",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraOption",
                                    "kind": "LinkedField",
                                    "name": "parentOptionValue",
                                    "plural": false,
                                    "selections": (v31/*: any*/)
                                  },
                                  {
                                    "concreteType": "JiraOption",
                                    "kind": "LinkedField",
                                    "name": "childOptionValue",
                                    "plural": false,
                                    "selections": (v31/*: any*/)
                                  }
                                ]
                              },
                              (v32/*: any*/)
                            ],
                            "type": "JiraCascadingSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v29/*: any*/),
                              {
                                "args": (v33/*: any*/),
                                "concreteType": "JiraComponentConnection",
                                "kind": "LinkedField",
                                "name": "selectedComponentsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraComponentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraComponent",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v34/*: any*/),
                                          (v35/*: any*/),
                                          (v13/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ],
                                "storageKey": "selectedComponentsConnection(first:1000)"
                              },
                              (v32/*: any*/)
                            ],
                            "type": "JiraComponentsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v36/*: any*/),
                              (v29/*: any*/),
                              (v32/*: any*/)
                            ],
                            "type": "JiraDateTimePickerField"
                          },
                          (v38/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraCommentSummary",
                                "kind": "LinkedField",
                                "name": "commentSummary",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "canAddComment"
                                  },
                                  (v18/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraCommentSummaryField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v29/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "uri"
                              },
                              (v32/*: any*/)
                            ],
                            "type": "JiraUrlField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v29/*: any*/),
                              {
                                "concreteType": "JiraOptionConnection",
                                "kind": "LinkedField",
                                "name": "fieldOptions",
                                "plural": false,
                                "selections": [
                                  (v18/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "JiraOption",
                                "kind": "LinkedField",
                                "name": "fieldOption",
                                "plural": false,
                                "selections": [
                                  (v30/*: any*/),
                                  {
                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                    "kind": "Condition",
                                    "passingValue": true,
                                    "selections": (v40/*: any*/)
                                  },
                                  {
                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider",
                                    "kind": "Condition",
                                    "passingValue": true,
                                    "selections": (v40/*: any*/)
                                  },
                                  (v13/*: any*/),
                                  (v41/*: any*/)
                                ]
                              },
                              (v32/*: any*/)
                            ],
                            "type": "JiraSingleSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v29/*: any*/),
                              {
                                "concreteType": "JiraOptionConnection",
                                "kind": "LinkedField",
                                "name": "selectedOptions",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraOptionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraOption",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v13/*: any*/),
                                          (v30/*: any*/),
                                          (v41/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v32/*: any*/)
                            ],
                            "type": "JiraMultipleSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v29/*: any*/),
                              {
                                "concreteType": "JiraOptionConnection",
                                "kind": "LinkedField",
                                "name": "selectedOptions",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraOptionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraOption",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v13/*: any*/),
                                          (v30/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v32/*: any*/)
                            ],
                            "type": "JiraCheckboxesField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v29/*: any*/),
                              {
                                "concreteType": "JiraUserConnection",
                                "kind": "LinkedField",
                                "name": "selectedUsersConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraUserEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v12/*: any*/),
                                          (v42/*: any*/),
                                          (v29/*: any*/),
                                          (v43/*: any*/),
                                          (v13/*: any*/),
                                          (v44/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "email"
                                              }
                                            ],
                                            "type": "AtlassianAccountUser"
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v32/*: any*/)
                            ],
                            "type": "JiraMultipleSelectUserPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v29/*: any*/),
                              {
                                "concreteType": "JiraTeamView",
                                "kind": "LinkedField",
                                "name": "selectedTeam",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "jiraSuppliedId"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "jiraSuppliedName"
                                  }
                                ]
                              },
                              (v32/*: any*/)
                            ],
                            "type": "JiraTeamViewField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraIssue",
                                "kind": "LinkedField",
                                "name": "issue",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraTimeTrackingField",
                                    "kind": "LinkedField",
                                    "name": "timeTrackingField",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/),
                                      {
                                        "concreteType": "JiraEstimate",
                                        "kind": "LinkedField",
                                        "name": "originalEstimate",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "timeInSeconds"
                                          }
                                        ]
                                      },
                                      {
                                        "concreteType": "JiraTimeTrackingSettings",
                                        "kind": "LinkedField",
                                        "name": "timeTrackingSettings",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "workingHoursPerDay"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "workingDaysPerWeek"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "defaultFormat"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "defaultUnit"
                                          }
                                        ]
                                      },
                                      (v24/*: any*/),
                                      (v29/*: any*/),
                                      (v16/*: any*/),
                                      (v32/*: any*/)
                                    ]
                                  },
                                  (v13/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraOriginalTimeEstimateField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v29/*: any*/),
                              {
                                "concreteType": "JiraOption",
                                "kind": "LinkedField",
                                "name": "selectedOption",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": "label",
                                    "kind": "ScalarField",
                                    "name": "value"
                                  },
                                  (v13/*: any*/),
                                  (v34/*: any*/)
                                ]
                              },
                              (v32/*: any*/)
                            ],
                            "type": "JiraRadioSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v29/*: any*/),
                              {
                                "args": (v33/*: any*/),
                                "concreteType": "JiraVersionConnection",
                                "kind": "LinkedField",
                                "name": "selectedVersionsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraVersionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraVersion",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v13/*: any*/),
                                          (v29/*: any*/),
                                          (v34/*: any*/),
                                          (v35/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "name": "versionId"
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ],
                                "storageKey": "selectedVersionsConnection(first:1000)"
                              },
                              (v32/*: any*/)
                            ],
                            "type": "JiraMultipleVersionPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "args": (v33/*: any*/),
                                "concreteType": "JiraGoalConnection",
                                "kind": "LinkedField",
                                "name": "selectedGoals",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraGoalEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraGoal",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v13/*: any*/),
                                          (v21/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ],
                                "storageKey": "selectedGoals(first:1000)"
                              }
                            ],
                            "type": "JiraGoalsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v29/*: any*/),
                              {
                                "args": (v33/*: any*/),
                                "concreteType": "JiraConfluenceRemoteIssueLinkConnection",
                                "kind": "LinkedField",
                                "name": "confluenceRemoteIssueLinks",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraConfluenceRemoteIssueLinkEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraConfluenceRemoteIssueLink",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v13/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "name": "href"
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  (v18/*: any*/)
                                ],
                                "storageKey": "confluenceRemoteIssueLinks(first:1000)"
                              },
                              (v32/*: any*/)
                            ],
                            "type": "JiraConfluenceRemoteIssueLinksField"
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraCmdbObjectConnection",
                            "kind": "LinkedField",
                            "name": "selectedCmdbObjectsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraCmdbObjectEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraCmdbObject",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "objectId"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "label"
                                      },
                                      {
                                        "concreteType": "JiraCmdbAvatar",
                                        "kind": "LinkedField",
                                        "name": "avatar",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraCmdbMediaClientConfig",
                                            "kind": "LinkedField",
                                            "name": "mediaClientConfig",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "clientId"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "fileId"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "mediaBaseUrl"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "mediaJwtToken"
                                              }
                                            ]
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "url48"
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "concreteType": "QueryError",
                                "kind": "LinkedField",
                                "name": "errors",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "message"
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraCMDBField"
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": "componentsOld",
                                "concreteType": "JiraComponentConnection",
                                "kind": "LinkedField",
                                "name": "selectedComponentsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraComponentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraComponent",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v45/*: any*/)
                                      }
                                    ]
                                  }
                                ]
                              }
                            ],
                            "type": "JiraComponentsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v37/*: any*/)
                            ],
                            "type": "JiraSingleLineTextField"
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "date"
                          },
                          (v29/*: any*/),
                          (v46/*: any*/),
                          (v32/*: any*/)
                        ],
                        "type": "JiraDatePickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v36/*: any*/)
                        ],
                        "type": "JiraDateTimePickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraEpic",
                            "kind": "LinkedField",
                            "name": "epic",
                            "plural": false,
                            "selections": [
                              (v21/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "summary"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "color"
                              },
                              (v13/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraEpicLinkField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "renderedFieldHtml"
                          }
                        ],
                        "type": "JiraFallbackField"
                      },
                      (v38/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraStatusCategory",
                            "kind": "LinkedField",
                            "name": "statusCategory",
                            "plural": false,
                            "selections": [
                              (v29/*: any*/),
                              (v25/*: any*/),
                              (v13/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraStatusCategoryField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraStatus",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                              (v29/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "statusId"
                              },
                              (v26/*: any*/),
                              (v13/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraStatusField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": (v33/*: any*/),
                            "concreteType": "JiraLabelConnection",
                            "kind": "LinkedField",
                            "name": "selectedLabelsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraLabelEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraLabel",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v29/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              (v18/*: any*/)
                            ],
                            "storageKey": "selectedLabelsConnection(first:1000)"
                          },
                          (v29/*: any*/),
                          (v32/*: any*/)
                        ],
                        "type": "JiraLabelsField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraAvatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "small"
                                  }
                                ]
                              },
                              (v29/*: any*/),
                              (v13/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraIssueTypeField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssue",
                            "kind": "LinkedField",
                            "name": "parentIssue",
                            "plural": false,
                            "selections": [
                              (v21/*: any*/),
                              (v47/*: any*/),
                              (v13/*: any*/)
                            ]
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssue",
                                "kind": "LinkedField",
                                "name": "parentIssue",
                                "plural": false,
                                "selections": [
                                  (v21/*: any*/),
                                  (v48/*: any*/),
                                  (v50/*: any*/),
                                  (v47/*: any*/),
                                  (v13/*: any*/)
                                ]
                              }
                            ]
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssue",
                                "kind": "LinkedField",
                                "name": "parentIssue",
                                "plural": false,
                                "selections": [
                                  (v21/*: any*/),
                                  (v48/*: any*/),
                                  (v50/*: any*/),
                                  (v47/*: any*/),
                                  (v13/*: any*/),
                                  (v23/*: any*/),
                                  (v51/*: any*/),
                                  {
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "ids",
                                        "value": [
                                          "summary",
                                          "issuetype",
                                          "status"
                                        ]
                                      }
                                    ],
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldsById",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v12/*: any*/),
                                              (v13/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v24/*: any*/),
                                                  (v37/*: any*/)
                                                ],
                                                "type": "JiraSingleLineTextField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v24/*: any*/),
                                                  {
                                                    "concreteType": "JiraIssueType",
                                                    "kind": "LinkedField",
                                                    "name": "issueType",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "issueTypeId"
                                                      },
                                                      (v29/*: any*/),
                                                      (v49/*: any*/),
                                                      (v28/*: any*/),
                                                      (v13/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraIssueTypeField"
                                              },
                                              (v27/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "storageKey": "fieldsById(ids:[\"summary\",\"issuetype\",\"status\"])"
                                  }
                                ]
                              },
                              (v29/*: any*/),
                              (v32/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraParentIssueField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraPriority",
                            "kind": "LinkedField",
                            "name": "priority",
                            "plural": false,
                            "selections": [
                              (v29/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "iconUrl"
                              },
                              (v13/*: any*/)
                            ]
                          },
                          (v29/*: any*/),
                          (v32/*: any*/)
                        ],
                        "type": "JiraPriorityField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraProject",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v29/*: any*/),
                              (v21/*: any*/),
                              {
                                "concreteType": "JiraAvatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "large"
                                  }
                                ]
                              },
                              (v13/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraProjectField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraResolution",
                            "kind": "LinkedField",
                            "name": "resolution",
                            "plural": false,
                            "selections": (v45/*: any*/)
                          }
                        ],
                        "type": "JiraResolutionField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraSprintConnection",
                            "kind": "LinkedField",
                            "name": "selectedSprintsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraSprintEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraSprint",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "sprintId"
                                      },
                                      (v29/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "state"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "endDate"
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          (v29/*: any*/),
                          (v32/*: any*/)
                        ],
                        "type": "JiraSprintField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v42/*: any*/),
                              (v43/*: any*/),
                              (v29/*: any*/),
                              (v13/*: any*/),
                              (v44/*: any*/)
                            ]
                          },
                          (v29/*: any*/),
                          (v46/*: any*/),
                          (v32/*: any*/)
                        ],
                        "type": "JiraSingleSelectUserPickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v29/*: any*/),
                          {
                            "args": (v33/*: any*/),
                            "concreteType": "JiraVersionConnection",
                            "kind": "LinkedField",
                            "name": "selectedVersionsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraVersionEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraVersion",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": (v45/*: any*/)
                                  }
                                ]
                              }
                            ],
                            "storageKey": "selectedVersionsConnection(first:1000)"
                          }
                        ],
                        "type": "JiraMultipleVersionPickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraRichText",
                            "kind": "LinkedField",
                            "name": "richText",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraADF",
                                "kind": "LinkedField",
                                "name": "adfValue",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraAdfToConvertedPlainText",
                                    "kind": "LinkedField",
                                    "name": "convertedPlainText",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "plainText"
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraRichTextField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraServiceManagementRequestType",
                            "kind": "LinkedField",
                            "name": "requestType",
                            "plural": false,
                            "selections": [
                              (v29/*: any*/),
                              (v49/*: any*/),
                              (v13/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraServiceManagementRequestTypeField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 1
                              }
                            ],
                            "concreteType": "JiraIssueLinkConnection",
                            "kind": "LinkedField",
                            "name": "issueLinkConnection",
                            "plural": false,
                            "selections": [
                              (v18/*: any*/),
                              {
                                "concreteType": "JiraIssueLinkEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueLink",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssue",
                                        "kind": "LinkedField",
                                        "name": "issue",
                                        "plural": false,
                                        "selections": [
                                          (v51/*: any*/),
                                          (v21/*: any*/),
                                          {
                                            "args": [
                                              {
                                                "kind": "Literal",
                                                "name": "ids",
                                                "value": [
                                                  "statusCategory"
                                                ]
                                              }
                                            ],
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fieldsById",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueFieldEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v12/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v26/*: any*/)
                                                        ],
                                                        "type": "JiraStatusCategoryField"
                                                      },
                                                      (v13/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ],
                                            "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                          },
                                          (v13/*: any*/)
                                        ]
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "relationName"
                                      },
                                      (v13/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "issueLinkConnection(first:1)"
                          }
                        ],
                        "type": "JiraIssueLinkField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "isStoryPointField"
                          },
                          (v52/*: any*/),
                          (v29/*: any*/),
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              (v52/*: any*/)
                            ]
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              (v52/*: any*/),
                              (v29/*: any*/),
                              (v32/*: any*/)
                            ]
                          },
                          (v32/*: any*/)
                        ],
                        "type": "JiraNumberField"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
],
v54 = [
  {
    "kind": "ScalarField",
    "name": "cursor"
  },
  {
    "kind": "ScalarField",
    "name": "pageNumber"
  },
  {
    "kind": "ScalarField",
    "name": "isCurrent"
  }
],
v55 = [
  (v18/*: any*/),
  {
    "concreteType": "JiraIssueEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssue",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v21/*: any*/),
          (v23/*: any*/),
          (v13/*: any*/),
          {
            "kind": "ScalarField",
            "name": "isResolved"
          },
          {
            "args": [
              {
                "kind": "Literal",
                "name": "ids",
                "value": [
                  "status"
                ]
              }
            ],
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "fieldsById",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v27/*: any*/),
                      (v13/*: any*/)
                    ]
                  }
                ]
              }
            ],
            "storageKey": "fieldsById(ids:[\"status\"])"
          },
          {
            "concreteType": "JiraIssueTypeField",
            "kind": "LinkedField",
            "name": "issueTypeField",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueType",
                "kind": "LinkedField",
                "name": "issueType",
                "plural": false,
                "selections": [
                  (v28/*: any*/),
                  (v13/*: any*/)
                ]
              },
              (v13/*: any*/)
            ]
          },
          {
            "kind": "ClientExtension",
            "selections": [
              (v19/*: any*/),
              {
                "kind": "ScalarField",
                "name": "isHighlightedIssueRow"
              }
            ]
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "condition": "shouldQueryFieldSetsById",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "fieldSetIds",
                        "variableName": "fieldSetIds"
                      },
                      (v14/*: any*/)
                    ],
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSetsById",
                    "plural": false,
                    "selections": (v53/*: any*/)
                  }
                ]
              },
              {
                "condition": "shouldQueryFieldSetsById",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "context",
                        "variableName": "fieldSetsContext"
                      },
                      {
                        "kind": "Variable",
                        "name": "filterId",
                        "variableName": "filterId"
                      },
                      (v14/*: any*/),
                      {
                        "kind": "Variable",
                        "name": "namespace",
                        "variableName": "namespace"
                      },
                      {
                        "kind": "Variable",
                        "name": "viewId",
                        "variableName": "viewId"
                      }
                    ],
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSetsForIssueSearchView",
                    "plural": false,
                    "selections": (v53/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      },
      (v12/*: any*/),
      (v20/*: any*/),
      {
        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 500
              }
            ],
            "concreteType": "JiraIssueFieldSetConnection",
            "kind": "LinkedField",
            "name": "fieldSets",
            "plural": false,
            "selections": (v53/*: any*/),
            "storageKey": "fieldSets(first:500)"
          }
        ]
      }
    ]
  },
  {
    "kind": "ScalarField",
    "name": "jql"
  },
  {
    "kind": "ScalarField",
    "name": "totalIssueSearchResultCount"
  },
  {
    "kind": "ScalarField",
    "name": "isCappingIssueSearchResult"
  },
  {
    "concreteType": "JiraIssueNavigatorPageInfo",
    "kind": "LinkedField",
    "name": "issueNavigatorPageInfo",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "firstIssuePosition"
      },
      {
        "kind": "ScalarField",
        "name": "lastIssuePosition"
      }
    ]
  },
  {
    "args": [
      {
        "kind": "Literal",
        "name": "maxCursors",
        "value": 7
      }
    ],
    "concreteType": "JiraPageCursors",
    "kind": "LinkedField",
    "name": "pageCursors",
    "plural": false,
    "selections": [
      {
        "concreteType": "JiraPageCursor",
        "kind": "LinkedField",
        "name": "first",
        "plural": false,
        "selections": (v54/*: any*/)
      },
      {
        "concreteType": "JiraPageCursor",
        "kind": "LinkedField",
        "name": "around",
        "plural": true,
        "selections": (v54/*: any*/)
      },
      {
        "concreteType": "JiraPageCursor",
        "kind": "LinkedField",
        "name": "last",
        "plural": false,
        "selections": (v54/*: any*/)
      }
    ],
    "storageKey": "pageCursors(maxCursors:7)"
  },
  {
    "kind": "LinkedField",
    "name": "issueSearchError",
    "plural": false,
    "selections": [
      (v12/*: any*/)
    ]
  },
  (v20/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ui_main_ConfigurableChildIssuesPanelWithEntryPointQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ui_issueViewCommonViews_ConfigurableChildIssuesPanelWithFragment_data"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v10/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v9/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewchildissuespanelissuesearchrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewchildissuespanelrealtimeupdaterelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "ui_main_ConfigurableChildIssuesPanelWithEntryPointQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              (v11/*: any*/),
              {
                "kind": "Variable",
                "name": "issueKey",
                "variableName": "issueKey"
              }
            ],
            "kind": "LinkedField",
            "name": "fieldSetViewQueryByProject",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v13/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "hasDefaultFieldSets"
                  },
                  {
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "filter",
                        "value": {
                          "fieldSetSelectedState": "SELECTED"
                        }
                      },
                      (v14/*: any*/)
                    ],
                    "concreteType": "JiraIssueSearchFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSets",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraIssueSearchFieldSetEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraIssueSearchFieldSet",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "jqlTerm"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "isSortable"
                              },
                              {
                                "concreteType": "JiraFieldSetPreferences",
                                "kind": "LinkedField",
                                "name": "fieldSetPreferences",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "width"
                                  }
                                ]
                              },
                              {
                                "kind": "ClientExtension",
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "isPlaceHolderField"
                                  }
                                ]
                              },
                              {
                                "concreteType": "JiraFieldType",
                                "kind": "LinkedField",
                                "name": "fieldType",
                                "plural": false,
                                "selections": [
                                  (v17/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v18/*: any*/),
                      (v20/*: any*/)
                    ]
                  }
                ],
                "type": "JiraFieldSetView"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v13/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewchildissuespanelrealtimeupdaterelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": [
                  (v11/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "key",
                    "variableName": "issueKey"
                  }
                ],
                "concreteType": "JiraIssue",
                "kind": "LinkedField",
                "name": "issueByKey",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectField",
                    "kind": "LinkedField",
                    "name": "projectField",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "alias": "editIssues",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "type",
                                    "value": "EDIT_ISSUES"
                                  }
                                ],
                                "concreteType": "JiraProjectAction",
                                "kind": "LinkedField",
                                "name": "action",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "canPerform"
                                  }
                                ],
                                "storageKey": "action(type:\"EDIT_ISSUES\")"
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "projectId"
                          },
                          (v21/*: any*/),
                          (v13/*: any*/)
                        ]
                      },
                      (v13/*: any*/)
                    ]
                  },
                  (v13/*: any*/)
                ]
              }
            ]
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewchildissuespanelissuesearchrelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v22/*: any*/),
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "issueSearch",
                "plural": false,
                "selections": (v55/*: any*/)
              }
            ]
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewchildissuespanelissuesearchrelayprovider",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": (v22/*: any*/),
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "issueSearchStable",
                "plural": false,
                "selections": (v55/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "7db0bb556b9ca0f00a34b9dff54017f437baf19b34ec430fc6a7caee557723c2",
    "metadata": {},
    "name": "ui_main_ConfigurableChildIssuesPanelWithEntryPointQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewchildissuespanelissuesearchrelayprovider": isIssueSearchAPIEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewchildissuespanelrealtimeupdaterelayprovider": isRealtimeUpdateEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider": isReparentingEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
    }
  }
};
})();

(node as any).hash = "eaf7d86aa3ee206c7a446414bfdf0a4f";

export default node;
