import getMeta from '@atlassian/jira-get-meta';
import { getIssueLimitErrorField } from '@atlassian/jira-issue-view-errors/src/common/utils/index.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { CONTENT_TOO_LARGE } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';

export const isSynthetic = () => getMeta('ajs-is-synthetic') === 'true';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractFetchErrorMessage = (error: any) => {
	if (error.statusCode === CONTENT_TOO_LARGE) {
		return getIssueLimitErrorField(error);
	}

	if (error instanceof FetchError && error.message) {
		try {
			const json = JSON.parse(error.message);
			if (json.errorMessages.length === 0 && json.errors && json.errors.comment) {
				return json.errors.comment;
			}
			return json.errorMessages ? json.errorMessages.join('; ') : undefined;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			trackOrLogClientError('issue.save.comment', 'Failed to parse FetchError', e);
			return undefined;
		}
	}
	return undefined;
};
