import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { ButtonGroup } from '@atlaskit/button';
import Heading from '@atlaskit/heading';

import { token } from '@atlaskit/tokens';

import Badge from '@atlaskit/badge';
import WorkItemsIcon from '@atlaskit/icon/core/work-items';
import { Inline } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { singleLineClamp } from '@atlassian/jira-common-styles/src/mixins.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { SectionHeading } from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { OPEN, RESOLVED, SMART_ISSUE_HEADING_ID } from '../../common/constants.tsx';
import {
	COHORTS,
	useDiscoverabilityExperiment,
} from '../../controllers/use-discoverability-experiment/index.tsx';
import { useRelatedIssuesForIssue } from '../../services/context.tsx';
import { useQueryText } from '../../services/use-query-text/index.tsx';
import Expander from './expander/index.tsx';
import messages from './messages.tsx';
import { SearchIssues } from './search-issues/index.tsx';

export type Props = {
	isExpanded: boolean;
	sessionId: string;
	onExpand: () => void;
	onCollapse: () => void;
};

const PanelHeader = ({ onExpand, onCollapse, isExpanded, sessionId }: Props) => {
	const { formatMessage } = useIntl();
	const queryText = useQueryText();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { cohort, isIncludedInExperiment, fireExposure } = useDiscoverabilityExperiment();
	const [{ data: similarIssues, error, isLoading }, { fetchRelatedIssuesForIssue }] =
		useRelatedIssuesForIssue();
	const issuesCount =
		(similarIssues?.[RESOLVED]?.length ?? 0) + (similarIssues?.[OPEN]?.length ?? 0);
	const shouldShowSearch =
		isIncludedInExperiment &&
		cohort === COHORTS.DYNAMIC &&
		!error &&
		!isLoading &&
		issuesCount === 0;

	useEffect(() => {
		if (isIncludedInExperiment) {
			fireExposure();
			if (cohort === COHORTS.DYNAMIC) {
				fetchRelatedIssuesForIssue(sessionId, createAnalyticsEvent, queryText);
			}
		}
	}, [
		cohort,
		createAnalyticsEvent,
		fetchRelatedIssuesForIssue,
		fireExposure,
		isIncludedInExperiment,
		queryText,
		sessionId,
	]);

	const handleHeaderClick = () => (isExpanded ? onCollapse() : onExpand());

	return (
		<HeaderWrapper onClick={handleHeaderClick} isExpanded={isExpanded}>
			<HeaderTextContainer>
				<Heading
					{...(fg('jsm_a11y_improvements_for_queue') ? { id: SMART_ISSUE_HEADING_ID } : {})}
					size="xsmall"
					as="h2"
				>
					{isIncludedInExperiment && cohort === COHORTS.DYNAMIC && (
						<Inline alignBlock="center" space="space.100">
							{formatMessage(messages.similarWorkItemsTitle)}
							{isLoading && (
								<Spinner
									testId="smart-related-issues-panel.ui.header.loading-work-items-count"
									label={formatMessage(messages.loadingSimilarWorkItemsCount)}
									size="small"
								/>
							)}
							{!isLoading && (
								<Badge testId="smart-related-issues-panel.ui.header.issues-count">
									{issuesCount}
								</Badge>
							)}
						</Inline>
					)}
					{isIncludedInExperiment && cohort === COHORTS.STATIC && (
						<Inline alignBlock="center" space="space.100">
							<WorkItemsIcon
								testId="smart-related-issues-panel.ui.header.issues-icon"
								label={formatMessage(messages.similarWorkItemsTitle)}
							/>
							{formatMessage(messages.similarWorkItemsTitle)}
						</Inline>
					)}
					{isIncludedInExperiment &&
						cohort === COHORTS.CONTROL &&
						formatMessage(messages.similarWorkItemsTitle)}
					{!isIncludedInExperiment && formatMessage(messages.title)}
				</Heading>
				{isExpanded && <HeaderDescription>{formatMessage(messages.description)}</HeaderDescription>}
			</HeaderTextContainer>
			<ButtonGroup>
				{!shouldShowSearch && (
					<Expander isContentExpanded={isExpanded} onExpand={onExpand} onCollapse={onCollapse} />
				)}
				{shouldShowSearch && (
					<SearchIssues
						onClick={(event) => {
							event.stopPropagation();
						}}
					/>
				)}
			</ButtonGroup>
		</HeaderWrapper>
	);
};

export default PanelHeader;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapper = styled.div<{
	isExpanded: boolean;
}>(
	{
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: token('space.100'),
		marginBottom: token('space.300'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${gridSize / 2}px`,
		borderWidth: '1px',
		borderStyle: 'solid',

		borderColor: `${token('color.border')}`,
		backgroundColor: token('elevation.surface'),
		paddingTop: token('space.200'),
		paddingRight: token('space.200'),
		paddingBottom: token('space.200'),
		paddingLeft: token('space.200'),
		'&:hover, &:focus, &:focus-within': {
			backgroundColor: token('elevation.surface.overlay.hovered'),
			cursor: 'pointer',
		},
		'&:focus': {
			'outline-color': token('color.border.focused'),
		},
		'&:active': {
			backgroundColor: token('elevation.surface.overlay.pressed'),

			borderColor: token('color.border'),
		},
		'&::-webkit-details-marker': {
			display: 'none',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isExpanded }) =>
		isExpanded && 'border-bottom-left-radius: 0;  border-bottom-right-radius: 0; margin-bottom: 0;',
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderTextContainer = styled(SectionHeading)({
	'@media (max-width: 640px)': {
		display: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	...singleLineClamp,
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderDescription = styled.span({
	color: token('color.text.subtlest'),
	marginTop: 0,
	marginRight: 'auto',
	marginBottom: 0,
	marginLeft: token('space.100'),
	textOverflow: 'ellipsis',
	overflow: 'hidden',
});
