/**
 * @generated SignedSource<<51977b537d1a23bfdfd840dd65cb8bea>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type view_userPreferenceDataStore$data = {
  readonly userPreferences: {
    readonly isCustomNavBarThemeFlagDismissed: boolean | null | undefined;
    readonly isCustomNavBarThemeSectionMessageDismissed: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "view_userPreferenceDataStore";
};
export type view_userPreferenceDataStore$key = {
  readonly " $data"?: view_userPreferenceDataStore$data;
  readonly " $fragmentSpreads": FragmentRefs<"view_userPreferenceDataStore">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "view_userPreferenceDataStore",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        }
      ],
      "concreteType": "JiraUserPreferences",
      "kind": "LinkedField",
      "name": "userPreferences",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isCustomNavBarThemeFlagDismissed"
        },
        {
          "kind": "ScalarField",
          "name": "isCustomNavBarThemeSectionMessageDismissed"
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "ddfdc6896b0bafe5b9615833c17f4e70";

export default node;
