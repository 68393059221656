// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook } from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	isLoading: true,
	isLoadingWithRelay: false,
} as const;

const CustomNavBarColorsFlagDismissalStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'custom-nav-bar-colors-flag-dismissal',
});

export const useCustomNavBarColorWarnings = createHook(CustomNavBarColorsFlagDismissalStore);
