import React, { useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useShouldBeCompact } from '@atlassian/jira-issue-hooks/src/services/use-should-be-compact/index.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width/index.tsx';
import type { mainIssueAggQuery$data } from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql.ts';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useIssueLayoutActivitySidePanel } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import FeedDisplay from './main.tsx';

type Props = {
	containerWidth?: number;
	rootRelayFragment: mainIssueAggQuery$data | null;
	renderDetailsAndConfigurePanels?: () => React.ReactNode;
};

const ActivityFeed = ({
	containerWidth,
	rootRelayFragment,
	renderDetailsAndConfigurePanels,
}: Props) => {
	const { shouldBeCompact, setMeasuredRef, forceUpdate } = useShouldBeCompact(containerWidth, 15);

	const setActivityItemsRef: (arg1: HTMLElement | null) => void = useMemo(
		() => setMeasuredRef('activity-items'),
		[setMeasuredRef],
	);
	const setSmartRequestSummaryTriggerRef: (arg1: HTMLElement | null) => void = useMemo(
		() => setMeasuredRef('issue-smart-summary-trigger'),
		[setMeasuredRef],
	);
	const setActivitySortRef: (arg1: HTMLElement | null) => void = useMemo(
		() => setMeasuredRef('activity-sort'),
		[setMeasuredRef],
	);

	const enableVisualRefreshChanges = isVisualRefreshEnabled();
	const { isActivityInVerticalSidePanel } = useIssueLayoutActivitySidePanel();

	return (
		<UFOSegment name="issue-activity-feed">
			{enableVisualRefreshChanges && !isActivityInVerticalSidePanel ? (
				<Box xcss={feedDisplayWrapperStyles}>
					<FeedDisplay
						shouldDisplayDropdown={shouldBeCompact}
						setActivityItemsRef={setActivityItemsRef}
						setActivitySortRef={setActivitySortRef}
						setSmartRequestSummaryTriggerRef={setSmartRequestSummaryTriggerRef}
						onForceUpdateCompactState={forceUpdate}
						rootRelayFragment={rootRelayFragment}
						renderDetailsAndConfigurePanels={renderDetailsAndConfigurePanels}
					/>
				</Box>
			) : (
				<FeedDisplay
					shouldDisplayDropdown={shouldBeCompact}
					setActivityItemsRef={setActivityItemsRef}
					setActivitySortRef={setActivitySortRef}
					setSmartRequestSummaryTriggerRef={setSmartRequestSummaryTriggerRef}
					onForceUpdateCompactState={forceUpdate}
					rootRelayFragment={rootRelayFragment}
					renderDetailsAndConfigurePanels={renderDetailsAndConfigurePanels}
				/>
			)}
		</UFOSegment>
	);
};

const feedDisplayWrapperStyles = xcss({
	marginTop: 'space.300',
});

const ActivityFeedWithSafeComponent: React.FC<Omit<Props, 'containerWidth'>> =
	flowWithSafeComponent(withContainerWidth)(ActivityFeed);
export default ActivityFeedWithSafeComponent;
