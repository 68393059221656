import { useEffect, useRef } from 'react';
import capitalize from 'lodash/capitalize';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fireUIAnalytics, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { sendPendoTrackEvent } from '../pendo/index.tsx';
import { BoardView } from './board-view/index.tsx';
import { FieldConfig } from './field-config/index.tsx';
import { Field } from './field/index.tsx';
import { IdeaView } from './idea-view/index.tsx';
import { ListView } from './list-view/index.tsx';
import { MatrixView } from './matrix-view/index.tsx';
import { TimelineView } from './timeline-view/index.tsx';
import { ViewHeader } from './view-header/index.tsx';
import { View } from './view/index.tsx';
import type { IssueAnalyticsData } from './types.tsx';

const mapViewKindToType = (viewKind: string): string => {
	const map: Record<string, string> = {
		TABLE: 'List',
	};

	return map[viewKind] ?? capitalize(viewKind);
};

export const fireCompoundAnalyticsEvent = {
	View,
	ViewHeader,
	IdeaView,
	FieldConfig,
	Field,
	BoardView,
	ListView,
	MatrixView,
	TimelineView,
	IdeaCommentCreated: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/yM5d3g_Uey9JJ_eD0aOn3b3fD7s
		sendPendoTrackEvent('idea_comment_created');

		// https://data-portal.internal.atlassian.com/analytics/registry/168
		fireTrackAnalytics(event, 'comment created');
	},
	IdeaCommentUpdated: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/1363
		fireTrackAnalytics(event, 'comment updated');
	},
	IdeaCommentDeleted: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/1364
		fireTrackAnalytics(event, 'comment deleted');
	},
	IdeaCreated: (event: UIAnalyticsEvent, issueId: string, attributes: object) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/xpkc3Fne_s-qq2bS3vILQyJQnNs
		sendPendoTrackEvent('idea_created');

		// https://data-portal.internal.atlassian.com/analytics/registry/871
		fireTrackAnalytics(event, 'issue created', String(issueId), {
			...attributes,
			projectType: PRODUCT_DISCOVERY_PROJECT,
		});
	},
	IdeaFieldUpdated: (
		event: UIAnalyticsEvent,
		fieldType: string | undefined,
		actionType: string,
	) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/H3xT1i_BL7YwQIXK8Wtw792AQwo
		sendPendoTrackEvent('idea_field_updated');

		// https://data-portal.internal.atlassian.com/analytics/registry/47658
		fireTrackAnalytics(event, 'fieldValue updated', {
			fieldType,
			actionType,
		});
	},
	IdeaDescriptionUpdated: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/l93NMzXG9zkSFeDPIZecMkSwNxQ
		sendPendoTrackEvent('idea_description_updated');

		// https://data-portal.internal.atlassian.com/analytics/registry/47087
		fireTrackAnalytics(event, 'ideaDescription updated');
	},
	RightSidebarFieldListViewOpened: () => {
		// https://app.pendo.io/s/4758009750487040/trackevents/qwLEc_xbDbYQUJrrm3rnaimAl28
		sendPendoTrackEvent('rightSidebarFieldListView opened');
	},
	RightSidebarIssueCommentsOpened: (event: UIAnalyticsEvent) => {
		// there are no separate sidebar for comments, it opens Idea and selects comments section
		// https://app.pendo.io/s/4758009750487040/trackevents/p2-VEQ3cO21s4mmhIfY_66_aFYA
		sendPendoTrackEvent('rightSidebarIssueComments opened');

		fireUIAnalytics(event, 'rightSidebarIssueComments opened');
	},
	RightSidebarIssueInsightsOpened: (event: UIAnalyticsEvent) => {
		// there are no separate sidebar for comments, it opens Idea and selects insights section
		// https://app.pendo.io/s/4758009750487040/trackevents/sGThOdFKoBxg8J_liR0JaC7DXwE
		sendPendoTrackEvent('rightSidebarIssueInsights opened');

		fireUIAnalytics(event, 'rightSidebarIssueInsights opened');
	},
	RightSidebarFieldEditViewOpened: () => {
		// https://app.pendo.io/s/4758009750487040/trackevents/DQP_0kv1ub59wQZ_mI05gd9eUNA
		sendPendoTrackEvent('rightSidebarFieldEditView opened');
	},
	RightSidebarFieldCreateViewOpened: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/5DhE-prOX7zgx38JEKNstW3s2Ng
		sendPendoTrackEvent('rightSidebarFieldCreateView opened');

		// https://data-portal.internal.atlassian.com/analytics/registry/47091
		fireUIAnalytics(event, 'rightSidebarFieldCreateView opened');
	},
	RightSidebarFieldOptionOpened: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/igbtQ-93jOjhhDLi032TTYep18U
		sendPendoTrackEvent('rightSidebarFieldOption opened');

		fireUIAnalytics(event, 'rightSidebarFieldOption opened');
	},
	RightSidebarFieldsFieldToggled: () => {
		sendPendoTrackEvent('viewFields updated fieldToggled');
	},
	RightSidebarFieldsFieldSorted: () => {
		sendPendoTrackEvent('viewFields updated fieldSorted');
	},
	RightSidebarFieldsFieldHidden: () => {
		sendPendoTrackEvent({ actionSubjectAndAction: 'viewFields updated fieldHidden' });
	},
	RightSidebarFieldsFieldAdded: () => {
		sendPendoTrackEvent('viewFields updated fieldAdded');
	},
	RightSidebarOnFilterOpened: () => {
		// https://app.pendo.io/s/4758009750487040/trackevents/W8B_-btramlDqbmwblM0sgJ3VMs
		sendPendoTrackEvent('rightSidebarOnFilter opened');
	},
	RightSidebarOnIdeaOpened: (event: UIAnalyticsEvent) => {
		// Does not trigger
		// https://app.pendo.io/s/4758009750487040/trackevents/6Hd4O0gOTewIXzIIRbneohkhyxc
		sendPendoTrackEvent('rightSidebarOnIdea opened');

		fireUIAnalytics(event, 'rightSidebarOnIdea opened');
	},
	RightSidebarOnPlayOpened: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/5Mo7NsNavjXsrJ8CzICmEx6XQ3E
		sendPendoTrackEvent('rightSidebarOnPlay opened');

		// https://data-portal.internal.atlassian.com/analytics/registry/47093
		fireUIAnalytics(event, 'rightSidebarOnPlay opened');
	},
	RightSidebarOnSortOpened: () => {
		// https://app.pendo.io/s/4758009750487040/trackevents/H0HJy3Pb03AFe4FUhziY3RtbePk
		sendPendoTrackEvent('rightSidebarOnSort opened');
	},
	RightSidebarViewSortFieldAdded: () => {
		sendPendoTrackEvent('viewSort updated fieldAdded');
	},
	RightSidebarViewSortFieldRemoved: () => {
		sendPendoTrackEvent('viewSort updated fieldRemoved');
	},
	RightSidebarViewSortDirectionChanged: () => {
		sendPendoTrackEvent('viewSort updated directionChanged');
	},
	RightSidebarViewSortReset: () => {
		sendPendoTrackEvent('viewSort updated sortReset');
	},
	RightSidebarViewSortFieldsReordered: () => {
		sendPendoTrackEvent('viewSort updated fieldsReordered');
	},
	RightSidebarViewSortFieldsChanged: () => {
		sendPendoTrackEvent('viewSort updated fieldsChanged');
	},
	RightSidebarGroupByColumnsOpened: () => {
		// https://app.pendo.io/s/4758009750487040/trackevents/un0GJBhSAansp_VkWBMpM5IsZF4
		sendPendoTrackEvent('openGroupByOptions opened');
	},
	RightSidebarGroupByRowsOpened: () => {
		// https://app.pendo.io/s/4758009750487040/trackevents/G7DQd6x0J_7WC1WI7seQw-J-GfY
		sendPendoTrackEvent('openVerticalGroupByOptions opened');
	},
	RightSidebarGroupByRowsFieldChanged: () => {
		sendPendoTrackEvent({
			actionSubjectAndAction: 'viewFields updated groupByRowFieldChanged',
		});
	},
	RightSidebarGroupByColumnsFieldChanged: () => {
		sendPendoTrackEvent('viewFields updated groupByColumnFieldChanged');
	},
	OpenMatrixXAxisOptionsOpened: () => {
		// https://app.pendo.io/s/4758009750487040/trackevents/yBOlM340T4ZzqcDSGc2XJF2ZRxc
		sendPendoTrackEvent('rightSidebarMatrixXAxisOptions opened');
	},
	OpenMatrixYAxisOptionsOpened: () => {
		// https://app.pendo.io/s/4758009750487040/trackevents/SL0S3e_8qSbkVLO-O_QjUfvcDQk
		sendPendoTrackEvent('rightSidebarMatrixYAxisOptions opened');
	},
	OpenMatrixZAxisOptionsOpened: () => {
		// https://app.pendo.io/s/4758009750487040/trackevents/ZnMVz7jumv61RhfycYSPqZ75KFw
		sendPendoTrackEvent('rightSidebarMatrixZAxisOptions opened');
	},
	RightSidebarClosed: () => {
		// https://app.pendo.io/s/4758009750487040/trackevents/4Qy9buugg-OhEldeBO2__dpJ-Ok
		sendPendoTrackEvent({ actionSubjectAndAction: 'rightSidebar closed' });
	},
	HyperlinkFieldMaxLengthError: (event: UIAnalyticsEvent) => {
		sendPendoTrackEvent('hyperlink_field_max_length_error');

		fireTrackAnalytics(event, 'error occurred', 'hyperlinkFieldMaxLengthError');
	},
	RightSidebarIdeaTemplatesOpened: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/HjknfGpnKMead8RV2_o3O0IQDSA
		sendPendoTrackEvent('rightSidebarIdeaTemplates opened');

		// https://data-portal.internal.atlassian.com/analytics/registry/47101
		fireUIAnalytics(event, 'rightSidebarIdeaTemplates opened');
	},
	RightSidebarIdeasBucketOpened: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/ljVwr3Sv_MYCLsa3UHcOHaD3fNA
		sendPendoTrackEvent({ actionSubjectAndAction: 'rightSidebarIdeasBucketOpened opened' });

		// https://data-portal.internal.atlassian.com/analytics/registry/47102
		fireUIAnalytics(event, 'rightSidebarIdeasBucket opened');
	},
	RightSidebarTimelineConfigOpened: () => {
		// https://app.pendo.io/s/4758009750487040/trackevents/CwJbzYOhkl68z6y843U6qziVc6M
		sendPendoTrackEvent({ actionSubjectAndAction: 'rightSidebarTimelineConfig opened' });
	},
	RightSidebarFieldOptionsSearchOccured: () => {
		sendPendoTrackEvent('rightSidebarFieldOptionsSearch occured');
	},
	IdeaDescriptionTemplatesButtonClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/JchzwUn3TuabHi4MyumsDRqBQwU
		sendPendoTrackEvent('ideaDescriptionTemplatesButton clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/47104
		fireUIAnalytics(event, 'button clicked', 'ideaDescriptionTemplatesButton');
	},
	IdeaDescriptionTemplateApplyClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/KdZV3lpa_O_MOZyzsSub8T0syH4
		sendPendoTrackEvent('ideaDescriptionTemplateApply clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/47105
		fireUIAnalytics(event, 'button clicked', 'ideaDescriptionTemplateApply');
	},
	IdeaDescriptionTemplateCreateClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/iDYpmhRk8iYXew9zsLep7s7Bfb4
		sendPendoTrackEvent('ideaDescriptionTemplateCreate clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/47106
		fireUIAnalytics(event, 'button clicked', 'ideaDescriptionTemplateCreate');
	},
	IdeaDescriptionTemplateUpdateClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/Y-cdYKUd1JzkhvvS3_u-vo-XmKg
		sendPendoTrackEvent('ideaDescriptionTemplateUpdate clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/47107
		fireUIAnalytics(event, 'button clicked', 'ideaDescriptionTemplateUpdate');
	},
	IdeaDescriptionTemplateDeleteClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/Eg4_nuY5UbatLwwO1pI5pCU7SMg
		sendPendoTrackEvent('ideaDescriptionTemplateDelete clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/47108
		fireUIAnalytics(event, 'button clicked', 'ideaDescriptionTemplateDelete');
	},
	IdeaDescriptionTemplateApplied: (event: UIAnalyticsEvent, templateId: string | undefined) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/nTQAWILD5CRM2qYw_xH732QrEws
		// https://app.pendo.io/s/4758009750487040/trackevents/crl2tr_HaEyOJpcM9a9qR4YFFx4
		// https://app.pendo.io/s/4758009750487040/trackevents/BVmG9LifMmegmliDAjGlab0NzvQ
		// https://app.pendo.io/s/4758009750487040/trackevents/wTLKudshXTHg45sd2ZsKRC90t_U
		// https://app.pendo.io/s/4758009750487040/trackevents/ilWZEZJMAX4ozUbyEzRW93ZL5sw
		sendPendoTrackEvent(`applyDescriptionTemplate${templateId || ''} clicked`);

		// https://data-portal.internal.atlassian.com/analytics/registry/47109
		fireTrackAnalytics(event, 'ideaDescriptionTemplate applied', templateId || '');
	},
	SortChangeToViewRankButtonClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/FqF53XXrRrC5a5tmcjDt6CyjLEE
		sendPendoTrackEvent('viewRankButton clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/47110
		fireUIAnalytics(event, 'button clicked', 'sortChangeToViewRankButton');
	},
	SortChangeToProjectRankButtonClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/ayeJj95x4OuyqD-dg33FITDD0-Q
		sendPendoTrackEvent('changeToProjectRankButton clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/47111
		fireUIAnalytics(event, 'button clicked', 'sortChangeToProjectRankButton');
	},
	SortChangeToProjectRankAndCommitButtonClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/mqPhVSm3WfHFO6xueL7FjerRVVQ
		sendPendoTrackEvent('changeToProjectAndCommitRankButton clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/47112
		fireUIAnalytics(event, 'button clicked', 'sortChangeToProjectAndCommitRankButton');
	},
	IdeaRankedInView: () => {
		// https://app.pendo.io/s/4758009750487040/trackevents/Rmc-JGgcaUg6_W5FAwHYzTjNaGs
		sendPendoTrackEvent('per-view-rank_updated');
	},
	MatrixBubbleTooltipDisplayed: (
		event: UIAnalyticsEvent,
		issueAnalyticsData:
			| (Partial<IssueAnalyticsData> & { bubbleType: 'single' })
			| { clusteredIssueIds: string[]; bubbleType: 'clustered' }
			| undefined,
	) => {
		sendPendoTrackEvent('matrixViewBubbleTooltip displayed');

		// https://data-portal.internal.atlassian.com/analytics/registry/50600
		fireUIAnalytics(event, 'tooltip displayed', 'matrixViewBubble', issueAnalyticsData || {});
	},
	ViewControls: {
		openColumnsSidebarButtonClicked: (event: UIAnalyticsEvent, attributes = {}) => {
			// https://app.pendo.io/s/4758009750487040/trackevents/5e7xyzPg4xscjaCRIt0bzKVNbYk
			sendPendoTrackEvent('openColumnsSidebarButton clicked');

			// https://data-portal.internal.atlassian.com/analytics/registry/51152
			fireUIAnalytics(event, 'button clicked', 'columns', attributes);
		},
		openGroupBySidebarButtonClicked: (event: UIAnalyticsEvent) => {
			// https://app.pendo.io/s/4758009750487040/trackevents/yc-bdFY4nzRIB8HGZFg8nmHLaMw
			sendPendoTrackEvent('openGroupBySidebarButton clicked');

			// https://data-portal.internal.atlassian.com/analytics/registry/51098
			fireUIAnalytics(event, 'button clicked', 'groupBy');
		},
		openFieldsSidebarButtonClicked: (event: UIAnalyticsEvent) => {
			// https://app.pendo.io/s/4758009750487040/trackevents/XNr3dWjVg28g4Ap2AfrjTSao5vU
			sendPendoTrackEvent('openFieldsSidebarButton clicked');

			// https://data-portal.internal.atlassian.com/analytics/registry/51106
			fireUIAnalytics(event, 'button clicked', 'fields');
		},
		openFilterSidebarButtonClicked: (event: UIAnalyticsEvent) => {
			// https://app.pendo.io/s/4758009750487040/trackevents/lkQcHgUcckvv4fqRwH5kH43uj0c
			sendPendoTrackEvent('openFilterSidebarButton clicked');

			// data-portal.internal.atlassian.com/analytics/registry/51100
			fireUIAnalytics(event, 'button clicked', 'filter');
		},
		openSortSidebarButtonClicked: (event: UIAnalyticsEvent) => {
			sendPendoTrackEvent('openSortSidebarButton clicked');

			// https://data-portal.internal.atlassian.com/analytics/registry/51102
			fireUIAnalytics(event, 'button clicked', 'sort');
		},
		openMatrixXAxisSidebarButtonClicked: (event: UIAnalyticsEvent, attributes = {}) => {
			sendPendoTrackEvent('openMatrixXSidebarButton clicked');

			// https://data-portal.internal.atlassian.com/analytics/registry/51111
			fireUIAnalytics(event, 'button clicked', 'xAxis', attributes);
		},
		openMatrixYAxisSidebarButtonClicked: (event: UIAnalyticsEvent, attributes = {}) => {
			sendPendoTrackEvent('openMatrixYSidebarButton clicked');

			// https://data-portal.internal.atlassian.com/analytics/registry/51112
			fireUIAnalytics(event, 'button clicked', 'yAxis', attributes);
		},
		openMatrixZAxisSidebarButtonClicked: (event: UIAnalyticsEvent) => {
			sendPendoTrackEvent('openMatrixZSidebarButton clicked');

			// https://data-portal.internal.atlassian.com/analytics/registry/51115
			fireUIAnalytics(event, 'button clicked', 'size');
		},
	},

	NavSidebarPlusDropdownitemClicked: (event: UIAnalyticsEvent, viewKind: string) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/nu1EELTcS0GLr-UHw567_0k4ICk BOARD
		// https://app.pendo.io/s/4758009750487040/trackevents/G4co93jddFLvabbue_FGZlaLaZY MATRIX
		// https://app.pendo.io/s/4758009750487040/trackevents/wafmt5zQbXNRsxAVYtUhBpU85x0 SECTION
		// https://app.pendo.io/s/4758009750487040/trackevents/Md7wujC7XbHk6fcqv2jhB_UmDOQ TABLE
		// https://app.pendo.io/s/4758009750487040/trackevents/994nTMJuZg69jlOiJDdKi147MnA TIMELINE
		sendPendoTrackEvent(`navSidebarPlusButtonItem ${viewKind}`);

		// https://data-portal.internal.atlassian.com/analytics/registry/51025
		fireUIAnalytics(event, 'dropdownItem clicked', 'createViewOrSection', {
			selectedActionItem: `create${mapViewKindToType(viewKind)}`,
		});
		// archive this old event https://data-portal.internal.atlassian.com/analytics/registry/32259
	},
	NavSidebarCreateViewDropdownitemClicked: (event: UIAnalyticsEvent, viewKind: string) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/0EdRA1ITiMFiOSSEp3-dtavyZbo BOARD
		// https://app.pendo.io/s/4758009750487040/trackevents/gzxdZw-0LQeC2g89asY7Np7HLhI MATRIX
		// https://app.pendo.io/s/4758009750487040/trackevents/BkizrhPyoBdyyEvEIVXmLMn91kk SECTION
		// https://app.pendo.io/s/4758009750487040/trackevents/nX3mY7GocKIxPz5Zb4jqE9Wmr-o TABLE
		// https://app.pendo.io/s/4758009750487040/trackevents/SNnVX1fmm7aKSrSOqEt2zx2VWJY TIMELINE
		sendPendoTrackEvent(`navSidebarCreateViewButtonItem ${viewKind}`);

		// https://data-portal.internal.atlassian.com/analytics/registry/51025
		fireUIAnalytics(event, 'dropdownItem clicked', 'createViewOrSection', {
			selectedActionItem: `create${mapViewKindToType(viewKind)}`,
		});
		// archive this old event https://data-portal.internal.atlassian.com/analytics/registry/32259
	},
	NavSidebarViewKebabCloneViewAsListClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/lJG7R-6iywlELtqR6-ixHOp4MQo
		sendPendoTrackEvent('navSidebar cloneViewAsListKebab clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/51085
		fireUIAnalytics(event, 'dropdownItem clicked', 'viewActionItem', {
			selectedActionItem: 'cloneAsList',
		});
	},
	NavSidebarViewKebabCloneViewAsBoardClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/HA4TEsBJklVPQDHvABXaSh3yhts
		sendPendoTrackEvent('navSidebar cloneViewAsBoardKebab clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/51085
		fireUIAnalytics(event, 'dropdownItem clicked', 'viewActionItem', {
			selectedActionItem: 'cloneAsBoard',
		});
	},
	NavSidebarViewKebabCloneViewAsMatrixClicked: (event: UIAnalyticsEvent) => {
		sendPendoTrackEvent('navSidebar cloneViewAsMatrixKebab clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/51085
		fireUIAnalytics(event, 'dropdownItem clicked', 'viewActionItem', {
			selectedActionItem: 'cloneAsMatrix',
		});
	},
	NavSidebarViewKebabCloneViewAsTimelineClicked: (event: UIAnalyticsEvent) => {
		sendPendoTrackEvent('navSidebar cloneViewAsTimelineKebab clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/51085
		fireUIAnalytics(event, 'dropdownItem clicked', 'viewActionItem', {
			selectedActionItem: 'cloneAsTimeline',
		});
	},
	NavSidebarViewKebabDeleteViewClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/nCqpRV9mZzGZbxRlFtNBUc0J2Ik
		sendPendoTrackEvent('navSidebar deleteViewKebab clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/51085
		fireUIAnalytics(event, 'dropdownItem clicked', 'viewActionItem', {
			selectedActionItem: 'delete',
		});
	},
	NavSidebarViewKebabRenameViewClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/qov7DM6puEYBB-gy-KtJomlxLb8
		sendPendoTrackEvent('navSidebar renameViewKebab clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/51085
		fireUIAnalytics(event, 'dropdownItem clicked', 'viewActionItem', {
			selectedActionItem: 'rename',
		});
	},
	NavSidebarViewEmojiRemove: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/gRjWMaouDLTuByt85NPGsNiq0oU
		sendPendoTrackEvent('navSidebar removeEmoji clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/51085
		fireUIAnalytics(event, 'dropdownItem clicked', 'viewActionItem', {
			selectedActionItem: 'removeEmoji',
		});
	},
	NavSidebarViewEmojiAdd: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/OXnJYJtQaJDwoj1Wu9KnkFaoDUE
		sendPendoTrackEvent('navSidebar addEmoji clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry
		fireUIAnalytics(event, 'navSidebarItem clicked', 'viewAddEmoji');
	},
	NavSidebarViewClicked: (event: UIAnalyticsEvent, viewKind = '') => {
		// https://app.pendo.io/s/4758009750487040/trackevents/0PGTFtKgeZmnnSyt6eue3hkZZlM BOARD
		// https://app.pendo.io/s/4758009750487040/trackevents/qXWj21iEeMmo21WvKVW951KZhTo MATRIX
		// https://app.pendo.io/s/4758009750487040/trackevents/xeRrNQmZIZvnUtaRWu4S8lsQQL8 TABLE
		// https://app.pendo.io/s/4758009750487040/trackevents/LoEQ2kACHWa-hBGwG2NcwPP4eCw TIMELINE
		sendPendoTrackEvent(`navSidebar ${viewKind} clicked`);

		// https://data-portal.internal.atlassian.com/analytics/registry/47126
		fireUIAnalytics(event, 'navSidebarItem clicked', 'view', {
			viewKind,
		});
	},
	NavSidebarArchiveViewClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/uZq6qCPnbyTKB84D9kI2c1xY2lM ARCHIVE
		sendPendoTrackEvent('navSidebar ARCHIVE clicked');

		// https://data-portal.internal.atlassian.com/analytics/registry/47126
		fireUIAnalytics(event, 'navSidebarItem clicked', 'view', {
			viewKind: 'ARCHIVE',
		});
	},
	NavSidebarSettingsClicked: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/Bh7L9c2N2UYwt3THvgqrihcH0q0 settings

		sendPendoTrackEvent('navSidebar settings clicked');
		// https://data-portal.internal.atlassian.com/analytics/registry/47126
		fireUIAnalytics(event, 'navSidebarItem clicked', 'settings');
	},
	TimelineGranularityChanged: (
		event: UIAnalyticsEvent,
		attributes: { timelineGranularity: string },
	) => {
		sendPendoTrackEvent({ actionSubjectAndAction: 'timelineGranularity clicked', attributes });
		fireUIAnalytics(event, 'button clicked', 'timelineGranularity', attributes);
	},
	TimelineGroupToggled: (event: UIAnalyticsEvent) => {
		sendPendoTrackEvent({ actionSubjectAndAction: 'timelineGroup clicked' });
		fireUIAnalytics(event, 'timelineGroup clicked', 'view');
	},
	TimelineRangeChanged: (event: UIAnalyticsEvent, attributes: { newDate: string }) => {
		sendPendoTrackEvent({ actionSubjectAndAction: 'timelineRange changed', attributes });
		fireUIAnalytics(event, 'timelineRange changed', 'settings', attributes);
	},
	TimelineFieldKeyChanged: () => {
		sendPendoTrackEvent('timelineFieldKey changed');
	},
	DateFilterTypeChanged: (event: UIAnalyticsEvent) => {
		sendPendoTrackEvent('dateFilterType changed');
		fireUIAnalytics(event, 'dateFilterType changed', 'settings');
	},
	ReactionAddedReactionsField: () => {
		sendPendoTrackEvent('reaction added');
	},
	ReactionRemovedReactionsField: () => {
		sendPendoTrackEvent('reaction removed');
	},
};

export const fireAnalyticsEventForIssueUpdate = (
	event: UIAnalyticsEvent,
	issueId: string | undefined,
	attributes: object,
) => {
	event.context.push({ objectId: issueId, objectType: 'issue' });
	issueId
		? fireTrackAnalytics(event, 'issue updated', issueId, attributes)
		: fireTrackAnalytics(event, 'issue updated', attributes);
};

type UseViewOpenedAnalyticsArgs = {
	viewId: string | undefined;
	viewAccessLevel: string | undefined;
	isViewPermissionsEnabled: boolean;
	isViewConfigured?: boolean;
	analyticsCallback: (viewAccessLevel: string | undefined) => void;
	isSharedView: boolean;
};

export const useViewOpenedAnalytics = ({
	viewId,
	viewAccessLevel,
	isViewPermissionsEnabled,
	isViewConfigured,
	analyticsCallback,
	isSharedView,
}: UseViewOpenedAnalyticsArgs) => {
	const previousCurrentViewId = useRef<string | undefined>(undefined);
	const previousViewAccessLevel = useRef<string | undefined>(undefined);
	const previousIsViewConfigured = useRef<boolean | undefined>(undefined);

	useEffect(() => {
		if (isSharedView) {
			return;
		}

		if (
			viewId !== previousCurrentViewId.current ||
			isViewConfigured !== previousIsViewConfigured.current
		) {
			previousCurrentViewId.current = viewId;
			previousIsViewConfigured.current = isViewConfigured;
			previousViewAccessLevel.current = undefined;
		}

		if (isViewPermissionsEnabled) {
			if (viewAccessLevel !== undefined && previousViewAccessLevel.current === undefined) {
				analyticsCallback(viewAccessLevel);
				previousViewAccessLevel.current = viewAccessLevel;
			}
		} else {
			analyticsCallback(undefined);
		}
	}, [
		isViewPermissionsEnabled,
		viewId,
		viewAccessLevel,
		analyticsCallback,
		isViewConfigured,
		isSharedView,
	]);

	useEffect(() => {
		if (isSharedView) {
			analyticsCallback(undefined);
		}
	}, [isSharedView, analyticsCallback]);
};
