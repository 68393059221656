import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import RecurIcon from '@atlaskit/icon-lab/core/recur';
import CalendarIcon from '@atlaskit/icon/core/migration/calendar';
import ClockIcon from '@atlaskit/icon/core/clock';
import WarningOutlineIcon from '@atlaskit/icon-lab/core/warning-outline';
import Tooltip from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { useRecurWorkItemState } from '@atlassian/jira-recur-work-item/src/controllers/index.tsx';
import { isRecurOnCompleteToggleRule } from '@atlassian/jira-recur-work-item/src/ui/utils.tsx';
import { messages } from './messages.tsx';

const RecurringWorkItemIcon = ({ testId }: { testId?: string }) => {
	const { issueProperties, isUpdatingRule } = useRecurWorkItemState();

	if ((!issueProperties && !isUpdatingRule) || isRecurOnCompleteToggleRule(issueProperties)) {
		return null;
	}

	return <RecurIcon label="" testId={testId} />;
};

export type ColoredDueDateProps = {
	dateString: string;
	pastDue: boolean;
	dueToday: boolean;
	warningIconTestId: string;
	clockIconTestId: string;
	calendarIconTestId: string;
	tooltipTestId?: string;
	isShortFormat?: boolean;
	maybeShowRecurIcon?: boolean;
	recurIconTestId?: string;
};

export const ColoredDueDate = ({
	dateString,
	pastDue,
	dueToday,
	warningIconTestId,
	clockIconTestId,
	calendarIconTestId,
	tooltipTestId,
	isShortFormat = false,
	maybeShowRecurIcon = false,
	recurIconTestId,
}: ColoredDueDateProps) => {
	let locale = useLocale();
	if (locale === undefined) {
		locale = 'en_US';
	}
	const localeString = locale.replace('_', '-');
	const formatter = new Intl.DateTimeFormat(localeString, {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		timeZone: 'UTC',
		timeZoneName: 'short',
	});
	const parts = formatter.formatToParts(new Date(dateString));

	const day = parts.find((part) => part.type === 'day')?.value;
	const month = parts.find((part) => part.type === 'month')?.value;
	const year = parts.find((part) => part.type === 'year')?.value;
	const timezone = parts.find((part) => part.type === 'timeZoneName')?.value;

	// Use short format (without year) if isShortFormat is true
	const displayDate = isShortFormat ? `${day} ${month}` : `${day} ${month} ${year}`;
	const tooltipDate = `${day} ${month} ${year}, ${timezone}`;

	const dueMessage = pastDue ? (
		<FormattedMessage {...messages.overdue} values={{ date: tooltipDate }} />
	) : (
		<FormattedMessage {...messages.due} values={{ date: tooltipDate }} />
	);

	const { formatMessage } = useIntl();
	const iconLabel = pastDue
		? formatMessage(messages.overdue, { date: tooltipDate })
		: formatMessage(messages.due, { date: tooltipDate });

	return (
		<Tooltip content={dueMessage} position="top" testId={tooltipTestId}>
			<Box
				xcss={[
					styles.dateTag,
					styles.flexContainer,
					pastDue ? styles.overdue : undefined,
					dueToday ? styles.dueToday : undefined,
					!pastDue && !dueToday ? styles.normalDate : undefined,
				]}
				testId="coloured-due-date.ui.colored-due-date-container"
				tabIndex={0}
				role="button"
				aria-label={iconLabel}
			>
				<Box xcss={styles.dueDateIcon} testId="coloured-due-date.ui.tooltip-container">
					{pastDue && (
						<WarningOutlineIcon
							LEGACY_size="medium"
							label={iconLabel}
							color={token('color.text.danger')}
							testId={warningIconTestId}
						/>
					)}
					{dueToday && (
						<ClockIcon
							LEGACY_size="medium"
							label={iconLabel}
							color={token('color.text.warning')}
							testId={clockIconTestId}
						/>
					)}
					{!dueToday && !pastDue && (
						<CalendarIcon
							label={iconLabel}
							color={token('color.text')}
							LEGACY_size="medium"
							testId={calendarIconTestId}
						/>
					)}
				</Box>
				<span aria-hidden="true">{displayDate}</span>
				{maybeShowRecurIcon && <RecurringWorkItemIcon testId={recurIconTestId} />}
			</Box>
		</Tooltip>
	);
};

const styles = {
	dateTag: xcss({
		paddingLeft: 'space.075',
		paddingRight: 'space.075',
		paddingTop: 'space.025',
		paddingBottom: 'space.025',
		borderStyle: 'solid',
		borderRadius: 'border.radius.100',
		borderWidth: 'border.width',
		color: 'color.text',
		cursor: 'pointer',
	}),
	flexContainer: xcss({
		display: 'flex',
		alignItems: 'center',
		gap: 'space.050',
	}),
	overdue: xcss({
		color: 'color.text.danger',
		borderColor: 'color.border.danger',
	}),
	dueToday: xcss({
		color: 'color.text.warning',
		borderColor: 'color.border.warning',
	}),
	normalDate: xcss({
		color: 'color.text',
		borderColor: 'color.border',
	}),
	dueDateIcon: xcss({
		display: 'flex',
		alignItems: 'center',
	}),
};
