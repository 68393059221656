import { createSelector } from 'reselect';
import values from 'lodash/values';
import compareAsc from 'date-fns/compareAsc';
import compareDesc from 'date-fns/compareDesc';
import {
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	CUSTOMER_SERVICE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import {
	OLDEST_FIRST,
	type ActivitySortOrderType,
} from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import {
	type CommentId,
	type OptimisticOrPersistedCommentsById,
	COMMENT_VISIBILITY_PUBLIC,
	type PersistedCommentsById,
} from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FULL_ISSUE } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import {
	isServiceDeskSelector,
	accountIdloggedInUserSelector,
	analyticsSourceSelector,
} from '../common/state/selectors/context-selector.tsx';
import {
	isCustomerServiceSelector,
	entitiesSelector,
	uiSelector,
	getUserByIdSelector,
	isSimplifiedProjectSelector,
	projectTypeSelector,
} from '../common/state/selectors/issue-selector.tsx';

import { permissionsSelector } from '../common/state/selectors/permissions-selector.tsx';
import {
	getSelectedActivitySortOrder,
	getSelectedActivityLayout,
} from './activity-feed-selector.tsx';
import { NEW_REPLY_COMMENT_ID_PREFIX } from './comment-constants.tsx';

export const deletedIdsSelector = createSelector(uiSelector, (ui) => ui.comments.pendingDeletedIds);

export const entitiesCommentsSelector = createSelector(
	entitiesSelector,
	(entities) => entities.comments,
);

export const totalCommentsSelector = createSelector(
	entitiesSelector,
	(entities) => entities.totalComments,
);

export const startIndexCommentsSelector = createSelector(
	entitiesSelector,
	(entities) => entities.commentsStartIndex,
);

export const loadedCommentsSelector = createSelector(
	entitiesSelector,
	(entities) => entities.loadedComments,
);

export const commentsPageInfoSelector = createSelector(
	entitiesSelector,
	(entities) => entities.commentsPageInfo,
);

export const isLoadingMoreCommentsSelector = createSelector(
	uiSelector,
	(ui) => ui.comments.isLoadingMoreComments,
);

export const hasScrolledPermalinkCommentSelector = createSelector(
	uiSelector,
	(ui) => ui.comments.hasScrolledPermalinkComment,
);

export const pendingCommentsSelector = createSelector(
	uiSelector,
	(ui) => ui.comments.pendingSavedValues,
);

const deleteModalCommentIdSelector = createSelector(
	uiSelector,
	(ui) => ui.comments.deleteModalCommentId,
);

export const loadingMoreChildCommentsSelector = (commentId: CommentId) =>
	createSelector(uiSelector, (ui) => ui.comments?.childButtonLoadingState?.[commentId] ?? null);

export const loadingStageSelector = createSelector(uiSelector, (ui) => ui.comments.loadingStage);

export const allCommentsSelector = createSelector(
	entitiesCommentsSelector,
	pendingCommentsSelector,

	(comments, optimisticComments) => {
		const allComments: OptimisticOrPersistedCommentsById | PersistedCommentsById = { ...comments };
		for (const key in optimisticComments) {
			if (Object.prototype.hasOwnProperty.call(comments, key)) {
				allComments[key] = {
					...allComments[key],
					...optimisticComments[key],
				};
			} else {
				allComments[key] = optimisticComments[key];
			}
		}
		return allComments;
	},
);

const commentSelector = (commentId: CommentId) =>
	createSelector(allCommentsSelector, (comments) => comments[commentId]);

const persistedCommentSelector = (commentId: CommentId) =>
	createSelector(entitiesCommentsSelector, (comments) => comments[commentId]);

// If the user is anonymous, then they are not considered comment author
const isUserCommentAuthorSelector = (commentId: CommentId) =>
	createSelector(
		commentSelector(commentId),
		accountIdloggedInUserSelector,
		(comment, loggedInUserId) =>
			loggedInUserId !== null && loggedInUserId === (comment && comment.authorId),
	);

const authorSelector = (commentId: CommentId, authorIdPropertyName: string) =>
	createSelector(commentSelector(commentId), getUserByIdSelector, (comment, userByIdSelector) =>
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'OptimisticComment | PersistedComment'.
		comment && comment[authorIdPropertyName]
			? // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'OptimisticComment | PersistedComment'.
				userByIdSelector(comment[authorIdPropertyName])
			: null,
	);

const uiAllCommentsSelector = createSelector(uiSelector, (ui) => (ui && ui.comments) || null);

const uiAllCommentEditorsSelector = createSelector(
	uiAllCommentsSelector,
	(uiComments) => (uiComments && uiComments.commentEditors) || null,
);

const uiCommentEditorSelector = (commentId: CommentId) =>
	createSelector(
		uiAllCommentEditorsSelector,
		(uiCommentEditors) => (uiCommentEditors && uiCommentEditors[commentId]) || null,
	);

export const commentAuthorSelector = (commentId: CommentId) =>
	authorSelector(commentId, 'authorId');

export const commentUpdateAuthorSelector = (commentId: CommentId) =>
	authorSelector(commentId, 'updateAuthorId');

export const commentEditingBodySelector = (commentId: CommentId) =>
	createSelector(
		uiCommentEditorSelector(commentId),
		(comment) => (comment && comment.editingValue) || null,
	);

export const commentEditingVisibilitySelector = (commentId: CommentId) =>
	createSelector(
		uiCommentEditorSelector(commentId),
		(comment) => (comment && comment.visibility) || null,
	);

export const commentEditingOrViewBodyAdfSelector = (commentId: CommentId) =>
	createSelector(
		commentEditingBodySelector(commentId),
		commentSelector(commentId),
		(editingComment, comment) => editingComment || (comment && comment.bodyAdf) || null,
	);

export const commentOutsiderCommentSelector = (commentId: CommentId) =>
	createSelector(commentSelector(commentId), (comment) =>
		comment ? comment.jsdAuthorCanSeeRequest : null,
	);

export const commentBodyHtmlSelector = (commentId: CommentId) =>
	createSelector(commentSelector(commentId), (comment) => (comment ? comment.bodyHtml : null));

export const commentIsDeletedSelector = (commentId: CommentId) =>
	createSelector(commentSelector(commentId), (comment) => (comment ? comment.isDeleted : null));

export const commentRepliesSelector = (commentId: CommentId) =>
	createSelector(
		persistedCommentSelector(commentId),
		allCommentsSelector,
		deletedIdsSelector,
		(comment, allComments, deletedIds) => {
			if (comment?.childCommentIds && comment.childCommentIds.size > 0) {
				const nonDeletedReplies = Array.from(comment.childCommentIds).filter(
					(id) => !deletedIds.includes(id) && allComments[id],
				);
				if (nonDeletedReplies.length > 0) {
					return nonDeletedReplies
						.map(mapIdToCommentSortInfo(allComments))
						.sort(compareComments)
						.map((sortedComment) => sortedComment.id);
				}
			}

			return null;
		},
	);

export const childCommentsPageInfoSelector = (commentId: CommentId) =>
	createSelector(
		persistedCommentSelector(commentId),
		(comment) => comment?.childCommentsPageInfo ?? {},
	);

export const commentCreatedDateSelector = (commentId: CommentId) =>
	createSelector(commentSelector(commentId), (comment) => (comment ? comment.createdDate : null));

export const commentUpdatedDateSelector = (commentId: CommentId) =>
	createSelector(commentSelector(commentId), (comment) => (comment ? comment.updatedDate : null));

export const commentEditedSelector = (commentId: CommentId) =>
	createSelector(commentSelector(commentId), (comment) => (comment ? comment.edited : null));

export const pendingCommentSelector = (commentId: CommentId) =>
	createSelector(pendingCommentsSelector, (pendingComments) => pendingComments[commentId]);

export const commentIsOptimisticSelector = (commentId: CommentId) =>
	createSelector(pendingCommentSelector(commentId), (pendingComment) => !!pendingComment);

export const commentSaveFailedSelector = (commentId: CommentId) =>
	createSelector(
		pendingCommentSelector(commentId),
		(pendingComment) => !!(pendingComment && pendingComment.hasSaveFailed),
	);

export const commentHasDeleteModalSelector = (commentId: CommentId) =>
	createSelector(
		deleteModalCommentIdSelector,
		(deleteModalCommentId) => deleteModalCommentId === commentId,
	);

export const commentVisibilitySelector = (commentId: CommentId) =>
	createSelector(commentSelector(commentId), (comment) => {
		return comment ? comment.visibility : COMMENT_VISIBILITY_PUBLIC;
	});

const commentIsInternalSelector = (commentId: CommentId) =>
	createSelector(commentSelector(commentId), (comment) => (comment ? comment.isInternal : false));

export const commentEditingOrViewVisibilitySelector = (commentId: CommentId) =>
	createSelector(
		commentEditingVisibilitySelector(commentId),
		commentVisibilitySelector(commentId),
		(editingVisibility, visibility) => editingVisibility || visibility,
	);

export const commentEditingEventOccurredAtSelector = (commentId: CommentId) =>
	createSelector(
		uiCommentEditorSelector(commentId),
		(comment) => (comment && comment.eventOccurredAt) || null,
	);

export const commentEventOccurredAtSelector = (commentId: CommentId) =>
	createSelector(commentSelector(commentId), (comment) =>
		comment && comment.eventOccurredAt ? comment.eventOccurredAt : null,
	);

export const commentEditingOrViewEventOccurredAtSelector = (commentId: CommentId) =>
	createSelector(
		commentEditingEventOccurredAtSelector(commentId),
		commentEventOccurredAtSelector(commentId),
		(editingEventOccurredAt, eventOccurredAt) => editingEventOccurredAt || eventOccurredAt,
	);

export const commentJsdIncidentActivityViewHiddenSelector = (commentId: CommentId) =>
	createSelector(commentSelector(commentId), (comment) =>
		comment && typeof comment.jsdIncidentActivityViewHidden === 'boolean'
			? comment.jsdIncidentActivityViewHidden
			: null,
	);

export const commentEditSessionContainsAttachmentsUsageSelector = (commentId: CommentId) =>
	createSelector(
		uiCommentEditorSelector(commentId),
		(comment): boolean => !!comment?.editSessionContainsAttachmentsUsage,
	);

export const isInternalCommentSelector = functionWithCondition(
	() => fg('jcs_project_type_m3'),
	(commentId: CommentId) =>
		createSelector(
			isServiceDeskSelector,
			isCustomerServiceSelector,
			commentIsInternalSelector(commentId),
			(isServiceDeskComment, isCustomerServiceComment, isInternal) =>
				(isServiceDeskComment || isCustomerServiceComment) && isInternal,
		),
	(commentId: CommentId) =>
		createSelector(
			isServiceDeskSelector,
			commentIsInternalSelector(commentId),
			(isServiceDeskComment, isInternal) => isServiceDeskComment && isInternal,
		),
);

export const isEditingInternalCommentSelector = (commentId: CommentId) =>
	createSelector(
		uiCommentEditorSelector(commentId),
		(commentEditor) => commentEditor?.isInternal ?? true,
	);

export const canAddCommentsSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canAddComments,
);

export const canDeleteCommentSelector = (commentId: CommentId) =>
	createSelector(
		permissionsSelector,
		isUserCommentAuthorSelector(commentId),
		(permissions, isUserCommentAuthor) =>
			permissions.canDeleteAllComments || (permissions.canDeleteOwnComments && isUserCommentAuthor),
	);

export const canEditCommentSelector = (commentId: CommentId) =>
	createSelector(
		permissionsSelector,
		isUserCommentAuthorSelector(commentId),
		(permissions, isUserCommentAuthor) =>
			permissions.canEditAllComments || (permissions.canEditOwnComments && isUserCommentAuthor),
	);

const mapIdToCommentSortInfo = (comments: OptimisticOrPersistedCommentsById) => (id: string) => {
	const comment = comments[id];
	return {
		id,
		createdDate: new Date(comment.createdDate),
		eventOccurredAt: comment.eventOccurredAt ? new Date(comment.eventOccurredAt) : null,
		size: comment?.childCommentIds?.size ?? 0,
	};
};

const compareComments = (
	a: { eventOccurredAt: number | Date | null; createdDate: number | Date },
	b: { eventOccurredAt: number | Date | null; createdDate: number | Date },
) => {
	if (
		a.eventOccurredAt &&
		b.eventOccurredAt &&
		compareAsc(a.eventOccurredAt, b.eventOccurredAt) === 0
	) {
		return compareAsc(a.createdDate, b.createdDate);
	}
	const dateForA = a.eventOccurredAt ? a.eventOccurredAt : a.createdDate;
	const dateForB = b.eventOccurredAt ? b.eventOccurredAt : b.createdDate;

	return compareAsc(dateForA, dateForB);
};

export const visibleCommentIdsSelector = createSelector(
	allCommentsSelector,
	deletedIdsSelector,
	(comments, deletedIds) => {
		// filter out any comments currently pending deletion
		const nonDeletedCommentIds = Object.keys(comments).filter((id) => !deletedIds.includes(id));

		// sort the remaining comment ids by their created date
		const sortedIds = nonDeletedCommentIds
			.map(mapIdToCommentSortInfo(comments))
			.sort(compareComments)
			.map((comment) => comment.id);
		return sortedIds;
	},
);

const compareCommentsInclSortOrderType =
	(sortOrderType: ActivitySortOrderType) =>
	(
		a: { eventOccurredAt: number | Date | null; createdDate: number | Date },
		b: { eventOccurredAt: number | Date | null; createdDate: number | Date },
	) => {
		if (
			a.eventOccurredAt &&
			b.eventOccurredAt &&
			compareAsc(a.eventOccurredAt, b.eventOccurredAt) === 0
		) {
			return sortOrderType === OLDEST_FIRST
				? compareAsc(a.createdDate, b.createdDate)
				: compareDesc(a.createdDate, b.createdDate);
		}
		const dateForA = a.eventOccurredAt ? a.eventOccurredAt : a.createdDate;
		const dateForB = b.eventOccurredAt ? b.eventOccurredAt : b.createdDate;

		return sortOrderType === OLDEST_FIRST
			? compareAsc(dateForA, dateForB)
			: compareDesc(dateForA, dateForB);
	};

export const isThreadedCommentsEnabledSelector = createSelector(
	projectTypeSelector,
	allCommentsSelector,
	(projectType, allComments) =>
		(projectType === SOFTWARE_PROJECT || projectType === CORE_PROJECT) &&
		allComments &&
		Object.keys(allComments).length > 0 &&
		expVal('threaded_comments_ga', 'isEnabled', false),
);

export const sortedVisibleCommentIdsSelector = createSelector(
	getSelectedActivitySortOrder,
	allCommentsSelector,
	deletedIdsSelector,
	isThreadedCommentsEnabledSelector,
	(sortOrderType, comments, deletedIds, isThreadedCommentsEnabled) => {
		// filter out any comments currently pending deletion and replies as well
		const nonDeletedCommentIds = Object.keys(comments).filter(
			(id) =>
				((deletedIds.includes(id) && comments[id]?.isDeleted && isThreadedCommentsEnabled) ||
					!deletedIds.includes(id)) &&
				(isThreadedCommentsEnabled ? !comments[id].parentId : true),
		);

		// sort the remaining comment ids by their created date
		const sortedIds = nonDeletedCommentIds
			.map(mapIdToCommentSortInfo(comments))
			.sort(compareCommentsInclSortOrderType(sortOrderType))
			.map((comment) => {
				// Both should be cleaned up together.
				if (fg('cleanup_unwanted_threaded_comments_flag_check') || isThreadedCommentsEnabled) {
					return {
						id: comment.id,
						size: comment.size,
					};
				}
				return comment.id;
			});
		return sortedIds;
	},
);

export const getPersistedLatestCommentTimeSelector = createSelector(
	uiSelector,
	(ui) => ui.comments.latestCommentViewInteractedTime,
);

export const getCommentsViewedEventTriggeredSelector = createSelector(
	uiSelector,
	(ui) => ui.comments.commentsViewedEventTriggered,
);

export const getIsRealTimeCommentUpdatedSelector = createSelector(
	uiSelector,
	(ui) => ui.comments.isRealTimeCommentUpdated,
);

export const getFullScreenLayoutSelector = createSelector(
	getSelectedActivityLayout,
	analyticsSourceSelector,
	projectTypeSelector,
	(selectedActivityFeedLayout, analyticSource, projectType) => {
		if (
			(projectType === SOFTWARE_PROJECT || projectType === CORE_PROJECT) &&
			analyticSource === FULL_ISSUE
		) {
			return selectedActivityFeedLayout;
		}
		return null;
	},
);

export const getUnreadCommentIdsSelector = createSelector(
	accountIdloggedInUserSelector,
	getPersistedLatestCommentTimeSelector,
	allCommentsSelector,
	deletedIdsSelector,
	(currentUserId, latestCommentTime, allComments, deletedIds) => {
		if (!latestCommentTime) {
			return [];
		}

		return Object.keys(allComments).filter((commentId) => {
			const { createdDate, authorId } = allComments[commentId];

			// Exclude comments from current user and deleted comments in unread count
			if (
				currentUserId === authorId ||
				deletedIds.includes(commentId) ||
				allComments[commentId]?.isDeleted
			) {
				return false;
			}

			// Check if the comment is more recent than the latest comment time
			return new Date(createdDate) > new Date(latestCommentTime);
		});
	},
);

export const getUnreadCommentCountSelector = createSelector(
	getUnreadCommentIdsSelector,
	(unreadCommentIds) => {
		return unreadCommentIds.length;
	},
);

export const getTopMostUnreadCommentIdSelector = createSelector(
	allCommentsSelector,
	sortedVisibleCommentIdsSelector,
	getUnreadCommentIdsSelector,
	(comments, visibleCommentIds, unreadCommentIds) => {
		for (const comment of visibleCommentIds) {
			const commentId = typeof comment === 'object' ? comment.id : comment;

			// Check if the parent comment itself is unread
			if (unreadCommentIds.includes(commentId)) {
				return commentId;
			}

			// Get child comment IDs for the current comment
			const childCommentIds = comments[commentId]?.childCommentIds;

			// If there are child comment IDs, check if any are unread
			if (childCommentIds instanceof Set) {
				for (const childCommentId of childCommentIds) {
					if (unreadCommentIds.includes(childCommentId)) {
						return childCommentId;
					}
				}
			}
		}
		return null;
	},
);

export const getCommentScrollStatusForRealTimeUpdates = createSelector(
	uiSelector,
	(ui) => ui.comments.commentScrollStatusForRealTimeUpdates,
);

export const isEditingCommentSelector = (commentId: CommentId) =>
	createSelector(
		uiCommentEditorSelector(commentId),
		(commentEditor) => !!commentEditor && commentEditor.isEditing,
	);

export const commentSessionIdSelector = createSelector(
	uiAllCommentsSelector,
	(uiComments) => (uiComments && uiComments.commentSessionId) || null,
);

export const getEditingCommentIdsSelector = createSelector(
	visibleCommentIdsSelector,
	(state) => state,
	(visibleCommentIds, state) =>
		visibleCommentIds.filter((commentId) => isEditingCommentSelector(commentId)(state)),
);

export const commentIdsSelector = createSelector(entitiesCommentsSelector, (comments) =>
	comments ? Object.keys(comments).filter((key) => typeof comments[key] === 'object') : [],
);

export const isEditingAnyCommentSelector = createSelector(
	commentIdsSelector,
	(state) => state,
	(commentIds, state) => commentIds.some((id) => isEditingCommentSelector(id)(state)),
);

export const isAddCommentEditorExpandedSelector = createSelector(
	uiSelector,
	(ui) => ui.comments.addCommentEditorExpand || false,
);

export const addCommentEditorForceFocusSelector = createSelector(
	uiSelector,
	(ui) => ui.comments.addCommentEditorForceFocus || 0,
);

export const isCommentEditorOpenSelector = createSelector(
	isAddCommentEditorExpandedSelector,
	uiAllCommentEditorsSelector,
	(isAddCommentEditorExpanded, commentEditors) =>
		isAddCommentEditorExpanded ||
		(commentEditors ? values(commentEditors).some((editor) => editor.isEditing) : false),
);

export const isCommentAttachmentInProgressSelector = createSelector(
	uiSelector,
	(ui) => ui.comments.isCommentAttachmentInProgress,
);

export const isCommentVisibilityRestrictionSupportedSelector = createSelector(
	isSimplifiedProjectSelector,
	projectTypeSelector,
	(isSimplified, projectType) =>
		!isSimplified &&
		(projectType === CORE_PROJECT ||
			projectType === SOFTWARE_PROJECT ||
			projectType === SERVICE_DESK_PROJECT ||
			(projectType === CUSTOMER_SERVICE_PROJECT && fg('jcs_project_type_m3'))),
);

const replyingToCommentIdSelector = createSelector(
	uiSelector,
	(ui) => ui.comments.replyingToCommentId,
);

export const isReplyingToCommentSelector = (commentId: CommentId) =>
	createSelector(
		replyingToCommentIdSelector,
		(replyingToCommentId) => replyingToCommentId === commentId,
	);

export const isCommentReplyEditorOpenSelector = createSelector(
	replyingToCommentIdSelector,
	(replyingToCommentId) => !!replyingToCommentId,
);

export const replyContentSelector = (commentId: CommentId) =>
	createSelector(
		commentEditingBodySelector(`${NEW_REPLY_COMMENT_ID_PREFIX}-${commentId}`),
		(editingComment) => editingComment || undefined,
	);

const replyingToAuthorIdSelector = createSelector(
	uiSelector,
	(ui) => ui.comments.replyingToAuthorId,
);

export const replyingToAuthorSelector = createSelector(
	replyingToAuthorIdSelector,
	getUserByIdSelector,
	(replyingToAuthorId, getUserById) => getUserById(replyingToAuthorId),
);

export const commentParentIdSelector = (commentId: CommentId) =>
	createSelector(commentSelector(commentId), (comment) => comment && comment.parentId);

export const commentParentSelector = (commentId: CommentId) =>
	createSelector(allCommentsSelector, commentParentIdSelector(commentId), (comments, parentId) =>
		parentId ? comments[parentId] : undefined,
	);

export const hasPublicCommentsSelector = createSelector(allCommentsSelector, (comments) =>
	Object.values(comments).some((comment) => comment.isInternal === false),
);
