import React, { type MouseEvent, type KeyboardEvent } from 'react';
import { LinkButton } from '@atlaskit/button/new';
import LinkExternalIcon from '@atlaskit/icon/utility/link-external';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import messages from './messages.tsx';

export type Props = {
	onClick?: (event: MouseEvent | KeyboardEvent) => void;
};

export const SearchIssues = ({ onClick }: Props) => {
	const { formatMessage } = useIntl();

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const redirectUrl = `/jira/servicedesk/projects/${projectKey}/issues`;

	return (
		<LinkButton
			testId="smart-related-issues-panel.ui.header.search-issues.link-button"
			iconAfter={LinkExternalIcon}
			target="_blank"
			href={redirectUrl}
			onClick={onClick}
		>
			{formatMessage(messages.search)}
		</LinkButton>
	);
};
