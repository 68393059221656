import React, { useCallback, useState } from 'react';
import Button from '@atlaskit/button/new';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useAssigneeField } from '@atlassian/jira-issue-field-assignee/src/services/use-assignee-field/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { AssigneeSuggestedAction } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/service/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

export const updateAssigneeTestId = 'servicedesk-ai-context-common.ui.actions.update-assignee';

export const UpdateAssignee = ({ onSuccess, onFailure, context }: ActionProps) => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag } = useFlagsService();
	const [isProcessing, setIsProcessing] = useState(false);
	const [, { saveValue: saveAssignee }] = useAssigneeField({ issueKey });

	const handleSuccess = useCallback(() => {
		const showSuccessDescription = () => {
			return fg('itsm-issue-renaming')
				? formatMessage(messages.successDescriptionIssueRefreshTerm, {
						assignee: context?.suggestion?.displayName,
					})
				: formatMessage(messages.successDescription, {
						assignee: context?.suggestion?.displayName,
					});
		};
		showFlag({
			type: 'success',
			title: formatMessage(messages.successTitle),
			description: showSuccessDescription(),
			messageId: 'servicedesk-ai-context-common.ui.actions.update-assignee.show-flag.success',
			messageType: 'transactional',
		});

		onSuccess && onSuccess();
	}, [showFlag, onSuccess, context, formatMessage]);

	const handleFailure = useCallback(
		(e: unknown) => {
			showFlag({
				type: 'error',
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.errorDescription),
				messageId: 'servicedesk-ai-context-common.ui.actions.update-assignee.show-flag.error',
				messageType: 'transactional',
			});

			onFailure && onFailure(e);
		},
		[showFlag, onFailure, formatMessage],
	);

	const updateAssignee = useCallback(async () => {
		setIsProcessing(true);
		const payload = {
			accountId: context.suggestion.accountId,
			displayName: context.suggestion.displayName,
			avatarUrls: context.suggestion.avatarUrls,
		};
		try {
			await saveAssignee(payload, null, createAnalyticsEvent({}));
			handleSuccess();
		} catch (e) {
			handleFailure(e);
		} finally {
			setIsProcessing(false);
		}
	}, [context, createAnalyticsEvent, saveAssignee, handleSuccess, handleFailure]);

	return (
		<>
			{fg('context_panel_ui_consistency') ? (
				<Button
					spacing="compact"
					onClick={updateAssignee}
					isLoading={isProcessing}
					testId={updateAssigneeTestId}
				>
					{formatMessage(messages.acceptAssignee)}
				</Button>
			) : (
				<Button onClick={updateAssignee} isLoading={isProcessing} testId={updateAssigneeTestId}>
					{formatMessage(messages.acceptAssignee)}
				</Button>
			)}
		</>
	);
};

export type ActionProps = {
	onSuccess?: () => void;
	onFailure?: (error?: unknown) => void;
	context: AssigneeSuggestedAction['context'];
};
