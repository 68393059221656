import React from 'react';
import { useFragment, graphql } from 'react-relay';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { IssueViewResolutionField } from '@atlassian/jira-issue-view-layout-resolution-field/src/ui/index.tsx';
import {
	DONE,
	categoryIdForStatusCategory,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { useWorkflowTransitionsTransitionInProgress } from '@atlassian/jira-issue-workflow-transitions-services/src/main.tsx';
import type { view_issueViewFoundation_ResolutionField$key } from '@atlassian/jira-relay/src/__generated__/view_issueViewFoundation_ResolutionField.graphql';

export type Props = {
	fragmentKey?: view_issueViewFoundation_ResolutionField$key | null;
};

export const Resolution = ({ fragmentKey }: Props) => {
	const data = useFragment<view_issueViewFoundation_ResolutionField$key>(
		graphql`
			fragment view_issueViewFoundation_ResolutionField on JiraIssue
			@argumentDefinitions(
				issueViewRelayResolutionFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-resolution.relayprovider"
				}
				issueViewRelayStatusFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-status.relayprovider"
				}
				issueViewRelayStatusPanelFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-status-panel.relayprovider"
				}
			) {
				resolutionField @include(if: $issueViewRelayResolutionFieldFlag) {
					...ui_issueViewLayoutResolutionField_IssueViewResolutionField

					fieldConfig {
						isEditable
					}
					resolution {
						# Check for the presence of a resolution
						__typename
					}
				}
				statusField @include(if: $issueViewRelayStatusPanelFlag) {
					status {
						statusCategory {
							statusCategoryId
						}
					}
				}
				...ui_issueViewLayoutStatusField_IssueViewStatusField
					@include(if: $issueViewRelayStatusFieldFlag)
			}
		`,
		fragmentKey ?? null,
	);

	const transitionInProgress = useWorkflowTransitionsTransitionInProgress();

	// Show the field if we have a resolution, or if the field is editable and the status is done
	const shouldShowResolutionField =
		data?.resolutionField?.resolution ||
		(data?.resolutionField?.fieldConfig?.isEditable &&
			data?.statusField?.status?.statusCategory?.statusCategoryId ===
				categoryIdForStatusCategory(DONE).toString());

	return (
		<>
			{!transitionInProgress && shouldShowResolutionField && (
				<UFOSegment name="issue-resolution">
					<div
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						data-testid="issue.views.issue-base.foundation.status.resolution"
					>
						<IssueViewResolutionField fragmentKey={data.resolutionField} />
					</div>
				</UFOSegment>
			)}
		</>
	);
};
