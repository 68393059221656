import { chain, freeze, merge, set, setIn } from 'icepick';
import omit from 'lodash/omit';
import { MINUTE } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import unsetIn from '@atlassian/jira-common-icepick/src/utils/unset-in/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import { noPermissions } from '@atlassian/jira-issue-view-common-constants/src/permissions.tsx';
import type { EntitiesState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	FETCH_ISSUE_PARENTS_SUCCESS,
	FETCH_ISSUE_PARENTS_WITH_INFO_SUCCESS,
	SET_PARENT_SUCCESS,
	UNSET_PARENT_SUCCESS,
} from '../../actions/assign-issue-parent-modal-actions.tsx';
import { PROJECT_ISSUE_TYPES_REQUEST_SUCCESS } from '../../actions/change-issue-type-actions.tsx';
import {
	CHILD_ISSUE_ADD_SUCCESS,
	SUBTASK_ADD_SUCCESS,
} from '../../actions/child-panel-actions.tsx';
import {
	DELETE_COMMENT_SUCCESS,
	FETCH_MORE_CHILD_COMMENTS_SUCCESS,
	FETCH_MORE_COMMENTS_SUCCESS,
	FETCH_SORTED_COMMENTS_SUCCESS,
	SAVE_COMMENT_SUCCESS,
} from '../../actions/comment-actions.tsx';
import { REQUEST_PANEL_METADATA_FETCH_SUCCESS } from '../../actions/request-panel-metadata-actions.tsx';
import { FETCH_LOGGED_IN_USER_SUCCESS } from '../../actions/user-fetch-actions.tsx';
import {
	FETCH_ISSUE_SUCCESS,
	REFRESH_ISSUE_SUCCESS,
} from '../../common/actions/issue-fetch-actions.tsx';
import { FETCH_SCREENID_SUCCESS } from '../../common/actions/issue-screenid-actions.tsx';
import loomReducer, { initialState as loomInitialState } from '../loom-reducer.tsx';
import cardCoverReducer, { initialState as cardCoverInitialState } from './card-cover-reducer.tsx';
import commentsReducer, { initialState as commentsInitialState } from './comments-reducer.tsx';
import confluenceAppLinksReducer, {
	initialState as confluenceAppLinksInitialState,
} from './confluence-app-links-reducer.tsx';
import confluencePagesReducer, {
	initialState as confluencePagesInitialState,
} from './confluence-pages-reducer.tsx';
import confluenceWhiteboardsReducer, {
	initialState as confluenceWhiteboardsInitialState,
} from './confluence-whiteboards-reducer.tsx';
import ecosystemReducer, { initialState as ecosystemInitialState } from './ecosystem-reducer.tsx';
import forgeReducer, { initialState as forgeInitialState } from './forge-reducer.tsx';
import issueContentPanelsReducer, {
	initialState as issueContentPanelsInitialState,
} from './issue-content-panels-reducer.tsx';
import issueLinksReducer, {
	initialState as issueLinksInitialState,
} from './issue-links-reducer.tsx';
import issueRemoteDataReducer, {
	initialState as issueRemoteDataInitialState,
} from './issue-remote-data-reducer.tsx';
import issueTypesReducer, {
	initialState as issueTypesInitialState,
} from './issue-types-reducer.tsx';
import jiraAppLinksReducer, {
	initialState as jiraAppLinksInitialState,
} from './jira-app-links-reducer.tsx';
import linkIdeasReducer, { initialState as jpdIdeasInitialState } from './link-idea-reducer.tsx';
import remoteIssueLinksReducer, {
	initialState as remoteIssueLinksInitialState,
} from './remote-issue-links-reducer.tsx';
import webLinksReducer, { initialState as webLinksInitialState } from './web-links-reducer.tsx';
import worklogReducer, { initialState as worklogInitialState } from './worklog-reducer.tsx';

export const initialState: EntitiesState = freeze({
	users: {},
	ecosystem: ecosystemInitialState,
	forge: forgeInitialState,
	issueLinks: issueLinksInitialState,
	issueTypes: issueTypesInitialState,
	issueContentPanels: issueContentPanelsInitialState,
	issueRemoteData: issueRemoteDataInitialState,
	permissions: noPermissions,
	comments: commentsInitialState,
	commentsPageInfo: {},
	totalComments: 0,
	commentsStartIndex: 0, // will be deprecated when jira_comments_agg_pagination is rolled out
	loadedComments: 0, // will be deprecated when jira_comments_agg_pagination is rolled out
	confluencePages: confluencePagesInitialState,
	confluenceWhiteboards: confluenceWhiteboardsInitialState,
	webLinks: webLinksInitialState,
	subtasks: [],
	cardCover: cardCoverInitialState,
	childrenIssues: [],
	parentIssue: null,
	parentLevelIssues: [],
	refreshedOn: 0,
	requestPanelMetadata: {
		channel: undefined,
		requestUrl: '',
	},
	worklog: worklogInitialState,
	confluenceAppLinks: confluenceAppLinksInitialState,
	remoteIssueLinks: remoteIssueLinksInitialState,
	jiraAppLinks: jiraAppLinksInitialState,
	issue: null,
	jiraSettings: {
		subtasksConfiguration: {
			enabled: true,
		},
		issueLinkTypes: [],
		timeTrackingConfiguration: {
			isTimeTrackingEnabled: false,
			daysPerWeek: 0,
			hoursPerDay: 0,
			// Possible values for defaultUnit can be: minute, hour, day, and week.
			defaultUnit: MINUTE,
		},
	},
	loom: loomInitialState,
	jpdIdeas: jpdIdeasInitialState,
});

const omitFieldsHandledByChildReducers = <T,>(payload: T, extraFieldsToOmit: string[] = []): T =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
	omit(payload as any, [...extraFieldsToOmit, 'forgeIssueProperties']) as any;

const reducerHelper = (
	state: EntitiesState,
	action: {
		type: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		payload: any;
	},
) => {
	const { type, payload } = action;
	switch (type) {
		case FETCH_ISSUE_SUCCESS: {
			const mergedState = merge(
				state,
				omitFieldsHandledByChildReducers(payload, ['issueContentPanels', 'issueTypes']),
			);

			return omit(
				mergedState,
				// @ts-expect-error TS2769: No overload matches this call.
				[
					// These keys should not appear inside the entities state, but exist inside the action payload
					['issue', 'fields'],
					['issue', 'contextItemsCategories'],
					['issue', 'contentItemsCategories'],
					'changeboardingLastInteracted',
					'licensedProducts',
					'licenseStates',
				],
			);
		}
		case FETCH_SCREENID_SUCCESS: {
			return merge(state, payload);
		}

		case FETCH_MORE_COMMENTS_SUCCESS: {
			return merge(state, payload);
		}

		case FETCH_MORE_CHILD_COMMENTS_SUCCESS: {
			const { parentId, comments, commentsPageInfo, users } = payload;

			let newState = chain(state)
				.setIn(['comments'], merge(state.comments, comments))
				.setIn(
					['comments', parentId, 'childCommentIds'],
					new Set([
						...(state.comments?.[parentId]?.childCommentIds ?? new Set()),
						...Object.keys(comments),
					]),
				)
				.setIn(['comments', parentId, 'childCommentsPageInfo'], commentsPageInfo)
				.value();

			if (users) {
				newState = set(newState, 'users', merge(state.users, users));
			}

			return newState;
		}

		case FETCH_SORTED_COMMENTS_SUCCESS: {
			const { comments, commentsStartIndex, loadedComments, totalComments } = payload;

			let newState;

			if (fg('jira_comments_agg_pagination')) {
				newState = set(state, 'comments', comments);

				if (totalComments !== null && totalComments !== undefined) {
					newState = set(newState, 'totalComments', totalComments);
				}
			} else {
				newState = chain(state)
					.setIn(['comments'], comments)
					.setIn(['totalComments'], totalComments)
					.value();
			}

			if (payload.users) {
				newState = set(newState, 'users', merge(state.users, payload.users));
			}
			if (fg('jira_comments_agg_pagination')) {
				newState = set(newState, 'commentsPageInfo', payload.commentsPageInfo);
			} else {
				newState = chain(newState)
					.setIn(['commentsStartIndex'], commentsStartIndex)
					.setIn(['loadedComments'], loadedComments)
					.value();
			}
			return newState;
		}

		case REFRESH_ISSUE_SUCCESS: {
			const actionPayload = omitFieldsHandledByChildReducers(payload);
			const refreshedState = Object.keys(actionPayload).reduce((updatedState, key) => {
				// HOT-85509 users in payload should not overwrite the existing users.
				if (key === 'users') {
					// merge users from payload into state.users
					return set(updatedState, 'users', merge(state.users, actionPayload.users));
				}

				if (key === 'issueTypes') {
					return updatedState;
				}

				// We do nothing when it comes to the following keys, because they should be handled by their own reducer.
				if (key === 'issueLinks' || key === 'issueContentPanels') {
					return updatedState;
				}

				return set(updatedState, key, actionPayload[key]);
			}, state);
			return setIn(refreshedState, ['refreshedOn'], Date.now());
		}
		case FETCH_LOGGED_IN_USER_SUCCESS: {
			const {
				user,
			}: {
				user: User;
			} = payload;
			const userKey = user.id;
			const users = { ...state.users };
			if (userKey && user.id && !users[user.id]) {
				users[user.id] = user;
			}
			return {
				...state,
				users,
			};
		}
		case SAVE_COMMENT_SUCCESS: {
			if (!payload.isNewComment) {
				return state;
			}

			if (fg('jira_comments_agg_pagination')) {
				return chain(state)
					.setIn(['totalComments'], state.totalComments + 1)
					.value();
			}

			return chain(state)
				.setIn(['loadedComments'], (state.loadedComments ?? 0) + 1)
				.setIn(['totalComments'], state.totalComments + 1)
				.value();
		}

		case DELETE_COMMENT_SUCCESS: {
			if (fg('jira_comments_agg_pagination')) {
				return chain(state)
					.setIn(['totalComments'], state.totalComments - 1)
					.value();
			}

			return chain(state)
				.setIn(['loadedComments'], (state.loadedComments ?? 0) - 1)
				.setIn(['totalComments'], state.totalComments - 1)
				.value();
		}

		case REQUEST_PANEL_METADATA_FETCH_SUCCESS: {
			const newState = unsetIn(state, ['requestPanelMetadata']);

			return setIn(newState, ['requestPanelMetadata'], payload);
		}

		case PROJECT_ISSUE_TYPES_REQUEST_SUCCESS:
			return setIn(state, ['project', 'issueTypes'], payload);

		case FETCH_ISSUE_PARENTS_SUCCESS:
			return setIn(state, ['parentLevelIssues'], payload);

		case FETCH_ISSUE_PARENTS_WITH_INFO_SUCCESS:
			return setIn(state, ['parentLevelIssues'], payload.candidates);

		case SET_PARENT_SUCCESS: {
			const { issueType, ...rest } = action.payload.parentIssue;
			const { name, iconUrl } = issueType || {};
			const parentIssue = {
				...rest,
				issueTypeName: name || '',
				issueTypeIconUrl: iconUrl || '',
			};

			return setIn(state, ['parentIssue'], parentIssue);
		}

		case UNSET_PARENT_SUCCESS:
			return setIn(state, ['parentIssue'], null);

		case CHILD_ISSUE_ADD_SUCCESS: {
			const {
				payload: { childIssue },
			} = action;

			// The inner store manages loading states
			childIssue.isLoading = undefined;

			const newChildren = JSON.parse(JSON.stringify(state.childrenIssues));
			newChildren.push(childIssue);
			return setIn(state, ['childrenIssues'], newChildren);
		}

		case SUBTASK_ADD_SUCCESS: {
			const {
				payload: { childIssue },
			} = action;

			const newSubtasks = JSON.parse(JSON.stringify(state.subtasks));
			newSubtasks.push(childIssue);
			return setIn(state, ['subtasks'], newSubtasks);
		}

		default:
			return state;
	}
};

const getFeatureFlaggedState = (state?: EntitiesState | null): EntitiesState => {
	// if we have state the reducer has already initialised the state
	if (state) {
		return state;
	}

	// each feature flagged state should populate this state object
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const featureFlaggedState: Record<string, any> = {};

	// use the initial state from the issue types reducer
	Object.assign(featureFlaggedState, {
		issueTypes: issueTypesInitialState,
	});

	return {
		...initialState,
		...featureFlaggedState,
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (stateFromParams: EntitiesState | null | undefined, action: any) => {
	const state = getFeatureFlaggedState(stateFromParams);

	// This is done to prevent the reducer logic from getting any more bloated
	// with switch cases. An eng-health task to refactor this file exists at BENTO-987.
	let newState = chain(state)
		.set('cardCover', cardCoverReducer(state.cardCover, action))
		.set('comments', commentsReducer(state.comments, action))
		.set('confluencePages', confluencePagesReducer(state.confluencePages, action))
		.set('confluenceWhiteboards', confluenceWhiteboardsReducer(state.confluenceWhiteboards, action))
		.set('issueLinks', issueLinksReducer(state.issueLinks, action))
		.set('ecosystem', ecosystemReducer(state.ecosystem, action))
		.set('forge', forgeReducer(state.forge, action))
		.set('confluenceAppLinks', confluenceAppLinksReducer(state.confluenceAppLinks, action))
		.set('issueContentPanels', issueContentPanelsReducer(state.issueContentPanels, action))
		.set('issueTypes', issueTypesReducer(state.issueTypes, action))
		.set('webLinks', webLinksReducer(state.webLinks, action))
		.set('jpdIdeas', linkIdeasReducer(state.jpdIdeas, action))
		.set('loom', loomReducer(state.loom, action))
		.value();

	newState = set(
		newState,
		'remoteIssueLinks',
		remoteIssueLinksReducer(state.remoteIssueLinks, action),
	);
	newState = set(newState, 'jiraAppLinks', jiraAppLinksReducer(state.jiraAppLinks, action));
	newState = set(newState, 'worklog', worklogReducer(state.worklog, action));
	newState = set(
		newState,
		'issueRemoteData',
		issueRemoteDataReducer(state.issueRemoteData, action),
	);

	// TODO: use combineReducers once each segment of state has been decomposed.
	return reducerHelper(newState, action);
};
