import React from 'react';
import WatchIcon from '@atlaskit/icon/core/migration/eye-open--watch';
import EyeOpenFilledIcon from '@atlaskit/icon/core/eye-open-filled';
import PersonIcon from '@atlaskit/icon/core/person';
import { Flex, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { REPORTER_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import messages from './messages.tsx';

type Props = {
	isInternal: boolean;
};

const CustomerBannerOld = ({ isInternal }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Flex xcss={[bannerStyles, isInternal && internalBannerStyles]} role="alert">
			<WatchIcon
				color={isInternal ? token('color.icon.accent.yellow') : undefined}
				label=""
				spacing="none"
				LEGACY_size="small"
			/>
			<Text>
				{formatMessage(isInternal ? messages.internalNoteBanner : messages.replyToCustomerBanner)}
			</Text>
		</Flex>
	);
};

const CustomerBannerNew = ({ isInternal }: Props) => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const [reporter] = useFieldValue({
		issueKey,
		fieldKey: REPORTER_TYPE,
	});

	const displayName = reporter?.displayName;
	const showDisplayName = displayName && !/@/.test(displayName);

	if (isInternal) {
		return (
			<Flex xcss={[bubleeAlertStyles, internalBubleeStyles]} role="alert">
				<EyeOpenFilledIcon label="" />
				<Text size="small" color="inherit">
					{formatMessage(messages.internalNote)}
				</Text>
			</Flex>
		);
	}

	return (
		<Flex xcss={bubleeAlertStyles} role="alert">
			<PersonIcon label="" />
			<Text size="small" color="inherit">
				{showDisplayName
					? formatMessage(messages.replyToCustomer, { displayName })
					: formatMessage(messages.replyToCustomerGeneric)}
			</Text>
		</Flex>
	);
};

export const CustomBanner = componentWithCondition(
	() => fg('berry-5112-ugly-panel-uplift'),
	CustomerBannerNew,
	CustomerBannerOld,
);

const bubleeAlertStyles = xcss({
	display: 'inline-flex',
	paddingBlock: 'space.075',
	paddingInline: 'space.100',
	alignItems: 'center',
	gap: 'space.050',
	borderStartStartRadius: 'border.radius.100',
	borderStartEndRadius: 'border.radius.100',
	backgroundColor: 'color.background.accent.gray.subtlest',
});

const internalBubleeStyles = xcss({
	backgroundColor: 'color.background.accent.yellow.subtlest',
	color: 'color.text.accent.yellow',
});

const bannerStyles = xcss({
	paddingInline: 'space.100',
	paddingBlock: 'space.150',
	alignItems: 'center',
	gap: 'space.100',
});

const internalBannerStyles = xcss({
	backgroundColor: 'color.background.accent.yellow.subtlest',
});
