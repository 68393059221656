import React, { useState, type ReactNode } from 'react';
import Button from '@atlaskit/button/new';
import { Box } from '@atlaskit/primitives';
import ChevronUpIcon from '@atlaskit/icon/utility/chevron-up';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const ShowHideMoreFieldsGroup = ({ items }: { items: ReactNode[] }) => {
	const { formatMessage } = useIntl();
	const [show, setShow] = useState(false);

	const handleShowToggle = () => setShow(!show);
	const actionText = formatMessage(show ? messages.hideFields : messages.showMoreFields, {
		itemsCount: items.length,
	});
	const chevronIcon = show ? ChevronUpIcon : ChevronDownIcon;

	return (
		<Box testId="issue-view-layout-templates-item-list.ui.show-hide-more-fields-group.show-hide-more-fields-group">
			{show && items}
			<Button onClick={handleShowToggle} iconBefore={chevronIcon} appearance="subtle">
				{actionText}
			</Button>
		</Box>
	);
};
