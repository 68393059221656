import { useState, useCallback } from 'react';
import { graphql, fetchQuery, useRelayEnvironment } from 'react-relay';
import { getHamsProductKey } from '@atlassian/jira-growth-utils/src/services/get-hams-product-key/index.tsx';
import type {
	canUpgradeAndPayQuery,
	canUpgradeAndPayQuery$data,
} from '@atlassian/jira-relay/src/__generated__/canUpgradeAndPayQuery.graphql';
import type { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import type {
	FREE_EDITION,
	PREMIUM_EDITION,
	STANDARD_EDITION,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

type CanUpgradeAndPayPayload = {
	canUpgradeAndPay: boolean;
	upgradeLink: string | null;
};
type CanUpgradeAndPayResponse = {
	fetch: () => void;
	data: CanUpgradeAndPayPayload | undefined;
	isLoading: boolean;
	error: Error | null;
};

// There's a method getProductOfferingKey on the @atlassian/jira-growth-utils package that we could use,
// but it returns null values, and required some type changes over more files
// So I decided to keep the offering keys here until we refactor all the other methods
const JSW_EDITIONS_OFFERING_KEYS = {
	FREE_EDITION: 'de2887c9-8a55-41d5-b5cf-ad6a5589ebed',
	STANDARD_EDITION: 'a70b5cbb-1ae1-4003-8f4a-9001a4a50526',
	PREMIUM_EDITION: '6dd805b4-da75-4374-a7a7-cf0b12f7ea07',
};

type CanUpgradeAndPayProps = {
	applicationKey: typeof SOFTWARE;
	edition: typeof FREE_EDITION | typeof STANDARD_EDITION | typeof PREMIUM_EDITION;
};

export const Query = graphql`
	query canUpgradeAndPayQuery($cloudId: ID!, $productKey: String!, $offeringKey: ID!) {
		tenantContexts(cloudIds: [$cloudId]) {
			entitlementInfo(hamsProductKey: $productKey) {
				entitlement {
					transactionAccount {
						experienceCapabilities {
							addPaymentMethod {
								isAvailableToUser
							}
						}
					}
					subscription {
						accountDetails {
							invoiceGroup {
								experienceCapabilities {
									configurePayment {
										isAvailableToUser
									}
								}
							}
						}
					}
					experienceCapabilities {
						changeOffering(offeringKey: $offeringKey) {
							experienceUrl
							isAvailableToUser
						}
					}
				}
			}
		}
	}
`;

export const useCanUpgradeAndPay = (props: CanUpgradeAndPayProps): CanUpgradeAndPayResponse => {
	const { applicationKey, edition } = props;

	const cloudId = useCloudId();
	const relayEnvironment = useRelayEnvironment();

	const [data, setData] = useState<CanUpgradeAndPayPayload | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);

	const fetch = useCallback(() => {
		setIsLoading(true);

		const variables = {
			cloudId,
			productKey: getHamsProductKey(applicationKey),
			// When more products are supported, we need to update this to the utils method
			offeringKey: JSW_EDITIONS_OFFERING_KEYS[edition],
		};

		fetchQuery<canUpgradeAndPayQuery>(relayEnvironment, Query, variables, {
			fetchPolicy: 'store-or-network',
		}).subscribe({
			complete: () => {
				setIsLoading(false);
			},
			next: (response: canUpgradeAndPayQuery$data) => {
				const { canUpgradeAndPay, upgradeLink } = extractCanUpgradeAndPayDetails(response);

				setData({ canUpgradeAndPay: canUpgradeAndPay ?? false, upgradeLink });
			},
			error: (fetchError: Error) => {
				setIsLoading(false);
				setError(fetchError);
			},
		});
	}, [applicationKey, cloudId, edition, relayEnvironment]);

	return { fetch, data, isLoading, error };
};

/**
 * Extracts `canUpgradeAndPay` and `upgradeLink` from the response from a `canUpgradeAndPayQuery` like query.
 * Only supports query results for a single tenantContext (cloudId).
 */
export const extractCanUpgradeAndPayDetails = (
	response: canUpgradeAndPayQuery$data | null,
): CanUpgradeAndPayPayload => {
	// only supports query results for a single tenantContext (cloudId)
	const entitlement = response?.tenantContexts?.[0]?.entitlementInfo?.entitlement;
	if (entitlement) {
		const configurePaymentCapability =
			entitlement?.subscription?.accountDetails?.invoiceGroup?.experienceCapabilities
				?.configurePayment;
		const transactionAccount =
			entitlement?.transactionAccount?.experienceCapabilities?.addPaymentMethod;

		const canUpgrade = Boolean(
			entitlement?.experienceCapabilities?.changeOffering?.isAvailableToUser,
		);
		const canPay = Boolean(
			configurePaymentCapability?.isAvailableToUser || transactionAccount?.isAvailableToUser,
		);

		const canUpgradeAndPay = canUpgrade && canPay;
		const upgradeLink = entitlement?.experienceCapabilities?.changeOffering?.experienceUrl || null;

		return { canUpgradeAndPay, upgradeLink };
	}
	return { canUpgradeAndPay: false, upgradeLink: null };
};
