import React from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import type { url_issueFieldUrlReadviewFull_UrlReadView$key as UrlFragment } from '@atlassian/jira-relay/src/__generated__/url_issueFieldUrlReadviewFull_UrlReadView.graphql';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import isValidEmailAddress from '@atlassian/jira-common-is-valid-email-address/src/index.tsx';
import prefixUrl from '@atlassian/jira-common-util-prefix-url/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { SimpleInlineSmartLink } from '@atlassian/jira-issue-field-smart-link/src/ui/simple-inline-smart-link/index.tsx';
import { formatReadValue } from './utils.tsx';
import type { UrlReadViewProps } from './types.tsx';

// eslint-disable-next-line no-script-url
const forbiddenScheme = 'javascript:';

export const UrlReadView = ({ fragmentRef, isSimpleInlineSmartLinkEnabled }: UrlReadViewProps) => {
	const { uri: value } = useFragment<UrlFragment>(
		graphql`
			fragment url_issueFieldUrlReadviewFull_UrlReadView on JiraUrlField {
				uri
			}
		`,
		fragmentRef,
	);

	if (value == null || value === '') {
		return <EmptyFieldText />;
	}

	if (isSimpleInlineSmartLinkEnabled && fg('jsc_inline_editing_field_refactor')) {
		if (value.startsWith(forbiddenScheme)) {
			return <Box xcss={truncatedStyles}>{value}</Box>;
		}
		if (isValidEmailAddress(value)) {
			return <SimpleInlineSmartLink anchorTarget="_blank" url={`mailto:${value}`} text={value} />;
		}

		return <SimpleInlineSmartLink anchorTarget="_blank" url={prefixUrl(value, '//')} />;
	}

	const lDisplayValue = formatReadValue(value);

	return <Ellipsis title={value}>{lDisplayValue}</Ellipsis>;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Ellipsis = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const truncatedStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
