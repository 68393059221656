import { useCallback, useMemo } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import type { ConfluenceAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { JIRA_ISSUE_AUTO_LINK_CONTENT_ON_LINK_PASTE } from '@atlassian/jira-issue-create-confluence-content/src/common/constants/embedded-confluence-source.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useUserHasConfluenceAccessCallback } from '../use-user-has-confluence-access-callback/index.tsx';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking/index.tsx';
import {
	filterLinkableConfluenceUrls,
	filterNewConfluenceLinks,
	findUniqueLinkUrls,
	batchCreateConfluenceContentLinks,
} from './utils.tsx';

export const useLinkNewConfluenceContent = ({
	currentLinkedConfluenceContent = [],
	currentLinkedConfluencePagesCount = 0,
	hasFetchedLinkedPages,
	isLinkIssuesEnabled = false,
	confluenceAppLinks = [],
	onAutoLinkPagesRequest,
	onAutoLinkPagesComplete,
}: {
	currentLinkedConfluenceContent: (ConfluencePage | FailedRemoteLink)[];
	currentLinkedConfluencePagesCount: number;
	hasFetchedLinkedPages: boolean;
	isLinkIssuesEnabled?: boolean;
	confluenceAppLinks?: ConfluenceAppLink[];
	onAutoLinkPagesRequest: () => void;
	onAutoLinkPagesComplete: (confluencePageLink: (ConfluencePage | FailedRemoteLink)[]) => void;
}) => {
	const tenantHasConfluence = useTenantHasConfluence();
	const issueId = useIssueId();

	const isTenantConfiguredToBeEligibleForAutoLinkPagesExp = useMemo(
		() => tenantHasConfluence && isLinkIssuesEnabled,
		[tenantHasConfluence, isLinkIssuesEnabled],
	);

	const fetchedLinkedPagesSuccessfully = useMemo(() => {
		if (!fg('prevent_auto_linking_on_fetch_error')) return true;
		return (
			hasFetchedLinkedPages &&
			currentLinkedConfluencePagesCount === currentLinkedConfluenceContent.length
		);
	}, [hasFetchedLinkedPages, currentLinkedConfluencePagesCount, currentLinkedConfluenceContent]);

	const { getConfluencePermissions } = useUserHasConfluenceAccessCallback(
		JIRA_ISSUE_AUTO_LINK_CONTENT_ON_LINK_PASTE,
	);

	const fireAnalyticsEvent = useEmbeddedPageTracking();

	const linkNewConfluenceContent = useCallback(
		async (description: ADF) => {
			if (!issueId) return;

			const [autoLinkPagesExperimentConfig, autoLinkPagesExperimentfireExposureEvent] =
				UNSAFE_noExposureExp('jira-issue_auto-link-pages-on-link-paste');

			const autoLinkPagesExperimentCohort = autoLinkPagesExperimentConfig.get(
				'cohort',
				'not-enrolled',
			);

			const uniqueLinkUrls = findUniqueLinkUrls(description);
			const linkableConfluenceUrls = filterLinkableConfluenceUrls(
				uniqueLinkUrls,
				confluenceAppLinks,
			);

			const isEligibleForAutoLinkPagesExp =
				isTenantConfiguredToBeEligibleForAutoLinkPagesExp && linkableConfluenceUrls.length > 0;

			if (isEligibleForAutoLinkPagesExp && fetchedLinkedPagesSuccessfully) {
				autoLinkPagesExperimentfireExposureEvent();

				if (autoLinkPagesExperimentCohort === 'experiment') {
					const newLinkableConfluenceUrls = filterNewConfluenceLinks(
						linkableConfluenceUrls,
						currentLinkedConfluenceContent,
					);

					if (currentLinkedConfluenceContent.length === 0 && newLinkableConfluenceUrls.length > 0) {
						const hasConfluenceAccess = await getConfluencePermissions();
						if (!hasConfluenceAccess) return;
					}

					batchCreateConfluenceContentLinks({
						issueId,
						urls: newLinkableConfluenceUrls,
						onAutoLinkPagesRequest,
						onAutoLinkPagesComplete,
						fireAnalyticsEvent,
					});
				}
			}
		},
		[
			confluenceAppLinks,
			currentLinkedConfluenceContent,
			fetchedLinkedPagesSuccessfully,
			fireAnalyticsEvent,
			getConfluencePermissions,
			isTenantConfiguredToBeEligibleForAutoLinkPagesExp,
			issueId,
			onAutoLinkPagesComplete,
			onAutoLinkPagesRequest,
		],
	);

	return {
		linkNewConfluenceContent,
	};
};
