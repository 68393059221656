import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	hideDoneErrorFlagTitle: {
		id: 'jiv-child-issue-table-hide-done-error-flag-title',
		defaultMessage: 'Hide done preference could not be updated.',
		description: 'Text shown in the error flag when hide done toggle fails to update',
	},
	hideDoneErrorFlagDescription: {
		id: 'jiv-child-issue-table-hide-done-error-flag-description',
		defaultMessage: 'Something went wrong. Please try again.',
		description: 'Text shown in the error flag when hide done toggle fails to update',
	},
});
