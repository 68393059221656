import {
	performGetRequest,
	performPostRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { PlaceholderTemplateError } from '../placeholder-template-error.tsx';

interface PersonalSpace {
	id: string;
	name: string;
}

interface UserInfo {
	publicName: string;
	personalSpace?: PersonalSpace;
}

export const fetchPersonalSpace = async (cloudId: string): Promise<UserInfo> => {
	try {
		const api = `/gateway/api/ex/confluence/${cloudId}/wiki/rest/api/user/current?expand=personalSpace`;
		const res = await performGetRequest(api);

		return res;
	} catch (error: unknown) {
		throw new PlaceholderTemplateError(
			'Failed to fetch user info',
			'fetchPersonalSpace',
			error instanceof FetchError ? error.statusCode : undefined,
			error instanceof FetchError ? error.traceId : undefined,
		);
	}
};

export const createPersonalSpace = async (
	cloudId: string,
	publicName: string,
): Promise<PersonalSpace> => {
	try {
		const api = `/gateway/api/ex/confluence/${cloudId}/wiki/rest/api/space`;
		return await performPostRequest(api, {
			body: JSON.stringify({
				name: publicName,
				type: 'PERSONAL',
			}),
		});
	} catch (error: unknown) {
		throw new PlaceholderTemplateError(
			'Failed to create personal space',
			'createPersonalSpace',
			error instanceof FetchError ? error.statusCode : undefined,
			error instanceof FetchError ? error.traceId : undefined,
		);
	}
};
