import { useMemo } from 'react';
import { useEntityLimitsByType } from '@atlassian/jira-polaris-component-entity-limits-store/src/controllers/entity-limits/selectors/entity-limits-hooks.tsx';
import { useCanManageViewBasedOnViewPermissions } from '@atlassian/jira-polaris-component-view-access/src/controllers/hooks/index.tsx';
import { ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-collection/src/constants.tsx';
import type { User } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import { useEntityLimitMessage } from '@atlassian/jira-polaris-component-limits/src/controllers/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useApplicationPermissions } from '@atlassian/jira-tenant-context-controller/src/components/application-permissions/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	useCollectionOwnerId,
	useCollectionsCount,
	useCollectionsOwnersIds,
} from './collections/index.tsx';
import { useUser, useUsers } from './users/index.tsx';

export const useCollectionsOwners = (): Record<string, User | undefined> => {
	const ownerIds = useCollectionsOwnersIds();
	const users = useUsers();

	return useMemo(
		() =>
			ownerIds.reduce<Record<string, User>>((acc, ownerId) => {
				const owner = users[ownerId];
				if (owner) {
					acc[ownerId] = owner;
				}
				return acc;
			}, {}),
		[ownerIds, users],
	);
};

export const useCollectionOwner = ({
	collectionUUID,
}: {
	collectionUUID: string | undefined;
}): User | undefined => {
	const ownerId = useCollectionOwnerId({ collectionUUID }) || '';
	const user = useUser(ownerId);

	return user;
};

export const useIsLicensedAdminOrCollectionOwner = ({
	collectionUUID,
}: {
	collectionUUID: string | undefined;
}): boolean => {
	const ownerId = useCollectionOwnerId({ collectionUUID });
	const currentUserId = useAccountId();
	const { isAdmin } = useTenantContext() ?? {};
	const isLicensedUser = useIsLicensedUser();

	return isLicensedUser && (isAdmin || currentUserId === ownerId);
};

export const useCanEditCollection = ({
	collectionUUID,
	forceLoading,
}: {
	collectionUUID: string | undefined;
	forceLoading?: boolean;
}): boolean => {
	const isLicensedUser = useIsLicensedUser();

	const { canEdit } = useCanManageViewBasedOnViewPermissions(
		collectionUUID,
		undefined,
		forceLoading,
		true,
	);

	return isLicensedUser && canEdit;
};

export const useIsLicensedUser = (): boolean => {
	const { hasProductDiscoveryAccess } = useApplicationPermissions() ?? {};

	return hasProductDiscoveryAccess;
};

export const useHasReachedCollectionsLimitMessage = (): string | null => {
	const entityLimitMessage = useEntityLimitMessage();

	const collectionsCount = useCollectionsCount();
	const collectionsLimit = useEntityLimitsByType(ENTITY_LIMIT_TYPE.COLLECTIONS_PER_SITE);

	if (collectionsLimit && collectionsCount >= collectionsLimit) {
		return entityLimitMessage(ENTITY_LIMIT_TYPE.COLLECTIONS_PER_SITE, collectionsLimit);
	}

	return null;
};
