import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';

export type GetProjectSettingsUrl = {
	projectKey: string;
	projectType?: ProjectType | string;
};

export const SUMMARY_VIEW_SOURCE = 'summaryView' as const;
export const LIST_VIEW_SOURCE = 'listView' as const;
export const ISSUE_VIEW_SOURCE = 'issueView' as const;
export const GIC_VIEW_SOURCE = 'gicView' as const;
export const GIC_VIEW_NO_RESULTS_SOURCE = 'gicViewNoResults' as const;
export const JSW_BOARD_SOURCE = 'jswBoard' as const;
export const JSW_BACKLOG_SOURCE = 'jswBacklog' as const;
export const JSW_TIMELINE_SOURCE = 'jswTimeline' as const;
export const UNKNOWN_SOURCE = 'unknown' as const;

export const EDIT_ISSUE_TYPE_ACTION_SUBJECT_ID = 'editIssueTypeButton';

export const DELETE_ISSUE_TYPE_ACTION_SUBJECT_ID = 'deleteIssueTypeButton';

export type ConfigureSource =
	| typeof SUMMARY_VIEW_SOURCE
	| typeof LIST_VIEW_SOURCE
	| typeof ISSUE_VIEW_SOURCE
	| typeof GIC_VIEW_SOURCE
	| typeof GIC_VIEW_NO_RESULTS_SOURCE
	| typeof JSW_BOARD_SOURCE
	| typeof JSW_BACKLOG_SOURCE
	| typeof JSW_TIMELINE_SOURCE
	| typeof UNKNOWN_SOURCE;

export type EditActionSubjectId = typeof EDIT_ISSUE_TYPE_ACTION_SUBJECT_ID;

export type DeleteActionSubjectId = typeof DELETE_ISSUE_TYPE_ACTION_SUBJECT_ID;

export type IssueType = {
	id: string;
	iconUrl: string;
	name: string;
	description: string;
};

export type Project = {
	label: string;
	value: string;
	iconUrl: string;
	projectKey: string;
	projectType: ProjectType;
	isSimplified: boolean;
	classificationTags?: string[];
};
