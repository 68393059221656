import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const optInBackOnboardingEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(/* webpackChunkName: "async-issue-details-onboarding-modal-entrypoint" */ './main'),
	),
	getPreloadProps: () => ({}),
});
