import React, { useMemo, useState, useCallback, useEffect, type ReactNode } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box } from '@atlaskit/primitives';
import Popup from '@atlaskit/popup';
import WarningIcon from '@atlaskit/icon/core/warning';
import { token } from '@atlaskit/tokens';
import InformationIcon from '@atlaskit/icon/core/information';
import Link from '@atlaskit/link';
import RecurIcon from '@atlaskit/icon-lab/core/recur';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { defineMessages, useIntl } from '@atlassian/jira-intl';
import type { EntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { MenuIcon } from '@atlassian/automation-manual-triggers';
import { parse } from '@atlassian/jira-ical/src/parse.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { ParseResult } from '@atlassian/jira-ical/src/types.tsx';
import {
	getRecurMenuItemLabel,
	isRecurOnCompleteToggleRule,
} from '@atlassian/jira-recur-work-item/src/ui/utils.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	useRecurWorkItemActions,
	useRecurWorkItemState,
} from '@atlassian/jira-recur-work-item/src/controllers/index.tsx';
import { useIsProjectAdmin } from '@atlassian/jira-issue-hooks/src/services/use-is-project-admin/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';

const messages = defineMessages({
	ruleDidNotLoad: {
		id: 'recur-work-menu-item.popup.rule-did-not-load',
		defaultMessage: 'Rule did not load. Try refreshing the page.',
		description: 'Message shown when an automation rule exists but fails to load',
	},
	userNotPermittedToEdit: {
		id: 'recur-work-menu-item.popup.user-not-permitted-to-edit',
		defaultMessage: '<link>Contact your administrator</link> to edit this rule.',
		description: 'Message shown when the user does not have permission to edit the rule',
	},
});

export type Props = {
	buttonRef: EntryPointButtonTrigger;
	onClick: () => void;
	issueKey: string;
	recurWorkFormEntryPoint: string;
};

export const RecurWorkMenuItem = ({
	buttonRef,
	onClick,
	issueKey,
	recurWorkFormEntryPoint,
}: Props) => {
	const { formatMessage } = useIntl();
	const isProjectAdmin = useIsProjectAdmin();
	const isSiteAdmin = useIsSiteAdmin();
	const projectKey = useProjectKey(issueKey);
	const [{ canEditIssues }] = useProjectPermissions(projectKey);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		issueProperties,
		hasFetched,
		hasBootstrapError,
		isRuleOwner,
		isFetching,
		isDeletingRule,
		isCreatingRule,
		isUpdatingRule,
	} = useRecurWorkItemState();
	const { fetch: refetch } = useRecurWorkItemActions();
	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const userPermittedToEdit =
		canEditIssues &&
		(isSiteAdmin || isProjectAdmin || (issueProperties && isRuleOwner) || !issueProperties);

	useEffect(() => {
		if (hasFetched && !(isFetching || isDeletingRule || isCreatingRule || isUpdatingRule)) {
			refetch();
		}
		fireTrackAnalytics(createAnalyticsEvent({}), 'menuItem viewed', 'recurOnSchedulePopup', {
			canViewRecurOnSchedulePopup: userPermittedToEdit,
			recurWorkFormEntryPoint,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const ruleFailedToLoad = hasFetched && hasBootstrapError;

	const menuItemMessage = useMemo(() => {
		const isRecurOnComplete = isRecurOnCompleteToggleRule(issueProperties);

		let parsedrRule: ParseResult | undefined;
		try {
			parsedrRule = parse(issueProperties?.recurOnScheduleConfig?.rRule);
		} catch (err) {
			log.safeErrorWithoutCustomerData(
				'jira-issue-field-status.src.ui.status-view.actions.actions-menu.recur-work-menu-item',
				'Error parsing rRule string',
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				err as Error,
			);
		}
		const rrule = parsedrRule?.event?.rrule ?? {};

		return getRecurMenuItemLabel(rrule, isRecurOnComplete);
	}, [issueProperties]);

	const handleClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'menuItem clicked', 'recurOnSchedulePopup', {
			recurWorkFormEntryPoint,
		});
		onClick();
	}, [createAnalyticsEvent, onClick, recurWorkFormEntryPoint]);

	if (ruleFailedToLoad || !userPermittedToEdit) {
		const popupMessage = ruleFailedToLoad
			? formatMessage(messages.ruleDidNotLoad)
			: formatMessage(messages.userNotPermittedToEdit, {
					link: (chunks: ReactNode) => <Link href="/jira/contact-administrator">{chunks}</Link>,
				});

		const testId = ruleFailedToLoad
			? 'issue-field-status.ui.status-view.actions.actions-menu.recur-work-menu-item.warn-button-item'
			: 'issue-field-status.ui.status-view.actions.actions-menu.recur-work-menu-item.info-button-item';

		const icon = ruleFailedToLoad ? (
			<WarningIcon color={token('color.icon.warning')} label="" />
		) : (
			<InformationIcon color={token('color.icon.discovery')} label="" />
		);

		return (
			<Popup
				isOpen={isPopupOpen}
				onClose={() => setIsPopupOpen(false)}
				placement="top"
				shouldRenderToParent
				content={() => <Box padding="space.200">{popupMessage}</Box>}
				trigger={(triggerProps) => (
					<ButtonItem
						{...triggerProps}
						onClick={() => setIsPopupOpen(!isPopupOpen)}
						iconBefore={<MenuIcon>{icon}</MenuIcon>}
						testId={testId}
					>
						{formatMessage(menuItemMessage)}
					</ButtonItem>
				)}
			/>
		);
	}

	return (
		<ButtonItem
			ref={buttonRef}
			onClick={handleClick}
			iconBefore={
				<MenuIcon>
					<RecurIcon label="" />
				</MenuIcon>
			}
			iconAfter={<ChevronRightIcon label="" />}
			testId="issue-field-status.ui.status-view.actions.actions-menu.recur-work-menu-item.button-item"
			interactionName="jira-issue-field-status.ui.status-view.actions.actions-menu.recur-work-menu-item.button"
		>
			{formatMessage(menuItemMessage)}
		</ButtonItem>
	);
};
