/**
 * This is a copy of CFFE's local storage so that we can make the same check against the storage set in CFFE.
 */
import { Targets } from '@atlassiansox/cross-flow-support';
import { fg } from '@atlassian/jira-feature-gating';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';

const STORAGE_PREFIX = 'cross-flow-frontend';
const STORAGE_KEY = 'requested-status';

// TODO Add an app prefix to separate apps
const storageProvider = createLocalStorageProvider('');

const storageKey = (key: string) => `${STORAGE_PREFIX}-${key}`;

const getLocalStorage = (
	key: string,
	reviver?: (key: string, value: Set<string>) => Set<string>,
) => {
	let value;
	if (fg('jfp-vulcan-browser-storage-migration')) {
		value = storageProvider.get(storageKey(key));
	} else {
		value = localStorage.getItem(storageKey(key));
	}

	if (value === null) {
		throw new Error(`No value stored for ${key} in localStorage`);
	}

	return JSON.parse(value, reviver);
};

interface RequestedProducts {
	[productKey: string]: Set<string>;
}

const loadInitialRequestedProductsData = (): RequestedProducts => {
	try {
		return getLocalStorage(STORAGE_KEY, (_, value) =>
			// Use Set to ensure only unique values are stored
			Array.isArray(value) ? new Set(value) : value,
		);
	} catch {
		return {};
	}
};

/**
 * This wrapper specifically omits the Target, as we're only concerned with Confluence
 * for purposes of this experiment.
 */
export const hasLocalStorageRequestedTrial = (cloudId: string) => {
	const requestedProducts = loadInitialRequestedProductsData();
	const tenantSet = requestedProducts[Targets.CONFLUENCE] || new Set();

	return tenantSet.has(cloudId);
};
