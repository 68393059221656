import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { ViewNotFoundError } from '@atlassian/jira-polaris-remote-view/src/common/errors/view-not-found-error.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { createGetViewByAri } from '../../../selectors/view/index.tsx';
import type { Props, State } from '../../../types.tsx';
import { updateViewState } from '../../utils/state/index.tsx';
import { internViewComment } from '../utils.tsx';

export const loadViewComments =
	(viewId: string, refresh = false) =>
	async ({ getState, setState }: StoreActionApi<State>, { viewRemote }: Props) => {
		try {
			if (
				!refresh &&
				createGetViewByAri(viewId)(getState())?.commentsLoadingState?.loaded &&
				fg('jpd-view-data-loading-optimization-for-idea-view')
			) {
				// don't load comments if we already have them using new property
				return;
			}

			if (
				!refresh &&
				createGetViewByAri(viewId)(getState())?.commentsLoaded &&
				!fg('jpd-view-data-loading-optimization-for-idea-view')
			) {
				// don't load comments if we already have them using legacy property
				return;
			}

			if (
				createGetViewByAri(viewId)(getState())?.commentsLoadingState?.isLoading &&
				fg('jpd-view-data-loading-optimization-for-idea-view')
			) {
				// don't load comments if it is currently already loading
				return;
			}

			if (viewRemote.fetchViewComments !== undefined) {
				const fetchCommentsTimestamp = refresh ? Date.now() : undefined;

				if (fg('jpd-view-data-loading-optimization-for-idea-view')) {
					const { changedView, viewSets } = updateViewState(
						getState().viewSets,
						(view: View): boolean => view.viewId === viewId,
						(view: View) => ({
							...view,
							fetchCommentsTimestamp,
							commentsLoadingState: {
								isLoading: true,
								loaded: false,
							},
						}),
					);

					if (changedView) {
						setState({ viewSets });
					}
				} else {
					const { changedView, viewSets } = updateViewState(
						getState().viewSets,
						(view: View): boolean => view.viewId === viewId,
						(view: View) => ({
							...view,
							fetchCommentsTimestamp,
						}),
					);

					if (changedView) {
						setState({ viewSets });
					}
				}

				const data = await viewRemote.fetchViewComments(viewId);

				if (fg('jpd-view-data-loading-optimization-for-idea-view')) {
					const { changedView: changedViewAfterCreation, viewSets: viewSetsAfterCreation } =
						updateViewState(
							getState().viewSets,
							(view: View): boolean =>
								view.viewId === viewId && view.fetchCommentsTimestamp === fetchCommentsTimestamp,
							(view: View) => ({
								...view,
								comments: data.comments.map(internViewComment),
								fetchCommentsTimestamp: undefined,
								commentsLoadingState: {
									isLoading: false,
									loaded: true,
								},
							}),
						);
					if (changedViewAfterCreation) {
						setState({ viewSets: viewSetsAfterCreation });
					}
				} else {
					const { changedView: changedViewAfterCreation, viewSets: viewSetsAfterCreation } =
						updateViewState(
							getState().viewSets,
							(view: View): boolean =>
								view.viewId === viewId && view.fetchCommentsTimestamp === fetchCommentsTimestamp,
							(view: View) => ({
								...view,
								comments: data.comments.map(internViewComment),
								fetchCommentsTimestamp: undefined,
								commentsLoaded: true,
							}),
						);
					if (changedViewAfterCreation) {
						setState({ viewSets: viewSetsAfterCreation });
					}
				}
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics(
				createErrorAnalytics(
					'polaris.error.controllers.views.actions.comments.load-view-comments',
					error,
				),
			);

			if (fg('jpd-view-data-loading-optimization-for-idea-view')) {
				const { viewSets: viewSetsAfterErrorUpdate } = updateViewState(
					getState().viewSets,
					(view: View): boolean => view.viewId === viewId,
					(view: View) => ({
						...view,
						commentsLoadingState: {
							isLoading: false,
							loaded: false,
						},
					}),
				);
				setState({
					viewSets: viewSetsAfterErrorUpdate,
				});
			}

			if (!(error instanceof ViewNotFoundError)) {
				throw error;
			}
		}
	};
