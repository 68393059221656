import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ProfileCardAction, ProfileCardClientData } from '@atlaskit/profilecard';
import { fireUIAnalytics, fireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';

import type { IntlShape } from '@atlassian/jira-intl';
import { profileCardMessages } from './messages.tsx';
import type { Actions, JiraV3UserApiRestResponse } from './types.tsx';
import {
	REPORTED_ISSUE_ACTION,
	ASSIGNED_ISSUE_ACTION,
	VIEW_PROFILE_ACTION,
	VIEW_FILTERS_ACTION,
} from './constants.tsx';

export const generateUserLink = (userId: string): string => `/jira/people/${userId}`;
export const generateTeamLink = (teamId: string): string => `/jira/people/team/${teamId}`;

export const getDefaultActionProp = (label: string, profileUrl: string): ProfileCardAction => ({
	id: 'view-profile',
	label,
	callback: (event: Event) => {
		event.stopPropagation();

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(profileUrl);
	},
});

export const isTeamProfileCardEventContentRendered = (analyticsPayload?: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}): boolean =>
	analyticsPayload?.action === 'rendered' && analyticsPayload?.actionSubjectId === 'content';

export const fireLegacyTeamAnalytics = (analyticsEvent: UIAnalyticsEvent) => {
	if (analyticsEvent?.payload?.eventType === 'ui') {
		fireUIAnalytics(analyticsEvent, `legacyTeamProfileCard ${analyticsEvent.payload.action}}`);

		if (isTeamProfileCardEventContentRendered(analyticsEvent?.payload)) {
			fireScreenAnalytics(analyticsEvent);
		}
	}
};

export const restResponseToUserData = (
	userData: JiraV3UserApiRestResponse,
): ProfileCardClientData => ({
	avatarUrl: userData.avatarUrls?.['48x48'],
	email: userData.emailAddress,
	fullName: userData.displayName,
	isBot: false,
	isCurrentUser: false,
	status: userData.active ? 'active' : 'inactive',
	accountType: userData.accountType,
});

/**
 * @deprecated Check the accountType instead
 * check if the account is a customer account
 */
export const isCustomerAccount = (accountId: string): boolean =>
	accountId ? accountId.lastIndexOf('qm:', 0) === 0 : false;

const getSpaViewProfileAction = (
	intl: IntlShape,
	accountId: string,
	onPushAction: (href: string) => void,
	onActionCleanup: undefined | (() => void),
) => ({
	label: intl && intl.formatMessage(profileCardMessages.viewProfile),
	id: 'view-profile',
	callback: () => {
		onPushAction(`/jira/people/${accountId}`);
		onActionCleanup && onActionCleanup();
	},
	link: `/jira/people/${accountId}`,
	shouldRender: (data: ProfileCardClientData) =>
		/*
		 * Those without @atlassian.com accounts do not have viewable profiles
		 */
		data?.accountType !== 'customer',
});

const getAssignedIssuesAction = (intl: IntlShape, accountId: string) => ({
	label: intl && intl.formatMessage(profileCardMessages.assignedIssues),
	id: 'assigned-issues',
	callback: () => {
		const jql = encodeURIComponent(`assignee="${accountId || ''}" AND resolution is empty`);
		const url = `/issues/?jql=${jql}`;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(url, '_blank');
	},
});

const getReportedIssuesAction = (intl: IntlShape, accountId: string) => ({
	label: intl && intl.formatMessage(profileCardMessages.reportedIssues),
	id: 'reported-issues',
	callback: () => {
		const jql = encodeURIComponent(`reporter="${accountId || ''}" order by created DESC`);
		const url = `/issues/?jql=${jql}`;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(url, '_blank');
	},
});

const getViewFiltersAction = (intl: IntlShape, accountId: string) => ({
	label: intl && intl.formatMessage(profileCardMessages.viewFilters),
	id: 'view-filters',
	callback: () => {
		const url = `/jira/filters?owner=${encodeURIComponent(accountId)}`;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(url, '_blank');
	},
});

export const getSpaActions = (
	accountId: string,
	intl: IntlShape,
	onPushAction: (href: string) => void,
	actions?: Actions[],
	onActionCleanup?: () => void,
): ProfileCardAction[] => {
	if (actions) {
		return actions.map((action: Actions) => {
			switch (action) {
				case VIEW_PROFILE_ACTION:
					return getSpaViewProfileAction(intl, accountId, onPushAction, onActionCleanup);
				case ASSIGNED_ISSUE_ACTION:
					return getAssignedIssuesAction(intl, accountId);
				case REPORTED_ISSUE_ACTION:
					return getReportedIssuesAction(intl, accountId);
				case VIEW_FILTERS_ACTION:
					return getViewFiltersAction(intl, accountId);
				default:
					throw new Error('unexpected action');
			}
		});
	}

	return [getSpaViewProfileAction(intl, accountId, onPushAction, onActionCleanup)];
};
