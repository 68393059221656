// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { fetchIssueProperties, fetchRuleSummary } from '../../services/fetch-rule.tsx';
import type { State, StoreContainerProps } from '../types.tsx';
import { RuleState } from '../../common/constants.tsx';
import { deleteIssueProperties } from '../../services/set-non-recurring.tsx';
import { callServiceWithAnalytics } from '../../common/utils.tsx';
import { isRecurOnCompleteToggleRule } from '../../ui/utils.tsx';

export const fetch =
	(): Action<State, StoreContainerProps, Promise<void>> =>
	async (
		{ setState },
		{ createAnalyticsEvent, issueKey, environment, cloudId, projectId, currentUserAccountId },
	) => {
		setState({
			isFetching: true,
			errors: null,
		});

		try {
			// First fetch issue-properties
			const { value: issueProperties } = await callServiceWithAnalytics(
				createAnalyticsEvent({}),
				fetchIssueProperties(issueKey),
				'fetchIssueProperties',
			);
			let ruleSummary;

			// Only fetch rule summary if a rule exists in issue-properties and not a grandfathered recur-on-complete rule
			if (issueProperties?.automationRuleUuid) {
				ruleSummary = await callServiceWithAnalytics(
					createAnalyticsEvent({}),
					fetchRuleSummary(environment, cloudId, projectId, issueProperties.automationRuleUuid),
					'fetchRuleSummary',
				);

				// If rule doesn't exist or is disabled, delete issue-properties
				if (!ruleSummary || ruleSummary?.ruleState === RuleState.DISABLED) {
					await callServiceWithAnalytics(
						createAnalyticsEvent({}),
						deleteIssueProperties({ issueKey }),
						'deleteIssueProperties',
					);
					setState({
						isFetching: false,
						hasFetched: true,
						issueProperties: null,
					});
					return;
				}
			}

			let isRuleOwner;

			if (isRecurOnCompleteToggleRule(issueProperties)) {
				isRuleOwner = true;
			} else if (ruleSummary) {
				isRuleOwner = ruleSummary.authorAccountId === currentUserAccountId;
			} else {
				isRuleOwner = null;
			}

			setState({
				isFetching: false,
				hasFetched: true,
				issueProperties,
				isRuleOwner,
				...(fg('jira-recur-single-work-item-on-sched-due-date-gate') && {
					hasBootstrapError: false,
				}),
			});
		} catch (error: unknown) {
			setState({
				isFetching: false,
				hasFetched: true,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				errors: [error as Error],
				hasBootstrapError: true,
			});
		}
	};
