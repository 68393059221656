import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import type { Store } from 'redux';
import noop from 'lodash/noop';
import {
	IssueBreakdownAiContainer,
	useIssueBreakdownActions,
	useIssueBreakdown,
	steps,
} from '@atlassian/jira-ai-work-breakdown/src/controllers/context.tsx';
import { useAssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/context.tsx';
import AppStyle from '@atlassian/jira-common-components-app-style/src/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { FlagsDispatcher } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import {
	useOnIssueKeyChange,
	useIssueKey,
	useProjectKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { OnIssueKeyChangeFn } from '@atlassian/jira-issue-context-service/src/types.tsx';
import { useFieldConfigActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldsValuesActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import AppBase from '@atlassian/jira-issue-view-app-base/src/index.tsx';
import { statusNone } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { useHasExceededChildIssuesLimitAfterLoad } from '@atlassian/jira-issue-view-services/src/child-issues/use-has-exceeded-child-issues-limit/index.tsx';
import { useTriggerIssueViewModal } from '@atlassian/jira-open-issue-view-modal-controller/src/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectId,
	useProjectType,
	useIsSimplifiedProject,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import {
	toIssueKey,
	toIssueId,
	toProjectId,
	toBaseUrl,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { ISSUE_VIEW_CONSUMER } from '@atlassian/jira-ai-work-breakdown/src/constants.tsx';
import {
	useAiWorkBreakDownAnalytics,
	ISSUE_BREAKDOWN_PROACTIVE_QUICK_ADD_ID,
} from '@atlassian/jira-ai-work-breakdown/src/common/analytics/index.tsx';
import { CHILD_ISSUES_KEY_MAP } from './common/constants.tsx';
import flagMapper from './flags/index.tsx';
import { ChildIssuesContainer } from './hooks/context.tsx';
import { useSortableIssueIds } from './hooks/main.tsx';
import { CLASSIC_SUBTASKS } from './model/types.tsx';
import { transformIssueType } from './model/util.tsx';
import rootEpic from './ops/index.tsx';
import {
	setContext,
	setOnChange,
	requiresFullCreateCheckRequest,
} from './state/context/actions.tsx';
import { setEntities, fetchDetailsForIssuesRequest } from './state/entities/actions.tsx';
import createStore from './state/index.tsx';
import type { Props } from './state/types.tsx';
import { setFetchIssueTypesStatus, showInlineCreate } from './state/ui/actions.tsx';
import { useChildrenIssuesWithData } from './utils.tsx';
import View from './view/index.tsx';
import { useShouldProactivelySuggestIssues } from './view/hooks.tsx';

const ChildIssuesPanelBase = (props: Props) => {
	const {
		associatedIssuesContextActions,
		appEditions,
		baseUrl,
		childIssuesPanelType,
		customFieldIdRank,
		epicLinkFieldKey,
		fetchIssueTypesStatus,
		fieldConfigActions,
		fieldValuesActions,
		filterSubtasks,
		isIssueViewComplete = false,
		isSimplifiedProject,
		issueHierarchyLevel,
		issues = [],
		issueTypes = [],
		locale,
		messages,
		onChange,
		onEditAssociatedIssue,
		onNavigateToNewIssue,
		parentId,
		parentIssueTypeId,
		parentKey,
		projectId,
		projectType,
		quickAddClickCount,
		supportsIssueCreation,
		triggeredViaHotKey,
		user,
	} = props;

	const store: Store = useMemo(
		() =>
			createStore(
				rootEpic(associatedIssuesContextActions, fieldValuesActions, fieldConfigActions),
				parentKey,
				childIssuesPanelType,
			),
		[
			associatedIssuesContextActions,
			childIssuesPanelType,
			fieldConfigActions,
			fieldValuesActions,
			parentKey,
		],
	);

	const hasMounted = useRef(false);
	const prevQuickAddClickCount = useRef(quickAddClickCount);

	useEffect(() => {
		// Prevent concurrent mode rerunning this useEffect and making multiple api calls
		if (hasMounted.current) {
			return;
		}

		// TODO: Remove on cleanup of jira-issue-view-child-issues-panel-performance
		const oldContext = {
			baseUrl,
			locale,
			user,
			projectId,
			parentId,
			parentKey,
			parentIssueTypeId,
			customFieldIdRank,
			epicLinkFieldKey,
			supportsIssueCreation,
			messages,
			onNavigateToNewIssue,
			onChange,
			childIssuesPanelType,
			isIssueViewComplete,
			projectType,
			application: projectType !== null ? getApplicationForProject(projectType) : null,
			edition:
				projectType !== null
					? getEdition(getApplicationForProject(projectType), appEditions)
					: null,
			// Optimistically assume full create not needed
			requiresFullCreate: false,
			// TODO: Remove on jira-issue-view-child-issues-panel-merge-subtasks cleanup
			filterSubtasks,
			// if the endpoint doesn't return data quick enough, optimistically assume full create not needed
			requiresFullCreateByIssueType: {},
			onEditAssociatedIssue,
			isSimplifiedProject,
		};

		const newContext = {
			baseUrl,
			user,
			projectId,
			parentId,
			parentKey,
			parentIssueTypeId,
			epicLinkFieldKey,
			supportsIssueCreation,
			messages,
			onChange,
			childIssuesPanelType,
			projectType,
			application: projectType !== null ? getApplicationForProject(projectType) : null,
			edition:
				projectType !== null
					? getEdition(getApplicationForProject(projectType), appEditions)
					: null,
			// Optimistically assume full create not needed
			requiresFullCreate: false,
			// TODO: Remove on jira-issue-view-child-issues-panel-merge-subtasks cleanup
			filterSubtasks,
			// if the endpoint doesn't return data quick enough, optimistically assume full create not needed
			requiresFullCreateByIssueType: {},
			isSimplifiedProject,
		};

		const context = fg('jira-issue-view-child-issues-panel-performance') ? newContext : oldContext;
		store.dispatch(setContext(context));

		store.dispatch(requiresFullCreateCheckRequest());

		store.dispatch(
			setEntities({
				issueTypes: issueTypes.map((issueType) => transformIssueType(issueType, epicLinkFieldKey)),
				issues,
				issueHierarchyLevel,
			}),
		);

		if (childIssuesPanelType !== CLASSIC_SUBTASKS && issues.length > 0) {
			store.dispatch(fetchDetailsForIssuesRequest());
		}

		if (fetchIssueTypesStatus) {
			store.dispatch(setFetchIssueTypesStatus(fetchIssueTypesStatus));
		}

		if (quickAddClickCount > 0) {
			if (expVal('quick_actions_m3_experiment', 'hotKeysEnabled', false)) {
				store.dispatch(showInlineCreate({ triggeredViaHotKey }));
			} else {
				store.dispatch(showInlineCreate());
			}
		}

		hasMounted.current = true;
	}, [
		appEditions,
		baseUrl,
		childIssuesPanelType,
		customFieldIdRank,
		epicLinkFieldKey,
		fetchIssueTypesStatus,
		filterSubtasks,
		isIssueViewComplete,
		isSimplifiedProject,
		issueHierarchyLevel,
		issueTypes,
		issues,
		locale,
		messages,
		onChange,
		onEditAssociatedIssue,
		onNavigateToNewIssue,
		parentId,
		parentIssueTypeId,
		parentKey,
		projectId,
		projectType,
		props.appEditions,
		quickAddClickCount,
		store,
		supportsIssueCreation,
		triggeredViaHotKey,
		user,
	]);

	useEffect(() => {
		if (quickAddClickCount > prevQuickAddClickCount.current) {
			if (expVal('quick_actions_m3_experiment', 'hotKeysEnabled', false)) {
				store.dispatch(showInlineCreate({ triggeredViaHotKey }));
			} else {
				store.dispatch(showInlineCreate());
			}
		}
		prevQuickAddClickCount.current = quickAddClickCount;
	}, [quickAddClickCount, triggeredViaHotKey, store, props]);

	useEffect(() => {
		if (fetchIssueTypesStatus) {
			store.dispatch(setFetchIssueTypesStatus(fetchIssueTypesStatus || statusNone));
		}
	}, [fetchIssueTypesStatus, store]);

	useEffect(() => {
		if (onChange) {
			store.dispatch(setOnChange(onChange));
		}
	}, [onChange, store]);

	useEffect(() => {
		if (issues.length || fetchIssueTypesStatus) {
			store.dispatch(
				setEntities({
					issueTypes: issueTypes.map((issueType) =>
						transformIssueType(issueType, epicLinkFieldKey),
					),
					issues,
					issueHierarchyLevel,
				}),
			);
		}
	}, [issues, fetchIssueTypesStatus, issueTypes, epicLinkFieldKey, issueHierarchyLevel, store]);

	return (
		<AppBase store={store}>
			<ContextualAnalyticsData
				sourceName="childIssuesPanel"
				sourceType={SCREEN}
				attributes={{
					childIssuesPanelType,
					issueHierarchyLevel: issueHierarchyLevel - 1,
					analyticsSource: `issue-view (${props.analyticsSource})`,
					location: 'jiraChildIssues',
				}}
			>
				<AppStyle>
					<View
						// @ts-expect-error - onAddChildClick is AddChildEventHandler | undefined
						onAddChildClick={props.onAddChildClick}
						onIssueBreakdownClick={props.onIssueBreakdownClick}
						sourceName="childIssuesPanel"
						childIssuesLimitUrl={props.childIssuesLimitUrl}
						hasExceededIssuesLimitAfterLoad={props.hasExceededIssuesLimitAfterLoad}
						totalChildIssueCount={props.totalChildIssueCount}
						issueCreateSessionId={props.issueCreateSessionId}
						rootRelayFragment={props.rootRelayFragment}
					/>
					<FlagsDispatcher mapper={flagMapper} />
				</AppStyle>
			</ContextualAnalyticsData>
		</AppBase>
	);
};

// Wrapping for test assertions
export const useFirstAddClickHandled = (): [
	boolean,
	React.Dispatch<React.SetStateAction<boolean>>,
] => useState(false);

const ChildIssuePanelWithProactiveChecks = (props: Props) => {
	const [{ isProactivelySuggestingIssues, channelId }] = useIssueBreakdown();

	const {
		resetIssueBreakdownState,
		isIssueBreakdownOpen,
		setIsProactivelySuggestingIssues,
		setIssueBreakdownStep,
		updateStreamingStatus,
	} = useIssueBreakdownActions();

	const { fireTrack } = fg('aiwb_proactive_interaction_events')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useAiWorkBreakDownAnalytics({ channelId, consumer: ISSUE_VIEW_CONSUMER })
		: { fireTrack: noop };

	const shouldProactivelySuggestIssues = useShouldProactivelySuggestIssues(
		props.totalChildIssueCount,
	);

	const [isFirstAddClickHandled, setIsFirstAddClickHandled] = useFirstAddClickHandled();

	const { quickAddClickCount } = props;
	const prevQuickAddClickCount = usePrevious(quickAddClickCount);

	const startProactiveAIWBSuggestions = useCallback(() => {
		setIsProactivelySuggestingIssues(true);
		if (!isIssueBreakdownOpen()) {
			setIssueBreakdownStep(steps.draftListStep);
			updateStreamingStatus(true);
			fg('aiwb_proactive_interaction_events') &&
				fireTrack('aiInteraction initiated', 'proactiveFromQuickAddClick', {
					invokedFrom: ISSUE_BREAKDOWN_PROACTIVE_QUICK_ADD_ID,
				});
		}
	}, [
		fireTrack,
		isIssueBreakdownOpen,
		setIsProactivelySuggestingIssues,
		setIssueBreakdownStep,
		updateStreamingStatus,
	]);

	useEffect(() => {
		// This piece is to control the Add -> SubTask / Child issue click based on current state of proactive suggestions
		if (fg('proactive_ai_suggestions_for_child_items_targeting')) {
			// TODO: KEN-2210: Lift this up into quick add child/sub task click handlers in quick actions.
			// Keeping it here as for scope of experiment as refactor involves AIWB state wrapping & extension of handlers that's complex.
			// When Add -> Child issue is clicked on an issue with no child issues, child issue panel loads only then
			// which messes up the comparison with previous click count, hence the special handling for first click.
			if (quickAddClickCount === 1 && shouldProactivelySuggestIssues && !isFirstAddClickHandled) {
				setIsFirstAddClickHandled(true);
				startProactiveAIWBSuggestions();
			} else if (quickAddClickCount > 0 && quickAddClickCount !== prevQuickAddClickCount) {
				if (shouldProactivelySuggestIssues) {
					startProactiveAIWBSuggestions();
				} else if (!isProactivelySuggestingIssues) {
					resetIssueBreakdownState();
				}
			}
		}
	}, [
		isFirstAddClickHandled,
		isProactivelySuggestingIssues,
		prevQuickAddClickCount,
		quickAddClickCount,
		resetIssueBreakdownState,
		setIsFirstAddClickHandled,
		shouldProactivelySuggestIssues,
		startProactiveAIWBSuggestions,
	]);

	return <ChildIssuesPanelBase {...props} />;
};

const MaybeChildIssuePanelWithProactiveChecks = componentWithFG(
	'proactive_ai_suggestions_for_child_items_targeting',
	ChildIssuePanelWithProactiveChecks,
	ChildIssuesPanelBase,
);

const WrappedSortingChildIssuesPanel = (props: Props) => {
	const { issues } = props;

	const initialRenderRef = useRef(true);
	const [, { setSortableIssueIds }] = useSortableIssueIds();

	const [{ issueCreateSessionId }] = useTriggerIssueViewModal();

	useEffect(() => {
		if (initialRenderRef.current === true) {
			setSortableIssueIds(issues.map((issue) => toIssueId(issue.id)));
			initialRenderRef.current = false;
		}
	});

	return (
		<MaybeChildIssuePanelWithProactiveChecks
			{...props}
			issueCreateSessionId={issueCreateSessionId}
		/>
	);
};
export const WrappedStateRefactorChildIssuesPanel = (props: Props): React.JSX.Element => {
	const { childIssuesPanelType } = props;

	const childIssues = useChildrenIssuesWithData(CHILD_ISSUES_KEY_MAP[childIssuesPanelType]);

	return <WrappedSortingChildIssuesPanel {...props} issues={childIssues} />;
};

type ChildIssuesPanelWithHooksProps = Omit<
	Props,
	'appEditions' | 'parentKey' | 'projectId' | 'user' | 'baseUrl' | 'associatedIssuesContextActions'
>;

const ChildIssuesPanelWithHooks = (props: ChildIssuesPanelWithHooksProps) => {
	const { onNavigateToNewIssue } = props;
	const onIssueKeyChange = useOnIssueKeyChange();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey();
	const projectId = useProjectId(projectKey);
	const projectType = useProjectType(projectKey);
	const isSimplifiedProject = useIsSimplifiedProject(projectKey);
	const baseUrl = toBaseUrl('');
	const user = useAccountId();
	const locale = useLocale();
	const appEditions = useAppEditions();

	const associatedIssuesContextActions = useAssociatedIssuesContextActions();

	const [, fieldValueActions] = useFieldsValuesActions();

	const [, fieldsConfigActions] = useFieldConfigActions();

	const onNavigateToNewIssueCallback = useCallback<OnIssueKeyChangeFn>(
		(args) => {
			onNavigateToNewIssue && onNavigateToNewIssue?.(args);
			onIssueKeyChange?.(args);
		},
		[onIssueKeyChange, onNavigateToNewIssue],
	);

	const childIssueFieldKey = CHILD_ISSUES_KEY_MAP[props.childIssuesPanelType];
	// TODO: Remove props.hasExceededIssuesLimitAfterLoad on cleanup of jira-issue-view-child-issues-panel-performance
	const hasExceededIssuesLimitAfterLoad =
		childIssueFieldKey || fg('jira-issue-view-child-issues-panel-performance')
			? // eslint-disable-next-line react-hooks/rules-of-hooks
				useHasExceededChildIssuesLimitAfterLoad(childIssueFieldKey)
			: props.hasExceededIssuesLimitAfterLoad;

	return (
		<WrappedStateRefactorChildIssuesPanel
			{...props}
			onNavigateToNewIssue={onNavigateToNewIssueCallback}
			parentKey={issueKey || toIssueKey('')}
			projectId={toProjectId(projectId || '')}
			projectType={projectType || null}
			isSimplifiedProject={isSimplifiedProject}
			baseUrl={baseUrl}
			user={user}
			locale={locale}
			appEditions={appEditions}
			associatedIssuesContextActions={associatedIssuesContextActions}
			fieldValuesActions={fieldValueActions}
			fieldConfigActions={fieldsConfigActions}
			hasExceededIssuesLimitAfterLoad={hasExceededIssuesLimitAfterLoad}
		/>
	);
};

const DecoratedComponent = (props: ChildIssuesPanelWithHooksProps) => {
	const { childIssuesPanelType } = props;
	const issueKey = useIssueKey();
	const renderAiIssueBreakdown = useMemo(
		() => (
			<IssueBreakdownAiContainer scope={`${issueKey}-${childIssuesPanelType}-issue-breakdown`}>
				<ChildIssuesPanelWithHooks {...props} />
			</IssueBreakdownAiContainer>
		),
		[issueKey, childIssuesPanelType, props],
	);

	return (
		<ErrorBoundary id="issue.issue-view.common.child-issues-panel">
			<ReportErrors
				id="common.child-issues-panel"
				packageName="jiraIssueViewCommonViews"
				sendToPrivacyUnsafeSplunk
			>
				<ChildIssuesContainer scope={`${issueKey}-${childIssuesPanelType}`}>
					{renderAiIssueBreakdown}
				</ChildIssuesContainer>
			</ReportErrors>
		</ErrorBoundary>
	);
};

const ChildIssuesPanelWithConsolidatedProps = (props: ChildIssuesPanelWithHooksProps) => {
	const { childIssuesPanelType, onNavigateToNewIssue } = props;

	const projectKey = useProjectKey();
	const projectId = useProjectId(projectKey);
	const projectType = useProjectType(projectKey);
	const isSimplifiedProject = useIsSimplifiedProject(projectKey);
	const baseUrl = toBaseUrl('');
	const user = useAccountId();
	const locale = useLocale();
	const appEditions = useAppEditions();

	const issueKey = useIssueKey();
	const onIssueKeyChange = useOnIssueKeyChange();
	const [{ issueCreateSessionId }] = useTriggerIssueViewModal();

	const [, fieldValueActions] = useFieldsValuesActions();
	const [, fieldsConfigActions] = useFieldConfigActions();

	const associatedIssuesContextActions = useAssociatedIssuesContextActions();

	const onNavigateToNewIssueCallback = useCallback<OnIssueKeyChangeFn>(
		(args) => {
			onNavigateToNewIssue && onNavigateToNewIssue(args);
			onIssueKeyChange?.(args);
		},
		[onIssueKeyChange, onNavigateToNewIssue],
	);

	const childIssueFieldKey = CHILD_ISSUES_KEY_MAP[childIssuesPanelType];
	const hasExceededIssuesLimitAfterLoad =
		useHasExceededChildIssuesLimitAfterLoad(childIssueFieldKey);
	const issues = useChildrenIssuesWithData(childIssueFieldKey);

	return (
		<ErrorBoundary id="issue.issue-view.common.child-issues-panel">
			<ReportErrors
				id="common.child-issues-panel"
				packageName="jiraIssueViewCommonViews"
				sendToPrivacyUnsafeSplunk
			>
				<ChildIssuesContainer scope={`${issueKey}-${childIssuesPanelType}`}>
					<IssueBreakdownAiContainer scope={`${issueKey}-${childIssuesPanelType}-issue-breakdown`}>
						<MaybeChildIssuePanelWithProactiveChecks
							{...props}
							appEditions={appEditions}
							associatedIssuesContextActions={associatedIssuesContextActions}
							baseUrl={baseUrl}
							fieldValuesActions={fieldValueActions}
							fieldConfigActions={fieldsConfigActions}
							hasExceededIssuesLimitAfterLoad={hasExceededIssuesLimitAfterLoad}
							issues={issues}
							issueCreateSessionId={issueCreateSessionId}
							isSimplifiedProject={isSimplifiedProject}
							locale={locale}
							onNavigateToNewIssue={onNavigateToNewIssueCallback}
							parentKey={issueKey || toIssueKey('')}
							projectId={toProjectId(projectId || '')}
							projectType={projectType || null}
							user={user}
						/>
					</IssueBreakdownAiContainer>
				</ChildIssuesContainer>
			</ReportErrors>
		</ErrorBoundary>
	);
};

const ChildIssuePannelWithFG: React.FC<ChildIssuesPanelWithHooksProps> = componentWithFG(
	'jira-issue-view-child-issues-panel-performance',
	ChildIssuesPanelWithConsolidatedProps,
	DecoratedComponent,
);
export default ChildIssuePannelWithFG;
