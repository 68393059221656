export const RuleState = {
	DISABLED: 'DISABLED',
	ENABLED: 'ENABLED',
	MISSING_PERMS: 'MISSING_PERMS',
};

export const CONDITION = {
	SCHEDULE: 'SCHEDULE' as const,
	SCHEDULE_IF_COMPLETE: 'SCHEDULE_IF_COMPLETE' as const,
};

export type RuleState = (typeof RuleState)[keyof typeof RuleState];

export const ANALYTICS_SOURCE = 'recurWorkItemForm';
export const FORM_TITLE_ID = 'recur-work-item-form-title';

export const SOFTWARE_END_USER_TEMPLATE_2 = 'software_end_user_template_2' as const;
