import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	expandContextPanel: {
		id: 'issue-view.context-panel.resizer.expand',
		defaultMessage: 'Expand',
		description: 'Expand the issue view side panel',
	},
	collapseContextPanel: {
		id: 'issue-view.context-panel.resizer.collapse',
		defaultMessage: 'Collapse',
		description: 'Collapse the issue view side panel',
	},
});
