export function getPercentageWithinPixelBounds({
	currentWidth,
	minRatio,
	maxRatio,
}: {
	currentWidth: number;
	minRatio: number;
	maxRatio: number;
}): number {
	const resizeRange = maxRatio - minRatio;
	const widthAsPercentage = ((currentWidth - minRatio) / resizeRange) * 100;

	if (widthAsPercentage < 0) {
		return 0;
	}

	if (widthAsPercentage > 100) {
		return 100;
	}

	return Math.floor(widthAsPercentage);
}
