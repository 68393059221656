/* eslint-disable jira/ff/no-preconditioning */
/** @jsx jsx */
import React, { memo, useEffect, useMemo, useRef, type FunctionComponent } from 'react';
import { styled, jsx } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import UFOPlaceholder from '@atlaskit/react-ufo/placeholder';
import { AdControlProvider } from '@atlassian/ad-control-toolkit';
import { RelatedResourcesContainer } from '@atlassian/jira-ai-related-resources/src/controllers/related-resources-container.tsx';
import { SuggestRelatedResourcesButton } from '@atlassian/jira-ai-related-resources/src/ui/suggest-related-resources-button/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import {
	useIsAiEnabledForIssue,
	useIsJiraIssue,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import type {
	ConfluencePage,
	ConfluenceWhiteboard,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { IssueType } from '@atlassian/jira-issue-shared-types/src/common/types/issue-hierarchy-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import { ConfluencePageLineCardGroup } from '@atlassian/jira-issue-view-common-views/src/confluence-content-line-card/ui/confluence-page-line-card-group-view/confluence-page-line-card-group-view.tsx';
import { ConfluenceWhiteboardLineCardGroup } from '@atlassian/jira-issue-view-common-views/src/confluence-content-line-card/ui/confluence-whiteboard-line-card-group-view/confluence-whiteboard-line-card-group-view.tsx';
import LineCardSkeleton from '@atlassian/jira-issue-view-common-views/src/skeleton/item-line-card-group.tsx';
import { Container } from '@atlassian/jira-issue-view-common-views/src/smart-link-content/styled.tsx';
import {
	SectionHeading,
	SectionHeadingTitle,
	SectionHeadingIcons,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import type { Href, RemoteLinkGlobalId } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useRelatedResourcesEntrypointStoreActions } from '@atlassian/jira-ai-related-resources/src/controllers/entrypoint-params-store.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { AiRelatedResourcesEntrypoint } from '@atlassian/jira-ai-related-resources/entrypoint.tsx';
import {
	useRelatedResourcesActions,
	useRelatedResourcesState,
} from '@atlassian/jira-ai-related-resources/src/controllers/related-resources-context.tsx';
import { PersistentCrossJoinNudge } from '@atlassian/jira-persistent-cross-join-nudge/src/ui/confluence-content/lazy-confluence-content.tsx';
import type { ConfluenceAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { useIsIssueOfIncidentsPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import type { IncidentPanelExternalHandlers } from '@atlassian/jira-servicedesk-aiops-suggestions-common/src/ui/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import type {
	confluenceContent_issueViewBase_ConfluenceContentWithFragment$key,
	confluenceContent_issueViewBase_ConfluenceContentWithFragment$data,
} from '@atlassian/jira-relay/src/__generated__/confluenceContent_issueViewBase_ConfluenceContentWithFragment.graphql';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { ELIGIBLE_ISSUE_TYPES } from '../confluence-placeholder-template-experiment/common/constants.tsx';
import { ConfluenceDummyLinkProvider } from '../confluence-placeholder-template-experiment/controllers/confluence-dummy-link-context/index.tsx';
import AddButton from './add-button/index.tsx';
import ConfluencePageCreateLinkView from './create-link/index.tsx';
import DummyConfluenceLinkCoUseLazy from './dummy-confluence-link-co-use-lazy/index.tsx';
import { ConfluenceContentPlaceholderCrossJoin } from './dummy-confluence-link-grw-1117-lazy/index.tsx';
import DummyConfluenceLinkViewLazy from './dummy-confluence-link-view-lazy/index.tsx';
import { IncidentSuggestionPanelContainer } from './ai-suggestion-panel/index.tsx';
import { FindRelatedContentButtonContainer } from './ai-suggestion-panel/find-related-content-button/index.tsx';
import { MentionedConfluenceLinksSection } from './mentioned-confluence-links-section/index.tsx';

const LINKED_PAGES_GROUP_ID = 'linked-confluence-pages';
const LINKED_WHITEBOARDS_GROUP_ID = 'linked-confluence-whiteboards';
const MENTIONED_PAGES_GROUP_ID = 'mentioned-confluence-pages';
const runtimeProps = {};

export type ConfluenceContentProps = {
	isCreateLinkedPageOpened: boolean;
	isConfluencePageLinksEnabled?: boolean;
	canLinkConfluencePage: boolean;
	linkedPages: (ConfluencePage | FailedRemoteLink)[] | null | undefined;
	linkedPagesCachedCount?: number;
	linkedWhiteboards: ConfluenceWhiteboard[] | null | undefined;
	linkedWhiteboardsCachedCount?: number;
	systemConfluenceAppLinkUrl: string | undefined;
	licensedProducts?: { [key: string]: boolean };
	mentionedPages?: (ConfluencePage | FailedRemoteLink)[];
	mentionedPagesCachedCount?: number;
	mentionedPagesCount?: number;
	onLinkedPageClicked?: () => void;
	onLinkedWhiteboardClicked?: () => void;
	onMentionedPageClicked?: () => void;
	onAuthenticateApplink: (href: Href) => void;
	onDeleteConfluencePageLink: (globalId: RemoteLinkGlobalId) => void;
	/**
	 * This function is used to delete a confluence page link by its ARI.
	 */
	onDeleteConfluencePageLinkByAri: (ari: string) => void;
	onAddButtonClicked: () => void;
	onShowAISuggestionsClicked?: () => void;
	projectType?: string;
	issueType?: IssueType;
	projectName?: string;
	projectId?: string;
	hasActivatedConfluenceWithinFourteenDays?: boolean;
	isAISuggestionsOpen?: boolean;
	confluenceAppLinks: ConfluenceAppLink[] | [];
};

type ConfluenceContentPropsWithFragment = ConfluenceContentProps & {
	issueFragment?: confluenceContent_issueViewBase_ConfluenceContentWithFragment$data;
};

const ConfluenceContent: FunctionComponent<ConfluenceContentPropsWithFragment> = memo(
	({
		isCreateLinkedPageOpened,
		isConfluencePageLinksEnabled = false,
		canLinkConfluencePage,
		linkedPages,
		linkedPagesCachedCount = 0,
		linkedWhiteboards,
		linkedWhiteboardsCachedCount = 0,
		systemConfluenceAppLinkUrl,
		mentionedPages,
		mentionedPagesCachedCount = 0,
		mentionedPagesCount = 0,
		onLinkedPageClicked,
		onLinkedWhiteboardClicked,
		onMentionedPageClicked,
		onAuthenticateApplink,
		onDeleteConfluencePageLink,
		onDeleteConfluencePageLinkByAri,
		onAddButtonClicked,
		onShowAISuggestionsClicked,
		projectType,
		projectId,
		issueType,
		projectName,
		hasActivatedConfluenceWithinFourteenDays = false,
		isAISuggestionsOpen = false,
		confluenceAppLinks = [],
		issueFragment,
	}: ConfluenceContentPropsWithFragment) => {
		const isIssueTypeEligibleForPlaceholderTemplate = useMemo(
			() => (issueType ? ELIGIBLE_ISSUE_TYPES.includes(issueType.name.toLowerCase()) : false),
			[issueType],
		);

		const hasLinkedPages = useMemo(() => linkedPages && linkedPages.length > 0, [linkedPages]);

		const hasMentionedPages = useMemo(() => {
			if (fg('jiv-19125-fetch-remote-links-in-interactive-query')) {
				return mentionedPagesCount > 0;
			}

			return mentionedPages && mentionedPages.length > 0;
		}, [mentionedPages, mentionedPagesCount]);

		const hasLinkedWhiteboards = useMemo(
			() => linkedWhiteboards && linkedWhiteboards.length > 0,
			[linkedWhiteboards],
		);
		const hasContent = useMemo(
			() => hasLinkedPages || hasMentionedPages || hasLinkedWhiteboards,
			[hasLinkedPages, hasLinkedWhiteboards, hasMentionedPages],
		);

		const shouldShowConfluenceContent = useMemo(
			() => isCreateLinkedPageOpened || hasContent,
			[hasContent, isCreateLinkedPageOpened],
		);

		const isAiOptInEnabledHookVal = useIsAiEnabledForIssue();
		const isAiOptInEnabled = fg('jira_ai_powered_issue_related_confluence_resources')
			? isAiOptInEnabledHookVal
			: false;

		const isJiraIssue: boolean | null = fg('jira_ai_powered_issue_related_confluence_resources')
			? // eslint-disable-next-line react-hooks/rules-of-hooks
				useIsJiraIssue()
			: null;

		const canShowJiraAISuggestions = fg('jira_ai_powered_issue_related_confluence_resources')
			? isAiOptInEnabled && canLinkConfluencePage && isJiraIssue
			: false;

		const isIncidentIssue = useIsIssueOfIncidentsPractice();
		const canEnableRelatedContentPanel =
			isAiOptInEnabledHookVal && canLinkConfluencePage && isIncidentIssue;
		const incidentSuggestionPanelRef = useRef<IncidentPanelExternalHandlers>(null);

		const hasAnyLinkedPageLoaded = useMemo(
			() => linkedPages && linkedPages.length > 0,
			[linkedPages],
		);

		const hasAnyMentionedPageLoaded = useMemo(
			() => mentionedPages && mentionedPages.length > 0,
			[mentionedPages],
		);

		const { hostNamesFromApplinks } = useRelatedResourcesState();

		const { setHostNamesFromApplinks } = useRelatedResourcesActions();
		// Handle container update effects
		useEffect(() => {
			confluenceAppLinks && setHostNamesFromApplinks(confluenceAppLinks);
		}, [confluenceAppLinks, setHostNamesFromApplinks]);

		const entrypointParams = useMemo(() => {
			return {
				hostNames: Object.keys(hostNamesFromApplinks),
			};
		}, [hostNamesFromApplinks]);

		const entryPoint = useEntryPoint(AiRelatedResourcesEntrypoint, entrypointParams);
		const entrypointTriggerRef = useEntryPointButtonTrigger(entryPoint.entryPointActions, true);

		const { setData: setAIEntrypointParams } = useRelatedResourcesEntrypointStoreActions();

		useEffect(() => {
			setAIEntrypointParams({
				entrypointTriggerRef,
			});
		}, [setAIEntrypointParams, entrypointTriggerRef]);

		const hideManualWayOfLinkingPagesWhenAISuggestionsAreOpen = expVal(
			'jira_suggest_confluence_pages_when_linking_a_page',
			'isEnabled',
			false,
		)
			? false
			: canShowJiraAISuggestions && isAISuggestionsOpen;

		if (isConfluencePageLinksEnabled && shouldShowConfluenceContent) {
			const shouldShowHeading = shouldShowConfluenceContent;
			const shouldShowCoUseExperiment = !hasContent;

			return (
				<UFOSegment name="issue-confluence-pages">
					<JSErrorBoundary
						id="issue-view-confluence-content"
						packageName="confluenceContent"
						teamName="bento"
						fallback="unmount"
					>
						{shouldShowHeading && (
							<StyledSectionHeading>
								<SectionHeadingTitle>
									<FormattedMessage
										id="issue.details.confluence-content.heading"
										defaultMessage="Confluence content"
										description="Heading for linked Confluence content section."
									/>
								</SectionHeadingTitle>
								<SectionHeadingIcons>
									{canLinkConfluencePage && hasLinkedPages && (
										<AddButton onClick={onAddButtonClicked} />
									)}
									{fg('jira_ai_powered_issue_related_confluence_resources') ? (
										canShowJiraAISuggestions &&
										onShowAISuggestionsClicked && (
											<SuggestRelatedResourcesButton
												onShowAISuggestionsClicked={onShowAISuggestionsClicked}
											/>
										)
									) : (
										<></>
									)}
									{canEnableRelatedContentPanel &&
									expVal('suggested_incident_resources_v2', 'isEnabled', false) ? (
										<FindRelatedContentButtonContainer
											suggestionPanelRef={incidentSuggestionPanelRef}
										/>
									) : null}
								</SectionHeadingIcons>
							</StyledSectionHeading>
						)}
						{(hasLinkedPages || hasLinkedWhiteboards) && (
							<GroupContainer>
								{(linkedPages || linkedWhiteboards) && (
									<Container>
										{hasAnyLinkedPageLoaded && linkedPages && (
											<ConfluencePageLineCardGroup
												groupId={LINKED_PAGES_GROUP_ID}
												pages={linkedPages}
												onClick={onLinkedPageClicked}
												onAuthenticateApplink={onAuthenticateApplink}
												onDeleteConfluencePageLink={onDeleteConfluencePageLink}
												systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
											/>
										)}
										{hasLinkedWhiteboards && (
											<ConfluenceWhiteboardLineCardGroup
												groupId={LINKED_WHITEBOARDS_GROUP_ID}
												whiteboards={linkedWhiteboards ?? []}
												onClick={onLinkedWhiteboardClicked}
												systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
											/>
										)}
									</Container>
								)}
								{!linkedPages && <LineCardSkeleton itemCount={linkedPagesCachedCount} />}
								{!linkedWhiteboards && (
									<LineCardSkeleton itemCount={linkedWhiteboardsCachedCount} />
								)}
							</GroupContainer>
						)}
						{hasMentionedPages &&
							issueFragment != null &&
							fg('jiv-19125-fetch-remote-links-in-interactive-query') && (
								<UFOPlaceholder name="confluence-mentioned-pages" fallback={null}>
									<MentionedConfluenceLinksSection
										issueFragment={issueFragment}
										groupId={MENTIONED_PAGES_GROUP_ID}
										onPageClick={onMentionedPageClicked}
										onAuthenticateApplink={onAuthenticateApplink}
										onDeleteConfluencePageLink={onDeleteConfluencePageLinkByAri}
										systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
									/>
								</UFOPlaceholder>
							)}
						{hasMentionedPages && !fg('jiv-19125-fetch-remote-links-in-interactive-query') && (
							<GroupContainer>
								<Heading as="h3" size="xxsmall">
									<FormattedMessage
										id="issue.details.confluence-pages.mentioned-on"
										defaultMessage="mentioned on"
									/>
								</Heading>
								{!mentionedPages && <LineCardSkeleton itemCount={mentionedPagesCachedCount} />}
								{mentionedPages && (
									<Box xcss={containerWrapperStyles}>
										<Container>
											{hasAnyMentionedPageLoaded && mentionedPages && (
												<ConfluencePageLineCardGroup
													groupId={MENTIONED_PAGES_GROUP_ID}
													pages={mentionedPages}
													onClick={onMentionedPageClicked}
													onAuthenticateApplink={onAuthenticateApplink}
													onDeleteConfluencePageLink={onDeleteConfluencePageLink}
													systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
												/>
											)}
										</Container>
									</Box>
								)}
							</GroupContainer>
						)}
						{fg('confluence-placeholder-template-co-use-gate') && shouldShowCoUseExperiment ? (
							<AdControlProvider>
								<DummyConfluenceLinkCoUseLazy
									issueType={issueType}
									projectName={projectName}
									onAddButtonClicked={onAddButtonClicked}
									shouldShowHeading={!shouldShowHeading}
									hasActivatedConfluenceWithinFourteenDays={
										hasActivatedConfluenceWithinFourteenDays
									}
								/>
							</AdControlProvider>
						) : null}
						{fg('jira_ai_powered_issue_related_confluence_resources')
							? isCreateLinkedPageOpened &&
								!hideManualWayOfLinkingPagesWhenAISuggestionsAreOpen && (
									<ConfluencePageCreateLinkView />
								)
							: isCreateLinkedPageOpened && <ConfluencePageCreateLinkView />}

						{fg('jira_ai_powered_issue_related_confluence_resources') ? (
							canShowJiraAISuggestions &&
							isAISuggestionsOpen && (
								<Box
									paddingBlockStart={
										expVal('jira_suggest_confluence_pages_when_linking_a_page', 'isEnabled', false)
											? 'space.100'
											: 'space.0'
									}
								>
									<JiraEntryPointContainer
										entryPointReferenceSubject={entryPoint.entryPointReferenceSubject}
										id="related-resources-entry-point"
										packageName="jiraAiRelatedResources"
										fallback={null}
										runtimeProps={runtimeProps}
									/>
								</Box>
							)
						) : (
							<></>
						)}
						{canEnableRelatedContentPanel &&
						expVal('suggested_incident_resources_v2', 'isEnabled', false) ? (
							<IncidentSuggestionPanelContainer
								suggestionPanelRef={incidentSuggestionPanelRef}
								linkedPages={linkedPages || []}
							/>
						) : null}
					</JSErrorBoundary>
				</UFOSegment>
			);
		}

		if (projectType === SOFTWARE_PROJECT) {
			if (isIssueTypeEligibleForPlaceholderTemplate) {
				// the following 2 components can never be rendered at the same time
				return (
					<>
						<UFOSegment name="issue-confluence-pages-placeholder">
							<JSErrorBoundary
								id="issue-view-confluence-content-placeholder"
								packageName="confluenceContent"
								teamName="bento"
								fallback="unmount"
							>
								{/* Confluence Page Dummy Link Experiment
					(this component can only be rendered for tenants that DO NOT have confluence) */}
								{fg('issue_view_placeholder_template_e2e_gate') ? (
									<ContextualAnalyticsData
										sourceName="confluencePlaceholderTemplate"
										sourceType={SCREEN}
										attributes={{ experiment: 'placeholderTemplateContextual' }}
									>
										<Placeholder name="confluence-dummy-link-experiment" fallback={null}>
											<ConfluenceDummyLinkProvider>
												<AdControlProvider>
													<DummyConfluenceLinkViewLazy
														issueType={issueType}
														tenantHasConfluenceAppLinks={canLinkConfluencePage}
														projectName={projectName}
														projectId={projectId}
													/>
												</AdControlProvider>
											</ConfluenceDummyLinkProvider>
										</Placeholder>
									</ContextualAnalyticsData>
								) : null}
								{/* Confluence Placeholder Co-Use Experiment
					(this component can only be rendered for tenants that HAVE confluence) */}
								{fg('confluence-placeholder-template-co-use-gate') ? (
									<AdControlProvider>
										<DummyConfluenceLinkCoUseLazy
											issueType={issueType}
											projectName={projectName}
											onAddButtonClicked={onAddButtonClicked}
											shouldShowHeading
											hasActivatedConfluenceWithinFourteenDays={
												hasActivatedConfluenceWithinFourteenDays
											}
										/>
									</AdControlProvider>
								) : null}
								{fg('grw1117_jsw_pt_crossjoin_gate') ? (
									<ConfluenceDummyLinkProvider>
										<AdControlProvider>
											<ConfluenceContentPlaceholderCrossJoin
												issueType={issueType}
												projectName={projectName}
												onAddButtonClicked={onAddButtonClicked}
												shouldShowHeading
												hasActivatedConfluenceWithinFourteenDays={
													hasActivatedConfluenceWithinFourteenDays
												}
											/>
										</AdControlProvider>
									</ConfluenceDummyLinkProvider>
								) : null}
							</JSErrorBoundary>
						</UFOSegment>
						{fg('grw-1287-persistent-cross-join-nudge-gate') && (
							/* this experiment is similiar to grw1117 above but targets confluence activation after D14 */
							<UFOSegment name="persistent-cross-join-nudge">
								<JSErrorBoundary
									id="persistent-cross-join-nudge"
									packageName="persitentCrossJoinNudge"
									teamName="Groot (Growth)"
									fallback="unmount"
								>
									<PersistentCrossJoinNudge
										issueType={issueType}
										projectName={projectName}
										onAddButtonClicked={onAddButtonClicked}
										hasActivatedConfluenceWithinFourteenDays={
											hasActivatedConfluenceWithinFourteenDays
										}
										numberOfLinkedPages={linkedPagesCachedCount + linkedWhiteboardsCachedCount}
									/>
								</JSErrorBoundary>
							</UFOSegment>
						)}
					</>
				);
			}

			// we want to extend the above to cover also TASK issues
			if (fg('grw-1287-persistent-cross-join-nudge-gate')) {
				return (
					<UFOSegment name="persistent-cross-join-nudge">
						<JSErrorBoundary
							id="persistent-cross-join-nudge"
							packageName="persitentCrossJoinNudge"
							teamName="Groot (Growth)"
							fallback="unmount"
						>
							<PersistentCrossJoinNudge
								issueType={issueType}
								projectName={projectName}
								onAddButtonClicked={onAddButtonClicked}
								hasActivatedConfluenceWithinFourteenDays={hasActivatedConfluenceWithinFourteenDays}
								numberOfLinkedPages={linkedPagesCachedCount + linkedWhiteboardsCachedCount}
							/>
						</JSErrorBoundary>
					</UFOSegment>
				);
			}
		}

		return null;
	},
);

ConfluenceContent.defaultProps = {
	isConfluencePageLinksEnabled: false,
	linkedPagesCachedCount: 0,
	linkedWhiteboardsCachedCount: 0,
	mentionedPagesCachedCount: 0,
};

const ConfluenceContentWithFragment = (
	props: ConfluenceContentProps & {
		issueFragment: confluenceContent_issueViewBase_ConfluenceContentWithFragment$key;
	},
) => {
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<confluenceContent_issueViewBase_ConfluenceContentWithFragment$key>(
		graphql`
			fragment confluenceContent_issueViewBase_ConfluenceContentWithFragment on JiraIssue
			@argumentDefinitions(first: { type: "Int", defaultValue: 10 }, after: { type: "String" }) {
				...mentionedConfluenceLinksSection_issueViewBase
				confluenceMentionedLinks(first: $first, after: $after) {
					totalCount
				}
			}
		`,
		props.issueFragment,
	);

	// The mentioned pages count is used to determine to show the confluence content panel
	const mentionedPagesCount = data?.confluenceMentionedLinks?.totalCount ?? 0;

	return (
		<ConfluenceContent {...props} issueFragment={data} mentionedPagesCount={mentionedPagesCount} />
	);
};

const ConfluenceContentWithContainersNew = ({
	issueFragment,
	...props
}: ConfluenceContentProps & {
	issueFragment?: confluenceContent_issueViewBase_ConfluenceContentWithFragment$key;
}) => (
	<UFOPlaceholder name="confluence-content" fallback={null}>
		<RelatedResourcesContainer>
			{issueFragment != null && (
				<ConfluenceContentWithFragment {...props} issueFragment={issueFragment} />
			)}
		</RelatedResourcesContainer>
	</UFOPlaceholder>
);

/**
 * This is required since inside ConfluenceContent we access the related resources context to get the hostNamesFromApplinks
 */
const ConfluenceContentWithContainers = (props: ConfluenceContentProps) => (
	<RelatedResourcesContainer>
		<ConfluenceContent {...props} />
	</RelatedResourcesContainer>
);

export default componentWithCondition(
	() => fg('jiv-19125-fetch-remote-links-in-interactive-query'),
	ConfluenceContentWithContainersNew,
	ConfluenceContentWithContainers,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const StyledSectionHeading = styled(SectionHeading)<{}>({
	paddingBottom: token('space.100'),
});

const containerWrapperStyles = xcss({
	marginTop: 'space.100',
});

// 1px padding to the right to be aligned with the add comment box because of the shadow around it
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const GroupContainer = styled.div({
	marginTop: token('space.100'),
	marginRight: 0,
	marginBottom: token('space.100'),
	marginLeft: 0,
	paddingRight: token('space.025'),
});
