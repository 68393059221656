import React, { useCallback, type MouseEvent } from 'react';
import { Pressable, xcss } from '@atlaskit/primitives';
import CrossIcon from '@atlaskit/icon/utility/cross';
import Tooltip from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	onClick: () => void;
};

export const RemoveButton = ({ onClick }: Props) => {
	const { formatMessage } = useIntl();
	const handleClick = useCallback(
		(e: MouseEvent) => {
			e.stopPropagation();
			onClick();
		},
		[onClick],
	);

	return (
		<Tooltip content={formatMessage(messages.removeTooltip)}>
			{(tooltipProps) => (
				<Pressable
					{...tooltipProps}
					aria-label={formatMessage(messages.removeTooltip)}
					onClick={handleClick}
					padding="space.0"
					xcss={pressableStyles}
				>
					<CrossIcon color={token('color.icon')} spacing="compact" label="" />
				</Pressable>
			)}
		</Tooltip>
	);
};

const pressableStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	borderRadius: 'border.radius',
	display: 'flex',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});
