import React, { useState, useEffect, useCallback, useRef } from 'react';
import noop from 'lodash/noop';
import { Box, xcss, Pressable } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import CommentAddIcon from '@atlaskit/icon/core/comment-add';
import CloseIcon from '@atlaskit/icon/core/close';
import { IconButton } from '@atlaskit/button/new';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width/index.tsx';
import {
	canAddCommentsSelector,
	totalCommentsSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	MountEvent,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { COMMENTS } from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import { setSelectedActivityItem } from '@atlassian/jira-issue-view-store/src/actions/activity-feed-actions.tsx';
import { useDispatch, useSelector } from '@atlassian/jira-react-redux/src/index.tsx';
import { getSelectedActivityItem } from '@atlassian/jira-issue-view-store/src/selectors/activity-feed-selector.tsx';
import { addCommentFormExpand } from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { ISSUE_ACTION_SHORTCUT_ADD_COMMENT } from '@atlassian/jira-issue-view-keyboard-shortcuts/src/constant.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { messages as activityFeedMessages } from '../../messages.tsx';
import { messages } from './messages.tsx';
import { setHideAddCommentPlaceholder, getHideCommentPlaceholder } from './utils.tsx';

type Props = {
	totalComments: number;
	canAddComments?: boolean;
	containerWidth?: number;
	isSidebarCollapsed?: boolean;
	onToggleSidebar?: (expand: boolean, source?: string) => void;
};

export const AddCommentPlaceholder = ({
	canAddComments,
	totalComments,
	isSidebarCollapsed = false,
	onToggleSidebar = noop,
}: Props) => {
	const { formatMessage } = useIntl();
	const [isHidden, setIsHidden] = useState<boolean | undefined>(undefined);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const dispatch = useDispatch();
	const selectedActivityItem = useSelector(getSelectedActivityItem);
	const hasLoggedViewAttempt = useRef(false);

	useEffect(() => {
		setIsHidden(getHideCommentPlaceholder());
	}, []);

	// Analytics effect - runs once when all data is available
	useEffect(() => {
		// Skip if isHidden is still undefined or analytics already logged
		if (isHidden === undefined || hasLoggedViewAttempt.current || totalComments > 0) {
			return;
		}

		// Determine visibility reason with added check for permission
		let reason;
		if (!canAddComments) {
			reason = 'noPermission';
		} else if (isHidden) {
			reason = 'userDismissed';
		} else {
			reason = 'visible';
		}

		fireOperationalAnalytics(createAnalyticsEvent({}), 'addCommentPlaceholder viewAttempt', {
			isAddCommentsPlaceholderVisible: !isHidden && canAddComments,
			addCommentPlaceholderReason: reason,
		});

		hasLoggedViewAttempt.current = true;
	}, [isHidden, createAnalyticsEvent, canAddComments, totalComments]);

	const handleClose = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'add-comment-placeholder-cross-button',
		});
		fireUIAnalytics(analyticsEvent, 'dismissAddCommentPlaceholder');
		setHideAddCommentPlaceholder(true);
		setIsHidden(true);
	}, [createAnalyticsEvent]);

	// Split the handler into core functionality and form expansion
	const handleAddCommentCore = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'add-comment-placeholder',
		});
		fireUIAnalytics(analyticsEvent, 'clickAddCommentPlaceholder');

		// Expand the sidebar if it's collapsed
		if (isSidebarCollapsed) {
			onToggleSidebar(true, 'commentPlaceholder');
		}

		// Dispatch a custom event to hide the details tab if it's showing
		if (globalThis.document) {
			const toggleDetailsTabEvent = new CustomEvent('toggle-details-tab', {
				bubbles: true,
				detail: { visible: false },
			});
			globalThis.document.dispatchEvent(toggleDetailsTabEvent);
		}

		// Dispatch Redux action to select the comments tab
		if (!selectedActivityItem || selectedActivityItem.type !== COMMENTS) {
			dispatch(
				setSelectedActivityItem({
					key: COMMENTS,
					type: COMMENTS,
					name: formatMessage(activityFeedMessages.comments),
				}),
			);
		}
	}, [
		createAnalyticsEvent,
		isSidebarCollapsed,
		onToggleSidebar,
		selectedActivityItem,
		dispatch,
		formatMessage,
	]);

	// Click handler with form expansion
	const handleAddComment = useCallback(() => {
		handleAddCommentCore();
		// Only expand comment form on click, not on keypress
		dispatch(addCommentFormExpand());
	}, [handleAddCommentCore, dispatch]);

	// Add keyboard listener for 'M' key press
	useEffect(() => {
		// Only run this effect in browser environments
		if (typeof globalThis.document === 'undefined') {
			return;
		}

		const handleKeyDown = (event: KeyboardEvent) => {
			// Check if the key pressed is the add comment shortcut (case-insensitive)
			const target = event.target;
			const isInputField =
				target &&
				(target instanceof HTMLInputElement ||
					target instanceof HTMLTextAreaElement ||
					(target instanceof HTMLElement && target.isContentEditable));
			if (
				!isInputField &&
				event.key.toLowerCase() === ISSUE_ACTION_SHORTCUT_ADD_COMMENT.toLowerCase() &&
				!event.ctrlKey &&
				!event.altKey &&
				!event.metaKey &&
				canAddComments &&
				totalComments === 0
			) {
				// Trigger core functionality without form expansion
				handleAddCommentCore();
			}
		};

		// Add event listener to document - use globalThis.document consistently
		globalThis.document.addEventListener('keydown', handleKeyDown);

		// Cleanup function to remove event listener
		return () => {
			globalThis.document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleAddCommentCore, canAddComments, totalComments]);

	// Don't render anything while we're initializing to prevent flickering
	if (isHidden === undefined || !canAddComments || totalComments > 0 || isHidden) {
		return null;
	}

	return (
		<Box xcss={containerStyles}>
			<Pressable
				testId="issue-activity-feed.ui.add-comment-placeholder"
				xcss={wrapperStyles}
				onClick={handleAddComment}
			>
				<CommentAddIcon label="" color={token('color.icon.subtle')} LEGACY_size="medium" />
				<Box xcss={textStyles}>{formatMessage(messages.addCommentPlaceholderText)}</Box>
			</Pressable>
			<Box xcss={iconContainerStyles}>
				<IconButton
					label={formatMessage(messages.closeAddCommentPlaceholder)}
					onClick={handleClose}
					icon={CloseIcon}
					appearance="subtle"
				/>
			</Box>
		</Box>
	);
};

// Wrap component with ErrorBoundary and MountEvent
const AddCommentPlaceholderWithErrorBoundary = (props: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [errorDetails, setErrorDetails] = useState<{ message?: string; name?: string } | null>(
		null,
	);

	const handleError = useCallback(
		(location: string, error: Error) => {
			setErrorDetails({
				message: error?.message,
				name: error?.name,
			});

			// Send an immediate error analytics event
			fireOperationalAnalytics(createAnalyticsEvent({}), 'addCommentPlaceholder error', {
				errorLocation: location,
				errorMessage: error?.message,
				errorName: error?.name,
			});
		},
		[createAnalyticsEvent],
	);

	return (
		<JSErrorBoundary
			id="issue-activity-feed.ui.add-comment-placeholder-error-boundary"
			packageName="jiraIssueActionsActivityFeed"
			fallback="flag"
			onError={handleError}
		>
			<AddCommentPlaceholder {...props} />
			<MountEvent
				onMount={(analyticsEvent) => {
					if (props?.totalComments > 0) {
						return;
					}

					fireOperationalAnalytics(analyticsEvent, 'addCommentPlaceholder mounted', {
						canAddComments: props?.canAddComments,
						hasError: !!errorDetails,
						errorMessage: errorDetails?.message,
						errorType: errorDetails?.name,
					});
				}}
			/>
		</JSErrorBoundary>
	);
};

export default flowWithSafeComponent(
	connect(
		(state: State): Props => ({
			canAddComments: canAddCommentsSelector(state),
			totalComments: totalCommentsSelector(state),
		}),
	),
)(withContainerWidth(AddCommentPlaceholderWithErrorBoundary));

const containerStyles = xcss({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	marginTop: 'space.250',
});

const iconContainerStyles = xcss({
	position: 'absolute',
	right: 'space.100',
});

const wrapperStyles = xcss({
	borderStyle: 'dashed',
	borderColor: 'color.border',
	borderWidth: token('border.width'),
	borderRadius: token('border.radius'),
	height: '48px',
	width: '100%',
	paddingLeft: 'space.200',
	paddingRight: 'space.500',
	display: 'flex',
	alignItems: 'center',
	gap: 'space.100',
	backgroundColor: 'color.background.neutral.subtle',
	':hover': {
		borderStyle: 'solid',
	},
});

const textStyles = xcss({
	font: 'font.heading.xsmall',
	fontWeight: 'font.weight.medium',
	color: 'color.text.subtle',
});
