import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noneOption: {
		id: 'issue-field-single-user-picker-inline-edit-full.single-user-picker.none-option',
		defaultMessage: 'None',
		description: 'Text used in the dropdown item that, when select, will unset the selection.',
	},
	requiredErrorMessage: {
		id: 'issue-field-single-user-picker-inline-edit-full.single-user-picker.required-error-message',
		defaultMessage: '{fieldName} is required',
		description:
			'Validation message shown to the user when they attempt to clear a required field.',
	},
});
