/** @jsx jsx */
import React from 'react';
import { styled, css, jsx } from '@compiled/react';
import Button, { type ButtonProps } from '@atlaskit/button';
import ExpandIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import Pulse from './pulse/index.tsx';
import { DoneButton } from './styled.tsx';
import type { Props } from './types.tsx';

const getButton = (categoryId: number) => {
	switch (categoryId) {
		case 2:
			return ToDoButton;
		case 3:
			return DoneButton;
		case 4:
			return InProgressButton;
		default:
			return DefaultButton;
	}
};

const buttonStyle = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Required to increase specificity over Button's default fontWeight
	'&&': { fontWeight: token('font.weight.semibold') },
});

const buttonStyleRefresh = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Required to increase specificity over Button's default fontWeight
	'&&': {
		fontWeight: token('font.weight.medium'),
		'&::after': {
			border: 'none',
		},
	},
});

const buttonStyleRefreshDrop4 = css({
	paddingLeft: token('space.075'),
});

export const StatusButton = (props: Props) => {
	const {
		ariaLabel,
		isEditable,
		isOpen,
		status,
		targetRef,
		statusButtonRef,
		shouldFitContainer = true,
	} = props;
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const Button = getButton(status.statusCategory.id);
	return (
		<div ref={targetRef}>
			<Tooltip position="top" content={status.name}>
				<Pulse status={status}>
					<Button
						id="issue.fields.status-view.status-button"
						ref={statusButtonRef}
						aria-label={ariaLabel}
						aria-expanded={isOpen}
						isDisabled={!isEditable}
						iconAfter={<ExpandIcon label="" />}
						testId="issue-field-status.ui.status-view.status-button.status-button"
						shouldFitContainer={shouldFitContainer}
						// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
						css={[
							buttonStyle,
							isVisualRefreshEnabled() && buttonStyleRefresh,
							isVisualRefreshEnabled() && fg('visual-refresh_drop_4') && buttonStyleRefreshDrop4,
						]}
						// When the popup is open we don't want to track the click on this button as it solely closes
						// the popup.
						// The logic here may look incorrect, but it turns out that when clicking this button the
						// `isOpen` toggle happens before the `onClick` handler that sends the press tracing.
						// We need to disable the `jira/ufo/valid-labels` lint rule
						// because for this particular interaction we only want to track
						// opening the status popup.
						// eslint-disable-next-line jira/ufo/valid-labels
						interactionName={isOpen ? 'issue.fields.status.popup.open' : undefined}
					>
						{status.name}
					</Button>
				</Pulse>
			</Tooltip>
		</div>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const InProgressComponentVisualRefresh = styled(Button)(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				background: `${token('color.background.accent.blue.subtle')} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text')} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				background: `${token('color.background.accent.blue.subtle')} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text')} !important`,
			},
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const InProgressComponentCurrent = styled(Button)(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				background: `${token('color.background.information.bold')} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text.inverse')} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				background: `${token('color.background.information.bold.hovered')} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text.inverse')} !important`,
			},
		},
);

const InProgressButton = componentWithCondition(
	isVisualRefreshEnabled,
	InProgressComponentVisualRefresh,
	InProgressComponentCurrent,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ToDoButton = styled(Button)(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				background: `${token('color.background.neutral')} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text')} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				background: `${token('color.background.neutral.hovered')} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text')} !important`,
			},
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const DefaultButton = styled(Button)(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				background: `${token('color.background.neutral')} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text')} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				background: `${token('color.background.neutral.hovered')} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text')} !important`,
			},
		},
);
