import React from 'react';
import AlignTextLeftIcon from '@atlaskit/icon/core/align-text-left';
import ChildIssuesIcon from '@atlaskit/icon/core/child-issues';
import EditIcon from '@atlaskit/icon/core/edit';
import WorkItemsIcon from '@atlaskit/icon/core/work-items';
import PageIcon from '@atlaskit/icon/core/page';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import {
	SUGGEST_RELATED_RESOURCES_ID,
	IMPROVE_ISSUE_DESCRIPTION_ID,
	SUMMARIZE_COMMENTS_ID,
	SUGGEST_CHILD_ISSUES_ID,
	ADD_ISSUE_LINKS_ID,
} from '@atlassian/jira-issue-view-common-constants/src/quick-add-constants.tsx';
import { JIRA_CORE } from '@atlassian/jira-shared-types/src/application.tsx';
import { messages } from '../messages.tsx';
import type { AiActions, AiActionReturn } from '../types.tsx';

const getDisabledMessage = () => {
	if (fg('gravityai-2578-related-resources-copy-updates')) {
		// TODO: As part of GRAVITYAI-2504 (https://jplat.atlassian.net/browse/GRAVITYAI-2504), add
		// the correct message as per (https://hello.atlassian.net/wiki/x/wg3mDQE) for when no Confluence instance is attached.
		return messages.aiSuggestRelatedResourcesDisabledMessageReviewed;
	}
	if (fg('jira-issue-terminology-refresh-m3')) {
		return fg('confluence_live_pages_updates_jira_issue_view')
			? messages.aiSuggestRelatedContentDisabledMessageIssueTermRefresh
			: messages.aiSuggestRelatedResourcesDisabledMessageIssueTermRefresh;
	}
	return fg('confluence_live_pages_updates_jira_issue_view')
		? messages.aiSuggestRelatedContentDisabledMessage
		: messages.aiSuggestRelatedResourcesDisabledMessage;
};

export const getAiActions = ({
	application,
	formatMessage,
	onIssueBreakdownClick,
	issueBreakdownRef,
	isIssueBreakdownEnabled,
	onSmartSummaryClick,
	smartSummaryRef,
	isSmartSummaryEnabled,
	onRelatedResourcesClick,
	isImproveDescriptionEnabled,
	onImproveDescriptionClick,
	isRelatedConfluencePagesEnabled,
	onRelatedIssuesClick,
	relatedIssuesRef,
	isRelatedIssuesEnabled,
	relatedIssuesDisabledMessage,
	relatedConfluencePagesRef,
}: AiActions): AiActionReturn[] => {
	const relatedResourcesOption: AiActionReturn = {
		id: SUGGEST_RELATED_RESOURCES_ID,
		tooltip: fg('gravityai-2578-related-resources-copy-updates')
			? formatMessage(messages.aiLinkRelatedConfluenceLabel)
			: formatMessage(
					fg('confluence_live_pages_updates_jira_issue_view')
						? messages.aiSuggestRelatedContentLabel
						: messages.aiSuggestRelatedResourcesLabel,
				),
		label: fg('gravityai-2578-related-resources-copy-updates')
			? formatMessage(messages.aiLinkRelatedConfluenceLabel)
			: formatMessage(
					fg('confluence_live_pages_updates_jira_issue_view')
						? messages.aiSuggestRelatedContentLabel
						: messages.aiSuggestRelatedResourcesLabel,
				),
		icon: <PageIcon color={token('color.icon')} label="" />,
		onClick: onRelatedResourcesClick,
		itemIndex: 1,
		shouldRender: isRelatedConfluencePagesEnabled,
		disabledMessage: formatMessage(getDisabledMessage()),
		testId:
			'issue.issue-view.views.issue-base.foundation.quick-add.improve-issue.suggest-related-resources',
		ref: relatedConfluencePagesRef,
	};

	const getSummarizeLabel = () => {
		return messages.aiSuggestSummarizeCommentsLabel;
	};

	const aiItems: AiActionReturn[] = [
		{
			id: IMPROVE_ISSUE_DESCRIPTION_ID,
			tooltip: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.aiSuggestImproveDescriptionLabelIssueTermRefresh
					: messages.aiSuggestImproveDescriptionLabel,
			),
			label: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.aiSuggestImproveDescriptionLabelIssueTermRefresh
					: messages.aiSuggestImproveDescriptionLabel,
			),
			icon: <EditIcon color={token('color.icon')} label="" />,
			onClick: onImproveDescriptionClick,
			itemIndex: 0,
			shouldRender: isImproveDescriptionEnabled,
			disabledMessage: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.aiSuggestImproveDescriptionDisabledMessageIssueTermRefresh
					: messages.aiSuggestImproveDescriptionDisabledMessage,
			),
			testId:
				'issue.issue-view.views.issue-base.foundation.quick-add.improve-issue.improve-description',
		},
		...(fg('jira_ai_powered_issue_related_confluence_resources') ? [relatedResourcesOption] : []),
		{
			id: SUMMARIZE_COMMENTS_ID,
			tooltip: formatMessage(getSummarizeLabel()),
			label: formatMessage(getSummarizeLabel()),
			icon: <AlignTextLeftIcon color={token('color.icon')} label="" />,
			onClick: onSmartSummaryClick,
			itemIndex: 2,
			shouldRender: isSmartSummaryEnabled,
			disabledMessage: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.aiSuggestSummarizeCommentsDisabledMessageIssueTermRefresh
					: messages.aiSuggestSummarizeCommentsDisabledMessage,
			),
			testId:
				'issue.issue-view.views.issue-base.foundation.quick-add.improve-issue.summarize-comments',
			ref: smartSummaryRef,
		},
		{
			id: SUGGEST_CHILD_ISSUES_ID,
			tooltip: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.aiSuggestChildIssuesLabelIssueTermRefresh
					: messages.aiSuggestChildIssuesLabel,
			),
			label: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.aiSuggestChildIssuesLabelIssueTermRefresh
					: messages.aiSuggestChildIssuesLabel,
			),
			icon: <ChildIssuesIcon color={token('color.icon')} label="" />,
			onClick: onIssueBreakdownClick,
			itemIndex: 3,
			shouldRender: isIssueBreakdownEnabled,
			disabledMessage: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.aiSuggestChildIssuesDisabledStateIssueTermRefresh
					: messages.aiSuggestChildIssuesDisabledState,
			),
			testId:
				'issue.issue-view.views.issue-base.foundation.quick-add.improve-issue.suggest-child-issues',
			ref: issueBreakdownRef,
		},
		{
			id: ADD_ISSUE_LINKS_ID,
			tooltip: fg('aix-967-similar-issues-content-review')
				? formatMessage(messages.aiSuggestRelatedIssuesTooltipReviewed, {
						b: (text: React.ReactNode) => <b>{text}</b>,
					})
				: formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.aiSuggestRelatedIssuesLabelIssueTermRefresh
							: messages.aiSuggestRelatedIssuesLabel,
					),
			label: fg('aix-967-similar-issues-content-review')
				? formatMessage(messages.aiSuggestRelatedIssuesLabelReviewed)
				: formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.aiSuggestRelatedIssuesLabelIssueTermRefresh
							: messages.aiSuggestRelatedIssuesLabel,
					),
			icon: <WorkItemsIcon color={token('color.icon')} label="" />,
			onClick: onRelatedIssuesClick,
			itemIndex: 4,
			shouldRender: isRelatedIssuesEnabled,
			disabledMessage: relatedIssuesDisabledMessage,
			testId:
				'issue.issue-view.views.issue-base.foundation.quick-add.improve-issue.suggest-related-issues',
			ref: relatedIssuesRef,
		},
	];

	if (!fg('platform_gravityai-1853_issue_reformatter_ga')) {
		/*
			Business logic GRAVITYAI-1762
		*/
		if (application === JIRA_CORE) {
			return aiItems.filter((item) => item.id !== IMPROVE_ISSUE_DESCRIPTION_ID);
		}
	}

	if (!fg('jira_ai_related_issue_enabled')) {
		return aiItems.filter((item) => item.id !== ADD_ISSUE_LINKS_ID);
	}

	return aiItems;
};
