import type { ResizerProps } from '../../common/types.tsx';
import type { onResizeParams } from './types.tsx';

type ResizingState =
	| { type: 'idle' }
	| {
			type: 'resizing';
			/**
			 * The initial width from the first resize event needs to be stored for the onResizeEnd call, as each subsequent keyboard resize
			 * has a new `initialWidth` value based on the current panel width.
			 */
			initialWidth: number;
			/**
			 * Cancels the timeout to reset the state to idle.
			 */
			abort: () => void;
	  };

/**
 * The debounce time for the PanelSplitter resize callback functions.
 *
 * - For `onResizeStart`, it will call the callback immediately, but not
 * again until the user stops resizing and this wait time has passed.
 * - For `onResizeEnd`, it will call the callback only after this time has
 * passed since the last resize event.
 */
const resizeCallbackDebounceTimeMs = 500;

/**
 * Returns an instance of a state machine manager for keyboard resizing.
 * It handles calling the `onResizeStart` and `onResizeEnd` callbacks appropriately, as the user "starts" and "finishes" resizing
 * using the keyboard, to prevent calling them on every keydown.
 *
 * - `onResizeStart` is called immediately when the user starts resizing
 * - `onResizeEnd` is called after a wait time when the user stops resizing.
 */

export function createKeyboardResizeManager({
	onResizeStart,
	onResizeEnd,
}: {
	onResizeStart: () => void;
	onResizeEnd: ResizerProps['onRatioChange'];
}) {
	let resizingState: ResizingState = {
		type: 'idle',
	};

	/**
	 * Starts a timer to reset the state to idle and call `onResizeEnd` after a wait time.
	 */
	function startResizeEndTimer({ ratio, width, flexBasis }: onResizeParams) {
		const markResizeAsEndedTimeout = setTimeout(() => {
			onResizeEnd?.(ratio, width, flexBasis);
			resizingState = { type: 'idle' };
		}, resizeCallbackDebounceTimeMs);

		resizingState = {
			type: 'resizing',
			initialWidth: width,
			abort() {
				clearTimeout(markResizeAsEndedTimeout);
			},
		};
	}

	function onResize({ ratio, width, flexBasis }: onResizeParams) {
		if (resizingState.type === 'idle') {
			// If the state was idle, this counts as a resize start event.
			onResizeStart();

			startResizeEndTimer({ ratio, width, flexBasis });
			return;
		}

		if (resizingState.type === 'resizing') {
			// If the state was already resizing, clear the existing timeout and start a new one.
			resizingState.abort();

			startResizeEndTimer({
				ratio,
				width,
				flexBasis,
			});
		}
	}

	return {
		onResize,
	};
}
