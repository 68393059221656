/**
 * @generated SignedSource<<98ff9e60ca984f7a2a48babeef509f66>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { UpdatableFragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type updateHideDoneState$data = {
  isIssueViewHideDoneChildIssuesFilterEnabled: boolean | null | undefined;
  readonly " $fragmentType": "updateHideDoneState";
};
export type updateHideDoneState$key = {
  readonly " $data"?: updateHideDoneState$data;
  readonly $updatableFragmentSpreads: FragmentRefs<"updateHideDoneState">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "updateHideDoneState",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isIssueViewHideDoneChildIssuesFilterEnabled"
    }
  ],
  "type": "JiraUserPreferences"
};

(node as any).hash = "4d9e8be4e7a4ce0964105fa782efcef1";

export default node;
