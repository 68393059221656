import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { toBaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { useAnalyticsSource } from '@atlassian/jira-issue-context-service/src/index.tsx';
import { CORE_ISSUE } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import type { Context } from './common/types.tsx';
import { optInBackOnboardingEntryPoint } from './ui/onboarding/entrypoint.tsx';
import { useOnboardingTour } from './services/onboarding-tour/main.tsx';
import { getShouldShowWelcomeTourFromUserPreferences } from './common/utils.tsx';

const entryPointParams = {};
export const AsyncOptInBackOnboarding = (props: Context) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		optInBackOnboardingEntryPoint,
		entryPointParams,
	);
	const [{ isTourActive }] = useOnboardingTour();
	const accountIdLoggedInUser = useAccountId();
	const baseUrl = toBaseUrl('');
	const isJWM = useAnalyticsSource() === CORE_ISSUE;

	// Temporary for experimentation purposes, otherwise use entrypoint declarative triggers instead.
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		// Only load entrypoint when the onboarding tour is active or the user has never seen the welcome message before.
		const checkWelcomeSteps = async () => {
			const shouldLoadWelcomeSteps = await getShouldShowWelcomeTourFromUserPreferences(
				baseUrl,
				accountIdLoggedInUser,
			);

			// Undefined is default case for when users have never seen the welcome message before.
			if (shouldLoadWelcomeSteps || shouldLoadWelcomeSteps === undefined) {
				entryPointActions.load();
			}
		};

		if (isTourActive) {
			entryPointActions.load();
		} else if (!isJWM) {
			checkWelcomeSteps();
		}
	}, [accountIdLoggedInUser, baseUrl, entryPointActions, isJWM, isTourActive]);

	return (
		<JiraEntryPointContainer
			id="issue-onboarding-modal-lazy"
			packageName="AsyncOptInBackOnboarding"
			placeholderName="async-issue-details-onboarding-modal"
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={null}
			runtimeProps={props}
		/>
	);
};
