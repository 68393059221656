import React, { useState, useMemo, useCallback } from 'react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import SortAscendingIcon from '@atlaskit/icon/core/sort-ascending';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import DisabledButtonWithTooltip from '@atlassian/jira-disabled-button-with-tooltip/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { PRIORITY } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import {
	hasExceededIssuesLimitAfterLoad,
	hasExceededIssuesLimitInitialLoad,
} from '../../../common/util.tsx';
import {
	SORT_OPTION_PRIORITY,
	SORTING_OPTIONS,
	SORT_OPTION_RANKING,
	DEFAULT_SORTING_OPTION,
} from '../../../model/types.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const DropdownItemWithAnalytics = AnalyticsEventToProps('dropdownItem', {
	onClick: 'clicked',
})(DropdownItem);

export const ActionsSortByMenu = (props: Props) => {
	const { onSelect, childIssuesLimitStatus, isRankingEnabled } = props;
	const options = fg('chin-refactor-and-prune-jiv-child-issues-sorting')
		? [DEFAULT_SORTING_OPTION]
		: SORTING_OPTIONS;

	let availableSortingOptions = options;

	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	// we only want to allow filter by priority if we have `allowedValues` in context
	const [{ value: priorityField }] = useFieldConfig(issueKey, PRIORITY);

	if (!priorityField || !priorityField.allowedValues || priorityField.allowedValues.length <= 1) {
		availableSortingOptions = options.filter((option) => option !== SORT_OPTION_PRIORITY);
	}

	if (!isRankingEnabled && fg('hide-rank-option-when-unavailable')) {
		availableSortingOptions = availableSortingOptions.filter(
			(option) => option !== SORT_OPTION_RANKING,
		);
	}
	const optionLabels = useMemo(() => {
		const labelsMap = new Map();
		availableSortingOptions.forEach((sortOption) => {
			labelsMap.set(sortOption, formatMessage(messages[sortOption]));
		});
		return labelsMap;
	}, [availableSortingOptions, formatMessage]);

	const hasExceededLimitAfterLoad = hasExceededIssuesLimitAfterLoad(childIssuesLimitStatus);

	const button = useCallback(
		// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
		({ triggerRef, ...triggerProps } = {}) => (
			<DropdownItem
				{...triggerProps}
				isSelected={isOpen}
				elemBefore={<SortAscendingIcon color={token('color.icon')} label="" />}
				elemAfter={<ChevronRightIcon spacing="spacious" label="" />}
				testId="issue-view-common-views.child-issues-panel.heading.actions-sort-by-menu.trigger-button"
				isDisabled={hasExceededLimitAfterLoad}
				ref={triggerRef}
			>
				<Text>{formatMessage(messages.sortByLabel)}</Text>
			</DropdownItem>
		),
		[formatMessage, hasExceededLimitAfterLoad, isOpen],
	);

	if (hasExceededIssuesLimitInitialLoad(childIssuesLimitStatus)) {
		return null;
	}

	if (hasExceededLimitAfterLoad) {
		return (
			<Tooltip
				content={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.exceededChildIssuesLimitIssueTermRefresh
						: messages.exceededChildIssuesLimit,
				)}
				position="top"
			>
				<DisabledButtonWithTooltip isDisabled={hasExceededLimitAfterLoad}>
					{button()}
				</DisabledButtonWithTooltip>
			</Tooltip>
		);
	}

	return (
		<DropdownMenu
			placement="right-start"
			trigger={button}
			isOpen={isOpen}
			onOpenChange={() => {
				setIsOpen(!isOpen);
			}}
		>
			<DropdownItemGroup>
				{availableSortingOptions.map((option) => (
					<DropdownItemWithAnalytics
						key={option}
						data-testId={`issue.views.common.child-issues-panel.heading.sort-by-menu.option-${option}`}
						// @ts-expect-error - TS7006 - Parameter 'event' implicitly has an 'any' type. | TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
						onClick={(event, analyticsEvent) => onSelect(option, analyticsEvent)}
					>
						{optionLabels.get(option)}
					</DropdownItemWithAnalytics>
				))}
			</DropdownItemGroup>
		</DropdownMenu>
	);
};
