import React, {
	useMemo,
	useState,
	useCallback,
	useEffect,
	type ReactNode,
	type PropsWithChildren,
	forwardRef,
	useRef,
} from 'react';
import { ButtonItem } from '@atlaskit/menu';
import Lozenge from '@atlaskit/lozenge';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import WarningIcon from '@atlaskit/icon/core/warning';
import { token } from '@atlaskit/tokens';
import InformationIcon from '@atlaskit/icon/core/information';
import Link from '@atlaskit/link';
import Button from '@atlaskit/button/new';
import Popup from '@atlaskit/popup';
import RecurIcon from '@atlaskit/icon-lab/core/recur';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { defineMessages, useIntl } from '@atlassian/jira-intl';
import { MenuIcon } from '@atlassian/automation-manual-triggers';
import { parse } from '@atlassian/jira-ical/src/parse.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { ParseResult } from '@atlassian/jira-ical/src/types.tsx';
import {
	getRecurMenuItemLabel,
	isRecurOnCompleteToggleRule,
} from '@atlassian/jira-recur-work-item/src/ui/utils.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	useRecurWorkItemActions,
	useRecurWorkItemState,
} from '@atlassian/jira-recur-work-item/src/controllers/index.tsx';
import { useIsProjectAdmin } from '@atlassian/jira-issue-hooks/src/services/use-is-project-admin/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import type { TriggerComponentProps, RecurWorkProps } from './types.tsx';
import { BUTTON, BUTTON_ITEM_TEST_ID_SUFFIX } from './constants.tsx';

const messages = defineMessages({
	ruleDidNotLoad: {
		id: 'recur-work-work-item-trigger.popup.rule-did-not-load',
		defaultMessage: 'Rule did not load. Try refreshing the page.',
		description: 'Message shown when an automation rule exists but fails to load',
	},
	userNotPermittedToEdit: {
		id: 'recur-work-work-item-trigger.popup.user-not-permitted-to-edit',
		defaultMessage: '<link>Contact your administrator</link> to edit this rule.',
		description: 'Message shown when the user does not have permission to edit the rule',
	},
	newLozengeLabel: {
		id: 'recur-work-work-item-trigger.new-lozenge-label',
		defaultMessage: 'New',
		description: 'Lozenge indicating that this is a new feature',
	},
});

export const TriggerComponent = forwardRef<
	HTMLButtonElement,
	PropsWithChildren<TriggerComponentProps>
>(
	(
		{
			triggerType,
			shouldFitContainer,
			iconBefore: IconBefore,
			iconAfter: IconAfter,
			children,
			menuItemMessage,
			shouldShowNewLozenge,
			hasRule,
			...rest
		},
		ref,
	) => {
		const { formatMessage } = useIntl();
		if (triggerType === BUTTON) {
			return (
				<Button
					{...rest}
					iconAfter={IconAfter}
					shouldFitContainer={shouldFitContainer}
					ref={ref}
					interactionName="jira-issue-field.ui.recur-work.button"
					appearance="subtle"
				>
					<Inline alignBlock="center" space="space.075">
						{IconBefore ? <IconBefore label="" /> : undefined}
						<Box xcss={[ellipsisOverflowStyle, hasRule ? defaultTextStyle : subtleTextStyle]}>
							{formatMessage(menuItemMessage)}
						</Box>
						{shouldShowNewLozenge && (
							<Box xcss={lozengeStyle}>
								<Lozenge appearance="new">{formatMessage(messages.newLozengeLabel)}</Lozenge>
							</Box>
						)}
					</Inline>
				</Button>
			);
		}

		return (
			<ButtonItem
				{...rest}
				iconBefore={
					IconBefore ? (
						<MenuIcon>
							<IconBefore label="" />
						</MenuIcon>
					) : undefined
				}
				{...(IconAfter && { iconAfter: <IconAfter label="" /> })}
				ref={ref}
				interactionName="jira-issue-field.ui.recur-work-menu-item.button"
			>
				<Inline alignBlock="center" space="space.075">
					{formatMessage(menuItemMessage)}
					{shouldShowNewLozenge && (
						<Lozenge appearance="new">{formatMessage(messages.newLozengeLabel)}</Lozenge>
					)}
				</Inline>
			</ButtonItem>
		);
	},
);

export const RecurWorkComponent = ({
	triggerType,
	buttonRef,
	onClick,
	issueKey,
	recurWorkFormEntryPoint,
	testId,
	shouldShowNewLozenge,
}: RecurWorkProps) => {
	const { formatMessage } = useIntl();
	const isProjectAdmin = useIsProjectAdmin();
	const isSiteAdmin = useIsSiteAdmin();
	const projectKey = useProjectKey(issueKey);
	const [{ canEditIssues }] = useProjectPermissions(projectKey);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		issueProperties,
		hasFetched,
		hasBootstrapError,
		isRuleOwner,
		isFetching,
		isDeletingRule,
		isCreatingRule,
		isUpdatingRule,
	} = useRecurWorkItemState();
	const { fetch: refetch } = useRecurWorkItemActions();
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const hasMountedRef = useRef(false);
	const hasFiredViewedEventRef = useRef(false);
	const buttonRefForNonEditableRule = useRef<HTMLButtonElement | null>(null);

	const userPermittedToEdit =
		canEditIssues &&
		(isSiteAdmin || isProjectAdmin || (issueProperties && isRuleOwner) || !issueProperties);

	useEffect(() => {
		if (hasFetched && !(isFetching || isDeletingRule || isCreatingRule || isUpdatingRule)) {
			refetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!hasFiredViewedEventRef.current && hasFetched) {
			hasFiredViewedEventRef.current = true;
			fireTrackAnalytics(createAnalyticsEvent({}), 'menuItem viewed', 'recurOnSchedulePopup', {
				canViewRecurOnSchedulePopup: userPermittedToEdit && !hasBootstrapError,
				recurWorkFormEntryPoint,
			});
		}
	}, [
		hasFetched,
		createAnalyticsEvent,
		userPermittedToEdit,
		recurWorkFormEntryPoint,
		hasBootstrapError,
	]);

	useEffect(() => {
		// prevent focusing on mount, only when it changes from open -> closed
		if (!hasMountedRef.current) {
			hasMountedRef.current = true;
			return;
		}
		if (!isPopupOpen) {
			buttonRefForNonEditableRule.current?.focus();
		}
	}, [isPopupOpen]);

	const ruleFailedToLoad = hasFetched && hasBootstrapError;

	const menuItemMessage = useMemo(() => {
		const isRecurOnComplete = isRecurOnCompleteToggleRule(issueProperties);

		let parsedrRule: ParseResult | undefined;
		try {
			parsedrRule = parse(issueProperties?.recurOnScheduleConfig?.rRule);
		} catch (err) {
			log.safeErrorWithoutCustomerData(
				'jira-issue-field-status.src.ui.status-view.actions.actions-menu.recur-work-menu-item',
				'Error parsing rRule string',
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				err as Error,
			);
		}
		const rrule = parsedrRule?.event?.rrule ?? {};

		return getRecurMenuItemLabel(rrule, isRecurOnComplete);
	}, [issueProperties]);

	const handleClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'menuItem clicked', 'recurOnSchedulePopup', {
			recurWorkFormEntryPoint,
		});
		onClick();
	}, [createAnalyticsEvent, onClick, recurWorkFormEntryPoint]);

	if (ruleFailedToLoad || !userPermittedToEdit) {
		const popupMessage = ruleFailedToLoad
			? formatMessage(messages.ruleDidNotLoad)
			: formatMessage(messages.userNotPermittedToEdit, {
					link: (chunks: ReactNode) => <Link href="/jira/contact-administrator">{chunks}</Link>,
				});

		const dataTestId = ruleFailedToLoad
			? `${testId}.warn-button-item`
			: `${testId}.info-button-item`;

		const Icon = ruleFailedToLoad ? (
			<WarningIcon color={token('color.icon.warning')} label="" />
		) : (
			<InformationIcon color={token('color.icon.discovery')} label="" />
		);

		return (
			<Popup
				isOpen={isPopupOpen}
				onClose={() => {
					setIsPopupOpen(false);
				}}
				placement="top"
				shouldRenderToParent
				content={() => (
					<Box
						padding="space.200"
						onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
							const keyPressed = event.key.toLowerCase();
							if (keyPressed === 'escape') {
								// stop propagation is required so that once the user has tabbed into the popup,
								// pressing escape doesn't close the parent component (i.e. the date picker or
								// the actions menu)
								event.stopPropagation();
								setIsPopupOpen(false);
							}
						}}
					>
						{popupMessage}
					</Box>
				)}
				trigger={({ ref, ...triggerProps }) => {
					const mergedRefs = mergeRefs(buttonRefForNonEditableRule, ref);
					return (
						<TriggerComponent
							{...triggerProps}
							ref={mergedRefs}
							triggerType={triggerType}
							onClick={() => {
								setIsPopupOpen(!isPopupOpen);
							}}
							iconBefore={() => Icon}
							testId={dataTestId}
							shouldShowNewLozenge={shouldShowNewLozenge}
							menuItemMessage={menuItemMessage}
							hasRule={!!issueProperties}
						/>
					);
				}}
			/>
		);
	}

	return (
		<TriggerComponent
			triggerType={triggerType}
			ref={buttonRef}
			onClick={handleClick}
			iconBefore={RecurIcon}
			iconAfter={ChevronRightIcon}
			shouldFitContainer
			testId={`${testId}${BUTTON_ITEM_TEST_ID_SUFFIX}`}
			shouldShowNewLozenge={shouldShowNewLozenge}
			menuItemMessage={menuItemMessage}
			hasRule={!!issueProperties}
		/>
	);
};

const ellipsisOverflowStyle = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const defaultTextStyle = xcss({
	color: 'color.text',
});

const subtleTextStyle = xcss({
	color: 'color.text.subtle',
});

const lozengeStyle = xcss({
	flexShrink: 0,
});
