import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError, {
	ABORT_ERROR_NAME,
	VALIDATION_ERROR_NAME,
} from '@atlassian/jira-fetch/src/utils/errors.tsx';
import {
	isClientFetchError,
	isHttpClientErrorResponse,
} from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	getChildIssuesPanelType,
	getChildIssuesPanelApplication,
	getChildIssuesPanelEdition,
} from '../../state/context/selectors.tsx';
import {
	type CreateChildFailureAction,
	type LinkParentFailureAction,
	type LinkingIssueFailureAction,
	CREATE_CHILD_FAILURE,
	LINK_PARENT_FAILURE,
	LINKING_ISSUE_FAILURE,
} from '../../state/entities/actions.tsx';
import type { State } from '../../state/types.tsx';

type ChildIssueCreateFailureAction =
	| CreateChildFailureAction
	| LinkParentFailureAction
	| LinkingIssueFailureAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<ChildIssueCreateFailureAction>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(CREATE_CHILD_FAILURE, LINK_PARENT_FAILURE, LINKING_ISSUE_FAILURE)
		.do(({ type, payload: { error } }) => {
			const state = store.getState();
			const childIssuePanelType = getChildIssuesPanelType(state) || 'unknown_child';
			const application = getChildIssuesPanelApplication(state);
			const edition = getChildIssuesPanelEdition(state);

			/*
				SLA Monitoring

				We track all 4xx as part of taskFail events, which are filtered at SLO level.
				We have detectors added to track this 4xx error spikes.
			 */
			if (fg('bento_send_4xx_to_task_fail_gate_1')) {
				// add logging for all errors
				log.safeErrorWithoutCustomerData(
					'createIssue.child_issues_pannel.child_create_failure',
					error.message,
					error,
				);

				sendExperienceAnalytics({
					experience: 'createIssue',
					wasExperienceSuccesful: false,
					analyticsSource: childIssuePanelType,
					application,
					edition,
					additionalAttributes: {
						// @ts-expect-error - TS2339 - Property 'traceId' does not exist on type 'Error | ValidationError'.
						traceId: error?.traceId,
						errorMessage: `${type} ${error.message}`,
						...('statusCode' in error && fg('thor_add_missing_attributes_across_issue_view_1')
							? { statusCode: error.statusCode }
							: {}),
					},
				});
			} else if (type === CREATE_CHILD_FAILURE && error.name === VALIDATION_ERROR_NAME) {
				/*
				SLA Monitoring

				Almost any time these FAILURE actions are sent, we send a createIssue failure event.

				Except: it is possible for an admin to misconfigure their project in such a way that
				the Global Issue Create dialog doesn't handle all required fields, and the Issue Create
				API request still returns a 400 BAD REQUEST. This is "expected", and so we track it
				as a "success" and not a failure.

				Also, any AbortError or TypeError is a result of an operation being cancelled by the
				user (e.g. navigating away from the page before the request is complete) and so we
				do not count these in our frontend SLA events at all.
			 */
				sendExperienceAnalytics({
					experience: 'createIssue',
					wasExperienceSuccesful: true,
					analyticsSource: childIssuePanelType,
					application,
					edition,
					additionalAttributes: {
						// @ts-expect-error - TS2339 - Property 'traceId' does not exist on type 'Error | ValidationError'.
						traceId: error?.traceId,
						errorMessage: `${type} ${error?.message}`,
					},
				});
			} else if (error.name !== ABORT_ERROR_NAME && !isClientFetchError(error)) {
				// add logging for all errors
				log.safeErrorWithoutCustomerData(
					'createIssue.child_issues_pannel.child_create_failure',
					error.message,
					error,
				);

				if (isHttpClientErrorResponse(error)) {
					return;
				}

				sendExperienceAnalytics({
					experience: 'createIssue',
					wasExperienceSuccesful: false,
					analyticsSource: childIssuePanelType,
					application,
					edition,
					additionalAttributes: {
						// @ts-expect-error - TS2339 - Property 'traceId' does not exist on type 'Error | ValidationError'.
						traceId: error.traceId,
						errorMessage: `${type} ${error.message}`,
						...(error instanceof FetchError && fg('thor_add_missing_attributes_across_issue_view_1')
							? { statusCode: error.statusCode }
							: {}),
					},
				});
			}
		})
		.switchMap(() => Observable.empty<never>());
