/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { CardAction, ElementName, SmartLinkSize, TitleBlock } from '@atlaskit/smart-card';
import { CardSSR as Card } from '@atlaskit/smart-card/ssr';
import { Box, xcss } from '@atlaskit/primitives';
import { SmartCardProvider } from '@atlaskit/link-provider';
import { token } from '@atlaskit/tokens';
import { RemoveButton } from './remove-button/index.tsx';

const uiOptions = {
	clickableContainer: true,
	hideBackground: true,
	hideElevation: true,
	hidePadding: true,
	size: SmartLinkSize.Medium,
};

const actionOptions = {
	hide: false,
	exclude: [CardAction.ChangeStatusAction],
};

type TitleBlockProps = Parameters<typeof TitleBlock>[0];
type Props = {
	/** URL to resolve for the smart link. */
	url: string;
	/** When defined this will display a remove button after the link that calls `onRemove` when clicked. */
	onRemove?: () => void;
} & Pick<TitleBlockProps, 'anchorTarget' | 'text'>;

export const SimpleInlineSmartLink = ({ onRemove, url, anchorTarget, text }: Props) => {
	return (
		<SmartCardProvider>
			<Box paddingBlock="space.025" paddingInline="space.050" xcss={inlineStyles}>
				<Card
					appearance="inline"
					url={url}
					ui={uiOptions}
					actionOptions={actionOptions}
					showHoverPreview
				>
					<TitleBlock
						hideTitleTooltip
						maxLines={1}
						metadata={[
							{
								name: ElementName.State,
							},
						]}
						anchorTarget={anchorTarget ?? '_self'}
						text={text}
						css={titleBlockStyles}
					/>
				</Card>
				{onRemove && <RemoveButton onClick={onRemove} />}
			</Box>
		</SmartCardProvider>
	);
};

const inlineStyles = xcss({
	backgroundColor: 'elevation.surface.raised',
	borderColor: 'color.border',
	borderStyle: 'solid',
	borderWidth: 'border.width',
	borderRadius: 'border.radius',
	display: 'inline-flex',
	gap: 'space.050',
});

const titleBlockStyles = css({
	gap: token('space.050'),
});
