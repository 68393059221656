import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { getParentId, getIsChildrenIssuesPanel } from '../../state/context/selectors.tsx';
import {
	type ChildCreatedInFullDialogAction,
	CHILD_CREATED_IN_FULL_DIALOG,
	linkParentFailure,
	createChildSuccess,
} from '../../state/entities/actions.tsx';
import type { State } from '../../state/types.tsx';
import linkChildToParent from './utils.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<ChildCreatedInFullDialogAction>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(CHILD_CREATED_IN_FULL_DIALOG).mergeMap(({ payload, meta }) => {
		const state = store.getState();
		const { optimisticId, createdChild } = payload;
		const isChildrenIssuesPanel = getIsChildrenIssuesPanel(state);

		// For non-NextGen issues the child creation is a one-step process, so once the
		// child issue is created, we can dispatch the createChildSuccess action
		if (!isChildrenIssuesPanel) {
			return Observable.of(
				createChildSuccess(
					optimisticId,
					createdChild,
					meta.analyticsEvent,
					true,
					meta.additionalAttributes,
				),
			);
		}

		// If the child a NextGen issue, it requires an additional "link to parent" step
		// before we can dispatch createChildSuccess
		// Note: this is temporary (https://hello.atlassian.net/wiki/spaces/JPLAT/pages/376819072/DACI+How+to+create+next-gen+subtasks)
		// Note: CMP issues can reach this codepath (https://jira.atlassian.com/browse/JRACLOUD-93290)
		const parentId = getParentId(state);

		if (!parentId) {
			throw new Error('Attempted to link a child issue without a parent issue ID');
		}

		return Observable.from(linkChildToParent(parentId, createdChild.id))
			.map(() =>
				createChildSuccess(
					optimisticId,
					createdChild,
					meta.analyticsEvent,
					true,
					meta.additionalAttributes,
				),
			)
			.catch((error) => Observable.of(linkParentFailure(optimisticId, error)));
	});
