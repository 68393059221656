import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	justNow: {
		id: 'polaris-lib-date-time.just-now',
		defaultMessage: 'just now',
		description:
			'Relative time string explaining a date/time was within the past minute, to distinguish from longer periods e.g. "5 hours ago".',
	},
	quarterDisplay: {
		id: 'polaris-lib-date-time.quarter-display',
		defaultMessage: '{startMonth}-{endMonth}, {year}',
		description: 'Display string for a quarter e.g. "Jan-Mar, 2025"',
	},
	quarterDisplayWithYearRange: {
		id: 'polaris-lib-date-time.quarter-display-with-year-range',
		defaultMessage: '{startMonth}-{endMonth}, {startYear}-{endYear}',
		description: 'Display string for a quarter with a year range e.g. "Jan-Mar, 2025-2026"',
	},
});
