import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';

export const FlaggedFieldPopupContentFallback = () => (
	<Box
		xcss={fallbackStyles}
		testId="issue-view-foundation.status.flagged-field-popup.flag-field-popup-content.fallback"
	>
		<Spinner size="medium" />
	</Box>
);

const fallbackStyles = xcss({
	backgroundColor: 'elevation.surface',
	width: '312px',
	height: '194px',
	borderRadius: 'border.radius.100',
	boxShadow: 'elevation.shadow.overlay',
	padding: 'space.200',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});
