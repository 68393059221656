/**
 * @generated SignedSource<<190716bbd095b97700890bcf375b06e3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type view_issueViewCommonViews_ChildIssuesPanelBaseWithFragment$data = {
  readonly issueByKey: {
    readonly childIssues: {
      readonly __typename: "JiraChildIssuesWithinLimit";
      readonly issues: {
        readonly " $fragmentSpreads": FragmentRefs<"src_issueViewCommonViews_ProgressSummary">;
      } | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "view_issueViewCommonViews_ChildIssuesPanelBaseWithFragment";
};
export type view_issueViewCommonViews_ChildIssuesPanelBaseWithFragment$key = {
  readonly " $data"?: view_issueViewCommonViews_ChildIssuesPanelBaseWithFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"view_issueViewCommonViews_ChildIssuesPanelBaseWithFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "issueKey"
    }
  ],
  "kind": "Fragment",
  "name": "view_issueViewCommonViews_ChildIssuesPanelBaseWithFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        },
        {
          "kind": "Variable",
          "name": "key",
          "variableName": "issueKey"
        }
      ],
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issueByKey",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "childIssues",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "__typename"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "activeIssuesOnly",
                      "value": true
                    },
                    {
                      "kind": "Literal",
                      "name": "activeProjectsOnly",
                      "value": true
                    }
                  ],
                  "concreteType": "JiraIssueConnection",
                  "kind": "LinkedField",
                  "name": "issues",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "src_issueViewCommonViews_ProgressSummary"
                    }
                  ],
                  "storageKey": "issues(activeIssuesOnly:true,activeProjectsOnly:true)"
                }
              ],
              "type": "JiraChildIssuesWithinLimit"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "9dfbe8a4fb842dbc9e441ad9a2c91599";

export default node;
