/* eslint-disable @atlassian/relay/unused-fields */
import React, { useEffect } from 'react';
import OriginTracer from '@atlassiansox/origin-tracing';
import { graphql, useFragment } from 'react-relay';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { extractCanUpgradeAndPayDetails } from '@atlassian/jira-billing/src/controllers/can-upgrade-and-pay/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIsJiraSoftwareFree } from '@atlassian/jira-jsw-feature-gates/src/ui/index.tsx';
import type { ui_storageLimitsBanner_StorageLimitsBannerComponentWithQuery$key } from '@atlassian/jira-relay/src/__generated__/ui_storageLimitsBanner_StorageLimitsBannerComponentWithQuery.graphql';
import { ERROR_BOUNDARY_ID, PACKAGE_NAME, STORAGE_RATIO_TO_SHOW_BANNER } from './constants.tsx';
import { useCheckRequisitesAndFetchUpgradeLink, useGetUsedStorageData } from './utils.tsx';
import { BannerContent } from './BannerContent.tsx';

export type StorageLimitsBannerProps = {
	isFullWidth?: boolean;
};

export type IssueViewStorageLimitsBannerProps = {
	isFullWidth?: boolean;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};

const FREE_PLAN_STORAGE_LIMIT = 2;

function StorageLimitsBannerComponent(props: StorageLimitsBannerProps) {
	const { isFullWidth = false } = props;

	const {
		canView,
		upgradeLink,
		isLoading,
		error: upgradeAndPayError,
	} = useCheckRequisitesAndFetchUpgradeLink();

	const origin = new OriginTracer({ product: 'jira' });
	const upgradeLinkWithOrigin = upgradeLink ? origin.addToUrl(upgradeLink) : undefined;

	const usedStorageData = useGetUsedStorageData({ skip: !canView });
	const storageLimit =
		usedStorageData?.storageLimit !== undefined
			? usedStorageData.storageLimit
			: FREE_PLAN_STORAGE_LIMIT;
	const usedStorageRatio = usedStorageData?.storageRatio;
	const enoughStorageUsed =
		usedStorageRatio !== undefined && usedStorageRatio >= STORAGE_RATIO_TO_SHOW_BANNER;

	useEffect(() => {
		if (upgradeAndPayError) {
			fireErrorAnalytics({
				error: upgradeAndPayError,
				meta: {
					id: 'canUpgradeAndPay',
					packageName: PACKAGE_NAME,
					teamName: 'growth-tako',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}, [upgradeAndPayError]);

	if (
		!canView ||
		!enoughStorageUsed ||
		isLoading ||
		upgradeAndPayError ||
		usedStorageData?.edition !== 'free'
	) {
		return null;
	}

	return (
		<BannerContent
			isFullWidth={isFullWidth}
			upgradeLinkWithOrigin={upgradeLinkWithOrigin}
			storageLimit={storageLimit}
			usedStorageRatio={usedStorageRatio}
		/>
	);
}

function StorageLimitsBannerComponentWithQuery({
	rootRelayFragment,
	isFullWidth = false,
}: IssueViewStorageLimitsBannerProps) {
	const data = useFragment<ui_storageLimitsBanner_StorageLimitsBannerComponentWithQuery$key>(
		graphql`
			fragment ui_storageLimitsBanner_StorageLimitsBannerComponentWithQuery on Query {
				tenantContexts(cloudIds: [$cloudId]) {
					entitlementInfo(hamsProductKey: $productKey) {
						entitlement {
							transactionAccount {
								experienceCapabilities {
									addPaymentMethod {
										isAvailableToUser
									}
								}
							}
							subscription {
								accountDetails {
									invoiceGroup {
										experienceCapabilities {
											configurePayment {
												isAvailableToUser
											}
										}
									}
								}
							}
							experienceCapabilities {
								changeOffering(offeringKey: $offeringKey) {
									experienceUrl
									isAvailableToUser
								}
							}
						}
					}
				}
			}
		`,
		rootRelayFragment,
	);

	const { canUpgradeAndPay, upgradeLink } = extractCanUpgradeAndPayDetails(data);

	const origin = new OriginTracer({ product: 'jira' });
	const upgradeLinkWithOrigin = upgradeLink ? origin.addToUrl(upgradeLink) : undefined;

	const usedStorageData = useGetUsedStorageData({ skip: !canUpgradeAndPay });
	const storageLimit =
		usedStorageData?.storageLimit !== undefined
			? usedStorageData.storageLimit
			: FREE_PLAN_STORAGE_LIMIT;
	const usedStorageRatio = usedStorageData?.storageRatio;
	const enoughStorageUsed =
		usedStorageRatio !== undefined && usedStorageRatio >= STORAGE_RATIO_TO_SHOW_BANNER;

	if (
		data == null ||
		!canUpgradeAndPay ||
		!enoughStorageUsed ||
		usedStorageData?.edition !== 'free'
	) {
		return null;
	}

	return (
		<BannerContent
			isFullWidth={isFullWidth}
			upgradeLinkWithOrigin={upgradeLinkWithOrigin}
			storageLimit={storageLimit}
			usedStorageRatio={usedStorageRatio}
		/>
	);
}

export function StorageLimitsBannerUsingMainIssueAggQuery({
	isFullWidth,
	rootRelayFragment,
}: IssueViewStorageLimitsBannerProps) {
	const isJswFree = useIsJiraSoftwareFree();

	if (!isJswFree) {
		return null;
	}

	return (
		<JSErrorBoundary
			id={ERROR_BOUNDARY_ID}
			packageName={PACKAGE_NAME}
			fallback="unmount"
			teamName="growth-tako"
		>
			<StorageLimitsBannerComponentWithQuery
				isFullWidth={isFullWidth}
				rootRelayFragment={rootRelayFragment}
			/>
		</JSErrorBoundary>
	);
}

export default function StorageLimitsBanner(props: StorageLimitsBannerProps) {
	return (
		<JSErrorBoundary
			id={ERROR_BOUNDARY_ID}
			packageName={PACKAGE_NAME}
			fallback="unmount"
			teamName="growth-tako"
		>
			<StorageLimitsBannerComponent {...props} />
		</JSErrorBoundary>
	);
}
