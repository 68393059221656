/** @jsx jsx */
import React, { Component, type MouseEvent } from 'react';
import { styled, jsx } from '@compiled/react';
import { withAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import {
	fireOperationalAnalytics,
	MountEvent,
	fireUIAnalytics,
	ContextualAnalyticsData,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';

import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';

import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import withCommentVisibilities from '@atlassian/jira-issue-comment-base/src/ui/comment/comment-visibility/state/components/with-comment-visibilities.tsx';
import {
	type ActivitySortOrderType,
	NEWEST_FIRST,
	OLDEST_FIRST,
} from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import Comment from '@atlassian/jira-issue-view-activity-comment/src/comment-view/index.tsx';
import CommentListItem from '@atlassian/jira-issue-view-activity-common/src/component/comment-list-item/view.tsx';
import LoadMoreButton from '@atlassian/jira-issue-view-activity-common/src/component/load-more-button/view.tsx';
import {
	NUM_PAGED_ITEMS_TO_LOAD,
	LOAD_NEWER_BUTTON,
	LOAD_OLDER_BUTTON,
} from '@atlassian/jira-issue-view-common-constants/src/activity-feed.tsx';
import { COMMENTS } from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import type { LoadingStage } from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { calculateNumberOfCommentsToLoad } from '@atlassian/jira-issue-view-common-utils/src/epics/comment-fetch-epic.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	fetchOlderCommentsRequest,
	fetchNewerCommentsRequest,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { getSelectedActivitySortOrder } from '@atlassian/jira-issue-view-store/src/selectors/activity-feed-selector.tsx';
import { fullIssueUrlSelector } from '@atlassian/jira-issue-view-store/src/selectors/breadcrumbs-selector.tsx';
import {
	canAddCommentsSelector,
	isCommentVisibilityRestrictionSupportedSelector,
	loadedCommentsSelector,
	isLoadingMoreCommentsSelector,
	totalCommentsSelector,
	startIndexCommentsSelector,
	sortedVisibleCommentIdsSelector,
	loadingStageSelector,
	commentSessionIdSelector,
	isThreadedCommentsEnabledSelector,
	commentsPageInfoSelector,
	getUnreadCommentCountSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import { getPermalinkStatus } from '@atlassian/jira-platform-issue-permalinks/src/index.tsx';
import { withRemovePermalinkQuery } from '@atlassian/jira-platform-issue-permalinks/src/remove-permalink-query/index.tsx';

import type { mainIssueAggQuery$data } from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql.ts';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { CommentsPageInfo } from '@atlassian/jira-issue-shared-types/src/common/types/comment-transformer-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import CommentReplies from '@atlassian/jira-issue-view-activity-comment/src/comment-replies/index.tsx';
import { useRealTimeComments } from '@atlassian/jira-realtime-comments-controller/src/controllers/index.tsx';
import { SectionMessage } from './section-message/index.tsx';
import { JsmSmartRequestSummaryEntryPointContainer } from './servicedesk/smart-request-summary/index.tsx';
import {
	IssueSmartRequestSummaryEntryPointContainer,
	IssueSmartRequestSummaryEntryPointContainerOld,
} from './smart-request-summary/index.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line jira/styled/styled-component-order, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CommentsList = styled.div<{ isActivityInSidePanel: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	marginTop: ({ isActivityInSidePanel }) =>
		isActivityInSidePanel ? token('space.200') : token('space.300'),
	marginRight: 0,
	marginBottom: token('space.150'),
	marginLeft: 0,
});

type OwnProps = {
	// eslint-disable-next-line jira/react/handler-naming
	fetchCommentVisibilities?: () => void;
	// go/jfe-eslint
	createAnalyticsEvent: (arg1: { action: string }) => UIAnalyticsEvent;
	rootRelayFragment: mainIssueAggQuery$data | null;
};

type RealtimeProps = {
	isRealTimeCommentUpdated?: boolean;
	setRealTimeCommentUpdated?: (isRealTimeCommentUpdated: boolean) => void;
};

type StateProps = {
	isLoadingMoreComments: boolean;
	// go/jfe-eslint
	issueKey: IssueKey;
	// go/jfe-eslint
	shouldFetchCommentVisibilities: boolean;
	canAddComments: boolean;
	commentIds: (
		| string
		| {
				id: string;
				size: number;
		  }
	)[];
	totalComments?: number;
	startIndex?: number | null;
	loadedComments?: number;
	fullIssueUrl: string | null;
	loadingStage: LoadingStage;
	selectedSortOrder: ActivitySortOrderType;
	// go/jfe-eslint
	baseUrl: BaseUrl;
	projectType: ProjectType | null;
	commentSessionId?: string | null;
	commentsPageInfo?: CommentsPageInfo;
	unreadCommentsCount?: number;
	isThreadedCommentsEnabled: boolean;
};

type DispatchProps = {
	// eslint-disable-next-line jira/react/handler-naming
	fetchMoreOlderComments: () => void;
	// eslint-disable-next-line jira/react/handler-naming
	fetchMoreNewerComments: () => void;
};

type Props = OwnProps & RealtimeProps & StateProps & DispatchProps;

type CommentsState = {
	loadMoreButtonTypeClicked: typeof LOAD_NEWER_BUTTON | typeof LOAD_OLDER_BUTTON | undefined;
};

// eslint-disable-next-line jira/react/no-class-components
export class Comments extends Component<Props, CommentsState> {
	static displayName = COMMENTS;

	static defaultProps = {
		canAddComments: false,
	};

	state: CommentsState = {
		loadMoreButtonTypeClicked: undefined,
	};

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		// We fetch more comments when there are no comments left visible
		// but there are still comments available to be fetched (i.e. hidden)
		if (fg('jira_comments_agg_pagination')) {
			if (
				nextProps.commentsPageInfo &&
				nextProps.commentIds.length !== this.props.commentIds.length &&
				nextProps.commentIds.length === 0
			) {
				if (nextProps.commentsPageInfo.hasNextPage) {
					nextProps.fetchMoreOlderComments();
				} else if (nextProps.commentsPageInfo.hasPreviousPage) {
					nextProps.fetchMoreNewerComments();
				}
			}
		} else if (
			nextProps.loadedComments !== this.props.loadedComments &&
			nextProps.loadedComments === 0 &&
			nextProps.totalComments != null &&
			nextProps.totalComments > 0
		) {
			if (nextProps.startIndex === 0) {
				nextProps.fetchMoreOlderComments();
			} else {
				nextProps.fetchMoreNewerComments();
			}
		}

		if (!nextProps.isLoadingMoreComments) {
			this.setState({
				loadMoreButtonTypeClicked: undefined,
			});
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (fg('threaded_comments_new_analytics')) {
			if (JSON.stringify(prevProps.commentIds) !== JSON.stringify(this.props.commentIds)) {
				const analyticsEvent = this.props.createAnalyticsEvent({
					action: 'comments',
				});

				analyticsEvent &&
					fireOperationalAnalytics(analyticsEvent, 'comments loaded', {
						rootCommentsLoaded: (this.props.commentIds ?? []).length,
						rootCommentsWithReplies: (this.props.commentIds ?? []).filter(
							(info) => typeof info !== 'string' && info.size > 0,
						).length,
					});
			}
		}

		if (
			this.props.unreadCommentsCount &&
			this.props.unreadCommentsCount > 0 &&
			this.props.isRealTimeCommentUpdated === false &&
			fg('thor_send_realtime_attribute')
		) {
			this.props.setRealTimeCommentUpdated?.(true);
			const analyticsEvent = this.props.createAnalyticsEvent({ action: 'realtimeUpdated' });
			analyticsEvent && fireOperationalAnalytics(analyticsEvent, 'comments realtimeUpdated');
		}
	}

	onLoadOlderButtonClick = (event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
		const { fetchMoreOlderComments } = this.props;

		fireUIAnalytics(analyticsEvent, 'loadMoreButtonClicked', {
			loadMoreButtonType: LOAD_OLDER_BUTTON,
		});

		this.setState({
			loadMoreButtonTypeClicked: LOAD_OLDER_BUTTON,
		});

		fetchMoreOlderComments();
	};

	onLoadNewerButtonClick = (event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
		const { fetchMoreNewerComments } = this.props;

		fireUIAnalytics(analyticsEvent, 'loadMoreButtonClicked', {
			loadMoreButtonType: LOAD_NEWER_BUTTON,
		});

		this.setState({
			loadMoreButtonTypeClicked: LOAD_NEWER_BUTTON,
		});

		fetchMoreNewerComments();
	};

	renderLoadOlderButton(numOlderCommentsToLoad?: number) {
		const nextCommentsToLoadCount = numOlderCommentsToLoad ?? 0;

		const { isLoadingMoreComments, commentsPageInfo } = this.props;
		const { loadMoreButtonTypeClicked } = this.state;
		const { onLoadOlderButtonClick } = this;
		const isLoading = loadMoreButtonTypeClicked === LOAD_OLDER_BUTTON && isLoadingMoreComments;

		let loadButton;
		if (commentsPageInfo && fg('jira_comments_agg_pagination')) {
			const { hasNextPage } = commentsPageInfo;
			loadButton = hasNextPage;
		}

		return (
			<LoadMoreButton
				onClick={onLoadOlderButtonClick}
				numberToLoad={numOlderCommentsToLoad ?? 0}
				isDisabled={isLoadingMoreComments}
				isLoading={isLoading}
				loadButton={loadButton}
			>
				{fg('jira_comments_agg_pagination') ? (
					<FormattedMessage {...messages.showMoreComments} />
				) : (
					<>
						{nextCommentsToLoadCount < NUM_PAGED_ITEMS_TO_LOAD ? (
							<FormattedMessage
								id="issue.comments-view-sorted-older-remaining-comments-text-numberToLoad"
								defaultMessage="View {numberToLoad, plural, one {1 older comment} other {{numberToLoad} remaining older comments}}"
								description="Button to show older items in a list"
								values={{
									numberToLoad: nextCommentsToLoadCount,
								}}
							/>
						) : (
							<FormattedMessage
								id="issue.comments-view-sorted-older-comments-text"
								defaultMessage="View {numberToLoad, plural, one {1 older comment} other {{numberToLoad} older comments}}"
								description="Button to show older items in a list"
								values={{
									numberToLoad: nextCommentsToLoadCount,
								}}
							/>
						)}
					</>
				)}
			</LoadMoreButton>
		);
	}

	renderLoadNewerButton(numNewerCommentsToLoad?: number) {
		const prevCommentsToLoadCount = numNewerCommentsToLoad ?? 0;

		const { isLoadingMoreComments, commentsPageInfo } = this.props;
		const { loadMoreButtonTypeClicked } = this.state;
		const { onLoadNewerButtonClick } = this;
		const isLoading = loadMoreButtonTypeClicked === LOAD_NEWER_BUTTON && isLoadingMoreComments;

		let loadButton;
		if (commentsPageInfo && fg('jira_comments_agg_pagination')) {
			const { hasPreviousPage } = commentsPageInfo;
			loadButton = hasPreviousPage;
		}

		return (
			<LoadMoreButton
				onClick={onLoadNewerButtonClick}
				numberToLoad={prevCommentsToLoadCount}
				isDisabled={isLoadingMoreComments}
				isLoading={isLoading}
				loadButton={loadButton}
			>
				{fg('jira_comments_agg_pagination') ? (
					<FormattedMessage {...messages.showMoreComments} />
				) : (
					<>
						{prevCommentsToLoadCount < NUM_PAGED_ITEMS_TO_LOAD ? (
							<FormattedMessage
								id="issue.comments-view-sorted-newer-remaining-comments-text-numberToLoad"
								defaultMessage="View {numberToLoad, plural, one {1 newer comment} other {{numberToLoad} remaining newer comments}}"
								description="Button to show newer items in a list"
								values={{
									numberToLoad: prevCommentsToLoadCount,
								}}
							/>
						) : (
							<FormattedMessage
								id="issue.comments-view-sorted-newer-comments-text"
								defaultMessage="View {numberToLoad, plural, one {1 newer comment} other {{numberToLoad} newer comments}}"
								description="Button to show newer items in a list"
								values={{
									numberToLoad: prevCommentsToLoadCount,
								}}
							/>
						)}
					</>
				)}
			</LoadMoreButton>
		);
	}

	render() {
		const {
			commentIds,
			canAddComments,
			totalComments,
			loadedComments,
			fullIssueUrl,
			startIndex,
			loadingStage,
			selectedSortOrder,
			projectType,
			commentSessionId,
			rootRelayFragment,
			commentsPageInfo,
			isThreadedCommentsEnabled,
			isRealTimeCommentUpdated,
		} = this.props;

		if (!commentIds.length && !canAddComments) {
			return null;
		}

		let hasPrevComments = false;
		if (commentsPageInfo && fg('jira_comments_agg_pagination')) {
			const { hasPreviousPage } = commentsPageInfo;
			hasPrevComments = !!hasPreviousPage;
		}

		let prevCommentsToLoadCount;
		let nextCommentsToLoadCount;
		if (totalComments != null && loadedComments != null && !fg('jira_comments_agg_pagination')) {
			const { numPrevCommentsToLoad, numNextCommentsToLoad } = calculateNumberOfCommentsToLoad(
				totalComments,
				loadedComments,
				startIndex ?? 0,
			);
			prevCommentsToLoadCount = numPrevCommentsToLoad;
			nextCommentsToLoadCount = numNextCommentsToLoad;
		}

		const { hasPermalink, permalinkId } = getPermalinkStatus(COMMENTS);

		const sortedList = commentIds.length ? (
			<>
				{selectedSortOrder === NEWEST_FIRST && this.renderLoadNewerButton(prevCommentsToLoadCount)}
				{selectedSortOrder === OLDEST_FIRST && this.renderLoadOlderButton(nextCommentsToLoadCount)}
				<CommentsList
					// eslint-disable-next-line jira/integration/test-id-by-folder-structure
					data-testid="issue.activity.comments-list"
					isActivityInSidePanel={expVal(
						'issue-view-side-panel-activity',
						'isActivityInSidePanel',
						false,
					)}
				>
					{fg('jira_issue_mobile_section_message') && <SectionMessage />}
					{commentIds.map((commentInfo) => {
						const commentId: string =
							typeof commentInfo !== 'string' &&
							(isThreadedCommentsEnabled || fg('cleanup_unwanted_threaded_comments_flag_check'))
								? commentInfo.id
								: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									(commentInfo as string);
						return (
							<Comment
								key={commentId}
								commentId={commentId}
								fullIssueUrl={fullIssueUrl}
								isHighlighted={hasPermalink && commentId === permalinkId}
								commentSessionId={commentSessionId}
								isThreadedCommentsEnabled={isThreadedCommentsEnabled}
							>
								{isThreadedCommentsEnabled && (
									<CommentReplies
										commentId={commentId}
										fullIssueUrl={fullIssueUrl}
										commentSessionId={commentSessionId}
										hasReplies={typeof commentInfo !== 'string' ? commentInfo.size > 0 : false}
										{...(fg('thor_send_realtime_attribute')
											? {
													isRealTimeCommentUpdated,
												}
											: {})}
									/>
								)}
							</Comment>
						);
					})}
				</CommentsList>
				{selectedSortOrder === NEWEST_FIRST && this.renderLoadOlderButton(nextCommentsToLoadCount)}
				{selectedSortOrder === OLDEST_FIRST && this.renderLoadNewerButton(prevCommentsToLoadCount)}
			</>
		) : null;

		const renderSmartRequestSummaryEntryPointContainer = () => {
			if (projectType === SERVICE_DESK_PROJECT) {
				return <JsmSmartRequestSummaryEntryPointContainer />;
			}
			if (projectType === SOFTWARE_PROJECT || projectType === CORE_PROJECT) {
				return fg('proactive_comment_summary_targeting_gate') ? (
					<IssueSmartRequestSummaryEntryPointContainer />
				) : (
					<IssueSmartRequestSummaryEntryPointContainerOld />
				);
			}
			return null;
		};

		return (
			<>
				{fg('threaded_comments_new_analytics') && (
					<MountEvent
						onMount={(analyticsEvent: UIAnalyticsEvent) => {
							analyticsEvent &&
								fireOperationalAnalytics(analyticsEvent, 'comments loaded', {
									rootCommentsLoaded: commentIds?.length ?? 0,
									rootCommentsWithReplies: (commentIds ?? []).filter(
										(info) => typeof info !== 'string' && info.size > 0,
									).length,
								});
						}}
					/>
				)}
				{renderSmartRequestSummaryEntryPointContainer()}
				<ContextualAnalyticsData sourceType={SCREEN} sourceName="commentsActivityFeed">
					<CommentListItem
						loadingStage={loadingStage}
						canAddComments={canAddComments}
						list={sortedList}
						numPrevCommentsToLoad={prevCommentsToLoadCount ?? 0}
						hasPrevComments={hasPrevComments}
						selectedSortOrder={selectedSortOrder}
						rootRelayFragment={rootRelayFragment}
					/>
				</ContextualAnalyticsData>
			</>
		);
	}
}

const CommentWithPermaLinkRemoval = withRemovePermalinkQuery(withCommentVisibilities(Comments));

const ConnectedComments = connect(
	(state: State) => ({
		commentIds: sortedVisibleCommentIdsSelector(state),
		canAddComments: canAddCommentsSelector(state),
		...(!fg('jira_comments_agg_pagination') && {
			totalComments: totalCommentsSelector(state),
			startIndex: startIndexCommentsSelector(state),
			loadedComments: loadedCommentsSelector(state),
		}),
		isLoadingMoreComments: isLoadingMoreCommentsSelector(state),
		projectType: projectTypeSelector(state),
		// baseUrl, issueKey & shouldFetchCommentVisibilities are used in with-comment-visibilities.js
		baseUrl: baseUrlSelector(state),
		issueKey: issueKeySelector(state),
		shouldFetchCommentVisibilities: isCommentVisibilityRestrictionSupportedSelector(state),
		// fullIssueUrl for the comments permalink
		fullIssueUrl: fullIssueUrlSelector(state),
		loadingStage: loadingStageSelector(state),
		selectedSortOrder: getSelectedActivitySortOrder(state),
		commentSessionId: commentSessionIdSelector(state),
		commentsPageInfo: commentsPageInfoSelector(state),
		isThreadedCommentsEnabled: isThreadedCommentsEnabledSelector(state),
		...(fg('thor_send_realtime_attribute') && {
			unreadCommentsCount: getUnreadCommentCountSelector(state),
		}),
	}),
	(dispatch): DispatchProps => ({
		fetchMoreOlderComments: () => {
			dispatch(fetchOlderCommentsRequest());
		},
		fetchMoreNewerComments: () => {
			dispatch(fetchNewerCommentsRequest());
		},
	}),
)(CommentWithPermaLinkRemoval);

const ConnectedCommentsWithAnalyticsSubjectBase = AnalyticsSubject('comment')(
	// @ts-expect-error - Argument of type 'ConnectedComponentClass<ComponentType<CommentVisibilitiesComponentProps<OwnProps & StateProps & DispatchProps>>, Omit<...>>' is not assignable to parameter of type 'JSXElementConstructor<WithAnalyticsEventsProps> & ComponentClass<Omit<CommentVisibilitiesComponentProps<OwnProps & StateProps & DispatchProps>, "baseUrl" | ... 12 more ... | "shouldFetchCommentVisibilities">, any> & { ...; }'.
	withAnalyticsEvents()(ConnectedComments),
);

export type IssueViewCommentProps = {
	rootRelayFragment: mainIssueAggQuery$data | null;
};

const IssueViewCommentWithRealtimeCommentsData = (props: IssueViewCommentProps) => {
	const [{ isRealTimeCommentUpdated }, { setRealTimeCommentUpdated }] = useRealTimeComments();
	return (
		<ConnectedCommentsWithAnalyticsSubjectBase
			{...props}
			isRealTimeCommentUpdated={isRealTimeCommentUpdated}
			setRealTimeCommentUpdated={setRealTimeCommentUpdated}
		/>
	);
};

const ConnectedCommentsWithAnalyticsSubject = componentWithFG(
	'thor_send_realtime_attribute',
	IssueViewCommentWithRealtimeCommentsData,
	ConnectedCommentsWithAnalyticsSubjectBase,
);

const IssueViewComment = ({ rootRelayFragment }: IssueViewCommentProps) => (
	<UFOSegment name="issue-view-comments">
		<JSErrorBoundary
			id="issue.issue-view.activity.comments"
			packageName="jiraIssueViewActivity"
			teamName="bento"
			fallback="unmount"
		>
			<ConnectedCommentsWithAnalyticsSubject rootRelayFragment={rootRelayFragment} />
		</JSErrorBoundary>
	</UFOSegment>
);

export default IssueViewComment;
