import { useMemo } from 'react';
import {
	useAnalyticsEvents,
	fireTrackAnalytics,
	type ActionSubjectAndAction,
} from '@atlassian/jira-product-analytics-bridge';
import type { AnalyticsAttributesUntyped } from '@atlassian/jira-product-analytics-bridge/src/types';
import { useIsProactivePanelShown } from '../../controllers/state/index.tsx';

export const analyticsAttrs = {
	aiFeatureName: 'jiraSmartSummarizeComments',
	proactiveAIGenerated: 0,
	userGeneratedAI: 1,
	isAIFeature: 1,
	proactiveGeneratedAI: 0,
	isProactiveGenerated: false,
} as const;

const getIsProactiveAttributes = (isProactivePanelShown: boolean) => {
	if (isProactivePanelShown) {
		return {
			proactiveAIGenerated: 1,
			proactiveGeneratedAI: 1,
			userGeneratedAI: 0,
			isProactiveGenerated: true,
		};
	}

	return {};
};

export const useSmartSummarizeCommentsAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isProactivePanelShown] = useIsProactivePanelShown();

	const actions = useMemo(() => {
		const event = createAnalyticsEvent({});
		return {
			fireTrack: (name: ActionSubjectAndAction, id: string, attrs?: AnalyticsAttributesUntyped) =>
				fireTrackAnalytics(event, name, id, {
					...analyticsAttrs,
					...attrs,
					...getIsProactiveAttributes(isProactivePanelShown),
				}),
		};
	}, [createAnalyticsEvent, isProactivePanelShown]);
	return actions;
};
