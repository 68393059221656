/**
 * @generated SignedSource<<67cd3f3dcfc99484639292e674fa5126>>
 * @relayHash db177f23471e173a4506a7bd220320b0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0404673e7e67dda86d91a97fb85199dd2f551195a3559e26a5595ea0f72814a0

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type updateHideDoneStateMutation$variables = {
  isHideDoneEnabled: boolean;
  viewId: string;
};
export type updateHideDoneStateMutation$data = {
  readonly jira: {
    readonly updateIssueSearchHideDonePreference: {
      readonly isHideDoneEnabled: boolean | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type updateHideDoneStateMutation$rawResponse = {
  readonly jira: {
    readonly updateIssueSearchHideDonePreference: {
      readonly isHideDoneEnabled: boolean | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type updateHideDoneStateMutation = {
  rawResponse: updateHideDoneStateMutation$rawResponse;
  response: updateHideDoneStateMutation$data;
  variables: updateHideDoneStateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isHideDoneEnabled"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v2 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "isHideDoneEnabled",
            "variableName": "isHideDoneEnabled"
          },
          {
            "kind": "Variable",
            "name": "viewId",
            "variableName": "viewId"
          }
        ],
        "concreteType": "JiraIssueSearchHideDonePreferenceMutationPayload",
        "kind": "LinkedField",
        "name": "updateIssueSearchHideDonePreference",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "kind": "ScalarField",
            "name": "isHideDoneEnabled"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "updateHideDoneStateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateHideDoneStateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "0404673e7e67dda86d91a97fb85199dd2f551195a3559e26a5595ea0f72814a0",
    "metadata": {},
    "name": "updateHideDoneStateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "07180c4d73cc2de81cbdecc0c89bcaec";

export default node;
