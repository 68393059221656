/**
 * @generated SignedSource<<21a7cd83644f9a728af8ec27f89bf8da>>
 * @relayHash 52ad9c6bab2d76e57cc35caacc9affb3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4b259f0dc7a5d59efe932ca54f100cdf3fb529ebc26208deca39ae08f8b2abeb

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateParentFieldInput = {
  id: string;
  operation: JiraParentFieldOperationInput;
};
export type JiraParentFieldOperationInput = {
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type issueParentLegacyAndAgg_inlineData_Mutation$variables = {
  input: JiraUpdateParentFieldInput;
};
export type issueParentLegacyAndAgg_inlineData_Mutation$data = {
  readonly jira: {
    readonly updateParentField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type issueParentLegacyAndAgg_inlineData_Mutation = {
  response: issueParentLegacyAndAgg_inlineData_Mutation$data;
  variables: issueParentLegacyAndAgg_inlineData_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "text"
},
v8 = {
  "kind": "ScalarField",
  "name": "fieldId"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "issueParentLegacyAndAgg_inlineData_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraParentFieldPayload",
            "kind": "LinkedField",
            "name": "updateParentField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraParentIssueField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "issueParentLegacyAndAgg_inlineData_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraParentFieldPayload",
            "kind": "LinkedField",
            "name": "updateParentField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraParentIssueField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "parentIssue",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "key"
                      },
                      {
                        "concreteType": "JiraColorField",
                        "kind": "LinkedField",
                        "name": "issueColorField",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraColor",
                            "kind": "LinkedField",
                            "name": "color",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "colorKey"
                              },
                              (v4/*: any*/)
                            ]
                          },
                          (v4/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "JiraIssueTypeField",
                        "kind": "LinkedField",
                        "name": "issueTypeField",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v4/*: any*/)
                            ]
                          },
                          (v4/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "JiraSingleLineTextField",
                        "kind": "LinkedField",
                        "name": "summaryField",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v4/*: any*/)
                        ]
                      },
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "issueId"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "webUrl"
                      },
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "ids",
                            "value": [
                              "summary",
                              "issuetype",
                              "status"
                            ]
                          }
                        ],
                        "concreteType": "JiraIssueFieldConnection",
                        "kind": "LinkedField",
                        "name": "fieldsById",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "__typename"
                                  },
                                  (v4/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v8/*: any*/),
                                      (v7/*: any*/)
                                    ],
                                    "type": "JiraSingleLineTextField"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v8/*: any*/),
                                      {
                                        "concreteType": "JiraIssueType",
                                        "kind": "LinkedField",
                                        "name": "issueType",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "issueTypeId"
                                          },
                                          (v6/*: any*/),
                                          (v5/*: any*/),
                                          {
                                            "concreteType": "JiraIssueTypeHierarchyLevel",
                                            "kind": "LinkedField",
                                            "name": "hierarchy",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "level"
                                              }
                                            ]
                                          },
                                          (v4/*: any*/)
                                        ]
                                      }
                                    ],
                                    "type": "JiraIssueTypeField"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v8/*: any*/),
                                      {
                                        "concreteType": "JiraStatus",
                                        "kind": "LinkedField",
                                        "name": "status",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraStatusCategory",
                                            "kind": "LinkedField",
                                            "name": "statusCategory",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "statusCategoryId"
                                              },
                                              (v4/*: any*/)
                                            ]
                                          },
                                          (v4/*: any*/)
                                        ]
                                      }
                                    ],
                                    "type": "JiraStatusField"
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "storageKey": "fieldsById(ids:[\"summary\",\"issuetype\",\"status\"])"
                      }
                    ]
                  },
                  (v4/*: any*/),
                  (v8/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "4b259f0dc7a5d59efe932ca54f100cdf3fb529ebc26208deca39ae08f8b2abeb",
    "metadata": {},
    "name": "issueParentLegacyAndAgg_inlineData_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "3637067c489aee685ab73bb4b86b3c9e";

export default node;
