import type { ViewIssueData as IssueServiceViewIssueData } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import type {
	CommentsTransformResult,
	NormalizedLoadedCommentsV2,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import type { ViewIssueData } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/server-data.tsx';
import { NUM_INITIAL_ITEMS_TO_LOAD } from '@atlassian/jira-issue-view-common-constants/src/activity-feed.tsx';
import type { commentFetchAggServerQuery$data } from '@atlassian/jira-relay/src/__generated__/commentFetchAggServerQuery.graphql';
import {
	computeAggCommentsToUse,
	transformAggCommentsToLegacyGiraV2Helper,
} from '@atlassian/jira-issue-agg-field-transformers/src/common/utils/comments-transformer.tsx';
import type { CommentsPageInfo } from '@atlassian/jira-issue-shared-types/src/common/types/comment-transformer-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { commentFetchAggServerChildCommentsQuery$data } from '@atlassian/jira-relay/src/__generated__/commentFetchAggServerChildCommentsQuery.graphql.ts';
import unsetIn from '@atlassian/jira-common-icepick/src/utils/unset-in/index.tsx';
import {
	type ProjectType,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	transformComments,
	normalizeComments,
	normalizeCommentsV2,
	normalizeGiraComments,
} from '../../comment-transformer.tsx';
import { getCommentsQuery } from './graphql.tsx';

const CREATED_ORDER_DESCENDING = '-created';

export const getQuery = () =>
	getCommentsQuery(NUM_INITIAL_ITEMS_TO_LOAD, 0, CREATED_ORDER_DESCENDING);

export const transformData = (
	issue: ViewIssueData | IssueServiceViewIssueData,
): CommentsTransformResult => {
	const { comments } = issue;
	const emptyComments = {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		users: {} as any,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		comments: {} as any,
		commentsStartIndex: 0,
		loadedComments: 0,
		totalComments: 0,
	};

	if (!comments) {
		return emptyComments;
	}

	const { totalCount: totalComments, startIndex: commentsStartIndex } = comments;

	const response = {
		data: {
			issueComments: comments,
		},
	};

	if (fg('jira_comments_agg_pagination')) {
		return {
			...emptyComments,
			totalComments,
		};
	}

	return {
		...normalizeComments(transformComments(response), 0, commentsStartIndex),
		totalComments,
		commentsStartIndex,
	};
};

export const transformGiraData = (
	issue: ViewIssueData | null,
	loadedComments: number,
	commentsStartIndex: number,
	type?: string,
	orderBy?: string,
): CommentsTransformResult => {
	const emptyComments = {
		totalComments: 0,
		users: {},
		comments: {},
		commentsStartIndex: 0,
		loadedComments: 0,
	};

	if (issue?.comments == null) {
		return emptyComments;
	}

	const { comments } = issue;
	const { totalCount: totalComments } = comments;
	const response = {
		data: {
			issueComments: comments,
		},
	};

	if (fg('jira_comments_agg_pagination')) {
		return {
			...emptyComments,
			totalComments,
		};
	}

	return {
		...normalizeGiraComments(
			transformComments(response),
			loadedComments,
			commentsStartIndex,
			type,
			orderBy,
		),
		totalComments,
	};
};

/**
 *  To be used when graphql is only returning child comments and we need to establish parent comment's context
 */
export const transformChildCommentsAggData = (
	parentId: string,
	data?: commentFetchAggServerChildCommentsQuery$data,
	oldPageInfo?: CommentsPageInfo,
	isOlderButton?: boolean,
): NormalizedLoadedCommentsV2 => {
	const dummyParentCommentData: commentFetchAggServerQuery$data = {
		node: {
			threadedComments: {
				edges: [
					{
						cursor: '',
						node: {
							author: null,
							created: '',
							permissionLevel: null,
							richText: null,
							updateAuthor: null,
							updated: null,
							__typename: '',
							commentId: parentId,
							isDeleted: false,
							childComments: data?.node?.childComments,
						},
					},
				],
				pageInfo: {
					endCursor: null,
					hasNextPage: false,
					hasPreviousPage: false,
					startCursor: null,
					...data?.node?.childComments?.pageInfo,
				},
			},
		},
	};

	const dummyProject = SOFTWARE_PROJECT;

	const {
		users,
		commentsPageInfo,
		comments: transformedComments,
	} = transformAggData(dummyParentCommentData, dummyProject, oldPageInfo, isOlderButton);

	return { users, commentsPageInfo, comments: unsetIn(transformedComments, [parentId]) };
};

export const transformAggData = (
	data: commentFetchAggServerQuery$data | undefined,
	projectType: ProjectType | undefined,
	oldPageInfo?: CommentsPageInfo,
	isOlderButton?: boolean,
): NormalizedLoadedCommentsV2 => {
	const emptyComments = {
		users: {},
		comments: {},
		commentsPageInfo: {},
	};

	const { comments, isThreadedCommentsEnabled } = computeAggCommentsToUse(
		data?.node?.comments,
		data?.node?.threadedComments,
		projectType,
	);

	if (comments?.pageInfo) {
		const transformedPageInfo = { ...comments.pageInfo };

		// the relay query will gave pageInfo for just the current returned set of comments, whereas
		// we need pageInfo for the currently displayed comments, hence
		// use old pageInfo and combine with new pageInfo to create the accurate representation
		if (oldPageInfo) {
			if (isOlderButton) {
				transformedPageInfo.startCursor = oldPageInfo.startCursor;
				transformedPageInfo.hasPreviousPage = !!oldPageInfo.hasPreviousPage;
			} else {
				transformedPageInfo.endCursor = oldPageInfo.endCursor;
				transformedPageInfo.hasNextPage = !!oldPageInfo.hasNextPage;
			}
		}

		const transformedComments = transformAggCommentsToLegacyGiraV2Helper(
			comments,
			isThreadedCommentsEnabled,
		);
		if (transformedComments?.comments) {
			const normalizedComments = normalizeCommentsV2(
				transformedComments.comments,
				transformedPageInfo,
			);

			return normalizedComments;
		}
	}

	return emptyComments;
};
