import React from 'react';
import { AIAnalyticsProvider } from '@atlassian/jira-instrumentation-common/src/ai-analytics-context/context.tsx';

import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { AI_CONFIG } from '../common/analytics.tsx';
import type { AiContextServiceResource } from '../common/types.tsx';
import ServiceAIAnalyticsWrapper from './analytics/index.tsx';
import { Content } from './content.tsx';

export type Props = {
	resource: AiContextServiceResource;
};

export const AIContextServicePanel = ({ resource }: Props) => {
	return (
		<UFOSegment name="ai-context-service-panel">
			<AIAnalyticsProvider config={AI_CONFIG}>
				<ServiceAIAnalyticsWrapper resource={resource}>
					<Content resource={resource} />
				</ServiceAIAnalyticsWrapper>
			</AIAnalyticsProvider>
		</UFOSegment>
	);
};
