export const KNOWN_COLUMN_TYPES = {
	ASSIGNEE: 'assignee',
	CHECKBOX: '__issuetable_internal_checkbox',
	CHECKBOX_SELECT: 'com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes',
	DESCRIPTION: 'description',
	ENVIRONMENT: 'environment',
	EXPAND_BUTTON: '__issuetable_internal_expand_button',
	ISSUE_KEY: 'issuekey',
	ISSUE_OPERATIONS: '__issuetable_internal_meatball',
	STATUS: 'status',
	SUMMARY: 'summary',
	ISSUE_TYPE: 'issuetype',
	RESOLUTION: 'resolution',
	PARENT: 'parent',
	LABELS: 'labels',
	COMPONENTS: 'components',
	FIX_VERSIONS: 'fixVersions',
	STATUS_CATEGORY: 'statusCategory',
	PROJECT: 'project',
	READ_ONLY: 'com.atlassian.jira.plugin.system.customfieldtypes:readonlyfield',
	SPRINT: 'com.pyxis.greenhopper.jira:gh-sprint',
	TEXT: 'com.atlassian.jira.plugin.system.customfieldtypes:textfield',
	EPIC_LINK: 'com.pyxis.greenhopper.jira:gh-epic-link',
	CREATED: 'created',
	RESOLUTION_DATE: 'resolutiondate',
	TEXT_AREA: 'com.atlassian.jira.plugin.system.customfieldtypes:textarea',
	UPDATED: 'updated',
	DUE_DATE: 'duedate',
	REPORTER: 'reporter',
	CREATOR: 'creator',
	PRIORITY: 'priority',
	AFFECTS_VERSIONS: 'versions',
	REQUEST_TYPE: 'com.atlassian.servicedesk:vp-origin',
	NUMBER: 'com.atlassian.jira.plugin.system.customfieldtypes:float',
	STORY_POINT_ESTIMATE: 'com.pyxis.greenhopper.jira:jsw-story-points',
	USER_PICKER: 'com.atlassian.jira.plugin.system.customfieldtypes:userpicker',
	CUSTOM_LABELS: 'com.atlassian.jira.plugin.system.customfieldtypes:labels',
	DATE_TIME: 'com.atlassian.jira.plugin.system.customfieldtypes:datetime',
	URL: 'com.atlassian.jira.plugin.system.customfieldtypes:url',
	SINGLE_SELECT: 'com.atlassian.jira.plugin.system.customfieldtypes:select',
	MULTI_SELECT: 'com.atlassian.jira.plugin.system.customfieldtypes:multiselect',
	MULTI_USER_PICKER: 'com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker',
	TEAM: 'com.atlassian.jira.plugin.system.customfieldtypes:atlassian-team',
	ORIGINAL_ESTIMATE: 'timeoriginalestimate',
	GOALS: 'com.atlassian.jira.plugin.system.customfieldtypes:goals',
	CASCADING_SELECT: 'com.atlassian.jira.plugin.system.customfieldtypes:cascadingselect',
	CONFLUENCE_PAGES: 'confluenceRemoteIssueLinks',
} as const;

export const COLUMN_ID_MEATBALL_MENU = '__issuetable_internal_meatball';
export const COLUMN_ID_EXPAND_BUTTON = '__issuetable_internal_expand_button';
export const COLUMN_ID_CHECKBOX = '__issuetable_internal_checkbox';
