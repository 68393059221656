import React, { type PropsWithChildren } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import RecurIcon from '@atlaskit/icon-lab/core/recur';
import { DateWithUrgencyIndicator } from '@atlassian/jira-date-with-urgency-indicator/src/index.tsx';
import { ColoredDueDate } from '@atlassian/jira-coloured-due-date/src/ui/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import { useDateTimeFormatter } from '@atlassian/jira-issue-format-date/src/main.tsx';
import { DUE_DATE } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import type { date_issueFieldDateReadviewFull_DateReadView$key as DateFragment } from '@atlassian/jira-relay/src/__generated__/date_issueFieldDateReadviewFull_DateReadView.graphql';
import { useRecurWorkItemState } from '@atlassian/jira-recur-work-item/src/controllers/index.tsx';
import { isRecurOnCompleteToggleRule } from '@atlassian/jira-recur-work-item/src/ui/utils.tsx';
import { getNoOfDaysSinceUTCDateString } from '@atlassian/jira-issue-view-common/src/utils/date-utils/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';

const RecurringWorkItemIcon = ({ useUrgentColour }: { useUrgentColour: boolean }) => {
	const { issueProperties, isUpdatingRule } = useRecurWorkItemState();

	if ((!issueProperties && !isUpdatingRule) || isRecurOnCompleteToggleRule(issueProperties)) {
		return null;
	}

	return (
		<RecurIcon
			label=""
			testId="issue-field-date-readview-full.ui.date.recur-work-icon"
			{...(useUrgentColour ? { color: token('color.icon.danger') } : {})}
		/>
	);
};

const EmptyContainer = ({ children }: PropsWithChildren<unknown>) => {
	return <>{children}</>;
};

export const DateReadView = ({
	fragmentRef,
	fieldId,
	includeUrgencyTreatment,
	shouldShowRecurWorkIcon = false,
}: Props) => {
	const { formatDateOnly } = useDateTimeFormatter();
	const data = useFragment<DateFragment>(
		graphql`
			fragment date_issueFieldDateReadviewFull_DateReadView on JiraDatePickerField {
				date
			}
		`,
		fragmentRef,
	);

	const { date } = data || {};
	if (!date) {
		return <EmptyFieldText />;
	}

	const daysSinceDate = getNoOfDaysSinceUTCDateString(date);
	const isDatePast = daysSinceDate != null && daysSinceDate > 0;
	const isDueToday = daysSinceDate === 0;

	const renderColoredDueDate = () => {
		return (
			<ColoredDueDate
				dateString={date}
				pastDue={isDatePast && Boolean(includeUrgencyTreatment) && fieldId === DUE_DATE}
				dueToday={isDueToday && Boolean(includeUrgencyTreatment) && fieldId === DUE_DATE}
				warningIconTestId="date-field.warning-icon"
				clockIconTestId="date-field.clock-icon"
				calendarIconTestId="date-field.calendar-icon"
				maybeShowRecurIcon={shouldShowRecurWorkIcon}
				{...(shouldShowRecurWorkIcon ? { recurIconTestId: 'date-field.recur-icon' } : {})}
			/>
		);
	};

	const renderedValue =
		includeUrgencyTreatment &&
		fieldId === DUE_DATE &&
		expVal('due_date_colured_field', 'showUrgencyIndicator', false) ? (
			<DateWithUrgencyIndicator value={date} />
		) : (
			formatDateOnly(date)
		);

	const Container = shouldShowRecurWorkIcon ? Inline : EmptyContainer;

	return (
		<Container space="space.075" alignBlock="center">
			<Box as="span" xcss={dateReadViewStyles}>
				{fg('color-due-date-all-views') ? renderColoredDueDate() : renderedValue}
			</Box>
			{!fg('color-due-date-all-views') && shouldShowRecurWorkIcon && (
				<RecurringWorkItemIcon
					useUrgentColour={
						(isDatePast || isDueToday) &&
						!!includeUrgencyTreatment &&
						expVal('due_date_colured_field', 'showUrgencyIndicator', false)
					}
				/>
			)}
		</Container>
	);
};

const dateReadViewStyles = xcss({
	display: '-webkit-box',
	WebkitBoxOrient: 'vertical',
	WebkitLineClamp: 2,
	maxWidth: '100%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
