import React from 'react';
import { ConfluenceIcon } from '@atlaskit/logo';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import { expValEquals, expVal } from '@atlassian/jira-feature-experiments';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { fetchPersonalSpace, createPersonalSpace } from '../services/personal-space.tsx';
import { PlaceholderTemplateError } from '../placeholder-template-error.tsx';
import { getPagesBySpaceId } from '../services/pages-by-space-id.tsx';
import {
	getPlaceholderTemplateUserProperty,
	setPlaceholderTemplateUserProperty,
} from '../services/user-properties.tsx';
import messages from '../messages.tsx';

export const usePlaceholderNudge = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();
	const accountId = useAccountId() || '';

	const getPersonalSpaceId = async (cloudId: string) => {
		try {
			// Check if personal space exists
			const userInfo = await fetchPersonalSpace(cloudId);

			let personalSpaceId = userInfo.personalSpace?.id;

			// Create personal space if it doesn't exist
			if (!personalSpaceId) {
				const newSpace = await createPersonalSpace(cloudId, userInfo.publicName);
				personalSpaceId = newSpace.id;
			}

			return personalSpaceId;
		} catch (error: unknown) {
			fireErrorAnalytics({
				meta: {
					id: 'confluencePlaceholderNudgeGetPersonalSpaceIdError',
					packageName: '@atlassian/jira-issue-content-placeholder-template',
					teamName: 'starburst',
				},
				attributes: {
					message: 'Error getting personal space id or creating personal space',
					statusCode: error instanceof PlaceholderTemplateError ? error.statusCode : null,
					traceId: error instanceof PlaceholderTemplateError ? error.traceId : null,
				},
			});
		}
	};

	const getGettingStartedPage = async (cloudId: string, personalSpaceId: string) => {
		const GETTING_STARTED_TEMPLATE_ID =
			'com.atlassian.confluence.plugins.confluence-space-blueprints:xflow-getting-started-page-blueprint-v2';

		// The "getting started" page is created quickly but retry helps ensure the page is available
		let gettingStartedPage = null;
		for (let retry = 0; retry < 3; retry++) {
			try {
				// https://eslint.org/docs/latest/rules/no-await-in-loop#when-not-to-use-it
				// eslint-disable-next-line no-await-in-loop
				const pagesInSpace = await getPagesBySpaceId(cloudId, personalSpaceId);

				gettingStartedPage = pagesInSpace
					? pagesInSpace.find((page) => page.sourceTemplateEntityId === GETTING_STARTED_TEMPLATE_ID)
					: null;

				if (gettingStartedPage) {
					break;
				}
			} catch (error: unknown) {
				fireErrorAnalytics({
					meta: {
						id: 'confluencePlaceholderNudgeGetGettingStartedPageError',
						packageName: '@atlassian/jira-issue-content-placeholder-template',
						teamName: 'starburst',
					},
					attributes: {
						message: 'Error getting getting started page',
						statusCode: error instanceof PlaceholderTemplateError ? error.statusCode : null,
						traceId: error instanceof PlaceholderTemplateError ? error.traceId : null,
					},
				});
			}

			if (retry < 3 - 1) {
				// eslint-disable-next-line no-await-in-loop
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}
		}

		if (!gettingStartedPage?._links?.webui) {
			fireErrorAnalytics({
				meta: {
					id: 'confluencePlaceholderNudgeGettingStartedPageNotFound',
					packageName: '@atlassian/jira-issue-content-placeholder-template',
					teamName: 'starburst',
				},
				attributes: {
					message: 'Getting started page not found for user',
				},
			});
			return null;
		}

		return `/wiki${gettingStartedPage._links.webui}`;
	};

	const showPlaceholderNudge = async (cloudId: string, fallbackLink = '') => {
		const nudgePropertyKey = 'hasViewedPlaceholderTemplateNudge';
		try {
			const hasViewedPlaceholderTemplateNudge = await getPlaceholderTemplateUserProperty(
				accountId,
				nudgePropertyKey,
			);
			// Nudge should only be shown once per user
			if (hasViewedPlaceholderTemplateNudge) {
				return;
			}

			// There is no check for personal space creation permissions so to preserve
			// 		the integrity of the experiment data this should happen before expVal
			const personalSpaceId = await getPersonalSpaceId(cloudId);
			if (!personalSpaceId) {
				return;
			}

			if (expValEquals('grw_1277_placeholder_template_nudge_after_xflow', 'cohort', 'variation')) {
				const gettingStartedPage = await getGettingStartedPage(cloudId, personalSpaceId);
				const flagLink = gettingStartedPage || fallbackLink;

				if (!flagLink) {
					fireErrorAnalytics({
						meta: {
							id: 'confluencePlaceholderNudgeNoFlagLinkAvailable',
							packageName: '@atlassian/jira-issue-content-placeholder-template',
							teamName: 'starburst',
						},
					});
				}

				// delay showing flag for better UX if multiple flags
				await new Promise((resolve) => setTimeout(resolve, 1000));
				showFlag({
					messageId: 'issue-content-placeholder-template.controllers.show-flag.info',
					messageType: 'transactional',
					type: 'info',
					isAutoDismiss: true,
					icon: <ConfluenceIcon appearance="brand" size="medium" />,
					actions: [
						{
							content: formatMessage(messages.placeholderNudgeCta),
							onClick: () => {
								// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
								window.open(`${window.location.origin}${flagLink}`, '_blank');
								fireTrackAnalytics(createAnalyticsEvent({}), 'placeholderTemplateNudge clicked', {
									isUsingGettingStartedPage: !!gettingStartedPage,
								});
							},
						},
					],
					title: formatMessage(messages.placeholderNudgeTitle),
					description: formatMessage(messages.placeholderNudgeDescription),
				});
				fireTrackAnalytics(createAnalyticsEvent({}), 'placeholderTemplateNudge viewed', {
					isUsingGettingStartedPage: !!gettingStartedPage,
				});
				setPlaceholderTemplateUserProperty(accountId, nudgePropertyKey);
			}

			fireTrackAnalytics(createAnalyticsEvent({}), 'placeholderTemplateNudge exposed', {
				cohort: expVal('grw_1277_placeholder_template_nudge_after_xflow', 'cohort', 'not-enrolled'),
			});
		} catch (error: unknown) {
			fireErrorAnalytics({
				meta: {
					id: 'confluencePlaceholderNudgeShowPlaceholderNudgeError',
					packageName: '@atlassian/jira-issue-content-placeholder-template',
					teamName: 'starburst',
				},
				attributes: {
					message: error instanceof PlaceholderTemplateError ? error.message : null,
				},
			});
		}
	};

	return showPlaceholderNudge;
};
