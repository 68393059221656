import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { getSupportsIssueCreation } from '../../../state/context/selectors.tsx';
import { getAllIssues } from '../../../state/entities/selectors.tsx';
import type { State } from '../../../state/types.tsx';
import AddChild from '../add-child/index.tsx';
import Meatballs from '../meatballs/src/index.tsx';
import HeaderSectionHeadingIcons from './view.tsx';

export default flowWithSafeComponent(
	connect((state: State) => {
		return {
			Meatballs,
			AddChild,
			allIssues: getAllIssues(state),
			supportsIssueCreation: getSupportsIssueCreation(state),
			isSortingAvailable: false,
		};
	}, {}),
)(HeaderSectionHeadingIcons);
