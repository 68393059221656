import React, { memo, useCallback, useMemo, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { ModalContextProvider } from '@atlassian/jira-modal-context-provider/src/ModalContextProvider.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { deleteIssueLinkConfirmationEntryPoint } from './confirmation-modal/entrypoint.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ModalEntryPointTrigger = ({
	linkId,
	issueKey,
	globalId,
	deleteButtonTooltipContent,
	buttonLabel,
}: {
	linkId?: string;
	issueKey: IssueKey;
	globalId?: string;
	deleteButtonTooltipContent: string;
	buttonLabel: string;
}) => {
	const entryPointProps = useMemo(
		() => ({
			linkId,
			issueKey,
			globalId,
		}),
		[linkId, globalId, issueKey],
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// this replicates handleDeleteAction in issue-view-base/src/content/issue-links/view.tsx. It's passed to the button as onDelete
	const onClickHandler = useCallback(
		(e: SyntheticEvent<HTMLElement>) => {
			e.stopPropagation();
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});

			fireUIAnalytics(analyticsEvent, 'linkedIssueCardDeleteButton', {
				issueLinkToDeleteId: linkId,
				isRemote: !isEmpty(globalId),
			});
		},
		[createAnalyticsEvent, linkId, globalId],
	);

	const modalProps = useMemo(
		() => ({
			width: 'small',
			testId: 'issue-line-card.ui.remove-button.confirmation-modal.delete',
		}),
		[],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={deleteIssueLinkConfirmationEntryPoint}
			entryPointProps={entryPointProps}
			interactionName="removeLinkedIssue"
			modalProps={modalProps}
			useInternalModal
		>
			{({ ref }) => (
				<Button
					ref={ref}
					testId="issue-line-card.ui.remove-button"
					iconBefore={
						<EditorCloseIcon color={token('color.text')} label={deleteButtonTooltipContent} />
					}
					onClick={onClickHandler}
					appearance="subtle"
					spacing="none"
					aria-label={buttonLabel}
				/>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

export const RemoveButton = memo<Props>(
	({ linkId, issueKey, globalId, isRemote, shouldShowRemoveAction, onDelete }: Props) => {
		const intl = useIntl();

		const buttonLabel = intl.formatMessage(
			isRemote ? messages.remoteLinkDeleteLinkTooltip : messages.issueLinkDeleteLinkTooltip,
		);

		const deleteButtonTooltipContent = shouldShowRemoveAction ? buttonLabel : '';

		const deleteButtonAction = useCallback(
			(e: SyntheticEvent<HTMLElement>) => {
				e.stopPropagation();
				if (issueKey != null) {
					onDelete(issueKey, globalId);
				}
			},
			[globalId, issueKey, onDelete],
		);

		/* A container surrounding the button is needed as tabbing from issue line card without an
		 *  intermediary always 'existent' container results in tabbing to 'nothing'/the body div
		 *  as the tab event occurs before the close button is chosen as the next node to tab into
		 */
		return (
			<Tooltip content={deleteButtonTooltipContent} position="bottom">
				<DeleteLinkContainer
					show={shouldShowRemoveAction}
					data-testid="issue-line-card.ui.remove-button.delete-link-container"
				>
					{onDelete !== noop && fg('itsm-agni-issue-line-card-modal') ? (
						<Button
							testId="issue-line-card.ui.remove-button.custom"
							iconBefore={
								<EditorCloseIcon color={token('color.text')} label={deleteButtonTooltipContent} />
							}
							onClick={deleteButtonAction}
							appearance="subtle"
							spacing="none"
							aria-label={buttonLabel}
						/>
					) : (
						<ModalContextProvider>
							<ModalEntryPointTrigger
								linkId={linkId}
								issueKey={issueKey}
								globalId={globalId}
								deleteButtonTooltipContent={deleteButtonTooltipContent}
								buttonLabel={buttonLabel}
							/>
						</ModalContextProvider>
					)}
				</DeleteLinkContainer>
			</Tooltip>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DeleteLinkContainer = styled.div<{ show?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 4}px`,
	height: '100%',
	// TODO Delete this comment after verifying space token -> previous value ``${gridSize / 4}px``
	marginLeft: token('space.025'),
	marginRight: token('space.negative.100'),
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: (props) => (props.show ? 1 : 0),
});
