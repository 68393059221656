import type { FC } from 'react';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import type { ChildIssuesLimitStatus } from '@atlassian/jira-issue-view-common-constants/src/child-issues.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	getBaseUrl,
	getChildIssuesPanelType,
	getParentKey,
} from '../../../../state/context/selectors.tsx';
import { getAllIssues, getIncompleteIssues } from '../../../../state/entities/selectors.tsx';
import type { State } from '../../../../state/types.tsx';
import { toggleHideDone } from '../../../../state/ui/actions.tsx';
import { getHideDone } from '../../../../state/ui/selectors.tsx';
import { Meatballs } from './view.tsx';

// FIXME: JIV-17455 should be fully typed
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default flowWithSafeComponent(
	ComponentWithAnalytics('item', {
		onBulkEditClicked: 'clicked',
		onViewInSearchClicked: 'clicked',
		onHideDoneClicked: 'clicked',
	}),
	withFireUiAnalytics({
		// @ts-expect-error - TS7006 - Parameter 'issueCount' implicitly has an 'any' type.
		onBulkEditClicked: (issueCount) => ({
			name: 'bulkEdit',
			issueCount,
		}),
		// @ts-expect-error - TS7006 - Parameter 'issueCount' implicitly has an 'any' type.
		onViewInSearchClicked: (issueCount) => ({
			name: 'viewInSearch',
			issueCount,
		}),
		// @ts-expect-error - TS7006 - Parameter 'totalIssues' implicitly has an 'any' type. | TS7006 - Parameter 'incompleteIssues' implicitly has an 'any' type. | TS7006 - Parameter 'hideDone' implicitly has an 'any' type.
		onHideDoneClicked: (totalIssues, incompleteIssues, hideDone) => ({
			name: 'toggleHideDone',
			totalIssues,
			incompleteIssues,
			hideDone,
		}),
	}),
	// @ts-expect-error - Argument of type 'InferableComponentEnhancerWithProps<{ baseUrl: string; childIssuesPanelType: ChildIssuesPanelType | null; parentKey: string | null; hideDone: boolean; allIssues: ChildIssue[]; incompleteIssues: ChildIssue[]; } & { ...; }, {}>' is not assignable to parameter of type 'FlowStep<WithUIAnalyticsEvent<PropsWithoutRef<Omit<{ [K in keyof Props]: K extends "onBulkEditClicked" | "onViewInSearchClicked" | "onHideDoneClicked" ? (...args: [...Parameters<Props[K]>, UIAnalyticsEvent]) => ReturnType<...> : Props[K]; }, keyof WithAnalyticsEventsProps>>, { ...; }>, ConnectedComponent<...>>'.
	connect(
		(state: State) => ({
			baseUrl: getBaseUrl(state),
			childIssuesPanelType: getChildIssuesPanelType(state),
			parentKey: getParentKey(state),
			hideDone: getHideDone(state),
			allIssues: getAllIssues(state),
			incompleteIssues: getIncompleteIssues(state),
		}),
		{
			onHideDoneClicked: toggleHideDone,
		},
	),
)(Meatballs) as FC<{ childIssuesLimitStatus: ChildIssuesLimitStatus }>;
