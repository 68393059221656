export const HEADER_BREADCRUMBS_BEGIN_MARK_KEY = 'HEADER_BREADCRUMBS_BEGIN_MARK';
export const HEADER_BREADCRUMBS_END_MARK_KEY = 'HEADER_BREADCRUMBS_END_MARK';

export const HEADER_ACTIONS_BEGIN_MARK_KEY = 'HEADER_ACTIONS_BEGIN_MARK';
export const HEADER_ACTIONS_END_MARK_KEY = 'HEADER_ACTIONS_END_MARK';

export const ISSUE_NAME_BEGIN_MARK_KEY = 'ISSUE_NAME_BEGIN_MARK';
export const ISSUE_NAME_END_MARK_KEY = 'ISSUE_NAME_END_MARK';

export const QUICK_ADD_ITEMS_BEGIN_MARK_KEY = 'QUICK_ADD_ITEMS_BEGIN_MARK';
export const QUICK_ADD_ITEMS_END_MARK_KEY = 'QUICK_ADD_ITEMS_END_MARK';

export const CONTENT_ITEMS_BEGIN_MARK_KEY = 'CONTENT_ITEMS_BEGIN_MARK';
export const CONTENT_ITEMS_END_MARK_KEY = 'CONTENT_ITEMS_END_MARK';

export const ACTIVITY_FEED_BEGIN_MARK_KEY = 'ACTIVITY_FEED_BEGIN_MARK';
export const ACTIVITY_FEED_END_MARK_KEY = 'ACTIVITY_FEED_END_MARK';

export const FOOTNOTE_BEGIN_MARK_KEY = 'FOOTNOTE_BEGIN_MARK';
export const FOOTNOTE_END_MARK_KEY = 'FOOTNOTE_END_MARK';

export const ISSUE_STATUS_BEGIN_MARK_KEY = 'ISSUE_STATUS_BEGIN_MARK';
export const ISSUE_STATUS_END_MARK_KEY = 'ISSUE_STATUS_END_MARK';

export const defaultRatioRightColumn = 0.35;
export const activitySidePanelRatio = 0.4;
export const getIssueContainerMaxWidth = () => 1680;
export const ACTIVITY_SIDE_PANEL_MIN_WIDTH = 400;
