/** @jsx jsx */
import React, { memo, useEffect, useMemo, useRef, type FunctionComponent } from 'react';
import { jsx } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import UFOPlaceholder from '@atlaskit/react-ufo/placeholder';
import { AdControlProvider } from '@atlassian/ad-control-toolkit';
import { RelatedResourcesContainer } from '@atlassian/jira-ai-related-resources/src/controllers/related-resources-container.tsx';
import { SuggestRelatedResourcesButton } from '@atlassian/jira-ai-related-resources/src/ui/suggest-related-resources-button/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import {
	useIsAiEnabledForIssue,
	useIsJiraIssue,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import type {
	ConfluencePage,
	ConfluenceWhiteboard,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { IssueType } from '@atlassian/jira-issue-shared-types/src/common/types/issue-hierarchy-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import { ConfluencePageLineCardGroup } from '@atlassian/jira-issue-view-common-views/src/confluence-content-line-card/ui/confluence-page-line-card-group-view/confluence-page-line-card-group-view.tsx';
import { ConfluenceWhiteboardLineCardGroup } from '@atlassian/jira-issue-view-common-views/src/confluence-content-line-card/ui/confluence-whiteboard-line-card-group-view/confluence-whiteboard-line-card-group-view.tsx';
import LineCardSkeleton from '@atlassian/jira-issue-view-common-views/src/skeleton/item-line-card-group.tsx';
import { Container } from '@atlassian/jira-issue-view-common-views/src/smart-link-content/styled.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	SectionHeading,
	SectionHeadingTitle,
	SectionHeadingIcons,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import type { Href, RemoteLinkGlobalId } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { usePlaceholderTemplate } from '@atlassian/jira-issue-content-placeholder-template/src/index.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import {
	useRelatedResourcesActions,
	useRelatedResourcesState,
} from '@atlassian/jira-ai-related-resources/src/controllers/related-resources-context.tsx';
import type { ConfluenceAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { AiRelatedResourcesEntrypoint } from '@atlassian/jira-ai-related-resources/entrypoint.tsx';
import { useRelatedResourcesEntrypointStoreActions } from '@atlassian/jira-ai-related-resources/src/controllers/entrypoint-params-store.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { PersistentCrossJoinNudge } from '@atlassian/jira-persistent-cross-join-nudge/src/ui/confluence-content/lazy-confluence-content.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/index.tsx';
import { PersistentInviteBanner } from '@atlassian/jira-invite-prompt-on-link-insert/src/ui/confluence-content-invite-banner/persistent-invite-banner.tsx';
import { useIsIssueOfIncidentsPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import type { IncidentPanelExternalHandlers } from '@atlassian/jira-servicedesk-aiops-suggestions-common/src/ui/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import type {
	confluenceContentV2_issueViewBase_ConfluenceContentWithFragment$key,
	confluenceContentV2_issueViewBase_ConfluenceContentWithFragment$data,
} from '@atlassian/jira-relay/src/__generated__/confluenceContentV2_issueViewBase_ConfluenceContentWithFragment.graphql';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { BackgroundProvisioningEditor } from '../confluence-placeholder-template-experiment/confluence-dummy-link/experiments/background-provisioning-editor/index.tsx';
import AddButton from './add-button/index.tsx';
import ConfluencePageCreateLinkView from './create-link/index.tsx';
import { PlaceholderTemplatePopup } from './placeholder-template-popup/index.tsx';
import AdControls from './ad-controls/index.tsx';
import { PlaceholderTemplate } from './placeholder-template/index.tsx';
import { EmbeddedConfluenceModal } from './embedded-confluence-modal/index.tsx';
import { IncidentSuggestionPanelContainer } from './ai-suggestion-panel/index.tsx';
import { FindRelatedContentButtonContainer } from './ai-suggestion-panel/find-related-content-button/index.tsx';
import { MentionedConfluenceLinksSection } from './mentioned-confluence-links-section/index.tsx';

const LINKED_PAGES_GROUP_ID = 'linked-confluence-pages';
const LINKED_WHITEBOARDS_GROUP_ID = 'linked-confluence-whiteboards';
const MENTIONED_PAGES_GROUP_ID = 'mentioned-confluence-pages';
const PLACEHOLDER_TEMPLATE_SOURCE = 'issueView';
const runtimeProps = {};

export type ConfluenceContentV2Props = {
	isCreateLinkedPageOpened: boolean;
	isConfluencePageLinksEnabled?: boolean;
	canLinkConfluencePage: boolean;
	linkedPages: (ConfluencePage | FailedRemoteLink)[] | null | undefined;
	linkedPagesCachedCount?: number;
	linkedWhiteboards: ConfluenceWhiteboard[] | null | undefined;
	linkedWhiteboardsCachedCount?: number;
	systemConfluenceAppLinkUrl: string | undefined;
	licensedProducts?: { [key: string]: boolean };
	mentionedPages?: (ConfluencePage | FailedRemoteLink)[];
	mentionedPagesCachedCount?: number;
	mentionedPagesCount?: number;
	onLinkedPageClicked?: () => void;
	onLinkedWhiteboardClicked?: () => void;
	onMentionedPageClicked?: () => void;
	onAuthenticateApplink: (href: Href) => void;
	onDeleteConfluencePageLink: (id: RemoteLinkGlobalId) => void;
	/**
	 * This function is used to delete a confluence page link by its ARI.
	 */
	onDeleteConfluencePageLinkByAri: (ari: string) => void;
	onAddButtonClicked: () => void;
	onShowAISuggestionsClicked?: () => void;
	projectType?: string;
	issueType?: IssueType;
	projectName?: string;
	projectId?: string;
	hasActivatedConfluenceWithinFourteenDays: boolean;
	hasActivatedConfluenceWithinSixtythreeDays: boolean;
	isAISuggestionsOpen?: boolean;
	onLinkPage: (link: string, analyticsEvent: UIAnalyticsEvent) => void;
	confluenceAppLinks: ConfluenceAppLink[] | [];
};

type ConfluenceContentPropsWithFragment = ConfluenceContentV2Props & {
	issueFragment?: confluenceContentV2_issueViewBase_ConfluenceContentWithFragment$data;
};

const ConfluenceContentV2: FunctionComponent<ConfluenceContentPropsWithFragment> = memo(
	({
		isCreateLinkedPageOpened,
		isConfluencePageLinksEnabled = false,
		canLinkConfluencePage,
		linkedPages,
		linkedPagesCachedCount = 0,
		linkedWhiteboards,
		linkedWhiteboardsCachedCount = 0,
		systemConfluenceAppLinkUrl,
		mentionedPages,
		mentionedPagesCachedCount = 0,
		mentionedPagesCount = 0,
		onLinkedPageClicked,
		onLinkedWhiteboardClicked,
		onMentionedPageClicked,
		onAuthenticateApplink,
		onDeleteConfluencePageLink,
		onDeleteConfluencePageLinkByAri,
		onAddButtonClicked,
		onShowAISuggestionsClicked,
		projectType,
		issueType,
		projectName,
		hasActivatedConfluenceWithinFourteenDays,
		hasActivatedConfluenceWithinSixtythreeDays,
		isAISuggestionsOpen = false,
		onLinkPage,
		projectId,
		confluenceAppLinks = [],
		issueFragment,
	}: ConfluenceContentPropsWithFragment) => {
		const hasLinkedPages = useMemo(() => linkedPages && linkedPages.length > 0, [linkedPages]);

		const hasMentionedPages = useMemo(() => {
			if (fg('jiv-19125-fetch-remote-links-in-interactive-query')) {
				return mentionedPagesCount > 0;
			}

			return mentionedPages && mentionedPages.length > 0;
		}, [mentionedPages, mentionedPagesCount]);

		const hasLinkedWhiteboards = useMemo(
			() => linkedWhiteboards && linkedWhiteboards.length > 0,
			[linkedWhiteboards],
		);
		const hasContent = useMemo(
			() => hasLinkedPages || hasMentionedPages || hasLinkedWhiteboards,
			[hasLinkedPages, hasLinkedWhiteboards, hasMentionedPages],
		);

		const shouldShowConfluenceContent = useMemo(
			() => (isCreateLinkedPageOpened || hasContent) && isConfluencePageLinksEnabled,
			[hasContent, isCreateLinkedPageOpened, isConfluencePageLinksEnabled],
		);

		const isAiOptInEnabledHookVal = useIsAiEnabledForIssue();
		const isAiOptInEnabled = fg('jira_ai_powered_issue_related_confluence_resources')
			? isAiOptInEnabledHookVal
			: false;

		const isJiraIssueHoolVal = useIsJiraIssue();
		const isJiraIssue = fg('jira_ai_powered_issue_related_confluence_resources')
			? isJiraIssueHoolVal
			: null;

		const canShowJiraAISuggestions = fg('jira_ai_powered_issue_related_confluence_resources')
			? isAiOptInEnabled && canLinkConfluencePage && isJiraIssue && shouldShowConfluenceContent
			: false;

		const isIncidentIssue = useIsIssueOfIncidentsPractice();
		const canEnableRelatedContentPanel =
			isAiOptInEnabledHookVal && canLinkConfluencePage && isIncidentIssue;
		const incidentSuggestionPanelRef = useRef<IncidentPanelExternalHandlers>(null);

		const hasAnyLinkedPageLoaded = useMemo(
			() => linkedPages && linkedPages.length > 0,
			[linkedPages],
		);

		const hasAnyMentionedPageLoaded = useMemo(
			() => mentionedPages && mentionedPages.length > 0,
			[mentionedPages],
		);

		const { hostNamesFromApplinks } = useRelatedResourcesState();

		const { setHostNamesFromApplinks } = useRelatedResourcesActions();

		// Handle container update effects
		useEffect(() => {
			if (confluenceAppLinks) {
				setHostNamesFromApplinks(confluenceAppLinks);
			}
		}, [confluenceAppLinks, setHostNamesFromApplinks]);

		const entrypointParams = useMemo(() => {
			return {
				hostNames: Object.keys(hostNamesFromApplinks),
			};
		}, [hostNamesFromApplinks]);

		const entryPoint = useEntryPoint(AiRelatedResourcesEntrypoint, entrypointParams);
		const entrypointTriggerRef = useEntryPointButtonTrigger(entryPoint.entryPointActions, true);

		const { setData: setAIEntrypointParams } = useRelatedResourcesEntrypointStoreActions();

		useEffect(() => {
			setAIEntrypointParams({
				entrypointTriggerRef,
			});
		}, [setAIEntrypointParams, entrypointTriggerRef]);

		const {
			placeholderTarget,
			onDismissPlaceholderTemplate,
			placeholderUiProps,
			onProvisioningStarted,
		} = usePlaceholderTemplate(
			projectType,
			issueType?.name.toLowerCase(),
			projectName,
			PLACEHOLDER_TEMPLATE_SOURCE,
			'',
			hasActivatedConfluenceWithinFourteenDays,
			hasActivatedConfluenceWithinSixtythreeDays,
		);

		const shouldShowPlaceholderTemplate = placeholderTarget && placeholderUiProps && !hasContent;

		const cloudId = useCloudId();

		// grw-1287-persistent-cross-join-nudge
		const isIssueTypeEligiblePersistentCrossJoin = useMemo(
			() =>
				issueType ? ['bug', 'story', 'epic', 'task'].includes(issueType.name.toLowerCase()) : false,
			[issueType],
		);
		const hideManualWayOfLinkingPagesWhenAISuggestionsAreOpen = expVal(
			'jira_suggest_confluence_pages_when_linking_a_page',
			'isEnabled',
			false,
		)
			? false
			: canShowJiraAISuggestions && isAISuggestionsOpen;

		return (
			<UFOSegment name="issue-confluence-pages">
				<JSErrorBoundary
					id="issue-view-confluence-content"
					packageName="confluenceContent"
					teamName="bento"
					fallback="unmount"
				>
					<AdControlProvider>
						{shouldShowConfluenceContent || shouldShowPlaceholderTemplate ? (
							<>
								<Box xcss={sectionHeadingWrapperStyles}>
									<SectionHeading>
										<Box xcss={sectionHeadingTitleWrapper}>
											<SectionHeadingTitle>
												<FormattedMessage
													id="issue.details.confluence-content.heading"
													defaultMessage="Confluence content"
													description="Heading for linked Confluence content section."
												/>
											</SectionHeadingTitle>
											{shouldShowPlaceholderTemplate && <PlaceholderTemplatePopup />}
										</Box>
										<SectionHeadingIcons>
											<>
												{shouldShowPlaceholderTemplate && (
													<AdControls onPlaceholderDismissed={onDismissPlaceholderTemplate} />
												)}
											</>
											{((canLinkConfluencePage && hasLinkedPages) ||
												placeholderTarget === 'CoUse') && (
												<AddButton onClick={onAddButtonClicked} />
											)}
											{fg('jira_ai_powered_issue_related_confluence_resources') ? (
												canShowJiraAISuggestions &&
												onShowAISuggestionsClicked && (
													<SuggestRelatedResourcesButton
														onShowAISuggestionsClicked={onShowAISuggestionsClicked}
													/>
												)
											) : (
												<></>
											)}
											{canEnableRelatedContentPanel &&
											expVal('suggested_incident_resources_v2', 'isEnabled', false) ? (
												<FindRelatedContentButtonContainer
													suggestionPanelRef={incidentSuggestionPanelRef}
												/>
											) : null}
										</SectionHeadingIcons>
									</SectionHeading>
								</Box>
								{shouldShowPlaceholderTemplate && <PlaceholderTemplate {...placeholderUiProps} />}
								{shouldShowPlaceholderTemplate &&
									fg('placeholder-template-background-provisioning-gate') && (
										<BackgroundProvisioningEditor
											issueType={issueType?.name?.toLowerCase?.()}
											cloudId={cloudId}
											projectId={projectId}
											onCloseEditor={() => {
												if (fg('grw_1277_placeholder_template_nudge_gate')) {
													placeholderUiProps?.backgroundProvisioningEditor?.onClose();
												}
												onProvisioningStarted();
											}}
										/>
									)}
								{placeholderUiProps?.embeddedEditor?.isOpen &&
									placeholderUiProps?.embeddedEditor?.url && (
										<EmbeddedConfluenceModal
											activePageUrl={placeholderUiProps?.embeddedEditor?.url}
											onPublish={placeholderUiProps?.embeddedEditor?.onPublish}
											onClose={placeholderUiProps?.embeddedEditor?.onClose}
											embeddedConfluenceSource="jswIssueView"
											linkConfluencePage={onLinkPage}
										/>
									)}
								{(hasLinkedPages || hasLinkedWhiteboards) && (
									<Box xcss={groupContainerStyles}>
										{(linkedPages || linkedWhiteboards) && (
											<Container>
												{hasAnyLinkedPageLoaded && linkedPages && (
													<>
														{fg('persistent_invite_on_link_insert_gate') && (
															<JSErrorBoundary
																id="persistent-invite-banner"
																packageName="persistentInviteBanner"
																teamName="Groot (Growth)"
																fallback="unmount"
															>
																<PersistentInviteBanner pages={linkedPages} />
															</JSErrorBoundary>
														)}
														<ConfluencePageLineCardGroup
															groupId={LINKED_PAGES_GROUP_ID}
															pages={linkedPages}
															onClick={onLinkedPageClicked}
															onAuthenticateApplink={onAuthenticateApplink}
															onDeleteConfluencePageLink={onDeleteConfluencePageLink}
															systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
														/>
													</>
												)}
												{hasLinkedWhiteboards && (
													<ConfluenceWhiteboardLineCardGroup
														groupId={LINKED_WHITEBOARDS_GROUP_ID}
														whiteboards={linkedWhiteboards ?? []}
														onClick={onLinkedWhiteboardClicked}
														systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
													/>
												)}
											</Container>
										)}
										{!linkedPages && <LineCardSkeleton itemCount={linkedPagesCachedCount} />}
										{!linkedWhiteboards && (
											<LineCardSkeleton itemCount={linkedWhiteboardsCachedCount} />
										)}
									</Box>
								)}
								{hasMentionedPages &&
									issueFragment != null &&
									fg('jiv-19125-fetch-remote-links-in-interactive-query') && (
										<UFOPlaceholder name="confluence-mentioned-pages" fallback={null}>
											<MentionedConfluenceLinksSection
												issueFragment={issueFragment}
												groupId={MENTIONED_PAGES_GROUP_ID}
												onPageClick={onMentionedPageClicked}
												onAuthenticateApplink={onAuthenticateApplink}
												onDeleteConfluencePageLink={onDeleteConfluencePageLinkByAri}
												systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
											/>
										</UFOPlaceholder>
									)}
								{hasMentionedPages && !fg('jiv-19125-fetch-remote-links-in-interactive-query') && (
									<>
										<Box xcss={groupContainerStyles}>
											<Heading as="h3" size="xxsmall">
												<FormattedMessage
													id="issue.details.confluence-pages.mentioned-on"
													defaultMessage="mentioned on"
												/>
											</Heading>
											{!mentionedPages && (
												<LineCardSkeleton itemCount={mentionedPagesCachedCount} />
											)}
											{mentionedPages && (
												<Box xcss={containerWrapperStyles}>
													<Container>
														{hasAnyMentionedPageLoaded && mentionedPages && (
															<ConfluencePageLineCardGroup
																groupId={MENTIONED_PAGES_GROUP_ID}
																pages={mentionedPages}
																onClick={onMentionedPageClicked}
																onAuthenticateApplink={onAuthenticateApplink}
																onDeleteConfluencePageLink={onDeleteConfluencePageLink}
																systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
															/>
														)}
													</Container>
												</Box>
											)}
										</Box>
									</>
								)}
								{fg('jira_ai_powered_issue_related_confluence_resources')
									? isCreateLinkedPageOpened &&
										!hideManualWayOfLinkingPagesWhenAISuggestionsAreOpen && (
											<ConfluencePageCreateLinkView />
										)
									: isCreateLinkedPageOpened && <ConfluencePageCreateLinkView />}

								{fg('jira_ai_powered_issue_related_confluence_resources') ? (
									canShowJiraAISuggestions &&
									isAISuggestionsOpen && (
										<Box
											paddingBlockStart={
												expVal(
													'jira_suggest_confluence_pages_when_linking_a_page',
													'isEnabled',
													false,
												)
													? 'space.100'
													: 'space.0'
											}
										>
											<JiraEntryPointContainer
												entryPointReferenceSubject={entryPoint.entryPointReferenceSubject}
												id="related-resources-entry-point"
												packageName="jiraAiRelatedResources"
												fallback={null}
												runtimeProps={runtimeProps}
											/>
										</Box>
									)
								) : (
									<></>
								)}
								{canEnableRelatedContentPanel &&
								expVal('suggested_incident_resources_v2', 'isEnabled', false) ? (
									<IncidentSuggestionPanelContainer
										linkedPages={linkedPages || []}
										suggestionPanelRef={incidentSuggestionPanelRef}
									/>
								) : null}
							</>
						) : (
							<>
								{/* grw-1287-persistent-cross-join-nudge */}
								{projectType === SOFTWARE_PROJECT &&
									isIssueTypeEligiblePersistentCrossJoin &&
									fg('grw-1287-persistent-cross-join-nudge-gate') && (
										<UFOSegment name="persistent-cross-join-nudge">
											<JSErrorBoundary
												id="persistent-cross-join-nudge"
												packageName="persitentCrossJoinNudge"
												teamName="Groot (Growth)"
												fallback="unmount"
											>
												<PersistentCrossJoinNudge
													issueType={issueType}
													projectName={projectName}
													onAddButtonClicked={onAddButtonClicked}
													hasActivatedConfluenceWithinFourteenDays={
														hasActivatedConfluenceWithinFourteenDays
													}
													numberOfLinkedPages={
														linkedPagesCachedCount + linkedWhiteboardsCachedCount
													}
												/>
											</JSErrorBoundary>
										</UFOSegment>
									)}
							</>
						)}
					</AdControlProvider>
				</JSErrorBoundary>
			</UFOSegment>
		);
	},
);

ConfluenceContentV2.defaultProps = {
	isConfluencePageLinksEnabled: false,
	linkedPagesCachedCount: 0,
	linkedWhiteboardsCachedCount: 0,
	mentionedPagesCachedCount: 0,
};

const ConfluenceContentWithFragment = (
	props: ConfluenceContentV2Props & {
		issueFragment: confluenceContentV2_issueViewBase_ConfluenceContentWithFragment$key;
	},
) => {
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<confluenceContentV2_issueViewBase_ConfluenceContentWithFragment$key>(
		graphql`
			fragment confluenceContentV2_issueViewBase_ConfluenceContentWithFragment on JiraIssue
			@argumentDefinitions(first: { type: "Int", defaultValue: 10 }, after: { type: "String" }) {
				...mentionedConfluenceLinksSection_issueViewBase
				confluenceMentionedLinks(first: $first, after: $after) {
					totalCount
				}
			}
		`,
		props.issueFragment,
	);

	// The mentioned pages count is used to determine to show the confluence content panel
	const mentionedPagesCount = data?.confluenceMentionedLinks?.totalCount ?? 0;

	return (
		<ConfluenceContentV2
			{...props}
			issueFragment={data}
			mentionedPagesCount={mentionedPagesCount}
		/>
	);
};

const ConfluenceContentV2WithContainersNew = ({
	issueFragment,
	...props
}: ConfluenceContentV2Props & {
	issueFragment?: confluenceContentV2_issueViewBase_ConfluenceContentWithFragment$key;
}) => (
	<UFOPlaceholder name="confluence-content" fallback={null}>
		<RelatedResourcesContainer>
			{issueFragment != null && (
				<ConfluenceContentWithFragment {...props} issueFragment={issueFragment} />
			)}
		</RelatedResourcesContainer>
	</UFOPlaceholder>
);

/**
 * This is required since inside ConfluenceContent we access the related resources context to get the hostNamesFromApplinks
 */
const ConfluenceContentV2WithContainers = (props: ConfluenceContentV2Props) => (
	<RelatedResourcesContainer>
		<ConfluenceContentV2 {...props} />
	</RelatedResourcesContainer>
);

export default componentWithCondition(
	() => fg('jiv-19125-fetch-remote-links-in-interactive-query'),
	ConfluenceContentV2WithContainersNew,
	ConfluenceContentV2WithContainers,
);

const sectionHeadingWrapperStyles = xcss({
	paddingBottom: 'space.100',
});

const containerWrapperStyles = xcss({
	marginTop: 'space.100',
});

// 1px padding to the right to be aligned with the add comment box because of the shadow around it
const groupContainerStyles = xcss({
	marginTop: 'space.100',
	marginRight: '0',
	marginBottom: 'space.100',
	marginLeft: '0',
	paddingRight: 'space.025',
});

const sectionHeadingTitleWrapper = xcss({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: 'space.075',
});
