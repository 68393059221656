import React, { useRef } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type { number_issueFieldNumberReadviewFull_NumberReadView$key as NumberFragment } from '@atlassian/jira-relay/src/__generated__/number_issueFieldNumberReadviewFull_NumberReadView.graphql';
import type { Props } from './types.tsx';

export const NumberReadView = ({
	fragmentRef,
	isSingleRowHeightEnabled = false,
	renderHoverPopover,
}: Props) => {
	const { formatNumber } = useIntl();
	const contentRef = useRef<HTMLElement | null>(null);

	const data = useFragment<NumberFragment>(
		graphql`
			fragment number_issueFieldNumberReadviewFull_NumberReadView on JiraNumberField {
				number
			}
		`,
		fragmentRef,
	);

	const { number } = data;
	const children = number != null ? formatNumber(number) : <EmptyFieldText />;

	if (isSingleRowHeightEnabled && renderHoverPopover) {
		return renderHoverPopover({
			content: formatNumber(number ?? 0),
			children: (
				<Text
					testId="issue-field-number-readview-full.ui.number.text"
					ref={contentRef}
					maxLines={1}
				>
					{children}
				</Text>
			),
			contentRef,
		});
	}

	return <span data-testid="issue-field-number-readview-full.ui.number.span">{children}</span>;
};
