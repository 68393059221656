import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { PlaceholderTemplateError } from '../placeholder-template-error.tsx';

export interface Page {
	id: string;
	title: string;
	sourceTemplateEntityId?: string;
	_type: string;
	_links: {
		webui: string;
	};
}

export const getPagesBySpaceId = async (
	cloudId: string,
	spaceId: string,
): Promise<Page[] | null> => {
	try {
		const api = `/gateway/api/ex/confluence/${cloudId}/wiki/api/v2/spaces/${spaceId}/pages`;
		const pagesData = await performGetRequest(api);

		return pagesData.results || null;
	} catch (error: unknown) {
		throw new PlaceholderTemplateError(
			'Failed to fetch pages',
			'getPagesBySpaceId',
			error instanceof FetchError ? error.statusCode : undefined,
			error instanceof FetchError ? error.traceId : undefined,
		);
	}
};
