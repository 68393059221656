import { useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useCustomNavBarColorWarnings } from '@atlassian/jira-custom-nav-bar-colors-flag/src/controllers/index.tsx';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import type { view_userPreferenceDataStore$key } from '@atlassian/jira-relay/src/__generated__/view_userPreferenceDataStore.graphql';

export const UserPreferencesDataStore = ({
	rootRelayFragment,
}: {
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
}) => {
	const [, actions] = useCustomNavBarColorWarnings();
	const data = useFragment<view_userPreferenceDataStore$key>(
		graphql`
			fragment view_userPreferenceDataStore on JiraQuery {
				userPreferences(cloudId: $cloudId) {
					isCustomNavBarThemeFlagDismissed
					isCustomNavBarThemeSectionMessageDismissed
				}
			}
		`,
		rootRelayFragment?.jira || null,
	);

	useEffect(() => {
		actions.setIsLoadingWithRelay();
	}, [actions]);

	useEffect(() => {
		actions.setFlagIsDismissed(
			Boolean(data?.userPreferences?.isCustomNavBarThemeFlagDismissed),
			false,
		);
		actions.setMessageIsDismissed(
			Boolean(data?.userPreferences?.isCustomNavBarThemeSectionMessageDismissed),
			false,
		);
	}, [
		data?.userPreferences?.isCustomNavBarThemeFlagDismissed,
		data?.userPreferences?.isCustomNavBarThemeSectionMessageDismissed,
		actions,
	]);

	return null;
};
