import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { DraftConfluencePage } from './confluence-create-draft.tsx';

export const createConfluenceBlankDraft = async (
	spaceKey: string,
): Promise<DraftConfluencePage | null> => {
	try {
		const response = await fetchJson('/wiki/rest/api/content', {
			method: 'POST',
			body: JSON.stringify({
				type: 'page',
				status: 'draft',
				space: {
					key: spaceKey,
				},
				metadata: {
					properties: {
						editor: {
							value: 'v2',
						},
					},
					frontend: {
						embedded: true,
					},
				},
			}),
		});
		return {
			contentId: response.id,
			draftShareId: response._links.edituiv2?.match(/draftShareId=(.*?)(#|&|$)/)?.[1],
		};
	} catch (error: unknown) {
		fireErrorAnalytics({
			meta: {
				id: 'jira-issue-content-placeholder-template/confluence-create-blank-draft',
				packageName: '@atlassian/jira-issue-content-placeholder-template',
				teamName: 'starburst',
			},
			attributes: { errorCode: error instanceof FetchError ? error.statusCode : null },
			error: {
				name: 'error',
				message: 'Unable to create a blank Confluence draft',
			},
		});
		return null;
	}
};
