import React from 'react';
import type { Store } from 'redux';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { ConnectedComponent } from 'react-redux--next/es';
import { graphql, useFragment } from 'react-relay';
import type { ReactReduxContextInstance } from 'react-redux--next/es/components/Context';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { GIVE_FEEDBACK } from '@atlassian/jira-issue-view-common-constants/src/view-constants.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { useIsConcealActionsInMeatballExpEnabled } from '@atlassian/jira-is-conceal-actions-experiment-enabled/src/useIsConcealActionsInMeatballExpEnabled.tsx';
import { IssueViewSecurityLevelField } from '@atlassian/jira-issue-view-layout-security-level-field/src/ui/index.tsx';
import type { FeedbackRenderer } from '@atlassian/jira-issue-view-model/src/feedback-type.tsx';
import type { ViewModeOptions } from '@atlassian/jira-issue-view-model/src/view-mode-options.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { applicationKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { Watchers } from '@atlassian/jira-issue-view-watchers/src/header/index.tsx';
import type { headerActions_issueViewFoundation_HeaderActionsView_root$key } from '@atlassian/jira-relay/src/__generated__/headerActions_issueViewFoundation_HeaderActionsView_root.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	useIssueKey,
	useProjectKey as useProjectKeyDeprecated,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useIsSimplifiedProject,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useVoteField } from '@atlassian/jira-issue-field-voters/src/services/vote-field-service/index.tsx';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import type {
	headerActions_issueViewFoundation_HeaderActionsView_headerActions$key,
	headerActions_issueViewFoundation_HeaderActionsView_headerActions$data,
} from '@atlassian/jira-relay/src/__generated__/headerActions_issueViewFoundation_HeaderActionsView_headerActions.graphql.ts';
import { useIssueLayoutActivitySidePanel } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import type { IssueDeleteCallbacks } from '../../issue-actions/delete-issue/types.tsx';
import IssueActions from '../../issue-actions/index.tsx';
import ModalActionsCloseButton from '../../modal-close-button.tsx';
import { IssueRestriction } from '../issue-restriction/index.tsx';
import { SecurityLevelIcon } from '../security-level/index.tsx';
import { ShareButton } from '../share-button/index.tsx';
import { Voters } from '../voters/index.tsx';
import { ActivityLayoutSelector } from '../activity-layout-preferences/index.tsx';
import { RightAligned } from './styled.tsx';

type HeaderActionsViewProps = {
	issueDeleteCallbacks?: IssueDeleteCallbacks;
	shouldShowCloseButton?: boolean;
	error?: string | null;
	applicationKey: string;
	renderFeedback?: FeedbackRenderer;
	viewModeOptions?: ViewModeOptions;
	onClose?: (event?: React.MouseEvent | React.KeyboardEvent) => void;
	headerActions?: headerActions_issueViewFoundation_HeaderActionsView_headerActions$key | null;
	rootRelayFragment?: MainIssueAggQueryRelayFragment | null;
};

type SecurityLevelFieldProps = {
	data: headerActions_issueViewFoundation_HeaderActionsView_headerActions$data | null;
};

type VotersFieldProps = {
	data: headerActions_issueViewFoundation_HeaderActionsView_headerActions$data | null;
};

const SecurityLevelField = ({ data }: SecurityLevelFieldProps) => {
	return data && fg('relay-migration-issue-header-and-parent') ? (
		<IssueViewSecurityLevelField securityLevelField={data} />
	) : (
		<SecurityLevelIcon
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.foundation.header.header-actions.security-level-icon"
		/>
	);
};

const VotersFieldNew = ({ data }: VotersFieldProps) => {
	const [{ value: votesValue }] = useVoteField({});
	const votesCountReduxVersion = votesValue.votes;
	if (fg('relay-migration-issue-header-and-parent')) {
		const votesCountRelayVersion = data?.votesField?.vote?.count;
		return votesCountRelayVersion ? <Voters votesField={data?.votesField} /> : null;
	}
	return votesCountReduxVersion ? <Voters votesField={data?.votesField} /> : null;
};

export const HeaderActionsView: {
	({
		onClose,
		renderFeedback,
		shouldShowCloseButton,
		issueDeleteCallbacks,
		viewModeOptions,
		applicationKey,
		headerActions,
		rootRelayFragment,
	}: HeaderActionsViewProps): React.JSX.Element;
	displayName: string;
} = ({
	onClose = noop,
	renderFeedback = () => null,
	shouldShowCloseButton = false,
	issueDeleteCallbacks = {},
	viewModeOptions = { viewModeSwitchEnabled: false },
	applicationKey,
	headerActions,
	rootRelayFragment,
}: HeaderActionsViewProps): React.JSX.Element => {
	const data =
		headerActions && fg('relay-migration-issue-header-and-parent')
			? // eslint-disable-next-line @atlassian/relay/query-restriction, react-hooks/rules-of-hooks
				useFragment<headerActions_issueViewFoundation_HeaderActionsView_headerActions$key>(
					graphql`
						fragment headerActions_issueViewFoundation_HeaderActionsView_headerActions on JiraIssue
						@argumentDefinitions(
							issueViewRelayWatchersFieldFlag: {
								type: "Boolean!"
								provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-watchers.relayprovider"
							}
							issueViewRelaySecurityLevelFieldFlag: {
								type: "Boolean!"
								provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-security-level.relayprovider"
							}
							issueViewRelayVotesFieldFlag: {
								type: "Boolean!"
								provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-votes.relayprovider"
							}
							issueViewRelayIssueRestrictionsFieldFlag: {
								type: "Boolean!"
								provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-issue-restrictions.relayprovider"
							}
							issueViewRelayConfigurationUrlFlag: {
								type: "Boolean!"
								provider: "@atlassian/jira-relay-provider/src/relay-migration-configuration-url.relayprovider"
							}
							issueViewRelayConcealItemsIntoMeatballMenuExperimentFlag: {
								type: "Boolean!"
								provider: "@atlassian/jira-relay-provider/src/conceal-items-into-meatball-menu-experiment.relayprovider.tsx"
							}
						) {
							...ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField
								@include(if: $issueViewRelaySecurityLevelFieldFlag)

							watchesField @include(if: $issueViewRelayWatchersFieldFlag) {
								...header_issueViewWatchers_WatchersItem
							}
							votesField @include(if: $issueViewRelayVotesFieldFlag) {
								...voters_issueViewFoundation_VotersItemNew
								vote @include(if: $issueViewRelayConcealItemsIntoMeatballMenuExperimentFlag) {
									count
								}
								...main_issueViewFoundation_VoteButtonRelay
									@include(if: $issueViewRelayConcealItemsIntoMeatballMenuExperimentFlag)
							}
							...issueActions_issueViewFoundation_IssueActionsMenuWithRelay_issueViewRelayFragment
								@include(if: $issueViewRelayConfigurationUrlFlag)
							...issueRestriction_issueViewFoundation_IssueRestrictionWrapper
								@include(if: $issueViewRelayIssueRestrictionsFieldFlag)
						}
					`,
					headerActions,
				)
			: null;

	// eslint-disable-next-line @atlassian/relay/query-restriction
	const root = useFragment<headerActions_issueViewFoundation_HeaderActionsView_root$key>(
		graphql`
			fragment headerActions_issueViewFoundation_HeaderActionsView_root on JiraQuery
			@argumentDefinitions(
				issueViewRelayConfigurationUrlFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-configuration-url.relayprovider"
				}
			) {
				...issueActions_issueViewFoundation_IssueActionsMenuWithRelay_root
					@include(if: $issueViewRelayConfigurationUrlFlag)
			}
		`,
		rootRelayFragment?.jira || null,
	);

	let isSimplifiedProject = false;
	if (fg('ken-1869-bug-fix-for-double-lock-icon-in-tmp')) {
		/* eslint-disable react-hooks/rules-of-hooks */
		if (fg('ken-2285-use-new-import-for-useprojectkey')) {
			const issueKey = useIssueKey();
			const projectKey = useProjectKey(issueKey);
			isSimplifiedProject = useIsSimplifiedProject(projectKey);
		} else {
			const projectKey = useProjectKeyDeprecated();
			isSimplifiedProject = useIsSimplifiedProject(projectKey);
		}
		/* eslint-enable react-hooks/rules-of-hooks */
	}

	let feedbackComponent;
	let feedback;
	if (renderFeedback) {
		feedback = renderFeedback(applicationKey, true);
		if (feedback != null) {
			feedbackComponent = (
				<SpotlightTarget name={GIVE_FEEDBACK}>
					<FeedbackWrapper>{feedback}</FeedbackWrapper>
				</SpotlightTarget>
			);
		}
	}

	const isConcealActionsExpEnabled = useIsConcealActionsInMeatballExpEnabled();
	const { isInSidePanelExperiment } = useIssueLayoutActivitySidePanel();

	const HeaderActionItems = (
		<>
			{isInSidePanelExperiment ? <ActivityLayoutSelector /> : null}
			{!isConcealActionsExpEnabled ? feedbackComponent : null}
			{!isSimplifiedProject ? <SecurityLevelField data={data} /> : null}
			<IssueRestriction fragmentKey={data} />
			<Watchers watches={data?.watchesField || undefined} />
			{isConcealActionsExpEnabled && <VotersFieldNew data={data} />}
			{!isConcealActionsExpEnabled && <Voters votesField={data?.votesField} />}
			<ShareButton />
			<IssueActions
				issueViewRelayFragment={data}
				{...(isConcealActionsExpEnabled ? { votesField: data?.votesField } : {})}
				issueDeleteCallbacks={issueDeleteCallbacks}
				viewModeOptions={viewModeOptions}
				onClose={onClose}
				shouldShowFeedback={Boolean(feedback)}
				rootRelayFragment={root}
			/>
			{shouldShowCloseButton ? <ModalActionsCloseButton onClose={onClose} /> : null}
		</>
	);

	return (
		<HeaderWrapper>
			{isVisualRefreshEnabled() ? (
				<Inline as="div" space="space.100" shouldWrap={false} alignInline="end">
					{HeaderActionItems}
				</Inline>
			) : (
				<UnselectableRightAligned>{HeaderActionItems}</UnselectableRightAligned>
			)}
		</HeaderWrapper>
	);
};
HeaderActionsView.displayName = 'HeaderActionsView';

export const HeaderActions: ConnectedComponent<
	{
		({
			onClose,
			renderFeedback,
			shouldShowCloseButton,
			issueDeleteCallbacks,
			viewModeOptions,
			applicationKey,
			headerActions,
			rootRelayFragment,
		}: HeaderActionsViewProps): React.JSX.Element;
		displayName: string;
	},
	{
		onClose?: ((event?: React.MouseEvent | React.KeyboardEvent) => void) | undefined;
		renderFeedback?: FeedbackRenderer | undefined;
		shouldShowCloseButton?: boolean | undefined;
		issueDeleteCallbacks?: IssueDeleteCallbacks | undefined;
		viewModeOptions?: ViewModeOptions | undefined;
		headerActions?:
			| (headerActions_issueViewFoundation_HeaderActionsView_headerActions$key | null)
			| undefined;
		rootRelayFragment?: (MainIssueAggQueryRelayFragment | null) | undefined;
		error?: string | null | undefined;
		context?: ReactReduxContextInstance | undefined;
		store?: Store | undefined;
	}
> = flowWithSafeComponent(
	connect(
		(state: State) => ({
			// FIXME: JIV-17455. This field can be null
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			applicationKey: applicationKeySelector(state)!,
		}),
		{},
	),
)(HeaderActionsView);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${gridSize * 4}px`,
	marginRight: 0,
	marginLeft: 'auto',
	marginBottom: token('space.025'),
	height: '100%',
	alignItems: 'center',
	maxWidth: '100%',
	display: 'flex',
	justifyContent: 'flex-end',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UnselectableRightAligned = styled(RightAligned)({
	userSelect: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FeedbackWrapper = styled.div({
	flexShrink: 0,
});
