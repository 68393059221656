import { createLocalExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';

export const localStorageManager = (
	propertyKey: string,
	storageKey: string,
	segmentId?: string,
) => {
	const localStorageProvider = createLocalExpirableStorageProvider(storageKey);
	const STORAGE_KEY = segmentId ? `${propertyKey}_${segmentId}` : propertyKey;
	const getLocalStorage = () => localStorageProvider.get(STORAGE_KEY);
	const LOCAL_STORAGE_EXPIRY_MS = 24 * 60 * 60 * 1000; // one day
	const setLocalStorage = (value: boolean) =>
		localStorageProvider.set(STORAGE_KEY, value, Date.now() + LOCAL_STORAGE_EXPIRY_MS);

	return { getLocalStorage, setLocalStorage };
};
