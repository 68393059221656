import type { FieldMap } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';

// Returns formula and formulaBackup for SFG migration reasons
// only when the project has both project and global fields
// otherwise simply returns the same formula
export const getSFGDualWriteFieldFormula = (
	fields: FieldMap,
	formula: DynamicFieldFormula,
	hasBackup: boolean,
): {
	formula: DynamicFieldFormula;
	formulaBackup?: DynamicFieldFormula;
} => {
	if (!fg('jpd-sfg-dualwrite-field-formula')) return { formula };

	const fieldArr = Object.values(fields);

	const globalInsightsFieldKey = fieldArr.find(
		(f) => f.type === FIELD_TYPES.INSIGHTS && f.global,
	)?.key;
	const globalLinkedIssuesFieldKey = fieldArr.find(
		(f) => f.type === FIELD_TYPES.LINKED_ISSUES && f.global,
	)?.key;

	const projectInsightsFieldKey = fieldArr.find(
		(f) => f.type === FIELD_TYPES.INSIGHTS && !f.global,
	)?.key;
	const projectLinkedIssuesFieldKey = fieldArr.find(
		(f) => f.type === FIELD_TYPES.LINKED_ISSUES && !f.global,
	)?.key;

	// if project or global system fields are missing, we don't dual write
	// we only dual write when the project has BOTH
	// project and global insights and linked issues fields
	if (
		!globalInsightsFieldKey ||
		!globalLinkedIssuesFieldKey ||
		!projectInsightsFieldKey ||
		!projectLinkedIssuesFieldKey
	) {
		return {
			formula,
		};
	}

	const formulaStr = JSON.stringify(formula);

	// replace project scoped fields with global fields
	const newFormulaStr = JSON.stringify(formula, (key, value) => {
		if (key !== 'id') return value;

		if (value === projectInsightsFieldKey) {
			return globalInsightsFieldKey;
		}

		if (value === projectLinkedIssuesFieldKey) {
			return globalLinkedIssuesFieldKey;
		}

		return value;
	});

	// if there is no change, and the field didn't have a backup formula already, then no need to calculate the backup
	if (!hasBackup && formulaStr === newFormulaStr) {
		return {
			formula,
		};
	}

	// backup formula with project fields - replace global fields with project fields
	const formulaBackup = JSON.stringify(formula, (key, value) => {
		if (key !== 'id') return value;

		if (value === globalInsightsFieldKey) {
			return projectInsightsFieldKey;
		}

		if (value === globalLinkedIssuesFieldKey) {
			return projectLinkedIssuesFieldKey;
		}

		return value;
	});

	return {
		formula: JSON.parse(newFormulaStr),
		formulaBackup: JSON.parse(formulaBackup),
	};
};
