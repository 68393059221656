import { useCallback, useRef } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { downloadBlob } from '@atlassian/jira-file-download/src/index.tsx';
import messages from '../messages.tsx';
import { useDownloadStatus } from './context.tsx';

export const useDownloadAllService = (issueId: IssueId, issueKey: IssueKey) => {
	const fileName = `${issueKey}_attachments.zip`;
	const [isDownloading, { setIsDownloading }] = useDownloadStatus(issueKey);
	const requestUrl = `/secure/issueAttachments/${issueId}.zip`;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag, dismissFlag } = useFlagsService();
	const flagErrorId = useRef<unknown>();

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	let onTryAgainClicked = useCallback(() => {}, []);

	const onDismissFlag = useCallback(() => {
		if (flagErrorId.current) {
			// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'string'.
			dismissFlag(flagErrorId.current);
		}
	}, [dismissFlag]);

	const onFailure = useCallback(
		(error: Error, locationId: string): void => {
			let statusCode = null;
			let traceId = null;
			let errorMessage = error.toString();

			if (error instanceof FetchError) {
				statusCode = error.statusCode;
				errorMessage = error.message;
				traceId = error.traceId;
			}

			setIsDownloading(issueKey, false);
			fireErrorAnalytics({
				error,
				meta: {
					id: locationId,
					packageName: 'jiraIssueAttachmentsBase',
					teamName: 'bento',
				},
				attributes: {
					statusCode,
					traceId,
				},
			});
			log.safeErrorWithoutCustomerData('issue.attachments.download-all', errorMessage);
			const flagErrorMessage =
				statusCode === 500
					? messages.downloadFailedExceedFileSizeDescription
					: messages.downloadFailedDescription;

			flagErrorId.current = showFlag({
				messageId: 'issue-attachments-base.services.download.show-flag.error',
				messageType: 'transactional',
				type: 'error',
				title: messages.downloadFailedTitle,
				description: [flagErrorMessage, { issueKey }],
				actions: [
					{
						onClick: onTryAgainClicked,
						content: messages.tryAgain,
					},
				],
			});
		},
		[issueKey, onTryAgainClicked, setIsDownloading, showFlag],
	);

	const makeDownloadRequest = useCallback(async () => {
		setIsDownloading(issueKey, true);
		fireOperationalAnalytics(createAnalyticsEvent({}), 'downloadAll requested');
		showFlag({
			messageId: 'issue-attachments-base.services.download.show-flag.success',
			messageType: 'transactional',
			type: 'success',
			title: messages.downloadStartTitle,
			description: messages.downloadStartDescription,
			isAutoDismiss: true,
		});

		fetch(requestUrl)
			.then((response) => {
				if (!response.ok) {
					throw new FetchError(
						response.status,
						response.statusText,
						response.headers.get('Atl-Traceid') || undefined,
					);
				}
				fireOperationalAnalytics(createAnalyticsEvent({}), 'downloadAll started');
				return response.blob();
			})
			.then((blob) => {
				try {
					downloadBlob(blob, fileName);
					setIsDownloading(issueKey, false);
					fireOperationalAnalytics(createAnalyticsEvent({}), 'downloadAll completed');
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					onFailure(error, 'useDownloadAllService-save-file');
				}
			})
			.catch((error) => onFailure(error, 'useDownloadAllService-fetch'));
	}, [createAnalyticsEvent, fileName, issueKey, onFailure, requestUrl, setIsDownloading, showFlag]);

	onTryAgainClicked = useCallback(() => {
		makeDownloadRequest();
		onDismissFlag();
	}, [makeDownloadRequest, onDismissFlag]);

	return [isDownloading, makeDownloadRequest] as const;
};
