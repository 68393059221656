export const PACKAGE_NAME = 'jira-issue-field-status';
export const POPUP_ID = 'issue-field-status-view-actions-menu-popup';
export const RECUR_WORK_COMPONENT_TEST_ID_PREFIX =
	'issue-field-status.ui.status-view.actions.actions-menu';

export const POPUP_KEYS = {
	recurWork: 'RECUR_WORK',
};

export type PopupKeys = (typeof POPUP_KEYS)[keyof typeof POPUP_KEYS];
