import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { BaseUrl, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (projectId: ProjectId) =>
	performGetRequest(`/rest/internal/2/project/${projectId}/hasValidatorsByIssueType`);

/**
 * @deprecated Unused code
 */
export const fetchHasValidatorsOnAnyIssueTypes = (projectId: ProjectId) =>
	performGetRequest(`/rest/internal/2/project/${projectId}/hasValidatorsOnAnyIssueTypes`);

/**
 * @deprecated Unused code
 */
export const fetchHasValidatorsOnSubtasks = (baseUrl: BaseUrl, projectId: ProjectId) =>
	performGetRequest(`${baseUrl}/rest/internal/2/project/${projectId}/hasValidatorsOnSubtasks`);
