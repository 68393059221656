import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { generateChildIssuesJQL } from '../../../../common/generate-child-issues-jql/index.tsx';
import type { ChildIssuesPanelType, SortingOption } from '../../../../model/types.tsx';

export const triggerButtonTestId =
	'issue.views.common.child-issues-panel.heading.meatballs.dropdown.trigger-button';

const BULK_EDIT_URL = '/secure/views/bulkedit/BulkEdit1!default.jspa?reset=true&new=true';

export const generateBulkEditLink = (
	parentKey: IssueKey,
	childIssuesPanelType: ChildIssuesPanelType,
	hasExceededIssuesLimitInitialLoad: boolean,
	sortByValue?: SortingOption,
) =>
	`${BULK_EDIT_URL}&${generateChildIssuesJQL(
		parentKey,
		childIssuesPanelType,
		hasExceededIssuesLimitInitialLoad,
		sortByValue,
	)}`;
