import React, { useRef, useEffect, memo } from 'react';
import { useAIAnalyticsContext } from '@atlassian/jira-instrumentation-common/src/ai-analytics-context/context.tsx';
import { AI_EVENTS } from '@atlassian/jira-instrumentation-common/src/ai-analytics-context/common.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import {
	getAiExperienceName,
	getAiSuggestedActionValues,
	getAiSuggestedActions,
} from '../../common/analytics.tsx';
import type { AiContextServiceResource } from '../../common/types.tsx';

type Props = {
	children: React.ReactNode;
	resource: AiContextServiceResource;
};

const ServiceAIAnalyticsWrapper = ({ children, resource }: Props) => {
	const { fireTrackAI } = useAIAnalyticsContext();
	const aiResultViewEvent = useRef(false);
	const { error, data } = resource;

	useEffect(() => {
		fireTrackAI(AI_EVENTS.INIT);
	}, [fireTrackAI]);

	useEffect(() => {
		// Here we need to use ref to track if view event has been fired,
		// because when we do an action - accept or dismiss we change the data and this block will be called again
		if (data && !aiResultViewEvent.current) {
			fireTrackAI(AI_EVENTS.VIEWED, {
				doesNotMeetMAUCriteria: true,
				aiExperienceName: getAiExperienceName(data),
				aiSuggestedActions: getAiSuggestedActions(data),
				aiSuggestedActionValues: getAiSuggestedActionValues(data),
				traceIds: data?.traceIds?.join(','),
			});
			aiResultViewEvent.current = true;
		}
	}, [data, fireTrackAI]);

	useEffect(() => {
		if (error) {
			fireTrackAI(AI_EVENTS.ERROR, {
				aiErrorMessage: error.message || 'Error loading AI context',
				aiErrorCode: 'statusCode' in error ? error.statusCode : 500,
				traceIds: error instanceof FetchError ? error.traceId : undefined,
			});
		}
	}, [error, fireTrackAI]);

	return <>{children}</>;
};

export default memo(ServiceAIAnalyticsWrapper);
