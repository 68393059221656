import { useCallback, useEffect, useState } from 'react';
import { useCrossFlow } from '@atlassiansox/cross-flow-component-support';
import { Targets } from '@atlassiansox/cross-flow-support';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFireCrossFlowAnalyticsEvents as useFireCrossFlowAnalyticsEventsBase } from '@atlassian/surface-analytics';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from '../messages.tsx';
import { PlaceholderTemplateError } from '../placeholder-template-error.tsx';
import { dismissConfluencePlaceholderTemplate } from '../services/ad-controls.tsx';
import type { Sources } from '../common.tsx';
import type { ConfluenceTemplate } from './confluence-templates.tsx';
import type { PlaceholderTargeting } from './targeting.tsx';
import { useSpaceCreation } from './confluence-create-space.tsx';
import { usePlaceholderNudge } from './placeholder-template-nudge.tsx';
import { openCffeForConfluence } from './cross-flow-front-end.tsx';
import { hasLocalStorageRequestedTrial } from './confluence-requested-trial.tsx';

export type PlaceholderUiProps = {
	onClick: () => Promise<void>;
	onFeatureExposed?: () => void;
	templateMessage: MessageDescriptor;
	lozengeMessage: MessageDescriptor;
	loadingMessage?: MessageDescriptor;
	testId: string;
	embeddedEditor?: {
		isOpen: boolean;
		url: string;
		onClose: () => void;
		onPublish: () => void;
	};
	backgroundProvisioningEditor?: {
		onClose: () => void;
	};
};

export const usePlaceholderUiProps = (
	targeting: PlaceholderTargeting,
	template: ConfluenceTemplate | undefined,
	projectName: string | undefined,
	isConfluenceRecentlyProvisioned: boolean,
	source: Sources,
	projectKey?: string,
): PlaceholderUiProps | undefined => {
	const crossFlow = useCrossFlow();
	const issueKey = useIssueKey();
	const cloudId = useCloudId();
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();
	const createSpace = useSpaceCreation(cloudId, projectName || '', template);
	const accountId = useAccountId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const showPlaceholderNudge = usePlaceholderNudge();
	const [hasRequestedTrial, setHasRequestedTrial] = useState(() =>
		hasLocalStorageRequestedTrial(cloudId),
	);
	const [embeddedEditor, setEmbeddedEditor] = useState({ isOpen: false, url: '', spaceKey: '' });
	const [isSpaceCreating, setIsSpaceCreating] = useState(false);

	const { fireInteractedUIEvent, fireViewedScreenEvent } = useFireCrossFlowAnalyticsEventsBase();
	const firePlaceholderClickedUiEvent = useCallback(
		(experiment: string) => {
			fireInteractedUIEvent(
				{
					action: 'clicked',
					actionSubject: 'link',
					actionSubjectId: 'confluencePlaceholderTemplateLink',
				},
				{
					targetProductId: Targets.CONFLUENCE,
					recommendedProductIds: [Targets.CONFLUENCE],
					source: 'confluencePlaceholderTemplateScreen',
				},
				{
					experiment, // Required for attribution
				},
			);
		},
		[fireInteractedUIEvent],
	);
	const firePlaceholderViewedScreenEvent = useCallback(
		(experiment: string) => {
			fireViewedScreenEvent(
				'confluencePlaceholderTemplateScreen',
				{
					recommendedProductIds: [Targets.CONFLUENCE],
				},
				{
					experiment,
				},
			);
		},
		[fireViewedScreenEvent],
	);
	// TODO: Remove track event once co-use attribution query has been updated to use link clicked event
	const fireCoUseExposureEvent = useCallback(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'placeholderTemplateCoUse exposed', {
			experiment: 'placeholderTemplateAsOnboardingTouchpoint',
		});
	}, [createAnalyticsEvent]);
	const onCoUseFeatureExposed = useCallback(() => {
		fireCoUseExposureEvent();
		firePlaceholderViewedScreenEvent('placeholderTemplateAsOnboardingTouchpoint');
	}, [fireCoUseExposureEvent, firePlaceholderViewedScreenEvent]);

	const openDraft = useCallback(async () => {
		try {
			setIsSpaceCreating(true);

			const activePage = await createSpace();

			if (activePage?.pageUrl) {
				setEmbeddedEditor({ isOpen: true, url: activePage.pageUrl, spaceKey: activePage.spaceKey });
			} else {
				throw Error('Did not get a page URL after creating a space');
			}
		} catch (error: unknown) {
			setIsSpaceCreating(false);
			showFlag({
				messageId: 'issue-content-placeholder-template.controllers.show-flag.error',
				messageType: 'transactional',
				type: 'error',
				isAutoDismiss: true,
				title: formatMessage(messages.pageLoadFailFlagTitle),
				description: formatMessage(messages.pageLoadFailFlagDescription),
			});
			fireErrorAnalytics({
				meta: {
					id: 'confluencePlaceholderOnClick',
					packageName: '@atlassian/jira-issue-content-placeholder-template',
					teamName: 'bento',
				},
				attributes: {
					message: error instanceof PlaceholderTemplateError ? error.message : null,
					statusCode: error instanceof PlaceholderTemplateError ? error.statusCode : null,
					traceId: error instanceof PlaceholderTemplateError ? error.traceId : null,
				},
			});
		}
	}, [createSpace, formatMessage, showFlag]);

	const closeEmbeddedEditor = useCallback(async () => {
		setIsSpaceCreating(false);
		if (fg('grw_1277_placeholder_template_nudge_gate')) {
			await showPlaceholderNudge(cloudId, `/wiki/spaces/${embeddedEditor.spaceKey}`);
		}
		setEmbeddedEditor({ isOpen: false, url: '', spaceKey: '' });
	}, [
		cloudId,
		showPlaceholderNudge,
		setIsSpaceCreating,
		setEmbeddedEditor,
		embeddedEditor.spaceKey,
	]);

	useEffect(() => {
		if (!targeting || !template || !accountId || !isConfluenceRecentlyProvisioned) {
			return;
		}

		openDraft();
	}, [template, accountId, targeting, openDraft, isConfluenceRecentlyProvisioned]);

	if (!targeting || !template || !accountId) {
		return;
	}

	const issueViewExperimentalOptions = {
		jswIssueType: template && template.suggestedIssueType,
		projectName: projectName || '',
		jswIssueLink:
			typeof window !== 'undefined' ? `${window.location.origin}/browse/${issueKey}` : undefined,
	};

	const listViewExperimentalOptions = {
		projectName: projectName || '',
		listViewLink: projectKey ? `/jira/software/projects/${projectKey}/list` : undefined,
	};

	switch (targeting) {
		case 'CoUse':
			return {
				onClick: async () => {
					// prevent double clicking
					if (isSpaceCreating) return;

					firePlaceholderClickedUiEvent('placeholderTemplateAsOnboardingTouchpoint');

					await openDraft();
				},
				onFeatureExposed: onCoUseFeatureExposed,
				templateMessage: template.name,
				lozengeMessage: messages.tryTemplateLozenge,
				loadingMessage: isSpaceCreating ? messages.loadingTemplate : undefined,
				testId: 'issue-content-placeholder-template.couse',
				embeddedEditor: {
					...embeddedEditor,
					onClose: closeEmbeddedEditor,
					onPublish: () => dismissConfluencePlaceholderTemplate(accountId, targeting, source),
				},
			};
		case 'RequestTrial':
			return {
				onClick: async () => {
					firePlaceholderClickedUiEvent('placeholderTemplateEndUserRequestExperiment');

					await openCffeForConfluence(
						crossFlow,
						'confluencePlaceholderTemplateScreen',
						'confluencePlaceholderTemplateLink',
						issueViewExperimentalOptions,
					);

					setHasRequestedTrial(hasLocalStorageRequestedTrial(cloudId));
				},
				onFeatureExposed: () =>
					firePlaceholderViewedScreenEvent('placeholderTemplateEndUserRequestExperiment'),
				templateMessage: template.name,
				lozengeMessage: hasRequestedTrial
					? messages.requestSentLozenge
					: messages.requestToTryLozenge,
				testId: 'issue-content-placeholder-template.request-trial',
			};
		case 'CrossFlow-Admin': {
			let sourceComponent = 'confluencePlaceholderTemplateScreen';
			let sourceContext = 'confluencePlaceholderTemplateLink';

			if (source === 'listViewEntrypoint') {
				sourceComponent = 'listViewEntrypointScreen';
				sourceContext = 'listViewEntrypoint';
			}

			const experimentalOptions =
				source === 'listViewEntrypoint'
					? listViewExperimentalOptions
					: issueViewExperimentalOptions;

			return {
				onClick: async () => {
					firePlaceholderClickedUiEvent('placeholderTemplateE2EIteration');

					await openCffeForConfluence(
						crossFlow,
						sourceComponent,
						sourceContext,
						experimentalOptions,
					);
				},
				onFeatureExposed: () => firePlaceholderViewedScreenEvent('placeholderTemplateE2EIteration'),
				templateMessage: template.name,
				lozengeMessage: messages.tryTemplateLozenge,
				testId: 'issue-content-placeholder-template.crossflow-admin',
				backgroundProvisioningEditor: {
					onClose: () => showPlaceholderNudge(cloudId, `/wiki/spaces/${embeddedEditor.spaceKey}`),
				},
			};
		}
		case 'CrossFlow-User':
			return {
				onClick: async () => {
					firePlaceholderClickedUiEvent('issueViewEndUsersCrossFlowViaE2EExperiment');

					await openCffeForConfluence(
						crossFlow,
						'confluencePlaceholderTemplateScreen',
						'confluencePlaceholderTemplateLink',
						issueViewExperimentalOptions,
					);
				},
				onFeatureExposed: () =>
					firePlaceholderViewedScreenEvent('issueViewEndUsersCrossFlowViaE2EExperiment'),
				templateMessage: template.name,
				lozengeMessage: messages.tryTemplateLozenge,
				testId: 'issue-content-placeholder-template.crossflow-eu',
				backgroundProvisioningEditor: {
					onClose: () => showPlaceholderNudge(cloudId, `/wiki/spaces/${embeddedEditor.spaceKey}`),
				},
			};
		default:
			// the user does not meet any targeting criteria, this should not be reached
			throw new Error(`Placeholder template not implemented for ${targeting}`);
	}
};
