import React, { createContext, useCallback, useContext, useMemo, useRef, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { v4 as uuid } from 'uuid';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	AI_EVENTS_TYPE,
	AIAnalyticsContextType,
	ProviderProps,
	AttributesType,
} from './common.tsx';

const AIAnalyticsContext = createContext<AIAnalyticsContextType | null>(null);
/**
 * It's adviced to pass config object as a constant and ideally it should not change.
 * But if parent re-renders and config object changes, we'll check it's properties equality and only then update the useRef.
 */
export const AIAnalyticsProvider = ({ config, children }: ProviderProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const singleInstrumentationID = useMemo(() => uuid(), []);
	const configRef = useRef<ProviderProps['config']>(config);
	const traceIdsRef = useRef<Set<string>>(new Set());
	const addTraceId = useCallback((traceId: string) => {
		traceIdsRef.current.add(traceId);
	}, []);

	useEffect(() => {
		if (!isEqual(configRef.current, config)) {
			configRef.current = config;
		}
	}, [config]);

	const fireTrackAI = useCallback(
		(eventName: AI_EVENTS_TYPE, attr: Omit<AttributesType, 'singleInstrumentationID'> = {}) => {
			const event = createAnalyticsEvent({});
			// use this console log to verify your events
			// console.log('... here', {
			// 	eventName,
			// 	...configRef.current,
			// 	...attr,
			// 	traceIds: traceIdsRef.current,
			// });
			fireTrackAnalytics(event, eventName, {
				singleInstrumentationID,
				traceIds: [...traceIdsRef.current].join(','),
				...configRef.current,
				...attr,
			});
		},
		[createAnalyticsEvent, singleInstrumentationID],
	);

	const contextValue = useMemo(() => ({ addTraceId, fireTrackAI }), [addTraceId, fireTrackAI]);

	return <AIAnalyticsContext.Provider value={contextValue}>{children}</AIAnalyticsContext.Provider>;
};

export const useAIAnalyticsContext = () => {
	const context = useContext(AIAnalyticsContext);
	if (!context) {
		throw new Error('useAIAnalytics must be used within a AIAnalyticsProvider');
	}
	return context;
};
