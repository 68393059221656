import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showMoreFields: {
		id: 'issue-view-layout-templates-item-list.show-hide-more-fields.show-more-fields-label',
		defaultMessage: 'Show {itemsCount} more field{itemsCount, plural, one {} other {s}}',
		description: 'Button label for showing more fields in the item list',
	},

	hideFields: {
		id: 'issue-view-layout-templates-item-list.show-hide-more-fields.hide-fields-label',
		defaultMessage: 'Hide {itemsCount} field{itemsCount, plural, one {} other {s}}',
		description: 'Button label for hiding fields in the item list',
	},
});
