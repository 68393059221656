import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';

import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';

export type CommentUpdateAction = 'ADD' | 'DELETE' | 'EDIT' | 'ATTACH';
export type CommentFetchAction = 'FETCH_MORE' | 'FETCH_SORTED' | 'FETCH_SURROUNDING';
export type CommentAction = CommentUpdateAction | CommentFetchAction;

const commentActionSet = new Set<CommentAction>([
	'FETCH_MORE',
	'FETCH_SORTED',
	'FETCH_SURROUNDING',
]);

type ExperienceDescriptionInput = {
	wasSuccessful: boolean;
	action: CommentAction;
	analyticsSource: string;
	projectType?: ProjectType;
	errorMessage?: string;
	statusCode?: number;
	isEventOccurredAtSelected?: boolean;
	traceId?: string;
	commentHasMentions?: boolean;
	isRootComment?: boolean;
	hasReplies?: boolean;
};

type ExperienceDescriptionInputWithExperience = ExperienceDescriptionInput & {
	experience: 'issueComment' | 'issueCommentAttachment' | 'issueCommentFetch';
};

export const experienceDescription = ({
	experience,
	wasSuccessful,
	action,
	analyticsSource,
	projectType,
	errorMessage,
	statusCode,
	isEventOccurredAtSelected,
	traceId,
	commentHasMentions,
	isRootComment = false,
	hasReplies = false,
}: ExperienceDescriptionInputWithExperience) => {
	let application = null;
	let edition = null;

	if (projectType !== undefined) {
		try {
			const { appEditions } = getTenantContext_DEPRECATED_DO_NOT_USE();
			application = getApplicationForProject(projectType);
			edition = getEdition(application, appEditions);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			// application and edition already default to null.
		}
	}

	return {
		experience: commentActionSet.has(action) ? 'issueCommentFetch' : experience,
		wasExperienceSuccesful: wasSuccessful,
		analyticsSource,
		application,
		edition,
		additionalAttributes: {
			action,
			statusCode: typeof statusCode !== 'undefined' ? statusCode : 0,
			...(errorMessage !== undefined ? { errorMessage } : null),
			...(isEventOccurredAtSelected !== undefined ? { isEventOccurredAtSelected } : null),
			...(traceId !== undefined ? { traceId } : null),
			isRootComment,
			hasReplies,
			// commentHasMentions will be cleaned up once we clean up with threaded_comments_ga experiment
			...(commentHasMentions !== undefined ? { commentHasMentions } : {}),
		},
	};
};

export const commentExperienceDescription = (props: ExperienceDescriptionInput) =>
	experienceDescription({ ...props, experience: 'issueComment' });
export const commentAttachmentExperienceDescription = (props: ExperienceDescriptionInput) =>
	experienceDescription({ ...props, experience: 'issueCommentAttachment' });
