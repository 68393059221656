import { JiraIssueSearchViewAri } from '@atlassian/ari/jira/issue-search-view';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { FlagConfiguration } from '@atlassian/jira-flags/src/services/types'; // ignore-for-ENGHEALTH-17759
import type {
	OptionResponse,
	SelectOption,
} from '@atlassian/jira-jql-builder-basic-picker/src/common/types.tsx';
import { MAX_COLUMNS as MAX_AMOUNT_OF_COLUMNS } from '@atlassian/jira-native-issue-table/src/common/constants.tsx';
import { KNOWN_COLUMN_TYPES } from '@atlassian/jira-issue-table-common/src/constants.tsx';
import type {
	ColumnConfigOverrideMap,
	SortOrder,
	ColumnType,
} from '@atlassian/jira-native-issue-table/src/common/types.tsx';
import type { useColumnLoaderQuery$data as UseColumnLoaderQueryData } from '@atlassian/jira-relay/src/__generated__/useColumnLoaderQuery.graphql';
import messages from '../ui/messages.tsx';
import type { ColumnId } from '../ui/types.tsx';

export const JQLBuilder = ({
	issueKey,
	hideDone,
	sortColumn,
	sortDirection,
	filterSubtasks,
}: {
	issueKey: string;
	hideDone: string | null;
	sortColumn: string;
	sortDirection: SortOrder;
	filterSubtasks: boolean | null;
}) => {
	const hideDoneFlag = hideDone === 'true';
	const excludeDoneIssuesJQL = hideDoneFlag ? '& NOT statusCategory=Done' : '';

	// TODO: Remove on jira-issue-view-child-issues-panel-merge-subtasks cleanup
	const hierarchyLevelJQL =
		filterSubtasks === null ? '' : `AND hierarchyLevel ${filterSubtasks ? '=' : '!='} -1`;

	return `parent = ${issueKey} ${excludeDoneIssuesJQL} ${hierarchyLevelJQL} ORDER BY ${sortColumn} ${sortDirection}`;
};

export const onColumnsChangeErrorFlag: FlagConfiguration = {
	type: 'error',
	title: messages.configureColumnsErrorTitle,
	description: messages.columnConfigMutationErrorDescription,
	isAutoDismiss: true,
	messageId:
		'issue-view-common-views.child-issues-panel.configurable-child-issues-panel.utils.on-columns-change-error-flag.error',
	messageType: 'transactional',
};

export const onChildIssueLoadErrorFlag: FlagConfiguration = {
	type: 'error',
	title: messages.getChildIssueErrorTitle,
	description: messages.getChildIssueErrorDescription,
	isAutoDismiss: true,
	messageId:
		'issue-view-common-views.child-issues-panel.configurable-child-issues-panel.utils.on-child-issue-load-error-flag.error',
	messageType: 'transactional',
};

export const onChildIssueResizeErrorFlag: FlagConfiguration = {
	type: 'error',
	title: messages.columnResizeMutationErrorTitle,
	description: messages.columnResizeMutationErrorDescription,
	isAutoDismiss: true,
	messageId:
		'issue-view-common-views.child-issues-panel.configurable-child-issues-panel.utils.on-child-issue-resize-error-flag.error',
	messageType: 'transactional',
};

export const createJiraIssueSearchViewAri = ({
	cloudId,
	activationId,
}: {
	cloudId: string;
	activationId: string;
}) =>
	JiraIssueSearchViewAri.create({
		siteId: cloudId,
		activationId,
		namespaceId: 'CHILD_ISSUE_PANEL',
		viewId: 'list_default',
	}).toString();

export const createConfigurableChildIssuesPanelQueryVariables = ({
	cloudId,
	jql,
	fieldSetIds,
	issueKey,
}: {
	cloudId: string;
	jql: string;
	fieldSetIds: ColumnId[];
	issueKey: string;
}) => ({
	cloudId,
	issueSearchInput: {
		jql,
	},
	amountOfColumns: MAX_AMOUNT_OF_COLUMNS,
	namespace: 'CHILD_ISSUE_PANEL',
	options: {},
	fieldSetIds,
	shouldQueryFieldSetsById: false,
	fieldSetsInput: expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
		? {
				viewInput: {
					namespace: 'CHILD_ISSUE_PANEL',
					context: {
						issueContext: {
							issueKey,
						},
					},
				},
			}
		: null,
	filterId: undefined,
	fieldSetsContext: { issueContext: { issueKey } },
	issueKey,
});

export const mapColumnPickerOptions = (
	data: UseColumnLoaderQueryData | undefined,
): OptionResponse => {
	const fieldSets = data?.jira?.fieldSetViewQueryByProject?.fieldSets;
	// Error
	if (!fieldSets) {
		return {
			options: [],
			totalCount: 0,
		};
	}
	const options: ReadonlyArray<SelectOption> =
		fieldSets?.edges?.map((edge) => ({
			optionType: 'option',
			cursor: edge?.cursor,
			value: edge?.node?.fieldSetId || '',
			label: edge?.node?.displayName || '',
		})) || [];
	return {
		options,
		totalCount: fieldSets?.totalCount || null,
		endCursor: fieldSets?.pageInfo.endCursor || undefined,
	};
};

type ColumnWidth = {
	[key: ColumnType]: number;
};
const bigColumnWidth: ColumnWidth = {
	[KNOWN_COLUMN_TYPES.ISSUE_TYPE]: 54,
	[KNOWN_COLUMN_TYPES.ISSUE_KEY]: 80,
	[KNOWN_COLUMN_TYPES.STORY_POINT_ESTIMATE]: 45,
	[KNOWN_COLUMN_TYPES.NUMBER]: 45,
	[KNOWN_COLUMN_TYPES.PRIORITY]: 112,
	[KNOWN_COLUMN_TYPES.ASSIGNEE]: 132,
	[KNOWN_COLUMN_TYPES.STATUS]: 112,
	[KNOWN_COLUMN_TYPES.ISSUE_OPERATIONS]: 32,
};

const smallColumnWidth: ColumnWidth = {
	[KNOWN_COLUMN_TYPES.ISSUE_TYPE]: 40,
	[KNOWN_COLUMN_TYPES.ISSUE_KEY]: 80,
	[KNOWN_COLUMN_TYPES.STORY_POINT_ESTIMATE]: 45,
	[KNOWN_COLUMN_TYPES.NUMBER]: 45,
	[KNOWN_COLUMN_TYPES.PRIORITY]: 40,
	[KNOWN_COLUMN_TYPES.ASSIGNEE]: 40,
	[KNOWN_COLUMN_TYPES.STATUS]: 112,
	[KNOWN_COLUMN_TYPES.ISSUE_OPERATIONS]: 32,
};

export const getColumnConfigOverridesOld = (): ColumnConfigOverrideMap => {
	return {
		[KNOWN_COLUMN_TYPES.ISSUE_TYPE]: () => ({ defaultWidth: 40 }),
		[KNOWN_COLUMN_TYPES.ISSUE_KEY]: () => ({ defaultWidth: 80 }),
		[KNOWN_COLUMN_TYPES.SUMMARY]: () => ({ defaultWidth: 342 }),
		[KNOWN_COLUMN_TYPES.STORY_POINT_ESTIMATE]: () => ({ defaultWidth: 45 }),
		[KNOWN_COLUMN_TYPES.NUMBER]: () => ({ defaultWidth: 45 }),
		[KNOWN_COLUMN_TYPES.PRIORITY]: () => ({ defaultWidth: 40 }),
		[KNOWN_COLUMN_TYPES.ASSIGNEE]: () => ({ defaultWidth: 40 }),
		[KNOWN_COLUMN_TYPES.STATUS]: () => ({ defaultWidth: 112 }),
		[KNOWN_COLUMN_TYPES.ISSUE_OPERATIONS]: () => ({ defaultWidth: 32 }),
	};
};

export const getColumnConfigOverrides = (
	containerWidth: number | undefined,
): ColumnConfigOverrideMap => {
	if (!containerWidth) return getColumnConfigOverridesOld();
	const miscellaneous = 15; // padding/margin etc
	const calculatedResult: ColumnConfigOverrideMap = {};
	const defaultSummaryWidth = 342;
	// full page
	if (containerWidth > 760) {
		Object.entries(bigColumnWidth).forEach(([type, width]) => {
			calculatedResult[type] = () => ({ defaultWidth: width });
		});
		calculatedResult[KNOWN_COLUMN_TYPES.SUMMARY] = (allTypes: string[] | undefined) => {
			if (allTypes) {
				const restWidth = allTypes.reduce((acc, current) => {
					return acc + (bigColumnWidth[current] || 0);
				}, 0);

				return {
					defaultWidth: Math.floor(
						containerWidth -
							restWidth -
							bigColumnWidth[KNOWN_COLUMN_TYPES.ISSUE_OPERATIONS] -
							miscellaneous,
					),
				};
			}
			return { defaultWidth: defaultSummaryWidth };
		};

		return calculatedResult;
	}

	const minimumSummaryWidth = 100;

	Object.entries(smallColumnWidth).forEach(([type, width]) => {
		calculatedResult[type] = () => ({ defaultWidth: width });
	});

	calculatedResult[KNOWN_COLUMN_TYPES.SUMMARY] = (allTypes: string[] | undefined) => {
		if (allTypes) {
			const restWidth = allTypes.reduce((acc, current) => {
				return acc + (smallColumnWidth[current] || 0);
			}, 0);

			return {
				defaultWidth: Math.max(
					Math.floor(
						containerWidth -
							restWidth -
							smallColumnWidth[KNOWN_COLUMN_TYPES.ISSUE_OPERATIONS] -
							miscellaneous,
					),
					minimumSummaryWidth,
				),
			};
		}
		return { defaultWidth: defaultSummaryWidth };
	};

	return calculatedResult;
};
