import { fg } from '@atlassian/jira-feature-gating';
import {
	getConfluencePlaceholderTemplate,
	getIssueTypeEnglish,
} from './controllers/confluence-templates.tsx';
import { useTargeting, type PlaceholderTargeting } from './controllers/targeting.tsx';
import { useAdControls } from './controllers/ad-controls.tsx';
import {
	usePlaceholderUiProps,
	type PlaceholderUiProps,
} from './controllers/placehoder-ui-props.tsx';
import { useConfluenceAccess } from './controllers/confluence-access.tsx';
import type { Sources } from './common.tsx';

export type Props = {
	placeholderTarget: PlaceholderTargeting | undefined;
	onDismissPlaceholderTemplate: () => Promise<void>;
	placeholderUiProps?: PlaceholderUiProps;
	onProvisioningStarted: () => void;
};

export const usePlaceholderTemplate = (
	projectType: string | undefined,
	issueType: string | undefined,
	projectName: string | undefined,
	source: Sources,
	projectKey?: string,
	hasActivatedConfluenceWithinFourteenDays?: boolean,
	hasActivatedConfluenceWithinSixtythreeDays?: boolean,
): Props => {
	const {
		isConfluenceRecentlyProvisioned,
		hasConfluenceAccess,
		onProvisioningStarted,
		isConfluenceProvisioningStarted,
	} = useConfluenceAccess();
	const issueTypeEnglish = fg('placeholder_template_i18n_killswitch')
		? getIssueTypeEnglish(issueType, source)
		: issueType;

	const placeholderTarget = useTargeting(
		projectType,
		issueTypeEnglish,
		hasConfluenceAccess,
		source,
		isConfluenceProvisioningStarted,
		hasActivatedConfluenceWithinFourteenDays,
		hasActivatedConfluenceWithinSixtythreeDays,
	);

	const template = getConfluencePlaceholderTemplate(issueTypeEnglish);

	const { hasDismissed, onDismissPlaceholderTemplate } = useAdControls(placeholderTarget, source);

	const placeholderUiProps = usePlaceholderUiProps(
		placeholderTarget,
		template,
		projectName,
		isConfluenceRecentlyProvisioned,
		source,
		projectKey,
	);

	return {
		placeholderTarget: hasDismissed ? undefined : placeholderTarget,
		onDismissPlaceholderTemplate,
		placeholderUiProps,
		onProvisioningStarted,
	};
};
