import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import toArray from 'lodash/toArray';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	OUTWARD_LINK_DIRECTION,
	type IssueLinks,
	type NormalizedIssueLinks,
	type NormalizedLinkedIssues,
	type IssueLink,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type { RemoteIssueLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-issue-link-type.tsx';
import type {
	EntitiesState,
	State,
} from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { entitiesSelector } from '../common/state/selectors/issue-selector.tsx';
import { jpdDeliverLinkTypeIdSelector } from '../common/state/selectors/jpd-delivery-link-id-selector.tsx';

/**
 * selectors for issue links
 *
 * issue links state looks like
 * issueLinks: {
 *  issueLinks: [
 *      link1,
 *      link2
 *  ],
 *  linkedIssueKeys: ['KEY-1', 'KEY-2'],
 *  linkedIssues: {
 *      'KEY-1': issue1,
 *      'KEY-2': issue2
 *  }
 * }
 */

const issueLinksRootSelector = createSelector(
	entitiesSelector,
	(entities: EntitiesState) => entities && entities.issueLinks,
);

/**
 * Performs a check to see if Issue Links are enabled. Done by checking if we have an `issueLinks`
 * object present in our `state.entities`. This object is initialised as `undefined` by default,
 * and only gets populated if we successfully retrieve issue links data. We do not receive issue
 * link data from GraphQL or REST if issue links are disabled by Jira's Global Default Field
 * Configurations.
 *
 * // according to the type, issueLinks are always present and this method should always return true
 *
 * @returns boolean signifying if Issue Links are enabled based on the Issue Links entity object
 * root being present.
 */
export const isIssueLinksEnabledSelector = createSelector(issueLinksRootSelector, (root) => !!root);

const linksDataSelector = createSelector(
	issueLinksRootSelector,
	(issueLinks) => issueLinks && issueLinks.issueLinks,
);

export const remoteIssuesSelector = (state: State): RemoteIssueLink[] =>
	state?.entities?.remoteIssueLinks?.remoteIssues || [];

export const optimisticLinksDataSelector = createSelector(
	linksDataSelector,
	(issueLinks: NormalizedIssueLinks) =>
		issueLinks && pickBy(issueLinks, (link) => link.isOptimistic),
);

const linkedIssuesSelector = createSelector(
	issueLinksRootSelector,
	(issueLinks) => issueLinks && issueLinks.linkedIssues,
);
/**
 * select list of linked issue keys
 * @returns array of issue keys
 */
export const linkedIssueKeysSelector = createSelector(
	issueLinksRootSelector,
	(issueLinks: IssueLinks) => issueLinks?.linkedIssueKeys || [],
);

/**
 * selects whether there is linked issues or not
 * @returns true if there is linked issues or false otherwise
 */
export const hasIssueLinksSelector = createSelector(linksDataSelector, (links) => !isEmpty(links));

export const hasCombinedIssueLinksSelector = createSelector(
	linksDataSelector,
	remoteIssuesSelector,
	(links, remoteLinks) => !isEmpty(links) || !isEmpty(remoteLinks),
);

export const combinedLinkedIssueCountSelector = createSelector(
	linkedIssueKeysSelector,
	remoteIssuesSelector,
	(links, remoteLinks) => links.length + remoteLinks.length,
);

/**
 * select linked issues with link type and data grouped by link type
 * @returns map where each entry is a link type with array of linked issues object of that type
 * {
 *  'blocks': [{
 *      id: 'link1',
 *      linkedIssue: issue1,
 *  }],
 *  'relates to': [{
 *      id: 'link2',
 *      linkedIssue: issue2,
 *  }]
 * }
 */
export const linksSelector = createSelector(
	linksDataSelector,
	linkedIssuesSelector,
	jpdDeliverLinkTypeIdSelector,
	(links, issues, jpdDeliverLinkTypeId) => {
		if (!isEmpty(links)) {
			return (
				toArray(links)
					.filter((link) => !link.isDeleted && !!link.linkedIssueKey)
					// Filter out links with jpd delivery link typeId and direction === "OUTWARD" and they are displayed in separate Ideas section
					.filter(removeOutwardImplementsIdeaLinks(jpdDeliverLinkTypeId, issues))
					.map((link) => ({ ...link, linkedIssue: issues[link.linkedIssueKey] })) // eslint-disable-next-line @typescript-eslint/no-explicit-any
					.reduce<Record<string, any>>(
						(linksMap, link) =>
							Object.assign(linksMap, {
								[link.typeName]: [...(linksMap[link.typeName] || []), link],
							}),
						{},
					)
			);
		}
		return null;
	},
);

function removeOutwardImplementsIdeaLinks(
	jpdDeliverLinkTypeId: string | null | undefined,
	issues: NormalizedLinkedIssues,
) {
	return (link: IssueLink) => {
		if (jpdDeliverLinkTypeId && fg('jpd_idea_panel_in_issue_view')) {
			const isImplementsLink = link.typeId === jpdDeliverLinkTypeId;
			const isOutwardLink = link.direction === OUTWARD_LINK_DIRECTION;
			const isIdea = issues[link.linkedIssueKey]?.issueProjectType === PRODUCT_DISCOVERY_PROJECT;

			if (isIdea && isImplementsLink && isOutwardLink) {
				return false;
			}
		}

		return true;
	};
}

export const combinedLinksSelector = createSelector(
	linksSelector,
	remoteIssuesSelector,
	(links, remoteIssues) => {
		if (links == null && remoteIssues.length === 0) {
			return null;
		}

		const extractBasicIssueDetails = (remoteIssueLinkEntity: RemoteIssueLink) => ({
			id: remoteIssueLinkEntity.remoteIssueId,
			issueKey: remoteIssueLinkEntity.issueKey,
			issueLink: remoteIssueLinkEntity.issueLinkUrl,
			issueSummary: remoteIssueLinkEntity.summary,
			issueTypeIconUrl: remoteIssueLinkEntity.iconUrl,
		});

		// FIXME: JIV-15410 - expected to return IssueLink[]
		return remoteIssues.reduce(
			(combinedLinks, remoteIssue) => {
				const {
					issueDetails,
					relationship,
					id,
					issueKey,
					issueLinkUrl,
					remoteIssueLinkError,
					globalId,
					isSaved,
					isLoading,
					isOptimistic,
					hasError,
					isDeleted,
				} = remoteIssue;

				if (relationship != null && isDeleted !== true) {
					return {
						// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
						...combinedLinks,
						[relationship]: [
							...(combinedLinks[relationship] || []),
							{
								id: id.toString(),
								direction: OUTWARD_LINK_DIRECTION,
								typeName: relationship,
								linkedIssueKey: issueKey,
								linkedIssue: issueDetails || extractBasicIssueDetails(remoteIssue),
								isRemote: true,
								globalId,
								remoteServerHostname: new URL(issueLinkUrl).hostname,
								remoteIssueLinkError,
								isSaved,
								isLoading,
								isOptimistic,
								hasError,
							},
						],
					};
				}
				return combinedLinks;
			},
			{ ...links },
		);
	},
);

export const issueLinkSelector = (issueLinkId: string) =>
	createSelector(linksDataSelector, (links) => (links && links[issueLinkId]) || null);

export const linkedIssueSelector = (issueKey: string) =>
	createSelector(
		linkedIssuesSelector,
		(linkedIssues) => (linkedIssues && linkedIssues[issueKey]) || null,
	);

export const quickAddClickedCountSelector = createSelector(
	issueLinksRootSelector,
	(issueLinks) => issueLinks && issueLinks.quickAddClicked,
);

export const isOpenedFromJsmSimilarIssuesSelector = createSelector(
	issueLinksRootSelector,
	(issueLinks) => !!issueLinks?.isOpenedFromJsmSimilarIssues,
);

export const quickAddClickedIssueSelector = createSelector(
	issueLinksRootSelector,
	(issueLinks) => issueLinks && issueLinks.quickAddClickedIssue,
);

export const shouldDisplayAddLinksSelector = createSelector(
	quickAddClickedCountSelector,
	(count) => !!count,
);

export const triggeredViaHotKeySelector = createSelector(
	issueLinksRootSelector,
	(issueLinks) => issueLinks && issueLinks.triggeredViaHotKey,
);

export const shouldDisplayRelatedIssuePanelSelector = createSelector(
	issueLinksRootSelector,
	(issueLinks) => issueLinks && issueLinks.aiLinkedIssueSuggestion,
);

export const canCreateLinkedIssueSelector = createSelector(
	issueLinksRootSelector,
	(issueLinks) => issueLinks && issueLinks.canCreateLinkedIssue,
);
