import React, { forwardRef, type HTMLProps } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import { COMMAND_CONTAINER_ROW_TEST_ID, COMPAL_MENU_ITEM_ID } from '../constants.tsx';
import { messages } from './messages.tsx';

export type ListItemProps = {
	id?: string;
	active?: boolean;
};

export const CommandPaletteSearchResultListItem = forwardRef<
	HTMLDivElement,
	HTMLProps<HTMLDivElement> & ListItemProps
>(({ id, active, ...rest }, ref) => {
	const { formatMessage } = useIntl();
	return (
		<div
			ref={ref}
			data-testid={COMMAND_CONTAINER_ROW_TEST_ID}
			aria-current={active}
			role="menuitem"
			id={`${COMPAL_MENU_ITEM_ID}-${id}`}
			{...(fg('a11y_enghealth_fixes_deliveroo') && {
				'aria-label': formatMessage(messages.resultListItemAriaLabel),
			})}
			{...rest}
		/>
	);
});
