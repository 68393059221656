import React from 'react';
import { v4 as uuid } from 'uuid';
import { isFedRamp } from '@atlassian/atl-context';
import { useIsSuggestedResourcesShown } from '@atlassian/jira-aiops-suggested-resources/src/common/utils.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useProjectKey,
	useIssueId,
	useIssueKey,
	useIsAiEnabledForIssue,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useIsIssueOfIncidentsPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { PROJECT_ARCHIVED } from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import { SIMILAR_ISSUES_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { AIAnalyticsProvider } from '@atlassian/jira-instrumentation-common/src/ai-analytics-context/context.tsx';
import {
	useProjectId,
	useProjectStatus,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	toIssueId,
	toProjectId,
	type BaseUrl,
	type CloudId,
	type IssueId,
	type ProjectId,
	type ProjectKey,
	toBaseUrl,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import {
	SIMILAR_INCIDENTS_AI_ANALYTICS_CONFIG,
	SIMILAR_ISSUES_AI_ANALYTICS_CONFIG,
} from '../common/constants.tsx';
import { Container as RelatedIssuesForIssueContainer } from '../services/context.tsx';
import RelatedIssuesPanelView from './main.tsx';
import SimilarIncidentsPanelView from './similar-incidents/index.tsx';

const hasValue = (x?: null | string | IssueId): x is string | IssueId =>
	x !== null && x !== undefined;

const hasStringValue = (str?: string | null): str is string => hasValue(str) && str !== '';

const isRequiredAttributesPresent = (
	issueId: IssueId | null | undefined,
	projectId: ProjectId | null | undefined,
	projectKey: ProjectKey,
	baseUrl: BaseUrl,
	cloudId: CloudId,
) => {
	if (
		hasStringValue(projectId) &&
		projectId !== toProjectId('') &&
		hasValue(issueId) &&
		issueId !== toIssueId('') &&
		hasStringValue(cloudId)
	) {
		return {
			issueId,
			projectId,
			projectKey,
			baseUrl,
			cloudId,
		};
	}

	return undefined;
};

const RelatedIssuesPanel = () => {
	const sessionId = uuid();
	const issueId = useIssueId();
	const projectKey = useProjectKey();
	const projectId = toProjectId(useProjectId(projectKey) || '');
	const projectStatus = useProjectStatus(projectKey);
	const cloudId = useCloudId();
	const baseUrl = toBaseUrl('');
	const issueKey = useIssueKey();
	const [similarIssuesTypeFieldValue] = useFieldValue({
		issueKey,
		fieldKey: SIMILAR_ISSUES_TYPE,
	});
	const isIssueOfIncidentPractice = useIsIssueOfIncidentsPractice();
	const isUserLoggedIn = !!useAccountId();
	const isAiOptInEnabled = useIsAiEnabledForIssue();

	const similarIssuesAnalyticsConfig = fg('agent_ai_enable_similar_issues_improvements')
		? ({
				...SIMILAR_ISSUES_AI_ANALYTICS_CONFIG,
				isAIFeature: isAiOptInEnabled ? 1 : 0,
				isLLMBased: isAiOptInEnabled ? 1 : 0,
			} as const)
		: SIMILAR_ISSUES_AI_ANALYTICS_CONFIG;

	const validatedProps = isRequiredAttributesPresent(
		issueId,
		projectId,
		projectKey,
		baseUrl,
		cloudId,
	);

	const isProjectArchived = projectStatus === PROJECT_ARCHIVED;

	// When the SuggestedResourcesPanel is displayed, the RelatedIssuesPanel will be hidden.
	const isSuggestedResourcesShown = fg('incident_suggested_resources')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsSuggestedResourcesShown()
		: false;

	return !isSuggestedResourcesShown &&
		!!similarIssuesTypeFieldValue &&
		validatedProps !== undefined &&
		!isProjectArchived &&
		!isFedRamp() &&
		(!fg('similar_issues_login_check') || isUserLoggedIn) ? (
		<UFOSegment name="issue-smart-related-issues">
			<JSErrorBoundary
				id="relatedIssuesPanel"
				packageName="issueSmartRelatedIssuesPanel"
				fallback="unmount"
				teamName="itsm-grimlock"
			>
				<RelatedIssuesForIssueContainer {...validatedProps}>
					<ContextualAnalyticsData
						sourceName="RelatedIssuesPanel"
						attributes={{
							smartSessionId: sessionId,
						}}
					>
						{isIssueOfIncidentPractice ? (
							<AIAnalyticsProvider config={SIMILAR_INCIDENTS_AI_ANALYTICS_CONFIG}>
								<SimilarIncidentsPanelView sessionId={sessionId} />
							</AIAnalyticsProvider>
						) : (
							<AIAnalyticsProvider config={similarIssuesAnalyticsConfig}>
								<RelatedIssuesPanelView sessionId={sessionId} />
							</AIAnalyticsProvider>
						)}
					</ContextualAnalyticsData>
				</RelatedIssuesForIssueContainer>
			</JSErrorBoundary>
		</UFOSegment>
	) : null;
};

export default RelatedIssuesPanel;
