import React from 'react';
import { graphql, useFragment } from 'react-relay';
import {
	IN_PROGRESS,
	DONE,
	statusCategoryForId,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { src_issueViewCommonViews_ProgressSummary$key } from '@atlassian/jira-relay/src/__generated__/src_issueViewCommonViews_ProgressSummary.graphql';
import { ProgressSummaryView } from '../view.tsx';

type Props = {
	issues: src_issueViewCommonViews_ProgressSummary$key | null;
	isClassicSubtaskPanel: boolean;
};
export const ProgressSummary = ({ issues, isClassicSubtaskPanel }: Props) => {
	const data = useFragment<src_issueViewCommonViews_ProgressSummary$key>(
		graphql`
			fragment src_issueViewCommonViews_ProgressSummary on JiraIssueConnection {
				edges {
					node {
						statusField {
							status {
								statusCategory @required(action: LOG) {
									statusCategoryId
								}
							}
						}
					}
				}
			}
		`,
		issues,
	);

	const edges = data?.edges || [];

	const getCompletedIssues = edges.filter(
		(e) =>
			statusCategoryForId(e?.node?.statusField?.status?.statusCategory.statusCategoryId) === DONE,
	);

	const getInProgressIssues = edges.filter(
		(e) =>
			statusCategoryForId(e?.node?.statusField?.status?.statusCategory.statusCategoryId) ===
			IN_PROGRESS,
	);

	return (
		<ProgressSummaryView
			isClassicSubtaskPanel={isClassicSubtaskPanel}
			totalIssueCount={edges.length}
			inProgressIssueCount={getInProgressIssues.length}
			completedIssueCount={getCompletedIssues.length}
		/>
	);
};
