import { setIn } from 'icepick';
import type { PersistedCommentsById } from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import {
	type SetCommentValueAction,
	type saveCommentSuccess,
	type saveCommentCancel,
	type deleteCommentSuccess,
	type deleteCommentRequest,
	type deleteCommentFailure,
	type SaveCommentRequestAction,
	SET_COMMENT_VALUE,
	SAVE_COMMENT_SUCCESS,
	DELETE_COMMENT_SUCCESS,
	DELETE_COMMENT_REQUEST,
	DELETE_COMMENT_FAILURE,
	SAVE_COMMENT_REQUEST,
	SAVE_COMMENT_CANCEL,
} from '../../actions/comment-actions.tsx';

type State = PersistedCommentsById;

type Action =
	| SetCommentValueAction
	| ReturnType<typeof saveCommentCancel>
	| ReturnType<typeof saveCommentSuccess>
	| ReturnType<typeof deleteCommentSuccess>
	| ReturnType<typeof deleteCommentRequest>
	| ReturnType<typeof deleteCommentFailure>
	| SaveCommentRequestAction;

export const initialState: State = {};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case SET_COMMENT_VALUE:
			return setIn(state, [action.payload.id, 'bodyAdf'], action.payload.value);

		case SAVE_COMMENT_CANCEL: {
			const { optimisticId, isNewComment, parentId } = action.payload;
			if (isNewComment && parentId && state[parentId]) {
				const parentComment = state[parentId];
				if (parentComment) {
					const updatedChildCommentIds: Set<string> = parentComment?.childCommentIds
						? new Set(parentComment.childCommentIds)
						: new Set();

					if (updatedChildCommentIds.has(optimisticId)) {
						updatedChildCommentIds.delete(optimisticId);

						const updatedParentComment = {
							...parentComment,
							childCommentIds: updatedChildCommentIds,
						};
						return {
							...state,
							[parentId]: updatedParentComment,
						};
					}
				}
			}
			return state;
		}

		case SAVE_COMMENT_REQUEST: {
			const { id, isNewComment, parentId } = action.payload.comment;
			if (isNewComment && parentId) {
				const parentComment = state[parentId];
				if (parentComment) {
					const updateChildComments: Set<string> = parentComment?.childCommentIds
						? new Set(parentComment?.childCommentIds)
						: new Set();

					if (!updateChildComments.has(id)) {
						updateChildComments.add(id);
					}

					const updatedParentComment = {
						...parentComment,
						childCommentIds: updateChildComments,
					};
					return {
						...state,
						[parentId]: updatedParentComment,
					};
				}
			}
			return state;
		}

		case SAVE_COMMENT_SUCCESS: {
			const { comment } = action.payload;

			const { optimisticId, isNewComment, parentId } = action.payload;
			if (isNewComment && parentId) {
				const parentComment = state[parentId];
				if (parentComment) {
					const updatedChildCommentIds: Set<string> = parentComment?.childCommentIds
						? new Set(parentComment?.childCommentIds)
						: new Set();

					if (updatedChildCommentIds.has(optimisticId)) {
						updatedChildCommentIds.delete(optimisticId);
					}

					updatedChildCommentIds.add(comment.id);

					const updatedParentComment = {
						...parentComment,
						childCommentIds: updatedChildCommentIds,
					};
					// @ts-expect-error - TS2322 - Type '{ [x: string]: Comment | undefined; }' is not assignable to type 'Partial<Record<string, PersistedComment>>'.
					return {
						...state,
						[comment.id]: {
							...comment,
							parentId,
						},
						[parentId]: updatedParentComment,
					};
				}
			}

			const existingComment = state[comment.id];
			if (existingComment) {
				const updatedChildComments: PersistedCommentsById = {};
				// copy parent comment's updated visibility to child comments
				if (
					existingComment.childCommentIds?.size &&
					existingComment.visibility.value !== comment.visibility.value
				) {
					const newVisibility = comment.visibility;
					existingComment.childCommentIds.forEach((childCommentId) => {
						updatedChildComments[childCommentId] = {
							...state[childCommentId],
							visibility: newVisibility,
						};
					});
				}

				// extract old values from existing comments which need to be persisted
				const existingCommentPropsToPersist = {
					cursor: existingComment.cursor,
					parentId: existingComment.parentId,
					childCommentIds: existingComment.childCommentIds,
					childCommentsPageInfo: existingComment.childCommentsPageInfo,
				};

				// @ts-expect-error - TS2322 - Type '{ [x: string]: Comment | undefined; }' is not assignable to type 'Partial<Record<string, PersistedComment>>'.
				return {
					...state,
					...updatedChildComments,
					[comment.id]: {
						...comment,
						...existingCommentPropsToPersist,
					},
				};
			}

			// @ts-expect-error - TS2322 - Type '{ [x: string]: Comment | undefined; }' is not assignable to type 'Partial<Record<string, PersistedComment>>'.
			return {
				...state,
				[comment.id]: comment,
			};
		}

		case DELETE_COMMENT_REQUEST: {
			const { hasReplies, id } = action.payload;

			if (hasReplies) {
				const nextState = { ...state };

				const comment = { ...state[id], isDeleted: true };
				nextState[id] = comment;
				return nextState;
			}

			return state;
		}

		case DELETE_COMMENT_FAILURE: {
			const id = action.payload.id;

			if (state[id]?.isDeleted) {
				const nextState = { ...state };

				const comment = { ...state[id], isDeleted: false };
				nextState[id] = comment;
				return nextState;
			}

			return state;
		}

		case DELETE_COMMENT_SUCCESS: {
			const { id, parentId } = action.payload;
			const nextState = { ...state };

			// handle child comment
			// find child comment reference in parent's "replies" and remove the entry from there
			// the child comment itself will also be removed at the end using delete nextState[id]
			if (parentId && state[parentId] && state[parentId].childCommentIds) {
				const parent = {
					...state[parentId],
					childCommentIds: new Set(state[parentId]?.childCommentIds),
				};
				if (parent) {
					if (parent.childCommentIds.has(id)) {
						parent.childCommentIds.delete(id);
						nextState[parentId] = parent;
						// when a root comment was deleted which had replies, it was left in the state with isDeleted marked as true
						// now, if there are no more comments left in the tree and the last child of this root comment is also being deleted, we should remove the entire tree from state
						if (
							parent.childCommentIds.size === 0 &&
							parent.isDeleted &&
							!parent.childCommentsPageInfo?.hasNextPage &&
							!parent.childCommentsPageInfo?.hasPreviousPage
						) {
							delete nextState[parentId];
						}
					}
				}
			} else if (state[id] && (state[id]?.childCommentIds ?? new Set()).size > 0) {
				// handle root comment with replies
				// when there are replies present, the root comment should not be deleted from state but rather its isDeleted property should be marked as true
				const comment = { ...state[id], isDeleted: true };
				nextState[id] = comment;
				// if there are replies present and root comment is deleted, preserve it
				return nextState;
			}

			delete nextState[id];
			return nextState;
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
