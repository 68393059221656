import { useCallback, useEffect, useMemo, useState } from 'react';
import { createLocalExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fetchUserTeamType } from '../services/user-growth-profile.tsx';

const JIRA_TEAM_TYPE_KEY = 'jiraOnboardingTeamType';
const TEAM_TYPES = new Set(['Software Development', 'IT Support']);

type Props = {
	skip?: boolean;
};

export const localStorageManager = (accountId: string | null) => {
	const storageKey = accountId ? `${accountId}_${JIRA_TEAM_TYPE_KEY}` : JIRA_TEAM_TYPE_KEY;
	const localStorageProvider = createLocalExpirableStorageProvider('jiraOnboardingTeamTypeStorage');

	const getLocalStorage = () => localStorageProvider.get(storageKey);
	const LOCAL_STORAGE_EXPIRY_MS = 24 * 60 * 60 * 1000; // one day
	const setLocalStorage = (value: string | undefined) =>
		localStorageProvider.set(storageKey, value, Date.now() + LOCAL_STORAGE_EXPIRY_MS);

	return { getLocalStorage, setLocalStorage };
};

export const useUserHasEligibleTeamType = ({ skip = false }: Props): boolean => {
	const accountId = useAccountId();
	const { getLocalStorage, setLocalStorage } = useMemo(
		() => localStorageManager(accountId),
		[accountId],
	);
	const cachedTeamType = getLocalStorage();
	const initialTeamType = cachedTeamType;
	const [teamType, setTeamType] = useState<string>(initialTeamType);

	const handleFetchTeamType = useCallback(async (): Promise<void> => {
		try {
			let newTeamType = '';

			if (accountId && fg('aa_grw-1544_whiteboards_pt_admin_gate')) {
				newTeamType = await fetchUserTeamType(accountId);
			}

			setTeamType(newTeamType);
			setLocalStorage(newTeamType);
		} catch (error: unknown) {
			fireErrorAnalytics({
				meta: {
					id: JIRA_TEAM_TYPE_KEY,
					packageName: '@atlassian/jira-issue-content-placeholder-template',
					teamName: 'starburst',
				},
				error: new Error(`Failed to get ${JIRA_TEAM_TYPE_KEY} status: ${error}`),
			});
		}
	}, [accountId, setLocalStorage]);

	useEffect(() => {
		if (!skip && accountId && !cachedTeamType) {
			handleFetchTeamType();
		}
	}, [accountId, skip, cachedTeamType, handleFetchTeamType]);

	return TEAM_TYPES.has(teamType);
};
