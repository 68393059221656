import type { Observable as ObservableType } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import type { ServerAssociatedIssue } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import { CHILDREN_ISSUES } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { BaseUrl, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ChildrenResponse } from './types.tsx';
import { fetchChildren } from './utils.tsx';

const transformResponseToChildren = (response: ChildrenResponse): ServerAssociatedIssue[] => {
	if (!response || !response.data || !response.data.issue) {
		return [];
	}

	const childrenIssues = response.data.issue.fields.find((field) => field.key === CHILDREN_ISSUES);

	if (childrenIssues && childrenIssues.value) {
		return childrenIssues.value.map(
			// @ts-expect-error - TS7031 - Binding element 'id' implicitly has an 'any' type. | TS7031 - Binding element 'key' implicitly has an 'any' type. | TS7031 - Binding element 'self' implicitly has an 'any' type. | TS7031 - Binding element 'assignee' implicitly has an 'any' type. | TS7031 - Binding element 'issuetype' implicitly has an 'any' type. | TS7031 - Binding element 'priority' implicitly has an 'any' type. | TS7031 - Binding element 'summary' implicitly has an 'any' type. | TS7031 - Binding element 'status' implicitly has an 'any' type.
			({ id, key, self, fields: { assignee, issuetype, priority, summary, status } }) => ({
				id,
				key,
				self,
				fields: {
					issueType: issuetype,
					priority,
					status,
					summary,
					assignee,
				},
			}),
		);
	}

	return [];
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (baseUrl: BaseUrl, parentId: IssueId): ObservableType<ServerAssociatedIssue[]> =>
	fetchChildren(baseUrl, parentId).map(transformResponseToChildren);
