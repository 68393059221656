import type { UnsupportedContentLevelsTracking } from '@atlaskit/editor-common/utils';

export const getUnsupportedContentLevelsTracking = (): UnsupportedContentLevelsTracking => {
	return {
		enabled: true,
		thresholds: {
			blocking: 1.5,
			degraded: 1,
		},
	};
};
