import React, { useState, useCallback, type PropsWithChildren } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import DateEditViewEntryPoint from '@atlassian/jira-issue-field-date-editview-full/src/entrypoint.tsx';
import type { DateEditViewProps } from '@atlassian/jira-issue-field-date-editview-full/src/ui/date/types.tsx';
import { DateReadView } from '@atlassian/jira-issue-field-date-readview-full/src/ui/date/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import type { date_issueFieldDate_DateField_Mutation as DateMutation } from '@atlassian/jira-relay/src/__generated__/date_issueFieldDate_DateField_Mutation.graphql';
import type { date_issueFieldDateInlineEditFull_DateInlineEditView_fragmentRef$key as DateFragment } from '@atlassian/jira-relay/src/__generated__/date_issueFieldDateInlineEditFull_DateInlineEditView_fragmentRef.graphql';
import type { date_issueFieldDateInlineEditFull_DateInlineEditViewWithIsEditable_fragmentRef$key as DateWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/date_issueFieldDateInlineEditFull_DateInlineEditViewWithIsEditable_fragmentRef.graphql';
import { expVal } from '@atlassian/jira-feature-experiments';
import { DUE_DATE } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { RecurWorkItemContainer as RecurWorkItemContainerMain } from '@atlassian/jira-recur-work-item/src/controllers/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useProjectType,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { CORE_PROJECT, SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useDateValidator } from './utils.tsx';
import type { Props, PropsWithIsEditable, JiraDatePickerValue } from './types.tsx';

const RecurWorkItemContainerEmpty = ({ children }: PropsWithChildren<{ issueKey: string }>) => {
	return <>{children}</>;
};

export const DateInlineEditViewWithIsEditable = (props: PropsWithIsEditable) => {
	const {
		attributes,
		spacing = 'compact',
		editViewPopup,
		editViewPopupAlignBlock,
		isEditing: isEditingProp = false,
		isEditable,
		onSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		fragmentRef,
		readViewFitContainerHeight,
		includeUrgencyTreatment,
		context,
	} = props;

	const data = useFragment<DateWithIsEditableFragment>(
		graphql`
			fragment date_issueFieldDateInlineEditFull_DateInlineEditViewWithIsEditable_fragmentRef on JiraDatePickerField {
				...date_issueFieldDateReadviewFull_DateReadView
				date
				id
				fieldId
				type
				name
				fieldConfig {
					isRequired
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<DateMutation>(graphql`
		mutation date_issueFieldDate_DateField_Mutation($input: JiraUpdateDateFieldInput!)
		@raw_response_type {
			jira {
				updateDateField(input: $input) {
					field {
						date
					}
					success
					errors {
						message
					}
				}
			}
		}
	`);

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { id: uniqueFieldId, fieldId, type, name, fieldConfig } = data;

	const { overriding } = useInlineEditFieldInjections();

	const fieldName = fg('relay-migration-issue-fields-date-sg')
		? overriding.overrideLabel(name)
		: name;

	const isFieldEditable = fg('relay-migration-issue-fields-date-sg')
		? overriding.overrideIsEditable(isEditable)
		: isEditable;

	const isFieldRequired = fieldConfig?.isRequired ?? false;
	const validator = useDateValidator(fieldName, isFieldRequired);

	const initialValue = data.date ?? null;

	const [updatedValue, setUpdatedValue] = useState<JiraDatePickerValue>(initialValue);

	const handleSubmit = useCallback(
		(value: JiraDatePickerValue, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(value);
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							date: value,
							operation: 'SET',
						},
					},
				},
				onCompleted(mutationData) {
					if (mutationData.jira?.updateDateField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error: Error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateDateField: {
							success: true,
							errors: null,
							field: {
								date: value,
								id: uniqueFieldId,
							},
						},
					},
				},
			});
		},
		[commit, onSubmit, uniqueFieldId],
	);

	const handleSubmitSucceeded = fg('relay-migration-issue-fields-date-sg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(
				(value: JiraDatePickerValue) => {
					onSubmitSucceeded?.(value);
				},
				[onSubmitSucceeded],
			)
		: undefined;

	const {
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
		handleCopy,
		handleCut,
		handlePaste,
		hasServerValidationError,
		invalidMessage,
		isEditing,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType: type,
		initialValue,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded: handleSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen: isEditingProp,
		updatedValue,
		validator,
		clipboardComponentType: 'date',
	});

	const isRecurWorkItemEnabled =
		(projectType === SOFTWARE_PROJECT || projectType === CORE_PROJECT) &&
		context === 'ISSUE_DETAIL' &&
		fieldId === DUE_DATE &&
		expVal('jira-recur-single-work-item-on-sched-due-date-exp', 'showRecurringWorkPopup', false);

	const renderReadView = useCallback(
		() => (
			<DateReadView
				fragmentRef={data}
				includeUrgencyTreatment={includeUrgencyTreatment}
				{...(fg('issue_view_due_date_colored_field_targeting_gate') ? { fieldId } : {})}
				shouldShowRecurWorkIcon={
					isRecurWorkItemEnabled &&
					expVal(
						'jira-recur-single-work-item-on-sched-due-date-exp',
						'showRecurWorkIconInDueDate',
						false,
					)
				}
			/>
		),
		[data, fieldId, includeUrgencyTreatment, isRecurWorkItemEnabled],
	);

	const RecurWorkItemContainer = isRecurWorkItemEnabled
		? RecurWorkItemContainerMain
		: RecurWorkItemContainerEmpty;

	const handleCancelWithEvent = useCallback(
		() => handleCancel(createAnalyticsEvent({})),
		[handleCancel, createAnalyticsEvent],
	);
	const getEditViewProps = (fieldProps: ValidationFieldProps): DateEditViewProps => ({
		...fieldProps,
		spacing,
		value: updatedValue,
		onChange: handleChange,
		isInvalid: !!invalidMessage,
		autoFocus: true,
		label: fieldName,
		...(isRecurWorkItemEnabled && {
			issueKey,
			isRecurWorkItemEnabled,
			onCancel: handleCancelWithEvent,
		}),
	});

	return (
		<RecurWorkItemContainer issueKey={issueKey}>
			<FieldInlineEditLiteWithEntryPoint
				editViewPopup={editViewPopup}
				editViewPopupAlignBlock={editViewPopupAlignBlock}
				editViewPopupMinWidth="small"
				editViewEntryPoint={DateEditViewEntryPoint}
				editViewEntryPointParams={{}}
				getEditViewProps={getEditViewProps}
				fieldName={fieldName}
				hasUnsubmittedChanges={hasServerValidationError}
				isEditing={isEditing}
				isEditable={isFieldEditable}
				onCancel={handleCancel}
				onConfirm={handleConfirm}
				onEdit={handleEdit}
				onCopy={handleCopy}
				onCut={handleCut}
				onPaste={handlePaste}
				readViewFitContainerHeight={readViewFitContainerHeight}
				readView={renderReadView}
				invalidMessage={invalidMessage}
				hideActionButtons
			/>
		</RecurWorkItemContainer>
	);
};

export const DateInlineEditView = ({ fragmentRef, ...props }: Props) => {
	const data = useFragment<DateFragment>(
		graphql`
			fragment date_issueFieldDateInlineEditFull_DateInlineEditView_fragmentRef on JiraDatePickerField {
				...date_issueFieldDateInlineEditFull_DateInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<DateInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
