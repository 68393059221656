import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import uniqBy from 'lodash/uniqBy';
import type {
	EntitiesState,
	State,
} from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type {
	WebLink,
	WebLinks,
	OptimisticallyDeletedWebLink,
	ApplicationDetails,
} from '@atlassian/jira-issue-view-common-types/src/web-links-type.tsx';
import type { WebLinkId } from '@atlassian/jira-shared-types/src/general.tsx';
import { entitiesSelector } from '../common/state/selectors/issue-selector.tsx';
import { permissionsSelector } from '../common/state/selectors/permissions-selector.tsx';

export const GENERIC_CACHE_SYMBOL = Symbol('GENERIC');

export const generateApplicationId = (applicationName: string, applicationType?: string | null) =>
	`${applicationName}${typeof applicationType === 'string' ? applicationType : ''}`;

const webLinksRootSelector = createSelector(entitiesSelector, (entities) => entities.webLinks);

export const webLinksSelector = createSelector(webLinksRootSelector, (webLinks) =>
	webLinks.links.filter(
		// @ts-expect-error - TS2339 - Property 'isDeleting' does not exist on type 'WebLink'. | TS2339 - Property 'isDeleting' does not exist on type 'WebLink'.
		(link: WebLink) => link.isDeleting === undefined || link.isDeleting !== true,
	),
);

// NOTE: from testing, we've found that we need to bypass reselect's internal
// memoization and implement our own, as the selector was still being called and
// recalculated on changes to state unrelated to the composed selectors.
export const webLinksWithApplicationIdSelector = createSelector(
	entitiesSelector,
	(_: State, applicationId: string) => applicationId,
	(() => {
		let previousWebLinks: WebLinks | null = null;
		const memoizedWebLinksWithApplicationId = memoize(
			(entities: EntitiesState, applicationId?: string): WebLink[] =>
				entities.webLinks.links
					.filter((link: WebLink): link is OptimisticallyDeletedWebLink => {
						const isLinkDeleted =
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							(link as OptimisticallyDeletedWebLink).isDeleting !== undefined &&
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							(link as OptimisticallyDeletedWebLink).isDeleting === true;

						if (isLinkDeleted) return false;

						// Filter for generic web links
						if (applicationId === undefined) {
							return typeof link.applicationName !== 'string' || link.applicationName.trim() === '';
						}
						// Filter for web links by id
						return (
							typeof link.applicationName === 'string' &&
							link.applicationName.trim() !== '' &&
							applicationId === generateApplicationId(link.applicationName, link.applicationType)
						);
					})
					// Sort from old to new
					.reverse(),
			({ webLinks }, appId) => {
				if (previousWebLinks !== null && previousWebLinks !== webLinks) {
					// @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
					memoizedWebLinksWithApplicationId.cache.clear();
				}
				previousWebLinks = webLinks;
				return appId !== undefined ? appId : GENERIC_CACHE_SYMBOL;
			},
		);
		return memoizedWebLinksWithApplicationId;
	})(),
);

export const additionalWebLinksSectionsSelector = createSelector(
	webLinksSelector,
	(allWebLinks) => {
		const linksWithId = allWebLinks.reduce<ApplicationDetails[]>((links, currentLink) => {
			const { applicationName, applicationType } = currentLink;
			if (typeof applicationName !== 'string' || applicationName.trim() === '') return links;
			return [
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				...links,
				{
					applicationName,
					applicationId: generateApplicationId(applicationName, applicationType),
				},
			] as const;
		}, []);

		const uniqLinkSections = uniqBy(linksWithId, 'applicationId');

		// Sort the sections in alphabetical order
		uniqLinkSections.sort((a, b) => a.applicationId.localeCompare(b.applicationId));

		// Filter out Loom as it is handled separately in content-items-view rather than by a weblinks section
		return uniqLinkSections.filter((section) => section.applicationName !== 'Loom');
	},
);

export const webLinkSelector = (webLinkId: WebLinkId): ((arg1: State) => WebLink | undefined) =>
	createSelector(webLinksSelector, (webLinks: WebLink[]): WebLink | undefined =>
		webLinks.find((webLink: WebLink) => webLink.id === webLinkId),
	);

export const canAddWebLinksSelector = createSelector(
	webLinksRootSelector,
	permissionsSelector,
	(webLinks, permissions) => webLinks.isLinkingEnabled === true && permissions.canLinkIssues,
);

export const webLinksCountSelector = createSelector(
	webLinksRootSelector,
	(webLinks) => webLinks.linkCount,
);

export const createWebLinkClickedCountSelector = createSelector(
	webLinksRootSelector,
	(webLinks) => webLinks.createClickCount,
);

export const isCreateWebLinkOpenedSelector = createSelector(
	createWebLinkClickedCountSelector,
	(createWebLinkClickedCount) => createWebLinkClickedCount > 0,
);
