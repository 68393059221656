import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { JiraIssueFieldOptionAri } from '@atlassian/ari/jira';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldsValuesActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { CascadingSelectValuePair } from '@atlassian/jira-issue-field-cascading-select-editview-full/src/ui/cascading-select/types.tsx';
import { CascadingSelectInlineEditView } from '@atlassian/jira-issue-field-cascading-select-inline-edit-full/src/ui/cascading-select/index.tsx';
import type { CascadingSelectParent } from '@atlassian/jira-issue-field-cascading-select/src/common/types.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

/**
 * Props for `<\{@link IssueViewCascadingSelectField}/>`
 */
interface IssueViewCascadingSelectFieldPropsWithFragment {
	/** Defines which area of the Issue View the field will be displayed */
	area?: Area;
	/** This is a reference to the relay fragment of GraphQL type JiraSingleSelectField */
	fragmentKey: ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField$key;
}

/**
 * Props for `<\{@link IssueViewCascadingSelectField}/>` only used by tests
 */
export type IssueViewCascadingSelectFieldProps = Omit<
	IssueViewCascadingSelectFieldPropsWithFragment,
	'fragmentKey'
>;

export const transformRelayDataToLegacyShape = (
	value?: CascadingSelectValuePair | null,
): CascadingSelectParent | null => {
	if (value == null || value.parentOptionValue == null) return null;

	const { parentOptionValue, childOptionValue } = value;
	const { optionId: parentOptionId } = JiraIssueFieldOptionAri.parse(parentOptionValue.id);
	const { optionId: childOptionId } = childOptionValue
		? JiraIssueFieldOptionAri.parse(childOptionValue.id)
		: { optionId: '' };

	return {
		id: parentOptionId || '',
		value: parentOptionValue.value || '',
		child: childOptionValue && {
			id: childOptionId || '',
			value: childOptionValue.value || '',
		},
	};
};

/**
 * Issue View wrapper of the cascading select field's inline-edit component,
 * with relevant information displayed by the Issue View (field name, description).
 */
export const IssueViewCascadingSelectField = ({
	area,
	fragmentKey,
}: IssueViewCascadingSelectFieldPropsWithFragment) => {
	const issueId = useIssueId();
	const issueKey = useIssueKey();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();
	const [, { setFieldValue }] = useFieldsValuesActions();

	const data =
		useFragment<ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField$key>(
			graphql`
				fragment ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField on JiraCascadingSelectField {
					...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
					...cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditView_fragmentRef
					fieldId
					id
				}
			`,
			fragmentKey,
		);

	const content = (
		<CascadingSelectInlineEditView
			fragmentRef={data}
			onSubmit={(value) => {
				issueId &&
					fieldChangeRequested(issueId, data.fieldId, transformRelayDataToLegacyShape(value));
			}}
			onSubmitSucceeded={(value) => {
				const cascadingOption = transformRelayDataToLegacyShape(value);

				issueId && fieldChanged(issueId, data.fieldId, cascadingOption);
				setFieldValue(issueKey, data.fieldId, cascadingOption);
			}}
			onSubmitFailed={() => issueId && fieldChangeFailed(issueId, data.fieldId)}
			spacing="compact"
		/>
	);

	return (
		<UFOSegment name="issue-field-cascading-select">
			<IssueViewFieldHeading
				area={area}
				fragmentKey={data}
				testId={`issue.issue-view-layout.issue-view-cascading-select-field.${data.fieldId}`}
			>
				{fg('jsc_inline_editing_field_refactor') ? (
					<Box xcss={fieldWrapperStyles}>{content}</Box>
				) : (
					content
				)}
			</IssueViewFieldHeading>
		</UFOSegment>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
});
