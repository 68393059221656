import React, { useMemo } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import { flagFieldPopupContentEntryPoint } from './entrypoint.tsx';

export const FlagFieldPopupContentEntryPointContext =
	createUseEntryPointContext<typeof flagFieldPopupContentEntryPoint>();

export const FlagFieldPopupContentEntryPointContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const entryPointParams = useMemo(() => ({}), []);
	const entryPointResult = useEntryPoint(flagFieldPopupContentEntryPoint, entryPointParams);
	return (
		<FlagFieldPopupContentEntryPointContext.Provider value={entryPointResult}>
			{children}
		</FlagFieldPopupContentEntryPointContext.Provider>
	);
};
