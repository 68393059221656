import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/zip';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/groupBy';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { type ActionsObservable, combineEpics } from 'redux-observable';

import { Observable } from 'rxjs/Observable';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { BAD_REQUEST } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-analytics/src/services/send-experience-analytics/index.tsx';
import {
	type CommentVisibility,
	COMMENT_VISIBILITY_TYPE_PUBLIC,
	COMMENT_VISIBILITY_TYPE_ROLE,
	COMMENT_VISIBILITY_TYPE_GROUP,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import type { Action } from '@atlassian/jira-issue-view-actions/src/index.tsx';
import type {
	SaveCommentPropsType,
	Comment,
	CommentProperties,
	CommentId,
} from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { calculateNumberOfCommentsToLoad } from '@atlassian/jira-issue-view-common-utils/src/epics/comment-fetch-epic.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { commentExperienceDescription } from '@atlassian/jira-issue-view-common-utils/src/experience-tracking/comment-experience-tracking.tsx';
import {
	transformComment,
	normalizeComment,
} from '@atlassian/jira-issue-view-services/src/issue/comment-transformer.tsx';
import { getSaveAdfCommentUrl } from '@atlassian/jira-issue-view-services/src/issue/comment-urls.tsx';
import {
	saveCommentRequest,
	saveCommentSuccess,
	saveCommentFailure,
	fetchSortedCommentsRequest,
	SAVE_COMMENT_REQUEST,
	SAVE_COMMENT_RETRY,
	SAVE_COMMENT_FAILURE,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { markTokenAsOutdated } from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions.tsx';
import {
	baseUrlSelector,
	isServiceDeskSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { isCustomerServiceSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	pendingCommentSelector,
	isEditingInternalCommentSelector,
	isCommentVisibilityRestrictionSupportedSelector,
	entitiesCommentsSelector,
	totalCommentsSelector,
	loadedCommentsSelector,
	startIndexCommentsSelector,
	commentsPageInfoSelector,
	childCommentsPageInfoSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import {
	isOnlyWhitespaceAdf,
	hasMediaFileNodes,
	hasMediaFileNodesUpdated,
} from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { extractTraceId } from '@atlassian/jira-software-sla-tracker/src/services/extract-trace-id/index.tsx';
import { createCommentWithAttachmentsExposure } from '../experiences.tsx';
import { extractFetchErrorMessage } from '../utils.tsx';

const retrySaveComment = (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(SAVE_COMMENT_RETRY).map((action) => {
		const state = store.getState();
		const { optimisticId, isNewComment } = action.payload;
		return saveCommentRequest({
			...pendingCommentSelector(optimisticId)(state),
			isNewComment,
		});
	});

const getSaveUrl = (
	store: MiddlewareAPI<State>,
	commentId: string,
	isNewComment: boolean | undefined,
) => {
	const state = store.getState();
	const baseUrl = baseUrlSelector(state);
	const issueKey = issueKeySelector(state);

	return getSaveAdfCommentUrl({
		baseUrl,
		issueKey,
		id: commentId,
		isNewComment,
	});
};

const getServiceDeskCommentProperties = (
	store: MiddlewareAPI<State>,
	commentId: string,
): CommentProperties => {
	const state = store.getState();

	if (
		isServiceDeskSelector(state) ||
		(isCustomerServiceSelector(state) && fg('jcs_project_type_m3'))
	) {
		const isInternal = isEditingInternalCommentSelector(commentId)(state);

		return {
			properties: [
				{
					key: 'sd.public.comment',
					value: { internal: isInternal },
				},
			],
		};
	}
	return {};
};

const getVisibilityOption = (store: MiddlewareAPI<State>, visibility: CommentVisibility) => {
	if (isCommentVisibilityRestrictionSupportedSelector(store.getState())) {
		/**
		 * Refer to https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-issue-comments#api-rest-api-3-issue-issueidorkey-comment-post
		 * for using identifier instead of value
		 */
		switch (visibility.type) {
			case COMMENT_VISIBILITY_TYPE_ROLE:
				// Role name is an acceptable identifier
				return {
					visibility: {
						identifier: visibility.value,
						type: visibility.type,
					},
				};
			case COMMENT_VISIBILITY_TYPE_GROUP:
				return {
					visibility: {
						identifier: visibility.groupId,
						type: visibility.type,
					},
				};
			case COMMENT_VISIBILITY_TYPE_PUBLIC:
			default:
				return { visibility: null };
		}
	}

	return {};
};

const getFetchOptions = (
	store: MiddlewareAPI<State>,
	commentId: string,
	bodyAdf: ADF,
	isNewComment: boolean | undefined,
	visibility: CommentVisibility,
	eventOccurredAt?: number | null,
	jsdIncidentActivityViewHidden?: boolean | null,
	parentId?: CommentId,
) => {
	const commentProperties = getServiceDeskCommentProperties(store, commentId);
	const visibilityOption = getVisibilityOption(store, visibility);

	return {
		method: isNewComment ? 'POST' : 'PUT',
		body: JSON.stringify({
			body: bodyAdf,
			...commentProperties,
			...visibilityOption,
			eventTime: eventOccurredAt,
			hidden: jsdIncidentActivityViewHidden,
			...(parentId ? { parentId } : {}),
		}),
	};
};

const saveComment = (
	store: MiddlewareAPI<State>,
	{
		id, // commentId, or optimisticCommentId if isNewComment
		bodyAdf,
		isNewComment,
		analyticsEvent,
		visibility,
		commentSessionId,
		jsdIncidentActivityViewHidden,
		eventOccurredAt,
		fullIssueUrl,
		triggerIncidentSaveCommentFlag,
		editSessionContainsAttachmentsUsage,
		parentId,
	}: SaveCommentPropsType,
	{ shouldFetchViewContext }: { shouldFetchViewContext: boolean },
) => {
	if (isNewComment && editSessionContainsAttachmentsUsage) {
		createCommentWithAttachmentsExposure.start();
	}

	return fetchJson$(
		getSaveUrl(store, id, isNewComment),
		getFetchOptions(
			store,
			id,
			bodyAdf,
			isNewComment,
			visibility,
			eventOccurredAt,
			jsdIncidentActivityViewHidden,
			parentId,
		),
	)
		.map(transformComment)
		.map(normalizeComment)
		.map((comment: Comment) => {
			sendExperienceAnalytics({
				getExperienceDescription: () =>
					commentExperienceDescription({
						wasSuccessful: true,
						action: isNewComment ? 'ADD' : 'EDIT',
						analyticsSource: 'commentSaveEpic',
						projectType: store.getState().entities.project?.projectType,
						isRootComment: !parentId,
						hasReplies:
							(store.getState().entities.comments?.[id]?.childCommentIds ?? new Set()).size > 0,
						isEventOccurredAtSelected: Boolean(eventOccurredAt),
					}),
			});

			if (isNewComment && editSessionContainsAttachmentsUsage) {
				createCommentWithAttachmentsExposure.success();
			}

			return saveCommentSuccess(
				comment,
				id,
				isNewComment,
				analyticsEvent,
				commentSessionId,
				{
					shouldFetchViewContext,
				},
				eventOccurredAt,
				jsdIncidentActivityViewHidden,
				fullIssueUrl,
				triggerIncidentSaveCommentFlag,
				parentId || undefined,
			);
		})
		.catch((error) => {
			sendExperienceAnalytics({
				getExperienceDescription: ({ wasSuccessful, statusCode }) => {
					if (!wasSuccessful) {
						trackOrLogClientError('issue.save.comment', 'Failed to save a comment', error);
					}
					return commentExperienceDescription({
						wasSuccessful,
						action: isNewComment ? 'ADD' : 'EDIT',
						analyticsSource: 'commentSaveEpic',
						projectType: store.getState().entities.project?.projectType,
						statusCode,
						isRootComment: !parentId,
						hasReplies:
							(store.getState().entities.comments?.[id]?.childCommentIds ?? new Set()).size > 0,
						isEventOccurredAtSelected: Boolean(eventOccurredAt),
						errorMessage: error?.message || 'UNKNOWN_ERROR',
						traceId: extractTraceId(error),
					});
				},
				error,
			});

			if (isNewComment && editSessionContainsAttachmentsUsage) {
				createCommentWithAttachmentsExposure.failure();
			}

			return Observable.of(
				saveCommentFailure({
					optimisticId: id,
					isServerValidationError: error ? error.statusCode === BAD_REQUEST : false,
					invalidMessage: extractFetchErrorMessage(error),
				}),
			);
		});
};

const saveCommentAndUpdateIsCommentSupportedEpic = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(SAVE_COMMENT_REQUEST)
		.groupBy((action) => action.payload.comment.id)
		.mergeMap((saveCommentRequest$) =>
			saveCommentRequest$.concatMap((saveCommentRequestAction) => {
				const {
					id,
					bodyAdf,
					isNewComment,
					visibility,
					eventOccurredAt,
					jsdIncidentActivityViewHidden,
					fullIssueUrl,
					triggerIncidentSaveCommentFlag,
					editSessionContainsAttachmentsUsage,
					parentId,
				} = saveCommentRequestAction.payload.comment;

				const { analyticsEvent, commentSessionId } = saveCommentRequestAction.payload;

				if (!bodyAdf || isOnlyWhitespaceAdf(bodyAdf)) {
					return Observable.empty<never>();
				}
				let shouldFetchViewContext = false;
				if (isNewComment) {
					shouldFetchViewContext = hasMediaFileNodes(bodyAdf);
				} else {
					const comment = entitiesCommentsSelector(store.getState());
					shouldFetchViewContext = hasMediaFileNodesUpdated(comment[id].bodyAdf, bodyAdf);
				}

				if (shouldFetchViewContext) {
					// @ts-expect-error - Argument of type '{ type: "MARK_TOKEN_AS_OUTDATED"; }' is not assignable to parameter of type 'Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: MyPreferences | undefined; }>; ... 5 more ...; validators: Validators; }>'.
					store.dispatch(markTokenAsOutdated());
				}

				const jsmTimelineComponents = {
					eventOccurredAt,
					jsdIncidentActivityViewHidden,
					fullIssueUrl,
					triggerIncidentSaveCommentFlag,
				};

				return saveComment(
					store,
					{
						id, // commentId, or optimisticCommentId if isNewComment or reply comment
						bodyAdf,
						isNewComment,
						analyticsEvent,
						visibility,
						commentSessionId,
						...jsmTimelineComponents,
						editSessionContainsAttachmentsUsage,
						...(parentId
							? {
									parentId,
								}
							: {}),
					},
					{ shouldFetchViewContext },
					// @ts-expect-error - TS2345 - Argument of type '(saveCommentResultAction: SaveCommentSuccessAction | SaveCommentFailureActionType) => Observable<SaveCommentFailureActionType> | Observable<...>' is not assignable to parameter of type '(value: SaveCommentSuccessAction | SaveCommentFailureActionType, index: number) => ObservableInput<SaveCommentFailureActionType>'.
				).mergeMap((saveCommentResultAction) => {
					if (saveCommentResultAction.type === SAVE_COMMENT_FAILURE) {
						return Observable.of(saveCommentResultAction);
					}

					if (isNewComment) {
						const state = store.getState();

						let shouldFetchSavedComment;
						let bypassLoader = false;
						if (fg('jira_comments_agg_pagination')) {
							if (parentId) {
								const childCommentsPageInfo = childCommentsPageInfoSelector(parentId)(state);
								shouldFetchSavedComment = childCommentsPageInfo?.hasPreviousPage;
								if (shouldFetchSavedComment) {
									bypassLoader = true;
								}
							} else {
								const pageInfo = commentsPageInfoSelector(state);
								shouldFetchSavedComment = pageInfo?.hasPreviousPage;
							}
						} else {
							const totalComments = totalCommentsSelector(state);
							const loadedComments = loadedCommentsSelector(state);
							const commentsStartIndex = startIndexCommentsSelector(state);

							const { numPrevCommentsToLoad } = calculateNumberOfCommentsToLoad(
								totalComments,
								loadedComments,
								commentsStartIndex,
							);

							shouldFetchSavedComment = numPrevCommentsToLoad > 0;
						}

						return shouldFetchSavedComment
							? Observable.zip(
									Observable.of(saveCommentResultAction),
									Observable.of(
										fetchSortedCommentsRequest(
											saveCommentResultAction.payload.comment.id,
											bypassLoader,
										),
									),
								).mergeMap((result) => Observable.from(result))
							: Observable.zip(Observable.of(saveCommentResultAction)).mergeMap((result) =>
									Observable.from(result),
								);
					}

					return Observable.zip(Observable.of(saveCommentResultAction)).mergeMap((result) =>
						Observable.from(result),
					);
				});
			}),
		);

export default combineEpics(retrySaveComment, saveCommentAndUpdateIsCommentSupportedEpic);
