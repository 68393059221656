import React, {
	useEffect,
	useCallback,
	useRef,
	type ReactNode,
	useMemo,
	useState,
	Fragment,
} from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import AKTooltip from '@atlaskit/tooltip';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useShouldBeCompact } from '@atlassian/jira-issue-hooks/src/services/use-should-be-compact/index.tsx';
import { QUICK_ADDS } from '@atlassian/jira-issue-view-common-constants/src/onboarding-constants.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	ADD_ISSUE_LINKS_ID,
	ADD_CONTENT_PANEL,
	SELECT_CONTENT_PANEL,
	CREATE_SUBTASK_ID,
	CREATE_ISSUE_IN_EPIC_ID,
	CREATE_CHILD_ID,
	LINK_CONF_PAGE_ID,
} from '@atlassian/jira-issue-view-common-constants/src/quick-add-constants.tsx';
import { ISSUE_FIELD_SPOTLIGHT_PREFIX } from '@atlassian/jira-issue-view-common-constants/src/view-constants.tsx';
import ContentPanelQuickAdd from '@atlassian/jira-issue-view-ecosystem/src/ecosystem-content-panel-quickadd-view.tsx';
import { useLinkAlertsModalActions } from '@atlassian/jira-linked-alerts/src/services/main.tsx';
import { useAiRelatedIssuesEntryPointActions } from '@atlassian/jira-ai-related-issues/src/controllers/context-provider.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useGlobalRefStoreActions } from '@atlassian/jira-refs-store/src/index.tsx';
import { PREMIUM_EDITION, UNLICENSED } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { UNSAFE_noExposureExp, expVal } from '@atlassian/jira-feature-experiments';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { useIsAiEnabledForIssue } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useAIOpsLinkIncidentSuggestionsEntryPointContext } from '@atlassian/jira-servicedesk-aiops-link-incident-suggestions/src/controllers/entrypoint-context-provider.tsx';
import { useAIOpsLinkConfluenceSuggestionsEntryPointContext } from '@atlassian/jira-servicedesk-aiops-link-confluence-suggestions/src/controllers/entrypoint-context-provider.tsx';
import {
	JiraIssueRelatedIssuesContainer,
	useRelatedIssuesActions,
	type InvokedFrom,
} from '@atlassian/jira-ai-related-issues/src/controllers/context.tsx';
import { INCIDENTS } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils/src/utils/component-with-condition/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { QUICK_ADD_ITEMS_ADD_BUTTON_REF_KEY } from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import { useRelatedConfluencePagesTriggerRef } from '@atlassian/jira-ai-related-resources/src/controllers/entrypoint-params-store.tsx';
import { useRegisterQuickAddInCommandPalette } from '../command-palette/index.tsx';
import { CreateConfluenceContentMenu } from '../create-confluence-content-menu/index.tsx';
import DropdownListItem from '../link-button/ui/dropdown-list-item.tsx';
import LinkButton from '../link-button/view.tsx';
import QuickAddItemView from '../quick-add-item/view.tsx';
import QuickAddItemsCompact from '../quick-add-items-compact/index.tsx';
import type { QuickAddItemAllProps, QuickAddItemToRender } from '../types.tsx';
import { useQuickActionTimingMetrics } from '../timing-metrics/context.tsx';
import getItemListCreator from './item-list/create-item-list.tsx';
import getItemList, { getItemListForAIEnabledIncidents } from './item-list/index.tsx';
import getQuickActionItems, { type AttachRefToItems } from './item-list/quick-action-items.tsx';
import messages from './messages.tsx';
import useExternalQuickActionComponents from './use-external-quick-action-components/index.tsx';
import { isMatch } from './item-list/utils.tsx';
import { useQuickActionShortcuts } from './use-quick-action-keyboard-shortcuts/index.tsx';

interface QuickAddActionData {
	actions?: ReactNode[];
	apps?: ReactNode;
	groupCounts?: number[];
}

const QuickAddItemsNew = (
	props: QuickAddItemAllProps & {
		// on proactive-jira-ai-similar-issues cleanup remove this and instead use setInvokedFrom from
		setInvokedFrom: (invokedFrom: InvokedFrom) => void | Promise<void>;
	},
) => {
	// Post cleanup of 'suggested_resource_refactor' move this code to QuickAddItemsOld
	// Don't forget to update 'QuickAddItemsOld' in stability-check.cy-integration.tsx
	const { entryPointActions: linkIssueEntryPointActions } =
		useAIOpsLinkIncidentSuggestionsEntryPointContext();
	const { entryPointActions: linkPageEntryPointActions } =
		useAIOpsLinkConfluenceSuggestionsEntryPointContext();

	const linkIssueTriggerRef = useEntryPointButtonTrigger(linkIssueEntryPointActions);
	const linkPageTriggerRef = useEntryPointButtonTrigger(linkPageEntryPointActions);

	const isAiEnabledForIssue = useIsAiEnabledForIssue();
	const isIncident =
		props.isServiceDesk && Array.isArray(props.practices) && props.practices.includes(INCIDENTS);

	const shouldTriggerAISuggestionOnLink = isAiEnabledForIssue && isIncident;
	const aiSuggestionTriggerRefsOnLinkItems: AttachRefToItems = useMemo(
		() => ({
			[ADD_ISSUE_LINKS_ID]: linkIssueTriggerRef,
			[LINK_CONF_PAGE_ID]: linkPageTriggerRef,
		}),
		[linkIssueTriggerRef, linkPageTriggerRef],
	);

	return (
		<QuickAddItemsOld
			{...props}
			shouldTriggerAISuggestionOnLink={shouldTriggerAISuggestionOnLink}
			aiSuggestionTriggerRefsOnLinkItems={
				shouldTriggerAISuggestionOnLink ? aiSuggestionTriggerRefsOnLinkItems : undefined
			}
		/>
	);
};

const QuickAddItemsOld = (
	props: QuickAddItemAllProps & {
		shouldTriggerAISuggestionOnLink?: boolean;
		aiSuggestionTriggerRefsOnLinkItems?: AttachRefToItems;
		// on proactive-jira-ai-similar-issues cleanup remove this and instead use setInvokedFrom from
		setInvokedFrom: (invokedFrom: InvokedFrom) => void | Promise<void>;
	},
) => {
	const { formatMessage } = useIntl();
	const [, { register }] = useGlobalRefStoreActions();
	const {
		onAddAttachmentClick,
		onAddWebLinkClick,
		onLinkConfluencePageClick,
		onLinkConfluencePageWithAISuggestionClick,
		fetchConfluenceAppLinks,
		triggerButtonClickAnalytics,
		onSelectContentPanel,
		isServiceDesk,
		isAnonymousUser,
		canCreateAttachment,
		canAddWebLinks,
		canLinkConfluencePage,
		onAddExtension,
		issueKey,
		containerWidth,
		onInsertLoomLinkClick,
		onLinkPage,
		childCount,
		rootRelayFragment,
		onChildCreateClick,
		onAddIssueLinkClick,
		onAddIssueLinkWithAISuggestionClick,
		shouldShowCreateSubtaskButton,
		supportsChildCreation,
		shouldShowIssueInEpicButton,
		onCreateIssueInEpicClick,
		onCreateSubtaskClick,
		canCreateIssueLink,
		setInvokedFrom,
	} = props;

	const { shouldBeCompact, setMeasuredRef, forceUpdate } = useShouldBeCompact(containerWidth);
	const { measureTimeOnClick } = useQuickActionTimingMetrics();
	const { keymap: quickActionKeymap } = useQuickActionShortcuts({
		onChildCreateClick,
		onCreateIssueInEpicClick,
		onCreateSubtaskClick,
		onAddIssueLinkClick,
		shouldShowCreateSubtaskButton,
		supportsChildCreation,
		shouldShowIssueInEpicButton,
		canCreateIssueLink,
	});
	const [, { openLinkAlertsModal: onLinkAlertsClick }] = useLinkAlertsModalActions();

	/**
	 * Similar Issues required variables to trigger the entry point
	 */
	const entryPointActions = useAiRelatedIssuesEntryPointActions();
	const relatedIssuesEntrypointRef = useEntryPointButtonTrigger(entryPointActions, true);
	const relatedResourcesEntrypointRef = useRelatedConfluencePagesTriggerRef();

	const createSubtaskRef = useRef<HTMLElement>(null);
	const linkIssueRef = useRef<HTMLButtonElement>(null);

	const addButtonRef = useRef<HTMLButtonElement>(null);

	const projectKey = useProjectKey(issueKey);

	const tenantContext = useTenantContext();
	const hasPremiumServiceDeskAccess = tenantContext.appEditions.serviceDesk === PREMIUM_EDITION;

	const appEditions = useAppEditions();
	const hasProductDiscoveryAccess = appEditions.productDiscovery !== UNLICENSED;

	const [searchQuery, setSearchQuery] = useState('');
	const ADD_BUTTON_MENU = 'addButton';

	/**
	 * We need to ensure the experiment is not sending an exposure here, since it isn't where the experience diverges
	 */
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [expConfig] = UNSAFE_noExposureExp('proactive-jira-ai-similar-issues');
	const isProactiveJiraAiSimilarIssuesEnabled = expConfig.get('isEnabled', false);

	let isQuickActionsListView = false;
	if (fg('quick_actions_menu_ga')) {
		/**
		 * Changing file level eslint disable to line level disables for rules of hooks
		 * as part of PIR-21595. To be cleaned up when above feature gates are removed.
		 */
		/* eslint-disable-next-line react-hooks/rules-of-hooks */
		const projectType = useProjectType(projectKey);

		const isJSMProject = projectType === 'service_desk';
		const isJcsProject = projectType === 'customer_service';

		isQuickActionsListView =
			!isJSMProject &&
			(!isJcsProject || !fg('jcs_developer_escalations')) &&
			fg('quick_actions_menu_ga');
	}

	const [openMenu, setOpenMenu] = useState<string | null>(null);

	useEffect(() => {
		fetchConfluenceAppLinks();
	}, [fetchConfluenceAppLinks, issueKey]);

	useEffect(() => {
		register('quick-add-items.create-subtask', createSubtaskRef.current);
		register(QUICK_ADD_ITEMS_ADD_BUTTON_REF_KEY, addButtonRef.current);
		register('quick-add-items.link-issue', linkIssueRef.current);
	}, [register, addButtonRef, createSubtaskRef, linkIssueRef]);

	const sendAnalyticsEvent = useCallback(
		(id: string, itemIndex: number, handleFunc: () => void) => () => {
			handleFunc();
			if (typeof triggerButtonClickAnalytics === 'function') {
				triggerButtonClickAnalytics(id, itemIndex, 4);
			}
			if (fg('quick_actions_click_timing_metrics')) {
				measureTimeOnClick(id);
			}
		},
		[measureTimeOnClick, triggerButtonClickAnalytics],
	);

	// when proactive-jira-ai-similar-issues is cleaned up, we can use setInvokedFrom directly from useRelatedIssuesActions();
	const handleAddIssueLinkWithAISuggestionClick = useCallback(() => {
		onAddIssueLinkWithAISuggestionClick?.();
		if (isProactiveJiraAiSimilarIssuesEnabled) {
			setInvokedFrom('linkedWorkItemQuickAdd');
		}
	}, [onAddIssueLinkWithAISuggestionClick, setInvokedFrom, isProactiveJiraAiSimilarIssuesEnabled]);

	// when proactive-jira-ai-similar-issues is cleaned up, we can use setInvokedFrom directly from useRelatedIssuesActions();
	const handleAddIssueLinkClick = useCallback(() => {
		onAddIssueLinkClick?.();
		if (isProactiveJiraAiSimilarIssuesEnabled) {
			setInvokedFrom('linkedWorkItemQuickAdd');
		}
	}, [onAddIssueLinkClick, setInvokedFrom, isProactiveJiraAiSimilarIssuesEnabled]);

	const onAddChildCommand = useMemo(
		() =>
			getItemList({
				...props,
				formatMessage,
				onLinkAlertsClick,
				hasPremiumServiceDeskAccess,
				hasProductDiscoveryAccess,
			}).issueActions.find(({ id }) =>
				[CREATE_SUBTASK_ID, CREATE_ISSUE_IN_EPIC_ID, CREATE_CHILD_ID].includes(id),
			)?.onClick,
		[
			formatMessage,
			onLinkAlertsClick,
			props,
			hasPremiumServiceDeskAccess,
			hasProductDiscoveryAccess,
		],
	);

	const shouldRenderAttachCommand = !isServiceDesk && canCreateAttachment;
	useRegisterQuickAddInCommandPalette({
		onAddAttachmentClick,
		onAddAUrlClick: onAddWebLinkClick,
		onLinkConfluencePageClick,
		onAddChildCommand: onAddChildCommand ?? noop,
		isAttachCommandEnabled: shouldRenderAttachCommand,
		isAddAUrlEnabled: canAddWebLinks,
		isLinkConfluencePageEnabled: canLinkConfluencePage,
		isAddChildEnabled: onAddChildCommand !== undefined,
	});

	const createItemViewInline = useCallback(
		(itemToRender: QuickAddItemToRender) => {
			const {
				id,
				tooltip,
				label,
				icon,
				onClick,
				itemIndex,
				testId,
				hasGlobalSpotlightTarget,
				hasSpotlightPulse,
				noWrapper,
				ref,
				rightElement,
			} = itemToRender;

			const quickAddItemViewComponent = (
				<QuickAddItemView
					key={id}
					id={id}
					icon={icon}
					onClick={sendAnalyticsEvent(id, itemIndex, onClick)}
					label={label}
					compactMode={shouldBeCompact}
					isQuickActionsListView={isQuickActionsListView}
					testId={testId}
					hasGlobalSpotlightTarget={hasGlobalSpotlightTarget}
					hasSpotlightPulse={hasSpotlightPulse}
					noWrapper={noWrapper}
					ref={fg('jcs_developer_escalations') ? ref : undefined}
					{...(id === CREATE_SUBTASK_ID && { ref: createSubtaskRef })}
					childCount={childCount}
					rightElement={rightElement}
				/>
			);
			if (isQuickActionsListView) {
				return quickAddItemViewComponent;
			}
			return (
				<AKTooltip content={tooltip} position="bottom" key={id}>
					{quickAddItemViewComponent}
				</AKTooltip>
			);
		},
		[sendAnalyticsEvent, shouldBeCompact, createSubtaskRef, isQuickActionsListView, childCount],
	);

	const createItemViewSegregated = useCallback(
		(itemsToRender: QuickAddItemToRender) => {
			const generatedCallback = getItemListCreator({
				isQuickActionsListView,
				sendAnalyticsEvent,
				shouldBeCompact,
				actionRefs: [{ actionId: CREATE_SUBTASK_ID, ref: createSubtaskRef }],
				closeMenu: () => setOpenMenu(null),
				searchQuery,
			});
			return generatedCallback(itemsToRender);
		},
		[isQuickActionsListView, sendAnalyticsEvent, shouldBeCompact, createSubtaskRef, searchQuery],
	);

	const createItemView = expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
		? createItemViewSegregated
		: createItemViewInline;

	const { issueActions, linkingActions, ideaActions } = useMemo(() => {
		if (
			props.shouldTriggerAISuggestionOnLink &&
			props.aiSuggestionTriggerRefsOnLinkItems &&
			props.onAddIssueLinkWithAISuggestionClick &&
			props.onLinkConfluencePageWithAISuggestionClick &&
			expVal('suggested_incident_resources_v2', 'isEnabled', false)
		) {
			return getItemListForAIEnabledIncidents({
				...props,
				formatMessage,
				onLinkAlertsClick,
				isQuickActionsListView,
				hasPremiumServiceDeskAccess,
				hasProductDiscoveryAccess,
				onAddIssueLinkWithAISuggestionClick: handleAddIssueLinkWithAISuggestionClick,
				onLinkConfluencePageWithAISuggestionClick: props.onLinkConfluencePageWithAISuggestionClick,
				attachRefToItems: props.aiSuggestionTriggerRefsOnLinkItems,
			});
		}
		const attachRefToItems: AttachRefToItems = {
			...(isProactiveJiraAiSimilarIssuesEnabled
				? { [ADD_ISSUE_LINKS_ID]: relatedIssuesEntrypointRef }
				: {}),
			...(expVal('jira_suggest_confluence_pages_when_linking_a_page', 'isEnabled', false)
				? { [LINK_CONF_PAGE_ID]: relatedResourcesEntrypointRef }
				: {}),
		};

		return getItemList({
			...props,
			formatMessage,
			onLinkAlertsClick,
			isQuickActionsListView,
			hasPremiumServiceDeskAccess,
			hasProductDiscoveryAccess,
			attachRefToItems,
			onAddIssueLinkClick: handleAddIssueLinkClick,
			onLinkConfluencePageClick: expVal(
				'jira_suggest_confluence_pages_when_linking_a_page',
				'isEnabled',
				false,
			)
				? onLinkConfluencePageWithAISuggestionClick || onLinkConfluencePageClick
				: onLinkConfluencePageClick,
		});
	}, [
		formatMessage,
		hasPremiumServiceDeskAccess,
		hasProductDiscoveryAccess,
		isQuickActionsListView,
		onLinkAlertsClick,
		onLinkConfluencePageClick,
		handleAddIssueLinkClick,
		handleAddIssueLinkWithAISuggestionClick,
		onLinkConfluencePageWithAISuggestionClick,
		props,
		relatedIssuesEntrypointRef,
		relatedResourcesEntrypointRef,
		isProactiveJiraAiSimilarIssuesEnabled,
	]);

	const ideaButtons = useMemo(
		() => ideaActions.filter((item) => isMatch(searchQuery, item.label)).map(createItemView),
		[createItemView, ideaActions, searchQuery],
	);

	const mappedIssueActions = useMemo(
		() => issueActions.map(createItemView),
		[createItemView, issueActions],
	);

	const linkDropdownButtonCompactLabelMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.linkDropdownButtonCompactLabelIssueTermRefresh
		: messages.linkDropdownButtonCompactLabel;

	const dropdownMessage = shouldBeCompact
		? linkDropdownButtonCompactLabelMessage
		: messages.linkDropdownButtonLabel;
	const dropdownTooltipLabel = formatMessage(dropdownMessage);

	const linkButton = useMemo(
		() => (
			<UFOSegment name="quick-add-link-button">
				<JSErrorBoundary
					id="quick-add-link-button"
					packageName="jiraQuickActions"
					teamName="bento"
					fallback="unmount"
				>
					<LinkButton
						compactMode={shouldBeCompact}
						actions={linkingActions}
						tooltipLabel={dropdownTooltipLabel}
						ref={linkIssueRef}
						isQuickActionsListView={isQuickActionsListView}
					/>
				</JSErrorBoundary>
			</UFOSegment>
		),
		[dropdownTooltipLabel, isQuickActionsListView, linkingActions, shouldBeCompact],
	);

	const linkButtonActions = useMemo(
		() =>
			isQuickActionsListView
				? [
						fg('concurrent-rendering-fix-unique-list-key') ? (
							<Fragment key="quick-add-link-button">{linkButton}</Fragment>
						) : (
							linkButton
						),
					]
				: [
						<SpotlightTarget
							key="quick-add-web-link-button"
							name={`${ISSUE_FIELD_SPOTLIGHT_PREFIX}${ADD_ISSUE_LINKS_ID}`}
						>
							{linkButton}
						</SpotlightTarget>,
					],
		[isQuickActionsListView, linkButton],
	);

	const ecosystemActions = useMemo(
		() => (
			<ContentPanelQuickAdd
				key="quick-add-content-panel"
				externalId="issue.ecosystem.quick-add-content-panel"
				// @ts-expect-error - TS7006 - Parameter 'addonModuleKey' implicitly has an 'any' type.
				onSelectContentPanel={(addonModuleKey) => {
					onSelectContentPanel(addonModuleKey);
					triggerButtonClickAnalytics(SELECT_CONTENT_PANEL, 3, 4);
				}}
				// @ts-expect-error - TS7006 - Parameter 'extension' implicitly has an 'any' type.
				onAddExtension={(extension) => {
					onAddExtension(extension);
					triggerButtonClickAnalytics(ADD_CONTENT_PANEL, 3, 4);
				}}
				createItemView={createItemView}
				startIndex={mappedIssueActions.length}
				isQuickActionsListView={isQuickActionsListView}
			/>
		),
		[
			createItemView,
			isQuickActionsListView,
			mappedIssueActions.length,
			onAddExtension,
			onSelectContentPanel,
			triggerButtonClickAnalytics,
		],
	);

	const ACTIONS_NATIVE = useMemo(
		() =>
			isQuickActionsListView &&
			expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
				? getQuickActionItems({
						...props,
						formatMessage,
						onLinkAlertsClick,
						isQuickActionsListView,
						hasPremiumServiceDeskAccess,
						hasProductDiscoveryAccess,
					})
				: {},
		[
			formatMessage,
			hasPremiumServiceDeskAccess,
			hasProductDiscoveryAccess,
			isQuickActionsListView,
			onLinkAlertsClick,
			props,
		],
	);

	const {
		components: [
			designActions,
			formsActions,
			developerEscalationsAction,
			changeManagementActions,
			reviewButton,
			suggestResourcesAction,
			asyncConfluenceContentMenu,
			loomVideoButtons,
			confluenceButtons,
		],
		actions: { setQuickActionListItem },
		crossflow,
	} = useExternalQuickActionComponents({
		isAnonymousUser,
		onInsertLoomLinkClick,
		shouldBeCompact,
		forceUpdate,
		projectKey,
		issueKey,
		isQuickActionsListView,
		canCreateIssueLink: props.canCreateIssueLink,
		onAddIssueLinkClick: props.onAddIssueLinkClick,
		createItemView,
		onLinkPage,
		searchQuery,
		closeMenu: expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
			? () => setOpenMenu(null)
			: undefined,
		staticComponents: {
			confluenceLiveDoc:
				ACTIONS_NATIVE.linkConfluenceLiveDoc?.shouldRender &&
				isMatch(searchQuery, ACTIONS_NATIVE.linkConfluenceLiveDoc.searchLabel)
					? createItemView(ACTIONS_NATIVE.linkConfluenceLiveDoc)
					: null,
			confluencePage:
				ACTIONS_NATIVE.linkConfluencePage?.shouldRender &&
				isMatch(searchQuery, ACTIONS_NATIVE.linkConfluencePage.searchLabel)
					? createItemView(ACTIONS_NATIVE.linkConfluencePage)
					: null,
			confluenceWhiteboard:
				ACTIONS_NATIVE.linkConfluenceWhiteboard?.shouldRender &&
				isMatch(searchQuery, ACTIONS_NATIVE.linkConfluenceWhiteboard.searchLabel)
					? createItemView(ACTIONS_NATIVE.linkConfluenceWhiteboard)
					: null,
		},
	});

	const coreActions = useMemo(() => {
		if (
			!isQuickActionsListView ||
			!expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
		) {
			return [];
		}

		return [
			ACTIONS_NATIVE.subtask,
			ACTIONS_NATIVE.issueInEpic,
			ACTIONS_NATIVE.createChild,
			ACTIONS_NATIVE.addIssueLinks,
		];
	}, [ACTIONS_NATIVE, isQuickActionsListView]);

	const coreIssueViewJTBDCount = coreActions.filter((item) =>
		isMatch(searchQuery, item.label),
	).length;

	const coreIssueViewJTBD = useMemo(() => {
		return coreActions.filter((item) => item.shouldRender).map((item) => createItemView(item));
	}, [coreActions, createItemView]);

	const secondaryActions = useMemo(() => {
		if (
			!isQuickActionsListView ||
			!expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
		) {
			return [];
		}

		return [
			...[ACTIONS_NATIVE.attachment]
				.filter((item) => item.shouldRender)
				.map((item) => ({
					component: createItemView(item),
					label: item.label,
				})),
			...formsActions,
			...[ACTIONS_NATIVE.addWebLink]
				.filter((item) => item.shouldRender)
				.map((item) => ({
					component: (
						<DropdownListItem
							key={item.id}
							item={{
								...item,
								isHidden: !isMatch(searchQuery, item.label),
							}}
						/>
					),
					label: item.label,
				})),
			...designActions,
		];
	}, [
		ACTIONS_NATIVE,
		createItemView,
		designActions,
		formsActions,
		isQuickActionsListView,
		searchQuery,
	]);

	const secondaryIssueViewJTBDCount = secondaryActions.filter((item) =>
		isMatch(searchQuery, item.label),
	).length;

	const secondaryIssueViewJTBD = useMemo(() => {
		return secondaryActions.map(({ component }) => component);
	}, [secondaryActions]);

	const externalActions = useMemo(() => {
		if (
			!isQuickActionsListView ||
			!expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
		) {
			return [];
		}

		return [
			...[ACTIONS_NATIVE.linkConfluenceListView]
				.filter((item) => item.shouldRender)
				.map((item) => ({
					component: (
						<DropdownListItem
							key={item.id}
							item={{
								...item,
								isHidden: !isMatch(searchQuery, item.label),
							}}
						/>
					),
					label: item.label,
				})),
			...confluenceButtons,
			...loomVideoButtons,
			...ideaActions.map((item) => ({
				component: createItemView(item),
				label: item.label,
			})),
		];
	}, [
		ACTIONS_NATIVE,
		confluenceButtons,
		createItemView,
		ideaActions,
		isQuickActionsListView,
		loomVideoButtons,
		searchQuery,
	]);

	const external2PQuickActionsCount = externalActions.filter((item) =>
		isMatch(searchQuery, item.label),
	).length;

	const external2PQuickActions = useMemo(() => {
		return externalActions.map(({ component }) => component);
	}, [externalActions]);

	const getQuickAddActionData = useCallback((): QuickAddActionData | ReactNode[] => {
		if (
			isQuickActionsListView &&
			expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
		) {
			return {
				actions: [coreIssueViewJTBD, secondaryIssueViewJTBD, external2PQuickActions],
				apps: ecosystemActions,
				groupCounts: [
					coreIssueViewJTBDCount,
					secondaryIssueViewJTBDCount,
					external2PQuickActionsCount,
				],
			};
		}

		const formsActionsComponents = formsActions.map(({ component }) => component);
		const confluenceButtonComponents = confluenceButtons.map(({ component }) => component);
		const loomVideoButtonsComponents = loomVideoButtons.map(({ component }) => component);
		const designActionComponents = designActions.map(({ component }) => component);
		const suggestResourcesActionComponents = suggestResourcesAction.map(
			({ component }) => component,
		);
		const changeManagementActionComponents = changeManagementActions.map(
			({ component }) => component,
		);
		const reviewButtonComponents = reviewButton.map(({ component }) => component);
		const developerEscalationsActionComponents = developerEscalationsAction.map(
			({ component }) => component,
		);
		const asyncConfluenceContentMenuComponents = asyncConfluenceContentMenu.map(
			({ component }) => component,
		);

		if (isQuickActionsListView) {
			return {
				actions: [
					// no JSM related actions in this list as quick
					// actions dropdown will not be enabled for JSM
					...mappedIssueActions,
					...linkButtonActions,
					...confluenceButtonComponents,
					...ideaButtons,
					...loomVideoButtonsComponents,
					...formsActionsComponents,
					...designActionComponents,
				],
				apps: ecosystemActions,
			};
		}

		return [
			...mappedIssueActions,
			...suggestResourcesActionComponents,
			linkButtonActions,
			...ideaButtons,
			...changeManagementActionComponents,
			...formsActionsComponents,
			...reviewButtonComponents,
			...developerEscalationsActionComponents,
			...designActionComponents,
			...asyncConfluenceContentMenuComponents,
			ecosystemActions,
		];
	}, [
		isQuickActionsListView,
		formsActions,
		confluenceButtons,
		loomVideoButtons,
		designActions,
		suggestResourcesAction,
		changeManagementActions,
		reviewButton,
		developerEscalationsAction,
		asyncConfluenceContentMenu,
		mappedIssueActions,
		linkButtonActions,
		ideaButtons,
		ecosystemActions,
		coreIssueViewJTBD,
		secondaryIssueViewJTBD,
		external2PQuickActions,
		coreIssueViewJTBDCount,
		secondaryIssueViewJTBDCount,
		external2PQuickActionsCount,
	]);

	const quickAddActionDataResult = fg('react-concurrent-quick-actions-fix')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(() => getQuickAddActionData(), [getQuickAddActionData])
		: null;

	if (isQuickActionsListView) {
		const quickAddActionData =
			quickAddActionDataResult !== null && fg('react-concurrent-quick-actions-fix')
				? quickAddActionDataResult
				: getQuickAddActionData();

		if ('actions' in quickAddActionData && 'apps' in quickAddActionData) {
			const { actions, apps, groupCounts } = quickAddActionData;
			const setMenuOpen = (menuName: string, isOpen: boolean) => {
				setOpenMenu(isOpen ? menuName : null);
				if (menuName === ADD_BUTTON_MENU) {
					setSearchQuery('');
				}
			};

			const { availableAddons, canInstallAddons } = props;

			const renderActions = () => {
				return (
					<>
						{actions && actions.length ? (
							<QuickAddWrapper ref={setMeasuredRef('quick-add-items')}>
								{expVal('quick_actions_m3_experiment', 'hotKeysEnabled', false) ? (
									<Shortcuts keyMap={quickActionKeymap} />
								) : null}
								<QuickAddItemsCompact
									actions={actions}
									apps={apps}
									crossflow={crossflow}
									availableAddons={availableAddons}
									canInstallAddons={canInstallAddons}
									setMenuOpen={setMenuOpen}
									openMenu={openMenu}
									onSearch={setSearchQuery}
									searchQuery={searchQuery}
									groupCounts={groupCounts}
									addButtonRef={addButtonRef}
									rootRelayFragment={rootRelayFragment}
								/>
								<CreateConfluenceContentMenu
									key="create-confluence-content-menu"
									appearance={shouldBeCompact ? 'compact' : ''}
									isQuickActionsListView={isQuickActionsListView}
									setQuickActionListItem={setQuickActionListItem}
									onLinkPage={onLinkPage}
									openMenu={openMenu}
								/>
							</QuickAddWrapper>
						) : (
							<></>
						)}
					</>
				);
			};

			return (
				<UFOSegment name="issue-quick-add-items" mode="list">
					<ErrorBoundary id="jira.issuebase.quickadditems">
						{fg('quick_actions_menu_ga') ? (
							<>{renderActions()}</>
						) : (
							<SpotlightTarget name={QUICK_ADDS}>{renderActions()}</SpotlightTarget>
						)}
					</ErrorBoundary>
				</UFOSegment>
			);
		}
	}

	const actions =
		quickAddActionDataResult !== null && fg('react-concurrent-quick-actions-fix')
			? quickAddActionDataResult
			: getQuickAddActionData();

	return (
		<UFOSegment name="issue-quick-add-items">
			<ErrorBoundary id="jira.issuebase.quickadditems">
				<SpotlightTarget name={QUICK_ADDS}>
					{Array.isArray(actions) && actions.length ? (
						<QuickAddWrapper ref={setMeasuredRef('quick-add-items')}>{actions}</QuickAddWrapper>
					) : (
						<div />
					)}
				</SpotlightTarget>
			</ErrorBoundary>
		</UFOSegment>
	);
};

export const QuickAddItemsWithFG = componentWithFG(
	'suggested_resource_refactor',
	QuickAddItemsNew,
	QuickAddItemsOld,
);

/**
 * Since we can't conditionally use hooks anymore, we need to drill the setInvokedFrom prop down to the QuickAddItems component
 */
const QuickAddItemsPropDrillingInvokedFrom = (props: QuickAddItemAllProps) => {
	const { setInvokedFrom } = useRelatedIssuesActions();
	return <QuickAddItemsWithFG {...props} setInvokedFrom={setInvokedFrom} />;
};

const QuickAddItemsWithRelatedIssueWrapper = (props: QuickAddItemAllProps) => {
	return (
		<JiraIssueRelatedIssuesContainer scope={`related-issues-${props.issueKey}`}>
			<QuickAddItemsPropDrillingInvokedFrom {...props} />
		</JiraIssueRelatedIssuesContainer>
	);
};

export const QuickAddItems = componentWithCondition(
	() => {
		/**
		 * We need to ensure the experiment is not sending an exposure here, since it isn't where the experience diverges
		 */
		// eslint-disable-next-line jira/ff/unsafe-no-exposure
		const [expConfig] = UNSAFE_noExposureExp('proactive-jira-ai-similar-issues');
		const isProactiveJiraAiSimilarIssuesEnabled = expConfig.get('isEnabled', false);
		return isProactiveJiraAiSimilarIssuesEnabled;
	},
	QuickAddItemsWithRelatedIssueWrapper,
	QuickAddItemsWithFG,
);

QuickAddItems.defaultProps = {
	canCreateIssueLink: false,
	canCreateAttachment: false,
	canLinkConfluencePage: false,
	canAddWebLinks: false,
	triggerButtonClickAnalytics: noop,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const QuickAddWrapper = styled.div({
	marginBottom: token('space.050'),
	marginTop: token('space.050'),
	paddingTop: token('space.050'),
	paddingRight: 0,
	paddingBottom: token('space.050'),
	paddingLeft: 0,
	display: 'inline-flex',
});
