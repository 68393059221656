import React, { useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { Props } from '@atlassian/jira-servicedesk-aiops-link-confluence-suggestions/src/ui/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { confluencePageAISuggestionIsOpenSelector } from '@atlassian/jira-issue-view-store/src/selectors/confluence-content-selector.tsx';
import {
	createConfluencePageLinkSuccess,
	linkConfluencePageAISuggestionsClosed,
} from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useAIOpsLinkConfluenceSuggestionsEntryPointContext } from '@atlassian/jira-servicedesk-aiops-link-confluence-suggestions/src/controllers/entrypoint-context-provider.tsx';

const IncidentSuggestionPanel = ({
	suggestionPanelRef,
	linkedPages,
	isPanelVisible = false,
	onHidePanel,
	onLinkConfluenceSuccess,
}: Omit<Props, 'isPanelVisible'> & { isPanelVisible?: boolean }) => {
	const { entryPointReferenceSubject } = useAIOpsLinkConfluenceSuggestionsEntryPointContext();

	const runtimeProps = useMemo(
		() => ({
			isPanelVisible,
			suggestionPanelRef,
			onHidePanel,
			onLinkConfluenceSuccess,
			linkedPages,
		}),
		[isPanelVisible, suggestionPanelRef, onHidePanel, onLinkConfluenceSuccess, linkedPages],
	);

	return (
		<Box xcss={suggestionPanelWrapperStyles}>
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="aiops-link-confluence-suggestion-panel"
				teamName="itsm-aiops-grogu"
				packageName="jiraServicedeskAiopsLinkConfluenceSuggestions"
				fallback={null}
				runtimeProps={runtimeProps}
			/>
		</Box>
	);
};

export const IncidentSuggestionPanelContainer = connect(
	(state: State) => ({
		isPanelVisible: confluencePageAISuggestionIsOpenSelector(state),
	}),
	{
		onHidePanel: linkConfluencePageAISuggestionsClosed,
		onLinkConfluenceSuccess: createConfluencePageLinkSuccess,
	},
)(IncidentSuggestionPanel);

const suggestionPanelWrapperStyles = xcss({
	marginTop: 'space.200',
});
